import React from 'react'

export default function RowComponent(props) {
  return (
    <div className="row align-items-center" style={{ paddingTop: "20px" }}>
        {props.children}
    </div>
  );
}

