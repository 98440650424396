import React, { useState } from 'react'
import './index.css'

export default function Login(props) {

  const handleSubmit = (event) => {
    event.preventDefault();
    const username = document.getElementById('txtUsername').value;
    const password = document.getElementById('txtPassword').value;

    // Check username and password against hardcoded values
    if (username === 'gdv' && password === 'newcore') {
      // Login successful
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('whoami', 'gdv');
      props.setIsLoggedIn(true)
      // history.push('/home');
      window.location.replace('/')
    } else
    if (username === 'qtv' && password === 'newcore') {
      // Login successful
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('whoami', 'qtv');
      props.setIsLoggedIn(true)
      window.location.replace('/')


      // history.push('/home');
    } 
    else {
      // Login failed
      // Display an error message to the user
      console.log('sai mat khau')
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div>
      <div
        className="container-fluid noCopy"
        style={{
          width: "100%",
          height: "100vh",
          // backgroundColor: "red",
          padding: "0",
        }}
      >
        <div
          className="row"
          style={{
            // backgroundColor: 'yellow',
            width: "100%",
            height: "90%",
          }}
        >
          <div
            className="col-8 text-center align-items-center d-flex justify-content-center"
            style={{
              backgroundColor: "#eceff4",

              padding: "0",
            }}
          >
            <div
              className="row"
              style={{
                // backgroundColor: "white",
                height: "80%",
                width: "75%",
                borderRadius: "10px",
                boxShadow:
                  "1px 1px 1px 3px rgba(225,228,232,0.7), 3px 3px 3px 5px rgba(225,228,232,0.5), 5px 5px 5px 8px rgba(225,228,232,0.3) ",
              }}
            >
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
                style={{
                  borderRadius: "10px",
                }}
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={0}
                    className="active"
                  />
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={1}
                  />
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={2}
                  />
                </ol>
                <div className="carousel-inner">
                  <div
                    className="carousel-item active"
                    // style={{
                    //   height:'300px'
                    // }}
                  >
                    <img
                      className="d-block w-100"
                      src="./slideLogin/img1.jpg"
                      alt="First slide"
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src="./slideLogin/img3.jpg"
                      alt="Second slide"
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src="./slideLogin/img5.jpg"
                      alt="Third slide"
                      style={{
                        borderRadius: "10px",
                        width: "759.59px",
                        height: "507.18px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          {/* <div
            className="col-3"
            style={{
              // backgroundColor: "#eceff4",
              // backgroundColor: '#6b6b6b'
              backgroundColor: 'white',
              // borderLeft: 'solid 2px rgba(107,107,107,.5)'
              boxShadow:
                  "5px 0px rgba(225,228,232,0.7), 10px 0px rgba(225,228,232,0.5), 15px 0px rgba(225,228,232,0.3) ",
            }}
          ></div> */}
          <div
            className="col-3"
            style={{
              height: "99.6%",
              backgroundColor: "white",
              boxShadow:
                "-1px -1px 0px 3px rgba(225,228,232,0.7), -3px -3px 0px 5px rgba(225,228,232,0.5), -5px -15px 0px 8px rgba(225,228,232,0.3)",
            }}
          >
            <div className='row'
              style={{
                height:'6%'
              }}
            >

            </div>
            <div
              className="row text-center align-items-center"
              style={{
                height: "30%",
                // backgroundColor: 'yellow',
                width: '100%',
                padding: '0',
                margin: '0',
                display: 'flex',
                    // backgroundColor: 'red',
                    alignItems: 'center',
                    justifyContent: 'center',

                // paddingLeft: "0",
                // backgroundColor: 'red'
              }}
            >
                <img 
                  src="./logo.png"
                  alt="logo"
                  style={{
                    height: '90%',
                    width: 'auto'
                  }}
                />

            </div>
            <div
              className="row align-items-center"
              style={{
                height: "60%",
                paddingLeft: "15%",
                // width: '300px'
                // padding: "10%",

                // backgroundColor: 'gray'
              }}
            >
              <form
                  style={{
                    width: '83%',
                    alignItems: 'center'
                  }}

                  onSubmit={handleSubmit}
              >
                {/* Email input */}
                <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form2Example1"
                    style={{
                      fontWeight: '600'
                    }}
                >
                    Username
                  </label>
                  <input
                    type="text"
                    id="txtUsername"
                    className="form-control"
                  />
                  
                </div>
                {/* Password input */}

                <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form2Example2"
                  style={{
                    fontWeight: '600'
                  }}
                >
                    Password
                  </label>
                  <input
                    type="password"
                    id="txtPassword"
                    className="form-control"
                  />
                  
                </div>
                {/* 2 column grid layout for inline styling */}
                <div className="row mb-4">
                  <div className="col d-flex justify-content-center">
                    {/* Checkbox */}
                    <div className="form-check"
                          
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue
                        id="form2Example31"
                        defaultChecked
                        style={{
                          // WebkitAppearance: "none",
                        
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="form2Example31"
                      >
                        {" "}
                        Remember me{" "}
                      </label>
                    </div>
                  </div>
              
                </div>
                <div
                  className='text-center align-items-center'
                  style={{
                    display: 'flex',
                    // backgroundColor: 'red',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                  
                >
                  {/* Submit button */}
                <button
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  type="submit"
                  // onClick={() => {
                  //   if(document.getElementById('txtUsername').value === 'gdv' && document.getElementById('txtPassword').value == 'newcore') {
                  //     props.setIsAuthen(1);
                  //   } else 
                  //   if(document.getElementById('txtUsername').value === 'qtv' && document.getElementById('txtPassword').value == 'newcore') {
                  //     props.setIsAuthen(2);
                  //   }
                  //   else props.setIsAuthen(0);
                  // }}
                  className="btn btn-primary btn-block mb-4"
                  style= {{
                    width: '100%',
                    backgroundColor: '#d71921',
                    border: 'none',
                    transition: 'background-color 0.3s ease',
                    ...(isHovered && { backgroundColor: '#a64853' }),
                    margin: '0 auto'
                  }}
                >
                  Sign in
                </button>
                {/* Register buttons */}
                </div>
                
              </form>
            </div>
          </div>

          <div
            className="col-1"
            style={{
              backgroundColor: "#eceff4",
            }}
          ></div>
        </div>

        <div
          className="row"
          style={{
            height: "10%",
            width: "100%",
            backgroundColor: "gray",
          }}
        ></div>
      </div>
    </div>
  );
}
