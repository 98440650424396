const TempData = {
    BooleanData: [
        { "id": 0, "name": ''},
        { "id": 1, "name": 'Yes'},
        { "id": 2, "name": 'No'},
    ],
    Gender: [
        { "id": 0, "name": ''},
        { "id": 1, "name": 'Male'},
        { "id": 2, "name": 'Female'},
    ],
    CusTitle: [
        { "id": 0, "name": ''},
        { "id": 1, "name": 'Mr'},
        { "id": 2, "name": 'Ms'},
        { "id": 3, "name": 'Mrs'},
    ],
    CusMaritalStatus: [
        { "id": 0, "name": ''},
        { "id": 1, "name": 'Single'},
        { "id": 2, "name": 'Married'},
        { "id": 3, "name": 'Divorced'},
    ],
    CustomerType: [
        { "id": 0, "name": ''},
        { "id": 1, "name": 'P - Person'},
        { "id": 2, "name": 'C - Corporate'},
    ],
    AccountStatus: [
        { "id": 1, "name": 'Active'},
        { "id": 2, "name": 'Blocked'},
        { "id": 3, "name": 'Closed'},
    ],
    Status: [
        { "id": 1, "name": 'Pending'},
        { "id": 2, "name": 'Valid'},
        { "id": 3, "name": 'Invalid'},
    ],
    SAProduct: [
        { "id": 1, "name": 'Trasitional FD - Arrears - Ind', "saType": 1},
        { "id": 2, "name": 'Arrear - Tuan nang dong', "saType": 1},
        { "id": 3, "name": 'Term Deposit - Arrears - Org', "saType": 1},
        { "id": 4, "name": 'Tradi FD - Periodic - Monthly', "saType": 2},
        { "id": 5, "name": 'Tradi FD - Periodic Quaterly', "saType": 2},
    ],
    TransactionType: [
        {"id": 1, "name": "Cash Deposit"},
        {"id": 2, "name": "Cash Withdrawal"},
        {"id": 3, "name": "Transfer Withdrawal"},
    ],
    SalaryPaymentTerm: [
        {"id": 1, "name": "None"},
        {"id": 2, "name": "Week"},
        {"id": 3, "name": "Month"},
    ]
}

  export default TempData;