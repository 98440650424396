
import React, { useState ,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap'
import mainAccountApi from '../../../../apis/mainAccountApi'
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField'
import RowComponent from '../../../../components/RowComponent/RowComponent'
import AccountType from '../../../../data/AccountType'
import Currency_1 from '../../../../data/Currency_1'
import TempData from '../../../../data/TempData'

export default function UnBlockAccount_MA(props){
        // 1. State check required
        const [arrRe, setArrRe] = useState([])
        const handleSetArrRe = (item) => {setArrRe(item);};
        // 2. State show Error
        const [showErrorModal, setShowErrorModal] = useState(false);
        const [errorMessage, setErrorMessage] = useState("error");
        const handleCloseErrorModal = () => setShowErrorModal(false);
        const handleShowErrorModal = () => setShowErrorModal(true);
        // 3. State show Success
        const [showSuccessModal, setShowSuccessModal] = useState(false);
        const handleCloseSuccessModal = () => setShowSuccessModal(false);
        const handleShowSuccessModal = () => setShowSuccessModal(true);
        // 5. State show Backend Error
        const [showBEError, setShowBEError] = useState(false);
        const handleCloseBEErrorModal = () => setShowBEError(false);
        const handleShowBEErrorModal = () => setShowBEError(true);
  

        //HANDLE BUTTON
        const [commitBtn, setCommitBtn] = useState(true)
        const [authorBtn, setAuthoBtn] = useState(false)
        const [editBtn, setEditBtn] = useState(false)
        const [searchBtn, setSearchBtn] = useState(false)
        const [printBtn, setPrintBtn] = useState(false)
        // Props: id (id that của account)
        const blockedAccountRefID = 1
        // GET ACCOUNT
        const [accountDB, setAccountDB] = useState({})
        const [accountBlockedDB, setAccountBlockedDB] = useState({})
        const [accountID, setAccountID] = useState(0) 
        const [accountExisted, setAccountExisted] = useState(false)

        const [isLoading, setIsLoading] = useState(true) 
        useEffect(() => {
          async function fetchBlock() {
            try {
              setAccountExisted(false)
              setAccountDB(0)
              if(accountID !== 0){
                const response1 = await mainAccountApi.enquiry({account: accountID, isBlocked: true})
                if(response1.data.data[0]){
                  const customerTemp1 = response1.data.data[0]
                  setAccountDB(customerTemp1)
                  let refIDTemp = customerTemp1.id
                  const response2 = await mainAccountApi.getBlockAccount(refIDTemp)
                  const customerTemp2 = response2.data.data
                  setAccountBlockedDB(customerTemp2)
                  // Set value
                  document.getElementById("txtCustomer").value= customerTemp2?.DEBITACCOUNT?.Customer?.GB_FullName
                  document.getElementById("txtAccount").value= customerTemp2?.DEBITACCOUNT?.Account
                  document.getElementById("txtCustomerName").value= customerTemp2?.DEBITACCOUNT?.Customer?.GB_FullName
                  document.getElementById("txtWorkingAmount").value= currencyFormat(customerTemp2?.DEBITACCOUNT?.WorkingAmount, getNamefromID(Currency_1, customerTemp2?.DEBITACCOUNT?.Currency))
                  document.getElementById("txtAmount").value= currencyFormat(customerTemp2?.Amount, getNamefromID(Currency_1, customerTemp2?.DEBITACCOUNT?.Currency))
                  document.getElementById("dtFromDate").value= customerTemp2?.StartDate
                  document.getElementById("dtToDate").value= customerTemp2?.EndDate
                  document.getElementById("txtDescription").value= customerTemp2?.Notes
                  if(customerTemp2)
                    setAccountExisted(true)
                  else
                    setAccountExisted(false)
                }else{
                  setAccountExisted(false)
                }
              }

              setIsLoading(false)
            } catch (error) {
      
            }
          }
      
          fetchBlock();
        }, [accountID]);
        //*-----------------------------------*    console.log(accountBlockedDB)
    console.log(isLoading)

    const [activeTab, setActiveTab] = useState("active");
    if(!isLoading)
    return (
      <div>
        <div><div
              className="row align-items-center text-center mb-3"
              style={{
              height: "50px",
              backgroundColor: "#eceff4",
              margin: "3px",
              position: 'sticky',
              top: '48.2px',
              zIndex: '10',
              marginTop: '0',
              marginLeft: '0',
              marginRight: '0'
            }}
          >
              <div
              className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
              style={ commitBtn && {
                  borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 1)",
                  height: "100%",
                  lineHeight: "50px",
              } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                      fontWeight: "",
                      color: "rgba(107,107,107, 0.2)",
                      height: "100%",
                      lineHeight: "50px",}}
  
              onClick={async (event)=> {
                checkRequired();
                if (arrRequired.length > 0) {
                  setShowErrorModal(true);
                  setArrRe(arrRequired);
                } else {
                const params = {
                  relievedDate: getToday()
                }
                mainAccountApi.unBlockAccount(accountDB.id, params )
                  .then(response => {
                    console.log(response)
                    // setRefIDRes(response.data.data.RefID)
                    setShowSuccessModal(true);
                    recreateInput()
                  })
                  .catch(error => {
                    setErrorMessage(error.response.data.message)
                    // errorMessage = error.Data
                    handleShowBEErrorModal();
                  });
                  
                }
              }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-save-fill"
                  viewBox="0 0 16 16"
                  style={commitBtn && {
                    color: "rgba(107,107,107,.9)",
                    marginRight: "8px",
                    } || {
                        color: "rgba(107,107,107,.2)",
                        marginRight: "8px",
                    }}
              >
                  <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
              </svg>
              <div
                  style={{
                  display: "inline",
                  }}
              >
                  {" "}
                  Commit Data{" "}
              </div>
              </div>
              <div
              className="col-2 noCopy"
              style={{
                  borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",
              }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                  style={{
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
                  }}
              >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              Authorize
              </div>
              <div
              className="col-2 noCopy"
              style={{
                  borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",
              }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                  style={{
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
                  }}
              >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
              Search
              </div>
              <div
              className="col-2 noCopy"
              style={{
                  borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",
              }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-printer-fill"
                  viewBox="0 0 16 16"
                  style={{
                  color: "rgba(107,107,107,0.2)",
                  marginRight: "8px",
                  }}
              >
                  <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                  <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              </svg>
              Print Deal Slip
              </div>
  
              <div
              className="col-2 noCopy"
              style={{
                  borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",
              }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-pencil-fill"
                  viewBox="0 0 16 16"
                  style={{
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
                  }}
              >
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
              </svg>
              Edit data
              </div>
          </div>
  
          <hr></hr>
  
          <div
              className="row noCopy"
              style={{
              margin: "20px 5px",
              }}
          >
              <ul className="nav nav-pills">
              <li className="nav-item">
                  <a
                  className={`nav-link ${activeTab === "active" ? "active" : ""}`}
                  aria-current="page"
                  href="#"
                  onClick={() => setActiveTab("active")}
                  style={{
                      backgroundColor: activeTab === "active" ? "#d71921" : "",
                  }}
                  >
                  Unblock Account
                  </a>
                  
              </li>
              </ul>
          </div>
  
          <hr></hr>
          <FieldsetComponent label="">
            <RowComponent>
                <InputField
                    label="Account Ref ID:"
                    type="text-handler"
                    id="txtAccountRefID"
                    state={accountID}
                    setState={setAccountID}
                    ph={"Enter Blocked Account Ref ID"}
                />
            </RowComponent>
            <hr></hr>
            {!accountExisted && <div style={{color: "red"}}>  Account not found/ Account was not blocked</div>}
          </FieldsetComponent>
  
          <div>
              <nav>
              <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
                  <a
                  className="nav-item nav-link active"
                  id="nav-customerInfo-tab"
                  data-toggle="tab"
                  href="#customer-info"
                  role="tab"
                  aria-controls="customer-info"
                  aria-selected="true"
                  >
                  UnBlock Account:  {accountDB?.Account}
                  </a>
              </div>
              </nav>
  
              <div className="tab-content" id="nav-tabContent">
                  <div
                      className="tab-pane fade show active"
                      id="customer-info"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                  >
                      {/* Fieldset 1 */}
                      <FieldsetComponent label="Account Information">
                          {/* row 1 */}
                          <RowComponent>
                            <InputField
                                label="Customer:"
                                type="text"
                                id="txtCustomer"
                                isDisabled={true}
                                value={accountBlockedDB?.DEBITACCOUNT?.Customer?.GB_FullName}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Account:"
                                type="text"
                                id="txtAccount"
                                isDisabled={true}
                                value={accountBlockedDB?.DEBITACCOUNT?.Account}
                            />
                          </RowComponent>
                           {/* row 1 */}
                           <RowComponent>
                              <InputField
                                  label="Customer Name:"
                                  type="text"
                                  id="txtCustomerName"
                                  isDisabled={true}
                                  value={accountBlockedDB?.DEBITACCOUNT?.Customer?.GB_FullName}
                              />
                              <div className="col-1"></div>
                              <InputField
                                label="Working Amount:"
                                type="text"
                                id="txtWorkingAmount"
                                isDisabled={true}
                                value={currencyFormat(accountBlockedDB?.DEBITACCOUNT?.WorkingAmount, getNamefromID(Currency_1, accountBlockedDB?.DEBITACCOUNT?.Currency)) || 0}
                            />
                          </RowComponent>
                      </FieldsetComponent>
  
  
                      {/* Fieldset 1 */}
                      <FieldsetComponent label="Block Information">
                          {/* row 1 */}
                          <RowComponent>
                          <InputField
                              label="Amount:"
                              type="text"
                              id="txtAmount"
                              isDisabled={true}
                              value={currencyFormat(accountBlockedDB?.Amount, getNamefromID(Currency_1, accountBlockedDB?.DEBITACCOUNT?.Currency))}
                              
                          />
                          <div className="col-1"></div>
                          <InputField
                              label="Block Account:"
                              type="select"
                              id="sltBlockAccount"
                              list={TempData.BooleanData}
                              isDisabled={true}
                              value={true}
                          />
                          </RowComponent>
                          {/* row 1 */}
                          <RowComponent>
                          <InputField
                              label="From Date:"
                              type="date"
                              id="dtFromDate"
                              isDisabled={true}
                              value={accountBlockedDB?.StartDate}
                          />
                          <div className="col-1"></div>
                          <InputField
                              label="To Date:"
                              type="date"
                              id="dtToDate"
                              isDisabled={true}
                              value={accountBlockedDB?.EndDate}
                          />
                          </RowComponent>
                          {/* row 1 */}
                          <RowComponent>
                          <InputField
                              label="Description:"
                              type="text"
                              id="txtDescription"
                              isDisabled={true}
                              value={accountBlockedDB?.Notes}
                          />
                          <div className="col-1"></div>
                          </RowComponent>
                      </FieldsetComponent>
                  </div>
              </div>
          </div>
  
              {/* --------------------------------------- */}
        {/* <Backend Error Model /> */}
        <div>
          <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <b>{errorMessage}</b>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseBEErrorModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* --------------------------------------- */}
        {/* <ErrorModal /> */}
        <div>
          <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {checkRequired()}
              {arrRe.map((item, index) => {
                return <>
                  <div>- {item}</div></>})}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseErrorModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* --------------------------------------- */}
        {/* Success Modal  */}
        <div>
          <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <b>Unblocking Account {accountDB?.DEBITACCOUNT?.Account}</b> was successful! <br />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
  
  
      </div>
      
      </div>
    )

}



let arrRequired = []
function checkRequired() {

  if (arrRequired.length > 0) return true; else return false;
}

function currencyFormat(value, currency){
  return value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
}

function getNamefromID(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}

function getToday(){
  let today = new Date()
  return today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate()
}

function recreateInput(){
  document.getElementById("txtCustomer").value= null
  document.getElementById("txtAccount").value= null
  document.getElementById("txtCustomerName").value= null
  document.getElementById("txtWorkingAmount").value= null
  document.getElementById("txtAmount").value= null
  document.getElementById("dtFromDate").value= null
  document.getElementById("dtToDate").value= null
  document.getElementById("txtDescription").value= null
}