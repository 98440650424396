import { PropaneSharp } from '@mui/icons-material';
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Button, Modal, Overlay, Popover } from 'react-bootstrap';
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountOfficers from '../../../../data/AccountOfficer';
import Category from '../../../../data/Category';
import ChargeCode from '../../../../data/ChargeCode';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import ProductLine from '../../../../data/ProductLine';
import RelationCode from '../../../../data/RelationCode';
import TempData from '../../../../data/TempData';
export default function OpenAccount_MA_Modal(props) {


    // 1. State check required
    const [arrRe, setArrRe] = useState([])
    const handleSetArrRe = (item) => {setArrRe(item);};
    // 2. State show Error
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("error");
    const handleCloseErrorModal = () => setShowErrorModal(false);
    const handleShowErrorModal = () => setShowErrorModal(true);
    // 3. State show Success
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
      // 5. State show Backend Error
      const [showBEError, setShowBEError] = useState(false);
      const handleCloseBEErrorModal = () => setShowBEError(false);
      const handleShowBEErrorModal = () => setShowBEError(true);

    
    // State response
    const [OKMessage, setOKMessage] = useState(""); 

    const [activeTab, setActiveTab] = useState("active");

      // 4. Select State
    const [getCategory, setCategory] = useState(0);
    useEffect(() => {
      clearDependentValue("sltProductLine")

    }, [getCategory]);

    // GET CUSTOMER LIST
    const [customerFormatList, setCustomerFormatList] = useState([])
    const [customerList, getCustomerList] = useState([])
    useEffect(() => {
      async function fetchCustomerList() {
        try {
          const response = await cusApi.getAll()
          const customerListDB = response.data.data.customer
          getCustomerList(customerListDB)

          let customersTemp = [{"id": 0, "name": ""}]
          if(customerListDB.length != 0){
            customerListDB.map((value, index) => {
              let temp = {"id": value.customer?.id, "name": value.customer?.RefID + " - " + value.customer?.GB_FullName}
              customersTemp.push(temp)
            })
          }
          setCustomerFormatList(customersTemp)
          setIsLoadingList(false)
        } catch (error) {

        }
      }

      fetchCustomerList();
    }, []);


    // BUTTON HANDLE
    const [commitBtn, setCommitBtn] = useState(false)
    const [authorBtn, setAuthoBtn] = useState(false)
    const [editBtn, setEditBtn] = useState(true)
    const [searchBtn, setSearchBtn] = useState(false)
    const [printBtn, setPrintBtn] = useState(false)

    // INFORMATION HANDLE
    // GET ACCOUNT FROM DB
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingList, setIsLoadingList] = useState(true);
    const [existedAccountDB, setExistedAccountDB] = useState({})
    const [accountStatusDB, setAccountStatusDB] = useState("Active")
    useEffect(() => {
        async function fetchAccount() {
            try {
            const response = await mainAccountApi.getAccount(props.modalInstanceID)
            let tempAccount = response.data.data
            setExistedAccountDB(tempAccount)
            
            //Set INI State
            setCategory(getNameFromId(Category, tempAccount?.Category))
            // setSelectedCustomer(tempAccount?.savingAccount?.CUSTOMER?.RefID + " - " + tempAccount?.savingAccount?.CUSTOMER?.GB_FullName)
            // setPrincipal(tempAccount?.detailAccount?.PrincipalAmount)
            setAccountStatusDB(tempAccount?.Status)
            // Authourize State
            let iniStatus = tempAccount?.Status_2
            if(iniStatus === 1 && localStorage.getItem('whoami') === 'qtv'){
                setAuthoBtn(true)
            }else 
                setAuthoBtn(false)
            setIsLoading(false);
        } catch (error) {

            }
        }
        fetchAccount();
    }, [props.modalInstanceID]);
    console.log("account fetch")
    console.log(existedAccountDB)


if(!isLoading && !isLoadingList)
  return (
    <div>
      <div
      className="row align-items-center text-center mb-3"
      style={{
        height: "50px",
        backgroundColor: "#eceff4",
        margin: "3px",
        position: 'sticky',
        top: '0px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'
      }}
      >
        <div
            className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ commitBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
          onClick={ async (event)=> {
            checkRequired(props.id);
            if (arrRequired.length > 0) {
              setShowErrorModal(true);
              setArrRe(arrRequired);
            } else {
              const params = {
                customerID: getIdFromName(customerFormatList, document.getElementById('sltCustomerID_' + props.id)?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltCustomerID_' + props.id)?.value),
                category:  getIdFromName(Category, document.getElementById('sltCategory_' + props.id)?.value) == 0 ? null : getIdFromName(Category, document.getElementById('sltCategory_' + props.id)?.value),
                productLine: getIdFromName(ProductLine, document.getElementById('sltProductLine_' + props.id)?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProductLine_' + props.id)?.value),
                currency:  getIdFromName(Currency_1, document.getElementById('sltCurrency_' + props.id)?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById('sltCurrency_' + props.id)?.value),
                accountTitle:  document.getElementById('txtAccountTitle_' + props.id).value || "",
                shortTitle:  document.getElementById('txtShortTitle_' + props.id).value || "",
                accountOfficer:  getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer_' + props.id)?.value) == 0 ? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer_' + props.id)?.value),
                chargeCode:  getIdFromName(ChargeCode, document.getElementById('sltChargeCode_' + props.id)?.value) == 0 ? null : getIdFromName(ChargeCode, document.getElementById('sltChargeCode_' + props.id)?.value),
                joinHolder:  getIdFromName(customerFormatList, document.getElementById('sltJoinHolder_' + props.id)?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltJoinHolder_' + props.id)?.value),
                relationCode: getIdFromName(RelationCode, document.getElementById('sltRelationCode_' + props.id)?.value) == 0 ? null : getIdFromName(RelationCode, document.getElementById('sltRelationCode_' + props.id)?.value),
                joinNotes:  document.getElementById('txtJoinNotes_' + props.id).value || ""
            };

            mainAccountApi.update(props.modalInstanceID, params)
              .then(response => {
                setOKMessage("Update Account Successfully")
                setShowSuccessModal(true);
              })
              .catch(error => {
                setErrorMessage(error.response.data.message)
                console.log(errorMessage)
                // errorMessage = error.Data
                handleShowBEErrorModal();
              });
              
              // recreateInput();
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-save-fill"
            viewBox="0 0 16 16"
            style={commitBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
          </svg>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
          style={ authorBtn && {
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 1)",
              height: "100%",
              lineHeight: "50px",
          } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",}}
            onClick={async () => {
            if(authorBtn){
                console.log("Authorize")
                let params = {
                    status: getIdFromName(TempData.Status, document.getElementById("sltAuthoStatus_" + props.id).value || 1)
                }
                await mainAccountApi.validate(props.modalInstanceID, params)
                .then(response => {
                    setOKMessage("Authorize Successfully")
                    setShowSuccessModal(true);
                })
                .catch(error => {
                    setErrorMessage(error.response.data.message)
                    console.log(errorMessage)
                    // errorMessage = error.Data
                    handleShowBEErrorModal();
                });
            }
        }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
            style={authorBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          Authorize
        </div>
        <div
         className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
         style={ searchBtn && {
             borderRight: "solid 1px rgba(0,0,0,.2)",
             fontWeight: "",
             color: "rgba(107,107,107, 1)",
             height: "100%",
             lineHeight: "50px",
         } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                 fontWeight: "",
                 color: "rgba(107,107,107, 0.2)",
                 height: "100%",
                 lineHeight: "50px",}}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
            style={searchBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          Search
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.9)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={async () => {
              const response = await mainAccountApi.export(props.modalInstanceID)
              const link = response.data.data
              let a = document.createElement('a')
              a.href = link
              a.download = `${props.modalInstanceID}.docx`
              a.click()
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg>
          Print Deal Slip
        </div>

        <div
           className="col-2 buttonAction noCopy"
           style={{
               borderRight: "solid 1px rgba(0,0,0,.2)",
               fontWeight: "",
               color: "rgb(107,107,107)",
               height: "100%",
               lineHeight: "50px",
           }}
           onClick={() => {
               props.setDisabledState(false)
               setCommitBtn(true)
           }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Get Account Information
            </a>
            
          </li>
        </ul>
      </div>

      <hr></hr>

      <div>
        <nav>
          <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Account Info
            </a>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
            <FieldsetComponent label="">
                <b>Account Status: 
                    <span
                    style={accountStatusDB === "Active" &&{
                    color: "Green"
                    }|| {
                    color: "Red"
                    }}>  {accountStatusDB}</span> </b>
            </FieldsetComponent>
            <FieldsetComponent label="Authorize">
                <RowComponent>
                    <InputField
                        label="Status:"
                        type="select"
                        id={"sltAuthoStatus_" + props.id}
                        list={TempData.Status.slice(1, 3)}
                        isDisabled={existedAccountDB?.Status_2 === 1 ? false : true}
                        defaultValue={getNameFromId(TempData.Status, existedAccountDB?.Status_2)}
                    />
                </RowComponent>
            </FieldsetComponent>
            <div
                className="tab-pane fade show active"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
                {/* Fieldset 1 */}
            <FieldsetComponent label="Account Information">
              {/* row 1 */}
              <RowComponent>
                  <InputField
                          label="Customer ID:"
                          type="select"
                          id={"sltCustomerID_" + props.id}
                          list={customerFormatList}
                          required={true}
                          isDisabled={true}
                          defaultValue={existedAccountDB.Customer?.RefID + " - " + existedAccountDB.Customer?.GB_FullName}
                      />
                    <div className="col-1"></div>
                    <InputField
                        label="Currency:"
                        type="select"
                        id={"sltCurrency_" + props.id} 
                        list={Currency_1}
                        required={true}
                        isDisabled={props.disabledState}
                        defaultValue = {existedAccountDB.CURRENCY?.Name}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label="Category:"
                        type="select-depend"
                        id={"sltCategory_" + props.id}
                        list={Category.slice(1, 3)}
                        required={true}
                        state={getCategory}
                        setState={setCategory}
                        defaultValue={getNameFromId(Category, existedAccountDB?.Category)}
                        isDisabled={props.disabledState}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Product Line:"
                        type="select"
                        id={"sltProductLine_" + props.id}
                        list={ProductLine.filter((instance) => {
                              
                            let value = getIdFromName(Category, getCategory)
                            return instance.category == value
                        })}
                        defaultValue={getNameFromId(ProductLine, existedAccountDB?.ProductLine)}
                        isDisabled={props.disabledState}
                    />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                    <InputField
                        label="Account Title:"
                        type="text"
                        id={"txtAccountTitle_" + props.id}
                        value={existedAccountDB?.AccountTitle}
                        isDisabled={props.disabledState}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Short Title:"
                        type="text"
                        id={"txtShortTitle_" + props.id}
                        value={existedAccountDB?.ShortTitle}
                        isDisabled={props.disabledState}
                    />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                    label="Account Officer:"
                    type="select"
                    id={"sltAccountOfficer_" + props.id}
                    list={AccountOfficers}
                    defaultValue={getNameFromId(AccountOfficers, existedAccountDB?.AccountOfficer)}
                    isDisabled={props.disabledState}
                    />
                    <div className="col-1"></div>
                    <InputField
                    label="Charge.Code:"
                    type="select"
                    id={"sltChargeCode_" + props.id}
                    list={ChargeCode}
                    defaultValue={getNameFromId(ChargeCode, existedAccountDB?.ChargeCode)}
                    isDisabled={props.disabledState}
                    />
              </RowComponent>
            </FieldsetComponent>

              {/* Fieldset 2 */}
            <FieldsetComponent label="Join Holder">
                {/* row 1 */}
              <RowComponent>
                <InputField
                        label="ID Join Holder:"
                        type="select"
                        id={"sltJoinHolder_" + props.id}
                        list={customerFormatList}
                        defaultValue={getNameFromId(customerFormatList, existedAccountDB?.JoinHolderID) || null}
                        isDisabled={props.disabledState}
                    />
                    <div className="col-1"></div>
                    <InputField
                    label="Relation Code"
                    type="select"
                    id={"sltRelationCode_" + props.id}
                    list={RelationCode}
                    defaultValue={getNameFromId(RelationCode, existedAccountDB?.RelationCode) || null}
                    isDisabled={props.disabledState}
                  />
              </RowComponent>
               {/* row 2 */}
               <RowComponent>
                <InputField
                        label="Join Notes:"
                        type="text"
                        id={"txtJoinNotes_" + props.id}
                        value={existedAccountDB?.JoinNotes}
                        isDisabled={props.disabledState}
                    />
                    <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>

            </div>
        </div>
    </div>


     {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired(props.id)}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                {OKMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
} 


//  if(document.getElementById("sltIndustry")?.value.length != 0 && document.getElementById("sltMainIndustry")?.value.length != 0 && obj2.industry != getIdFromName(Industry, document.getElementById("sltMainIndustry").value)){
//   arrRequired.push("SubIndustry is not belonged to Industry")
// }

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}


function clearDependentValue(id){
  document.getElementById(id).value = null
}


let arrRequired = []
function checkRequired(id) {
  arrRequired = []
  let arr = [
    {type: "select",id: "sltCustomerID_" + id, name: 'Customer ID'},
    {type: "select",id: "sltCurrency_" + id, name: 'Currency'},
    {type: "select",id: "sltCategory_" + id, name: 'Category'}
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  // check dependent value
  let categoryID_ = document.getElementById("sltProductLine_" + id)?.value
  let obj = ProductLine.find(obj => obj.name === categoryID_)
  if(document.getElementById("sltProductLine_" + id)?.value.length != 0 && document.getElementById("sltCategory_" + id)?.value.length != 0 && obj?.category != getIdFromName(Category, document.getElementById("sltCategory_" + id)?.value)){
    arrRequired.push("Product Line is not belonged to Category")
  }
  if (arrRequired.length > 0) return true; else return false;
}

function getNameFromId(list, id) {
    const obj = list.find(obj => obj.id === id);
    return obj ? obj.name : null;
  }