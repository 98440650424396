import React, { useState ,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap'
import mainAccountApi from '../../../../apis/mainAccountApi'
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField'
import RowComponent from '../../../../components/RowComponent/RowComponent'
import AccountType from '../../../../data/AccountType'
import TempData from '../../../../data/TempData'

export default function BlockAccount_MA(props) {

      // 1. State check required
      const [arrRe, setArrRe] = useState([])
      const handleSetArrRe = (item) => {setArrRe(item);};
      // 2. State show Error
      const [showErrorModal, setShowErrorModal] = useState(false);
      const [errorMessage, setErrorMessage] = useState("error");
      const handleCloseErrorModal = () => setShowErrorModal(false);
      const handleShowErrorModal = () => setShowErrorModal(true);
      // 3. State show Success
      const [showSuccessModal, setShowSuccessModal] = useState(false);
      const handleCloseSuccessModal = () => setShowSuccessModal(false);
      const handleShowSuccessModal = () => setShowSuccessModal(true);
      // 5. State show Backend Error
      const [showBEError, setShowBEError] = useState(false);
      const handleCloseBEErrorModal = () => setShowBEError(false);
      const handleShowBEErrorModal = () => setShowBEError(true);

    //HANDLE BUTTON
    const [commitBtn, setCommitBtn] = useState(true)
    const [authorBtn, setAuthoBtn] = useState(false)
    const [editBtn, setEditBtn] = useState(false)
    const [searchBtn, setSearchBtn] = useState(false)
    const [printBtn, setPrintBtn] = useState(false)

  // Props: id (id that của account)
  // const blockedAccountRefID = 1
  // GET ACCOUNT
  const [isLoading, setIsLoading] = useState(true) 
  
  const [accountID, setAccountID] = useState(0) 
  const [accountDB, setAccountDB] = useState({})
  const [accountExisted, setAccountExisted] = useState(false)

  useEffect(() => {
    async function fetchAccount() {
      try {
        setAccountExisted(false)
        setAccountDB(0)
        if(accountID !== 0){
            const response = await mainAccountApi.enquiry({account: accountID})
            console.log(response)
            
            if(response.data.data[0]){
              
              const customerTemp = response.data.data[0]
              console.log("customerTemp")
              console.log(customerTemp)
              setAccountDB(customerTemp)
              setAccountExisted(true)
            }else{
              setAccountExisted(false)
            }
        }
        setIsLoading(false)
      } catch (error) {
      }
    }
    fetchAccount();
  }, [accountID]);
  console.log(accountExisted)


  const [activeTab, setActiveTab] = useState("active");

  if(!isLoading)
  return (
    <div>
      <div><div
            className="row align-items-center text-center mb-3"
            style={{
            height: "50px",
            backgroundColor: "#eceff4",
            margin: "3px",
            position: 'sticky',
            top: '48.2px',
            zIndex: '10',
            marginTop: '0',
            marginLeft: '0',
            marginRight: '0'
            }}
        >
            <div
            className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ commitBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}

            onClick={async (event)=> {
              checkRequired();
              if (arrRequired.length > 0) {
                setShowErrorModal(true);
                setArrRe(arrRequired);
              } else {
              const params = {
                startDate: document.getElementById('dtFromDate').value || null,
                endDate: document.getElementById('dtToDate').value || null,
                amount: document.getElementById('txtAmount').value || 0,
                notes:  document.getElementById('txtDescription').value || "",
              }
              console.log('params')
              console.log(params)
              mainAccountApi.blockAccount(params, accountDB.id)
                .then(response => {
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log('errorMessage')
                  console.log(error)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });
                
                
                // RESET COMPONENT
                setAccountDB({})
                setAccountID(0)
                recreateInput();
              }
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-save-fill"
                viewBox="0 0 16 16"
                style={commitBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
            </svg>
            <div
                style={{
                display: "inline",
                }}
            >
                {" "}
                Commit Data{" "}
            </div>
            </div>
            <div
            className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ authorBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
                style={authorBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            Authorize
            </div>
            <div
            className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ searchBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
                style={searchBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            Search
            </div>
            <div
            className={printBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ printBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-printer-fill"
                viewBox="0 0 16 16"
                style={printBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            Print Deal Slip
            </div>

            <div
            className={editBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ editBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-pencil-fill"
                viewBox="0 0 16 16"
                style={editBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>
            Edit data
            </div>
        </div>

        <hr></hr>

        <div
            className="row noCopy"
            style={{
            margin: "20px 5px",
            }}
        >
            <ul className="nav nav-pills">
            <li className="nav-item">
                <a
                className={`nav-link ${activeTab === "active" ? "active" : ""}`}
                aria-current="page"
                href="#"
                onClick={() => setActiveTab("active")}
                style={{
                    backgroundColor: activeTab === "active" ? "#d71921" : "",
                }}
                >
                Block Account
                </a>
                
            </li> 
            </ul>
        </div>

        <hr></hr>
        <FieldsetComponent label="">
          <RowComponent>
              <InputField
                  label="Account Ref ID:"
                  type="text-handler"
                  id="txtAccountRefID"
                  state={accountID}
                  setState={setAccountID}
                  ph={"Enter Account Ref ID"}
              />
          </RowComponent>
          <hr></hr>
          {!accountExisted && <div style={{color: "red"}}>  Account not found or inactive</div>}
        </FieldsetComponent>
        <hr></hr>
        <div>
            <nav>
              <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
                  <a
                  className="nav-item nav-link active"
                  id="nav-customerInfo-tab"
                  data-toggle="tab"
                  href="#customer-info"
                  role="tab"
                  aria-controls="customer-info"
                  aria-selected="true"
                  >
                  Block Account: {accountDB?.Account}
                  </a>
              </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="customer-info"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                >
                    {/* Fieldset 1 */}
                    <FieldsetComponent label="Account Information">
                        {/* row 1 */}
                        <RowComponent>
                          <InputField
                              label="Customer:"
                              type="text"
                              id="txtCustomer"
                              isDisabled={true}
                              value={accountDB?.Customer?.RefID}
                          />
                          <div className="col-1"></div>
                          <InputField
                              label="Account:"
                              type="text"
                              id="txtAccount"
                              isDisabled={true}
                              value={accountDB?.Account}
                          />
                        </RowComponent>
                         {/* row 1 */}
                         <RowComponent>
                            <InputField
                                label="Customer Name:"
                                type="text"
                                id="txtCustomerName"
                                isDisabled={true}
                                value={accountDB?.Customer?.GB_FullName}
                            />
                            <div className="col-1"></div>
                            <InputField
                              label="Working Amount:"
                              type="text"
                              id="txtWorkingAmount"
                              isDisabled={true}
                              value={accountDB?.WorkingAmount}
                          />
                        </RowComponent>
                    </FieldsetComponent>


                    {/* Fieldset 1 */}
                    <FieldsetComponent label="Block Information">
                        {/* row 1 */}
                        <RowComponent>
                        <InputField
                            label="Amount:"
                            type="number-nohandle"
                            id="txtAmount"
                        />
                        <div className="col-1"></div>
                        <InputField
                            label="Block Account:"
                            type="select"
                            id="sltBlockAccount"
                            list={TempData.BooleanData}
                        />
                        </RowComponent>
                        {/* row 1 */}
                        <RowComponent>
                        <InputField
                            label="From Date:"
                            type="date"
                            id="dtFromDate"
                        />
                        <div className="col-1"></div>
                        <InputField
                            label="To Date:"
                            type="date"
                            id="dtToDate"
                        />
                        </RowComponent>
                        {/* row 1 */}
                        <RowComponent>
                        <InputField
                            label="Description:"
                            type="text"
                            id="txtDescription"
                        />
                        <div className="col-1"></div>
                        </RowComponent>
                    </FieldsetComponent>
                </div>
            </div>
        </div>

            {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <b>Block Account {accountDB?.Account}</b> was successful! <br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>


    </div>
    
    </div>
  )

  
}



let arrRequired = []
function checkRequired(id) {
    arrRequired = []
    let arr = [
      {type: "date",id: "dtFromDate" + id,name: 'From Date'},
      {type: "date",id: "dtToDate" + id,name: 'To Date'},
    ];
    arr.map((item, index) => {
      if (document.getElementById(item.id)?.value.length == 0)
        arrRequired.push(item.name)
    })
  if (arrRequired.length > 0) return true; else return false;
}

function recreateInput(){
  document.getElementById("txtAmount").value= null
  document.getElementById("dtFromDate").value= null
  document.getElementById("dtToDate").value= null
  document.getElementById("txtDescription").value= ""
}
