const SubSector = [
    {"id": 0, "name": ''},
    {"id": 1, "name": 'Thành phần kinh tế cá thể', "code": null, "sector": 15},
    {"id": 2, "name": 'Tổ chức kinh tế', "code": null, "sector": 1},
    {"id": 3, "name": 'Doanh nghiệp Nhà nước', "code": null, "sector": 2},
    {"id": 4, "name": 'Doanh nghiệp Nhà nước Trung Ương', "code": null, "sector": 2},
    {"id": 5, "name": 'Doanh nghiệp Nhà nước Địa phương', "code": null, "sector": 2},
    {"id": 6, "name": 'Công ty Cổ phần', "code": null, "sector": 3},
    {"id": 7, "name": 'Công ty Cổ phần do Nhà nước chi phối (>50%VDL)', "code": null, "sector": 3},
    {"id": 8, "name": 'Công ty Cổ phần Nhà nước khác', "code": null, "sector": 3},
    {"id": 9, "name": 'Công ty Cổ phần khác', "code": null, "sector": 3},
    {"id": 10, "name": 'Công ty TNHH', "code": null, "sector": 4},
    {"id": 11, "name": 'Công ty trách nhiệm MTV do Nhà nước sỡ hữu', "code": null, "sector": 4},
    {"id": 12, "name": 'Công ty trách nhiệm 2 thành viên trở lên do Nhà nước', "code": null, "sector": 4},
    {"id": 13, "name": 'Công ty trách nhiệm hữu hạn khác', "code": null, "sector": 4},
    {"id": 14, "name": 'Doanh nghiệp có vốn đầu tư nước ngoài', "code": null, "sector": 5},
    {"id": 15, "name": 'Doanh nghiệp 100% vốn đầu tư nước ngoài', "code": null, "sector": 5},
    {"id": 16, "name": 'Công ty liên doanh với DNNN', "code": null, "sector": 5},
    {"id": 17, "name": 'Tổ chức kinh tế tập thể', "code": null, "sector": 6},
    {"id": 18, "name": 'Hợp tác xã', "code": null, "sector": 6},
    {"id": 19, "name": 'Liên doanh giữa các thành phần kinh tế trong nước', "code": null, "sector": 6},
    {"id": 20, "name": 'Doanh nghiệp tư nhân', "code": null, "sector": 7},
    {"id": 21, "name": 'Hộ gia đình', "code": null, "sector": 7},
    {"id": 22, "name": 'Công ty hợp danh', "code": null, "sector": 8},
    { "id": 23, "name": 'Tổ chức kinh tế khác', "code": null, "sector": 9 },
    { "id": 24, "name": 'Thành phần kinh tế cá thể', "code": null, "sector": 10 },
    { "id": 25, "name": 'Định chế tài chính', "code": null, "sector": 11 },
    { "id": 26, "name": 'Kho bạc Nhà nước', "code": null, "sector": 12 },
    { "id": 27, "name": 'Ngân hàng Nhà nước', "code": null, "sector": 12 },
    { "id": 28, "name": 'Ngân hàng', "code": null, "sector": 12 },
    { "id": 29, "name": 'Công ty cho thuê tài chính', "code": null, "sector": 12 },
    { "id": 30, "name": 'Công ty tài chính', "code": null, "sector": 12 },
    { "id": 31, "name": 'Quỹ tín dụng, hợp tác xã tín dụng', "code": null, "sector": 12 },
    { "id": 32, "name": 'Các định chế tài chính khác', "code": null, "sector": 12 },
    { "id": 33, "name": 'Tổ chức phi kinh tế', "code": null, "sector": 13 },
    { "id": 34, "name": 'Tổ chức phi lợi nhuận trong nước', "code": null, "sector": 14 },
    { "id": 35, "name": 'Cơ quan chính phủ Trung ương', "code": null, "sector": 14 },
    { "id": 36, "name": 'Cơ quan chính phủ Địa phương', "code": null, "sector": 14 },
    { "id": 37, "name": 'Tổ chức chính trị, đoàn thể trong nước', "code": null, "sector": 14 },
    { "id": 38, "name": 'Tổ chức phi lợi nhuận quốc tế', "code": null, "sector": 15 },
    { "id": 39, "name": 'Các tổ chức quốc tế', "code": null, "sector": 15 },
    { "id": 40, "name": 'Tổ chức phi chính phủ', "code": null, "sector": 15 },
    { "id": 41, "name": 'Cơ quan đại diện, Lãnh sự quán nước ngoài', "code": null, "sector": 15 },
    { "id": 42, "name": 'Tổ chức chính trị, đoàn thể nước ngoài', "code": null, "sector": 15 },
]

  export default SubSector;