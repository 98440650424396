import React, { useEffect, useState } from 'react'
import './LayoutBasic.css'
import sidebarMenu from "../../data/sidebarMenu.json"
import { Link } from "react-router-dom";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

function getFormattedTime() {
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const date = new Date();
  return date.toLocaleDateString('en-US', options);
}

export default function LayoutBasic(props) {

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  const [time, setTime] = useState(getFormattedTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getFormattedTime());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

    const [menuID, setMenuID] = useState(0);
    const handleMenuClick = (idItem) => {
      setMenuID(idItem);
    };
  
    const renderMenu = (listItem) => {
        return listItem.map((item, index) => {
            return(
                <div>
                    <div className='row'
                        key={index}
                        style={{
                            height: "45px",
                            backgroundColor: "#eceff4",
                            marginTop: "5px"
                        }}
                    >
                        <div className='col-1'
                            style={{
                                height: "45px",
                                position: "relative",
                                padding: "0px",

                            }}
                        >
                            <div 
                                style={{
                                    backgroundColor: "#a64853",
                                    height: "100%",
                                    width: "5px",
                                    position: "absolute",
                                    top: "0",
                                    left: "0"
                                }}
                            >

                            </div>
                        </div>
                        <div className='col-11'
                            style={{
                                height: "45px",
                                padding: "0px",
                                lineHeight: "45px",
                                color: "#d71921",
                                fontWeight: "500"
                                
                            }}
                        >
                            {item.title}
                        </div>
                    </div>
                    {renderSubMenu(item.subMenuL2)}
                </div>
            )
        })
    }
    const renderSubMenu = (listItem) => {
        return listItem.map((item, index) => {
            return(
              <div key={index}>
                {/* <a
                  href={item.path}
                > */}
                <Link to={item.path}>
                <div 
                    
                    className='row menu'
                    style={{
                        height: "45px",
                        marginTop: "5px"
                    }}
                >
                    
                      <div className='col-2 text-center'
                        style={{
                            height: "45px",
                            padding: "0px",

                        }}
                        
                    >
                          <i 
                              className={`${item.icon}`}  
                              style={{
                                  lineHeight: "45px",
                                  fontSize: "18px",
                                  color: "#d71921",
                              }}
                          ></i>
                    </div>
                    <div className='col-10'
                        style={{
                            height: "45px",
                            padding: "0px",
                            lineHeight: "45px",
                            color: "#6b6b6b",
                            fontSize: "16px",
                            fontWeight: '600'
                        }}
                    >
                        {/* {item.title} */}
                        {item.title.length <= 20 ? item.title : item.title.substring(0,22)}
                    </div>
                    
                </div> 
                </Link>
                {/* </a> */}
                {(item.subMenuL3) && renderHoverSubMenu(item.subMenuL3)}
              </div>
            )
        })
    }

    const renderHoverSubMenu = (listItem) => {
      return listItem.map((item, index) => {
        return(
          <div className='hoverSubMenu'>
                <Link to={item.path}>

            <div 
                key={index}
                className='row menu'
                style={{
                    height: "45px",
                    marginTop: "5px"
                }}
            >
                <div className='col-2'
                            style={{
                                height: "45px",
                                position: "relative",
                                lineHeight: "45px",
                                padding: "0px ",
                                fontSize: "5px"

                            }}
                        >
                          <i className="fas fa-circle"
                            style={{
                              color: "#d71921",
                              top: "21px",
                              left: "23px",
                              position: "absolute",
                            }}
                          ></i>
                        </div>
                <div className='col-10'
                    style={{
                        height: "45px",
                        padding: "0px",
                        lineHeight: "45px",
                        color: "#6b6b6b",
                    }}
                >
                    {item.title}
                </div>
            </div>
            {(item.subMenuL4) && renderDropdownSubMenu(item.subMenuL4)}
          </Link>
          </div>
        )
    })
  }

  const renderDropdownSubMenu = (listItem) => {
    return listItem.map((item, index) => {
      return(
        <div className=''>
          <Link to={item.path}>
          <div 
              key={index}
              className='row menu'
              style={{
                  height: "45px",
                  marginTop: "5px"
              }}
          >
              <div className='col-3'
                          style={{
                              height: "45px",
                              position: "relative",
                              lineHeight: "45px",
                              padding: "0px ",
                              fontSize: "5px"

                          }}
                      >
                      </div>
              <div className='col-9'
                  style={{
                      height: "45px",
                      padding: "0px",
                      lineHeight: "45px",
                      color: "#6b6b6b",
                  }}
              >
                  {item.title}
              </div>
          </div>
        </Link>
        </div>
      )
  })
  }

  return (
    <div>
      <div className="container-fluid">
        <div
          className="row"
          style={{ height: "5px", backgroundColor: "#a64853" }}
          
        ></div>
        <div className="row noCopy" style={{ height: "70px" }}>
          <div
            className="col-2"
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              href="/"
            >
              <img
                src={"./logo.png"}
                alt="VietVictory"
                style={{ width: "75px" }}
              />
            </a>
          </div>
          <div
            className="col-5"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "24px",
              justifyContent: "center",
              //   color: "#a64853",
              color: "#d71921",
              fontWeight: "500",
            }}
          >
            HỆ THỐNG ĐÀO TẠO CORE BANKING 
          </div>
          <div
            className="col-5"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "20px",
              justifyContent: "end",
              color: "#6b6b6b",
              fontWeight: "500",
            }}
          >
            <div style={{ marginRight: "30px" }}>{time}</div>
            <i className="fa-solid fa-book" style={{ marginRight: "30px" }}></i>
            <i className="fa-solid fa-bell" style={{ marginRight: "30px" }}></i>
            <div style={{ marginRight: "15px" }}>{localStorage.getItem('whoami') === 'qtv' ? "Admin" : "User"}</div>
              <div className='avt_logo text-center'>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton 
                                onClick={handleClick}
                                size="small"
                                sx={{ p:0 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar alt="Remy Sharp" src="https://github.com/mdo.png"  sx={{ width: '32px', height: '32px' }}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {/* <MenuItem >Profile</MenuItem>
                            <MenuItem >My account</MenuItem> */}
                            <MenuItem 
                                onClick={
                                    () => {
                                        window.localStorage.removeItem('isLoggedIn')
                                        window.localStorage.removeItem('whoami')
                                        window.location.replace('/login')

                                        // window.history.pushState('Login', 'Title Login', '/login')
                                        // window.history.pushState('Login', 'Title Login', '/login')
                                    }
                                }
                            >Logout</MenuItem>
                        </Menu>
                    </Box>
                </div>
            
          </div>
        </div>

        <div
          className="row text-center noCopy"
          style={{
            height: "45px",
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "#DDDDDD",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            color: "#6b6b6b",
            fontWeight: "600",
            cursor: "pointer",
            lineHeight: "45px",
            paddingBottom: "45px",
            paddingRight: "10px",
            backgroundColor: "#ffffff",
            position: "sticky",
            top: "0",
            zIndex: "10",
          }}
        >
          <div className="col-2 menu" onClick={() => handleMenuClick(0)}
              // style={{letterSpacing: '1px'}}
          >
            TELLER OPERATION
          </div>
          <div className="col-2 menu" onClick={() => handleMenuClick(1)}>
            TRANSFER OPERATION
          </div>
          <div className="col-2 menu" onClick={() => handleMenuClick(2)}>
            CREDIT OPERATION
          </div>
          <div className="col-2 menu">TRADE FINANCE OPERATION</div>
          <div className="col-2 menu">TREASURY OPERATION</div>
          <div className="col-2 menu">REPORT</div>
        </div>
        <div className="row">
          <div
            className="col-2 sc"
            style={{
              borderRight: "2px solid #DDDDDD",
              height: "590px",
              paddingRight: "5px",
              paddingLeft: "5px",
              cursor: "pointer",

              backgroundColor: "#ffffff",

              position: "sticky",
              top: "45px",
              zIndex: "9",
              overflow: "auto",
            }}
          >
            <div
              className="container-fluid noCopy"
              style={{
                // backgroundColor: "#eceff4",
                height: "100%",
                // width:"100%"
              }}
            >
              {renderMenu(sidebarMenu[menuID].subMenuL1)}
            </div>
          </div>
          <div
            className="col-10"
            style={{
              // height: 'auto',
              height: "1000px",
              backgroundColor: "#eceff4",
            }}
          >
            <div
              style={{
                margin: "20px 7px",
                padding: "20px",
                // paddingTop: "50px",
                backgroundColor: "#ffffff",
                borderRadius: "9px",
                boxShadow:
                  "1px 1px 1px 3px rgba(225,228,232,0.7), 3px 3px 3px 5px rgba(225,228,232,0.5), 5px 5px 5px 8px rgba(225,228,232,0.3) ",
              }}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
