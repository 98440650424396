import axios from 'axios';
// get_all_corporate_customer
const cusApi = {
  getAll: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/customer/get_all_customer`, { mode: 'no-cors' });
  },

  postIndividual: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/customer/create_individual_customer`, params, { mode: 'no-cors' });
  },

  postCorporate: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/customer/create_corporate_customer`, params, { mode: 'no-cors' });
  },
  enquiry: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/customer/enquiry_customer`, params, { mode: 'no-cors' });
  },

  getIndividual: (param) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/customer/get_individual_customer/${param}`, { mode: 'no-cors' });
  },

  getAllCorporate: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/customer/get_all_corporate_customer`, { mode: 'no-cors' });
  }
};

export default cusApi;
