const SubIndustry = [
    { "id": 0, "name": '', "code": null, "industry": null },
    { "id": 1, "name": 'Trồng trọt', "code": null, "industry": 1 },
    { "id": 2, "name": 'Chăn nuôi gia súc', "code": null, "industry": 1 },
    { "id": 3, "name": 'Chăn nuôi gia cầm', "code": null, "industry": 1 },
    { "id": 4, "name": 'Chăn nuôi khác', "code": null, "industry": 1 },
    { "id": 5, "name": 'Ngành nông nghiệp khác', "code": null, "industry": 1 },
    { "id": 6, "name": 'Trồng rừng và chăm sóc rừng', "code": null, "industry": 2 },
    { "id": 7, "name": 'Khai thác lâm sản', "code": null, "industry": 2 },
    { "id": 8, "name": 'Dịch vụ lâm nghiệp', "code": null, "industry": 2 },
    { "id": 9, "name": 'Đánh bắt thủy sản', "code": null, "industry": 3 },
    { "id": 10, "name": 'Nuôi cá, tôm,...', "code": null, "industry": 3 },
    { "id": 11, "name": 'Dịch vụ thủy sản', "code": null, "industry": 3 },
    { "id": 12, "name": 'Nuôi trồng thủy sản khác', "code": null, "industry": 3 },
    { "id": 13, "name": 'Khai thác than', "code": null, "industry": 4 },
    { "id": 14, "name": 'Khai thác dầu khí', "code": null, "industry": 4 },
    { "id": 15, "name": 'Khai thác đá, cát, sỏi', "code": null, "industry": 4 },
    { "id": 16, "name": 'Khai thác mỏ khác', "code": null, "industry": 4 },
    { "id": 17, "name": 'Thực phẩm', "code": null, "industry": 5 },
    { "id": 18, "name": 'Gỗ, tre, nứa,...', "code": null, "industry": 5 },
    { "id": 19, "name": 'Giấy', "code": null, "industry": 5 },
    { "id": 20, "name": 'Hóa chất và vật liệu xây dựng', "code": null, "industry": 5 },
    { "id": 21, "name": 'Sản xuất kim loại', "code": null, "industry": 5 },
    { "id": 22, "name": 'Thiết bị điện', "code": null, "industry": 5 },
    { "id": 23, "name": 'Xe cộ động cơ', "code": null, "industry": 5 },
    { "id": 24, "name": 'Thức ăn chăn nuôi', "code": null, "industry": 6 },
    { "id": 25, "name": 'Công nghiệp chế biến sản xuất khác', "code": null, "industry": 6 },
    {"id": 26, "name": 'Đường', "code": null, "industry": 6},
    {"id": 27, "name": 'Lương thực', "code": null, "industry": 6},
    {"id": 28, "name": 'Dệt, đan len,...', "code": null, "industry": 7},
    {"id": 29, "name": 'May mặc', "code": null, "industry": 8},
    {"id": 30, "name": 'Chế biến lâm sản', "code": null, "industry": 9},
    {"id": 31, "name": 'Giấy và các sản phẩm giấy', "code": null, "industry": 10},
    {"id": 32, "name": 'Hóa và vật liệu xây dựng', "code": null, "industry": 11},
    {"id": 33, "name": 'Sắt thép', "code": null, "industry": 12},
    {"id": 34, "name": 'Thép không gỉ, inox', "code": null, "industry": 12},
    {"id": 35, "name": 'Máy vi tính', "code": null, "industry": 13},
    {"id": 36, "name": 'Ô tô', "code": null, "industry": 14},
    {"id": 37, "name": 'Tàu thuyền', "code": null, "industry": 14},
    {"id": 38, "name": 'Xe gắn máy', "code": null, "industry": 14},
    {"id": 39, "name": 'Điện năng, khí đốt, hơi nước', "code": null, "industry": 15},
    {"id": 40, "name": 'Điện', "code": null, "industry": 16},
    {"id": 41, "name": 'Khí đốt', "code": null, "industry": 16},
    {"id": 42, "name": 'Nước', "code": null, "industry": 17},
    {"id": 43, "name": 'Xây dựng', "code": null, "industry": 18},
    {"id": 44, "name": 'San lấp mặt bằng', "code": null, "industry": 18},
    {"id": 45, "name": 'Xây dựng dân dụng', "code": null, "industry": 18},
    {"id": 46, "name": 'Xây dựng công trình kỹ thuật', "code": null, "industry": 18},
    {"id": 47, "name": 'Xây dựng kết cấu công trình', "code": null, "industry": 18},
    {"id": 48, "name": 'Xây dựng khác', "code": null, "industry": 18},
    {"id": 49, "name": 'Lúa gạo', "code": null, "industry": 19},
    {"id": 50, "name": 'Thương nghiệp khác', "code": null, "industry": 19},
    {"id": 51, "name": 'Lâm sản', "code": null, "industry": 19},
    {"id": 52, "name": 'Dệt', "code": null, "industry": 19},
    {
        "id": 53,
        "name": 'Da và sản phẩm da',
        "code": null,
        "industry": 19
      },
      {
        "id": 54,
        "name": 'Giày dép',
        "code": null,
        "industry": 19
      },
      {
        "id": 55,
        "name": 'Giày và sản phẩm giày',
        "code": null,
        "industry": 19
      },
      {
        "id": 56,
        "name": 'Xăng dầu',
        "code": null,
        "industry": 19
      },
      {
        "id": 57,
        "name": 'Phân bón',
        "code": null,
        "industry": 19
      },
      {
        "id": 58,
        "name": 'Cao su',
        "code": null,
        "industry": 19
      },
      {
        "id": 59,
        "name": 'Vàng, kim loại quý, đá quý',
        "code": null,
        "industry": 19
      },
      {
        "id": 60,
        "name": 'Ô tô và phụ tùng',
        "code": null,
        "industry": 19
      },
      {
        "id": 61,
        "name": 'Xe gắn máy và phụ tùng',
        "code": null,
        "industry": 19
      },
      {
        "id": 62,
        "name": 'Sản phẩm khác',
        "code": null,
        "industry": 19
      },
      {
        "id": 63,
        "name": 'Sữa chữa ô tô, xe gắn máy',
        "code": null,
        "industry": 20
      },
      {
        "id": 64,
        "name": 'Sữa chữa đồ dùng cá nhân và gia đình',
        "code": null,
        "industry": 20
      },
      {
        "id": 65,
        "name": 'Nhà hàng - khách sạn',
        "code": null,
        "industry": 21
      },
      {
        "id": 66,
        "name": 'Hoạt động tài chính',
        "code": null,
        "industry": 22
      },
      {
        "id": 67,
        "name": 'Bất động sản',
        "code": null,
        "industry": 22
      },
      {
        "id": 68,
        "name": 'Tổ chức tín dụng',
        "code": null,
        "industry": 23
      },
      {
        "id": 69,
        "name": 'Chứng khoán',
        "code": null,
        "industry": 23
      },
      {
        "id": 70,
        "name": 'Bảo hiểm',
        "code": null,
        "industry": 23
      },
      {
        "id": 71,
        "name": 'Trung gian tài chính',
        "code": null,
        "industry": 23
      },
      {
        "id": 72,
        "name": 'Bất động sản, đường giao thông, cầu phà',
        "code": null,
        "industry": 24
      },
      {
        "id": 73,
        "name": 'Cho thuê tài sản',
        "code": null,
        "industry": 25
      },
      {
        "id": 74,
        "name": 'Giáo dục đào tạo',
        "code": null,
        "industry": 26
      },
      {
        "id": 75,
        "name": 'Y tế - cứu trợ xã hội',
        "code": null,
        "industry": 27
      },
      {
        "id": 76,
        "name": 'Văn hóa thể thao',
        "code": null,
        "industry": 28
      },
      {
        "id": 77,
        "name": 'Dịch vụ vệ sinh môi trường',
        "code": null,
        "industry": 29
      },
      {
        "id": 78,
        "name": 'Hoạt động khác',
        "code": null,
        "industry": 30
      },
      {
        "id": 79,
        "name": 'Ngành khác',
        "code": null,
        "industry": 31
      }
]

export default SubIndustry;