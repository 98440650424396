import axios from 'axios';

const transaction_maApi = {
    deposit: (params) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/transaction/create_deposit`, params,{ mode: 'no-cors' });
    },
    withdrawal: (params) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/transaction/create_withdrawal`, params,{ mode: 'no-cors' });
    },
    transfer: (params) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/transaction/create_transfer`, params,{ mode: 'no-cors' });
    },
    enquiry: (params) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/transaction/enquiry`, params,{ mode: 'no-cors' });
    },
    validateDeposit: (id, params) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/transaction/validate_deposit/` + id, params,{ mode: 'no-cors' });
    },
    validateWithdrawal: (id, params) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/transaction/validate_withdrawal/` + id, params,{ mode: 'no-cors' });
    },
    validateTransfer: (id, params) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/transaction/validate_transfer/` + id, params,{ mode: 'no-cors' });
    },
    exportDeposit: (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/export/deposit/` + id,{ mode: 'no-cors' });
    },
    exportWithdrawal: (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/export/withdrawal/` + id,{ mode: 'no-cors' });
    },
    exportTransfer: (id) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/export/transfer/` + id,{ mode: 'no-cors' });
    },
}

export default transaction_maApi;