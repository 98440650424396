import { PartyModeSharp } from "@mui/icons-material";
import axiosClient from "./axiosClient";
import { stringify } from "qs";

const customerApi = {
    getAll: (params) => {
        const url = '/customer/get_all_customer';
        return axiosClient.get(url, { params });
    },
    enquiry: (params) => {
        const url = '/customer/enquiry_customer';
        return axiosClient.post(url, {
            customerType: params.CustomerType,
            customerID: params.CustomerID,
            GB_FullName: params.GB_FullName,
            phoneNumber: params.PhoneNumber,
            docID: params.DocID,
            mainSector: params.MainSector,
            subSector: params.SubSector,
            mainIndustry: params.MainIndustry,
            subIndustry: params.SubIndustry
        });
    },
    getIndividual: (id) => {
        const url = `/customer/get_individual_customer/${id}`;
        return axiosClient.get(url);
    },
    getCorporate: (id) => {
        const url = `/customer/get_corporate_customer/${id}`;
        return axiosClient.get(url);
    },
    postIndividual: async (params) => {
        const url = '/customer/create_individual_customer';
        console.log('param o day')
        console.log(params)
        return await axiosClient.post(url, {
            // firstName: params.FirstName,
            // lastName: params.LastName,
            // middleName: params.MiddleName,
            // GB_ShortName: params.GBShortName,
            // GB_FullName: params.GBFullName,
            // GB_Street: params.GBStreet || '',
            // GB_Towndist: params.GBTownDist || '',
            // mobilePhone: params.MobilePhone || '',
            docID: "DOC123",
            // emailAddress: params.EmailAddress || '',
            // cityProvince: params.CityProvince,
            // GB_Country: params.GBCountry,
            // nationality: params.Nationality,
            // residence: params.Residence,
            doctype: 1,
            // mainSector: params.MainSector,
            // accountOfficer: params.AccountOfficer,
        })        
            .then(res => {
                return ('success')
            })
            .catch(err => {
                console.log('o day');
                console.log(err);
                return ('fail')
            })
        ;
    },
    updateIndividual: async (params, id) => {
        const url = `/customer/update_individual_customer/${id}`;
        return await axiosClient.put(url, { 
                firstName: params.FirstName,
                lastName: params.LastName,
                middleName: params.MiddleName,    
                GB_ShortName: params.GBShortName,
                GB_FullName: params.GBFullName,
                birthday: params.Birthday,
                GB_Street: params.GBStreet,
                GB_Towndist: params.GBTownDist,
                mobilePhone: params.MobilePhone,
                docID: params.DocID,
                emailAddress: params.EmailAddress,
    
                cityProvince: params.CityProvince,
                GB_Country: params.GBCountry,
                nationality: params.Nationality,
                residence: params.Residence,
                doctype: params.DocType,
                docID: params.DocID,
                docIssuePlace: params.DocIssuePlace,
                docIssueDate: params.DocExporyDate,
                mainIndustry: params.MainIndustry,
                industry: params.Industry,
                accountOfficer: params.AccountOfficer,
            })
            .then(res => {
                return ('success')
            })
            .catch(err => {
                return ('fail')
            })
        ;
    },
    postCorporate: async (params) => {
        const url = '/customer/create_corporate_customer';
        return await axiosClient.post(url, {     
            GB_ShortName: params.GB_ShortName,
            GB_FullName: params.GB_FullName,
            incorpDate: params.IncorpDate,
            contactPerson: params.ContactPerson,
            position: params.Position,
            telephone: params.Telephone,
            emailAddress: params.EmailAddress,
            remarks: params.Remarks,
            contactDate: params.ContactDate,
            officeNumber: params.CfficeNumber,
            totalCapital: params.TotalCapital,
            totalAssets: params.TotalAssets,
            totalRevenue: params.TotalRevenue,
            employeesNo: params.EmployeesNo,
            sector: params.Sector,
            GB_Street: params.GB_Street,
            GB_Towndist: params.GB_Towndist,
            GB_Country: params.GB_Country,
            docID: params.DocID,
            docIssuePlace: params.DocIssuePlace,
            docIssueDate: params.DocIssueDate,
            docExpiryDate: params.DocExpiryDate,
            companyBook: params.CompanyBook,
            liability: params.Liability,
            cityProvince: params.CityProvince,
            nationality: params.Nationality,
            residence: params.Residence,
            doctype: params.Doctype,
            mainSector: params.MainSector,
            mainIndustry: params.MainIndustry,
            industry: params.Industry,
            accountOfficer: params.AccountOfficer,
            relationCode: params.RelationCode

            })
            .then(res => {
                return ('success')
            })
            .catch(err => {
                console.log('err')
                console.log(err)
                return ('fail')
            })
        ;
    },

    updateCorporate: async (params, id) => {
        const url = `/customer/update_corporate_customer/${id}`;
        return await axiosClient.put(url, {    
                GB_ShortName: params.GBShortName,
                GB_FullName: params.GBFullName,
                GB_Street: params.GBStreet,
                GB_Towndist: params.GBTownDist,
                GB_Country: params.GBCountry,
                // incorpDate: params.IncorpDate,
                contactPerson: params.ContactPerson,
                position: params.Position,
                telephone: params.Telephone,
                officeNumber: params.OfficeNumber,

                emailAddress: params.EmailAddress,
                remarks: params.Remarks,
                cityProvince: params.CityProvince,
                GB_Country: params.GBCountry,
                nationality: params.Nationality,
                residence: params.Residence,
                doctype: params.DocType,
                docID: params.DocID,
                docIssuePlace: params.DocIssuePlace,
                // docIssueDate: params.DocExporyDate,
                mainIndustry: params.MainIndustry,
                industry: params.Industry,
                mainSector: params.MainSector ,
                sector: params.SubSector ,
                accountOfficer: params.AccountOfficer,
            })
            .then(res => {
                return ('success')
            })
            .catch(err => {
                console.log("err")
                console.log(err)
                return ('fail')
            })
        ;
    },

}
    export default customerApi;