const RelationCode = [
    { "id": 0, "name": ''},
    { "id": 1, "name": 'HEAD OFFICE'},
    { "id": 2, "name": 'MOTHER CONPANY'},
    { "id": 3, "name": 'SISTER COMPANY'},
    { "id": 4, "name": 'FUND MANAGER'},
    { "id": 5, "name": 'INTRODUCER'},
    { "id": 6, "name": 'STOCKHOLDER OF'},
    { "id": 7, "name": 'SAME OWNER'},
    { "id": 8, "name": 'BRANCH'},
    { "id": 9, "name": 'SUBS"id"IARY/AFFLIATE'},
    { "id": 10, "name": 'MANAGED CLIENT'},
    { "id": 11, "name": 'INTRODUCED CLIENT'},
    { "id": 12, "name": 'STOCK FROM'},
    { "id": 13, "name": 'SAME OWNER'},
    { "id": 14, "name": 'UNIQUE CLIENT'},
    { "id": 15, "name": 'PARENT'},
    { "id": 16, "name": 'SPOUSE'},
    { "id": 17, "name": 'SIBLING'},
    { "id": 18, "name": 'RELATIVE'},
    { "id": 19, "name": 'FRIEND'},
    { "id": 20, "name": 'EMPLOYER'},
    { "id": 21, "name": 'CHILD'}
  ];

  export default RelationCode;