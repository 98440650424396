const Sector = [
    { "id": 0, "name": ''},
    { "id": 1, "name": 'Tổ chức kinh tế'},
    { "id": 2, "name": 'Doanh nghiệp nhà nước'},
    { "id": 3, "name": 'Công ty Cổ phần'},
    { "id": 4, "name": 'Công ty Trách nhiệm hữu hạn'},
    { "id": 5, "name": 'Doanh nghiệp có vốn đầu tư nước ngoài'},
    { "id": 6, "name": 'Tổ chức kinh tế tập thể'},
    { "id": 7, "name": 'Doanh nghiệp tư nhân'},
    { "id": 8, "name": 'Công ty Hợp danh'},
    { "id": 9, "name": 'Tổ chức kinh tế khác'},
    { "id": 10, "name": 'Thành phần Kinh tế Cá thể'},
    { "id": 11, "name": 'Định chế tài chính'},
    { "id": 12, "name": 'Tổ chức phi kinh tế'},
    { "id": 13, "name": 'Tổ chức phi lợi nhuận trong nước'},
    { "id": 14, "name": 'Tổ chức phi lợi nhuận quốc tế'},
    { "id": 15, "name": 'Thành phần kinh tế cá thể - Individuals'}
  ];

  export default Sector;