import React from 'react'

export default function CommitDataIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-save-fill"viewBox="0 0 16 16"style={props.status && {
      color: "rgba(107,107,107,.9)",
      marginRight: "8px",
    } || {
      color: "rgba(107,107,107,.2)",
      marginRight: "8px",
    }}><path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" /></svg>
  )
}
