
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import savingAccountApi from "../../../../apis/savingAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import Currency_1 from "../../../../data/Currency_1";
import Currency_2 from "../../../../data/Currency_2";
import ProductLine from "../../../../data/ProductLine";
import SavingCurrencyAccount from "../../../../data/SavingCurrencyAccount";
import TempData from "../../../../data/TempData";

export default function Close_ArrearPeriodic_SA(props) {
  //HANDLE BUTTON
  const [commitBtn, setCommitBtn] = useState(true)
  const [authorBtn, setAuthoBtn] = useState(false)
  const [editBtn, setEditBtn] = useState(false)
  const [searchBtn, setSearchBtn] = useState(false)
  const [printBtn, setPrintBtn] = useState(false)
    
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [disabledState, setDisabledState] = useState(false);
  const [accountStatusDB, setAccountStatusDB] = useState("Active")
  const [closureAccount, setClosureAccount] = useState({})
    useEffect(() => {
      async function fetchAccount() {
        try {
          setIsLoading(true)
          props.setCheckAccountState(false)
          console.log(props.account)
          const response = await savingAccountApi.getAccount(props.account)
          const accountDB = response.data.data
          props.setState(accountDB)
          console.log("type")
          setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
          if(accountDB.savingAccount?.AccountStatus != "Active")
          {
            setDisabledState(true)
            setCommitBtn(false)
            
          }
          const accountType = accountDB.savingAccount?.Type
          if(accountType === 2 || accountType === 3){
            props.setCheckAccountState(true)
          }else{
            props.setCheckAccountState(false)
          }
          setIsLoading(false)
        } catch (error) {
  
        }
      }
      fetchAccount();
    }, [props.account]);
  
    console.log("fetch account xxxxxxxxxxx")
    
    let savingAccountDB = props.state
    console.log(savingAccountDB)
  
 if(!isLoading)
  if (props.checkAccountState)
  return (
    <>
    <nav>
    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
      <a
        className="nav-item nav-link active"
        id="nav-customerInfo-tab"
        data-toggle="tab"
        href="#customer-info"
        role="tab"
        aria-controls="customer-info"
        aria-selected="true"
      >
        Pre Close
      </a>
      <a
        className="nav-item nav-link"
        id="nav-1-2-tab"
        data-toggle="tab"
        href="#tab1-2"
        role="tab"
        aria-controls="nav-tab1.2"
        aria-selected="false"
      >
        Pre close View
      </a>
      <a
        className="nav-item nav-link"
        id="nav-1-3-tab"
        data-toggle="tab"
        href="#tab1-3"
        role="tab"
        aria-controls="nav-tab1.3"
        aria-selected="false"
      >
        Withdrawal - Term Savings
      </a>
    </div>
  </nav>

  <FieldsetComponent label="">
          <b>Account Status: 
            <span
            style={accountStatusDB === "Active" &&{
               color: "Green"
            }|| {
              color: "Red"
            }}>  {accountStatusDB}</span> </b>
  </FieldsetComponent>

  <div className="tab-content" id="nav-tabContent">
    <div
      className="tab-pane fade show active"
      id="customer-info"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >

      {/* Fieldset 1 */}
      <FieldsetComponent label="Customer Information">
        {/* row 1 */}
        <RowComponent>
          <InputField label="Customer:" type="text" id={`txtCustomer_Close1_1_` + props.id} isDisabled={true}
            value={savingAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName} />
          <div className="col-1"></div>
          <InputField label="Category:" type="text" id={"txtCategory_Close1_1_"  + props.id} isDisabled={true}
            value={getNameFromId(Category, savingAccountDB?.detailAccount?.Category)} />
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField label="Currency:" type="text" id={"txtCurrency_Close1_1_" + props.id} isDisabled={true}
            value={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)} />
          <div className="col-1"></div>
          <InputField label="Product Code:" type="text" id={"txtProductCode_Close1_1_" + props.id} isDisabled={true}
            value={"100"} />
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField
            label="Principal:"
            type="text"
            id={"txtPrincipal_Close1_1_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.PrincipalAmount}
          />
          <div className="col-1"></div>
          <InputField
            label="Maturity Date:"
            type="text"
            id={"txtMaturityDate_Close1_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.MaturityDate}
          />
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField
            label="Interested Rate:"
            type="text"
            id={"txtInterestedRate_Close1_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.InterestRate}
          />
          <div className="col-1"></div>
        </RowComponent>
      </FieldsetComponent>
      <FieldsetComponent label="Pre Close">
        <RowComponent>
          <InputField
            label="Preclose :"
            type="select"
            id={"sltPreClose_Close1_1_"  + props.id}
            isDisabled={true}
            list={TempData.BooleanData}
            defaultValue={"Yes"}
          />
          <div className="col-1"></div>
          <InputField
            label="Waive Charges:"
            type="select"
            id={"sltWaivesChargeClose1_1_" + props.id}
            isDisabled={true}
            list={TempData.BooleanData}
            defaultValue={"Yes"}
          />

        </RowComponent>
        <RowComponent>
          <InputField
            label="Working Account :"
            type="text"
            id={"txtWorkingAccount_Close1_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.WorkingAccountRefID}
          />
          <div className="col-1"></div>
        </RowComponent>
      </FieldsetComponent>
    </div>
    <div
      className="tab-pane fade "
      id="tab1-2"
      role="tabpanel"
      aria-labelledby="nav-1-2-tab"
    >
      <FieldsetComponent label="">
        <RowComponent>
          <InputField
            label="Customer:"
            type="text"
            id={"txtCustomer_Close1_2_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
          />
          <div className="col-1"></div>
          <InputField
            label="Category:"
            type="text"
            id={"txtCategory_Close1_2_"  + props.id}
            isDisabled={true}
            value={getNameFromId(Category, savingAccountDB?.detailAccount?.Category)} />

        </RowComponent>
        <RowComponent>
          <InputField
            label="Currency:"
            type="text"
            id={"txtCurrency_Close1_2_"  + props.id}
            isDisabled={true}
            value={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)} />

          <div className="col-1"></div>
          <InputField
            label="Product Code:"
            type="text"
            id={"txtProductCode_Close1_2_"  + props.id}
            isDisabled={true}
            value={"100"}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Open Date:"
            type="text"
            id={"txtOpenDate_Close1_2_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.ValueDate}
          />
          <div className="col-1"></div>
          <InputField
            label="Maturity Date:"
            type="text"
            id={"txtMaturityDate_Close1_2_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.MaturityDate}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Principal:"
            type="text"
            id={"txtPrincipal_Close1_2_"  + props.id}
            isDisabled={true}
            value={parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
          />
          <div className="col-1"></div>
          <InputField
            label="Original Principal:"
            type="text"
            id={"txtOriginalPrincipal_Close1_2_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.PrincipalAmount}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Interest Rate:"
            type="text"
            id={"txtInterestRate_Close1_2_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.InterestRate}
          />
          <div className="col-1"></div>
          <InputField
            label="Value Date:"
            type="date"
            id={"dtValueDate_Close1_2_"  + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>

        <hr />
        <RowComponent>
          <DataTable columnsTemp={[
            {
              label: "Start Date",
              field: "StartDate",
              width: 150
            },
            {
              label: "End Date",
              field: "EndDate",
              width: 150
            },
            {
              label: "Interest Rate",
              field: "InterestRate",
              width: 150
            },
            {
              label: "Interest Amount",
              field: "InterestAmount",
              width: 150
            }
          ]} rowsTemp={[{
            StartDate: savingAccountDB?.detailAccount?.ValueDate,
            EndDate: savingAccountDB?.detailAccount?.MaturityDate,
            InterestRate: "0.1",
            InterestAmount: Math.round(amountCal(savingAccountDB?.detailAccount?.PrincipalAmount, savingAccountDB?.detailAccount?.InterestRate) * 100) / 100
          }]}
            id="EnquiryTable_1">

          </DataTable>
        </RowComponent>
        <hr />

        <RowComponent>
          <InputField
            label="Total In Amt:"
            type="text"
            id={"txtTotalInAmt_Close1_2_"  + props.id}
            isDisabled={true}
            value={Math.round(amountCal(savingAccountDB?.detailAccount?.PrincipalAmount,savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
          />
          <div className="col-1"></div>
          <InputField
            label="Next Teller Tran:"
            type="text"
            id={"txtNextTellerTran_Close1_2_"  + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>
      </FieldsetComponent>
    </div>
    <div
      className="tab-pane fade"
      id="tab1-3"
      role="tabpanel"
      aria-labelledby="nav-1-3-tab"
    >
      <FieldsetComponent label="">
        <RowComponent>
          <InputField
            label="Payment No:"
            type="text"
            id={"txtPaymentNo_Close1_3_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.detailAccount?.PaymentNo}
          />
          <div className="col-1"></div>
          <InputField
            label="Currency:"
            type="text"
            id={"txtCurrency_Close1_3_" +  + props.id}
            isDisabled={true}
            value={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Customer:"
            type="text"
            id={"txtCustomer_Close1_3_"  + props.id}
            isDisabled={true}
            value={savingAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " +savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
          />
          <div className="col-1"></div>
          <InputField
            label="Dr Account:"
            type="text"
            id={"txtDrAccount_Close1_3_" + props.id}
            isDisabled={disabledState}
            value={savingAccountDB?.detailAccount?.WorkingAccountRefID}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Amount LCY:"
            type="number-nohandle"
            id={"txtAmountLCY_Close1_3_"  + props.id}
            isDisabled={disabledState}
            value={savingAccountDB?.detailAccount?.Currency === 12 ? parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100 : null}
          />
          <div className="col-1"></div>
          <InputField
            label="Amount FCY:"
            type="number-nohandle"
            id={"txtAmountFCY_Close1_3_"  + props.id}
            isDisabled={disabledState}
            value={savingAccountDB?.detailAccount?.Currency !== 12 ? parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100 : null}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Narrative:"
            type="text"
            id={"txtNarrative_Close1_3_"  + props.id}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>

        </RowComponent>
        <RowComponent>
          <InputField
            label="New Customer Balance:"
            type="text"
            id={"txtNewCustomerBalance_Close1_3_" + props.id}
            isDisabled={true}
            value={"-" + parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + parseFloat(Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100)}
          />
          <div className="col-1"></div>
          <InputField
            label="Amount Paid to Customer:"
            type="text"
            id={"txtAmountPaidCustomer_Close1_3_"  + props.id}
            isDisabled={true}
            value={parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
          />
        </RowComponent>

      </FieldsetComponent>
      <FieldsetComponent label="">
        <RowComponent>
          <InputField
            label="Deal Rate:"
            type="number-nohandle"
            id={"txtDealRate_Close1_3_"  + props.id}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>

        </RowComponent>
      </FieldsetComponent>
      <FieldsetComponent label="">
        <RowComponent>
          <InputField
            label="For Teller:"
            type="text"
            id={"txtForTeller_Close1_3_"  + props.id}
            isDisabled={disabledState}
            value={""}
          />
          <div className="col-1">{savingAccountDB?.detailAccount?.Teller || ""}</div>
          <InputField
            label="Credit CCY:"
            type="select"
            id={"sltCreditCCY_Close1_3_"  + props.id}
            isDisabled={disabledState}
            list={Currency_2}
            defaultValue={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Credit Account:"
            type="select"
            id={"sltCreditAccount_Close1_3_"  + props.id}
            list={SavingCurrencyAccount}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>
          <InputField
            label="Print Ln No of:"
            type="text"
            id={"txtPrintNo_Close1_3_"  + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>
      </FieldsetComponent>
    </div>
  </div>
  </>
  )
  else
  return (
    <div>
      Account does not existed.
    </div>
  )
}



function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
  
  function getNameFromId(list, id) {
    const obj = list.find(obj => obj.id === id);
    return obj ? obj.name : null;
  }
  
  
  function currencyFormat(value, currency) {
    let resultValue = value ? value.toLocaleString('it-IT', { style: 'currency', currency: currency }) : "";
    console.log(resultValue.slice(0, -4))
    return resultValue.slice(0, -4)
  }
  
  
  function amountCal(origin, rate) {
    console.log(origin)
    console.log(rate)
    return origin * 0.0004316238 * rate
  }