
import React, { useEffect, useState } from "react";
import chargeCollectionApi from "../../../../apis/chargeCollectionApi";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import ChargeCategory from "../../../../data/ChargeCategory";
import CityList from "../../../../data/CityList";
import Currency_1 from "../../../../data/Currency_1";
import ProductLine from "../../../../data/ProductLine";
import TempData from "../../../../data/TempData";

export default function Enquiry_CC() {
  const [activeTab, setActiveTab] = useState("active");
  const [rowState, setRowState] = useState([]);
  const handleSetRowState = (row) => {
    setRowState(row);
  };

  const [getCategory, setCategory] = useState(0);
  const Cell = ({ row }) => (
      <button className="btn btn-sm btn-primary me-2">Edit</button>

  );
 
  return (
    <div>
      {/* button action  */}
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{ height: "50px", backgroundColor: "#eceff4", margin: "3px" }}
      >
        {/* Button: Commit Data */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          onClick={() => {}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-save-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" /></svg>
          <div style={{ display: "inline" }}> Commit Data </div>
        </div>
        {/* Button: Authorize */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          // on Click
          onClick={() => {}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-check-circle-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>
          Authorize
        </div>
        {/* Button: Search */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          onClick={ ()=> {

              let params = {
                chargeType: getIdFromName(CollectionType, document.getElementById('sltCollectionType')?.value) == 0 ? null : getIdFromName(CollectionType, document.getElementById('sltCollectionType')?.value),
                chargeID: document.getElementById("txtReferenceID")?.value || null,
                customerID:  document.getElementById("txtCustomerID")?.value || null,
                customerName: document.getElementById("txtCustomerName")?.value || null,
                legalID: document.getElementById("txtLegalID")?.value || null,
                accountID: document.getElementById("txtAccountID")?.value || null,
                accountType:  getIdFromName(AccountType, document.getElementById('sltAccountType')?.value) == 0 ? null : getIdFromName(AccountType, document.getElementById('sltAccountType')?.value),
                chargesAmountfr: document.getElementById("txtAmtFrom")?.value || null,
                chargesAmountto: document.getElementById("txtAmtTo")?.value || null,
              }
            
              chargeCollectionApi.enquiry(params)
              .then(response => {
                console.log(response.data.data)
                let tempRow = []
                let tempObject = {}
                response.data.data.map((item, index) => {
                  if(getIdFromName(CollectionType, document.getElementById('sltCollectionType')?.value) == 1){
                    tempObject = {
                      Code: item.RefID,
                      AccountType: getNameFromId(AccountType ,item.AccountType),
                      Account: item.Account,
                      Category: getNameFromId(ChargeCategory, item.Category),
                      ChargeAmount:  item.ChargeAmountLCY,
                      Status: getNameFromId(TempData.Status, item.Status),
                      Button: {
                        render: () => {
                          return <div>Edit</div>;
                        }
                      }
                    }
                  }else{
                    tempObject = {
                      Code: item.CC.RefID,
                      AccountType: "Non Term Saving Account",
                      Account: "1001-1126-2002",
                      Category: getNameFromId(ChargeCategory, item.CC.Category),
                      ChargeAmount:  item.CC.ChargeAmountLCY,
                      Status: getNameFromId(TempData.Status, item.CC.Status),
                      Button: {
                        render: () => {
                          return <div>Edit</div>;
                        }
                      }
                    }
                  }
                  
                  tempRow.push(tempObject)
                })
                handleSetRowState(tempRow)
              })
              .catch(error => {
                console.log(error)
              });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-search"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
          Search
        </div>
        {/* Button: Print Deal Slip */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-printer-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" /><path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /></svg>
          Print Deal Slip
        </div>
        {/* Button: Edit Data  */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-pencil-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" /></svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Enquiry Charges Collection
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>

      {/* Fieldset 1 */}
      <FieldsetComponent label="">
        {/* row 1 */}
        <RowComponent>
          <InputField label="Collection Type:" type="select" id="sltCollectionType" list={CollectionType} 
            require={true}
            defaultValue={"Collect Charges From Account"}/>
          <div className="col-1"></div>
          <InputField label="Reference ID:" type="text" id="txtReferenceID" />
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField
            label="Account Type:"
            type="select"
            id="sltAccountType"
            list={AccountType}
          />
          <div className="col-1"></div>
          <InputField
            label="Account ID:"
            type="text"
            id="txtAccountID"
          />
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField
            label="Customer ID:"
            type="text"
            id="txtCustomerID"
          />
          <div className="col-1"></div>
          <InputField
            label="Customer Name:"
            type="text"
            id="txtCustomerName"
          />
        </RowComponent>
        {/* row 5 */}
        <RowComponent>
          <InputField
            label="Legal ID:"
            type="text"
            id="txtLegalID"
          />
          <div className="col-1"></div>
        </RowComponent>
        {/* row 5 */}
        <RowComponent>
          <InputField
            label="Charges Amount From:"
            type="number-nohandle"
            id="txtAmtFrom"
          />
          <div className="col-1"></div>
          <InputField
            label="Charges Amount To:"
            type="number-nohandle"
            id="txtAmtTo"
          />
        </RowComponent>
      </FieldsetComponent>

      <DataTable columnsTemp={columns} rowsTemp={rowState}/>
    </div>
    
  );
}

const CollectionType = [
  { "id": 1, "name": "Collect Charges From Account"},
  { "id": 2, "name": "Collect Charges By Cash"},
]


const columns = [
  {
    label: 'Code',
    field: 'Code',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Account Type',
    field: 'AccountType',
    sort: 'asc',
    width: 200
  },
  {
    label: 'Account',
    field: 'Account',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Category',
    field: 'Category',
    sort: 'asc',
    width: 250
  },
  {
    label: 'Charge Amount',
    field: 'ChargeAmount',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Status',
    field: 'Status',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Button',
    field: 'button',
    sort: 'asc',
    width: 150,
    // cell: 
      
  },
]
const rows =  [
  {
    name: 'Tiger Nixon',
    position: 'System Architect',
    office: 'Edinburgh',
    age: '61',
    date: '2011/04/25',
    salary: '$320'
  },
  
]


function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function getNameFromId(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}


function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}
