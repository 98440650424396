import React from 'react'

export default function FieldsetComponent(props) {
  return (
    <div
      className="row"
      style={{
        margin: "20px 0px",
        padding: "0",
        width: "100%",
      }}
    >
    <fieldset
      style={{
        width: "100%",
        border: "2px solid #ddd",
        padding: "10px",
        paddingLeft: "20px",
        paddingBottom: "20px",
      }}
    >
      <legend style={{ width: "auto", fontSize: "19px", fontWeight: "600" }}>
        {props.label}
      </legend>
      {props.children}
    </fieldset>
    </div>
  );
}
