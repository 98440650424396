import React from 'react'
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField'
import RowComponent from '../../../../components/RowComponent/RowComponent'

export default function Enquiry_CI(props) {
  return (
    <div>
              <hr></hr>
      {/* Fieldset 1 */}
      <FieldsetComponent>
        {/* row 1 */}
        <RowComponent>
          <InputField
            label="Cheque Reference:"
            type="text"
            id={`txtChequeReference_` + props.id}
          />
          <div className="col-1"></div>
          <InputField
            label="Working Account:"
            type="text"
            // id="txtCustomerAccount"
            id={`txtWorkingAccount_` + props.id}
          />
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField
            label="Cheque Type:"
            type="select"
            id={`sltChequeType_` + props.id}
            list={AccountType}
          />
          <div className="col-1"></div>
          <InputField
            label="Issued Date:"
            type="date"
            id={`dtIssuedDate_` + props.id}
          />
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField
            label="Cheque Number:"
            type="text"
            id={`txtChequeNumber_` + props.id}
          />
        </RowComponent>
      </FieldsetComponent>

    </div>
  )
}



const AccountType = [
    { "id": 1, "name": 'Current & Non-Term Saving Account'},
    { "id": 2, "name": 'Saving Account - Arrear'},
    { "id": 3, "name": 'Saving Account - Periodic'},
    { "id": 4, "name": 'Saving Account - Discounted'},
    { "id": 5, "name": 'Loan Working Account'},
  ];
