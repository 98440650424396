import React from 'react'

export default function Home() {
  return (
    <div 
        style={{
            height: "500px"
        }}
    ></div>
  )
}
