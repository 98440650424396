import { Autocomplete, debounce, TextField } from '@mui/material';
import React, { useState } from 'react'

export default function InputField(props) {
  const [isShown, setIsShown] = useState(false);

  const handleChange = (event, value) => {
    props.setState(value)
  };

const {type} = props;

let invalid=''
if (props.required == true) invalid ='is-invalid'

const [check, setCheck] = useState(invalid)

const handleCheck = (n) => {
    console.log(n)
    if (n > 0)
    setCheck('');
    else setCheck('is-invalid');
};
// Control value - disabled

let inputElement = null;
switch (type) {
    case 'text':
        inputElement = (
          <input type="text" 
          className={`form-control`} id={props.id} placeholder={props.ph}   disabled={props.isDisabled} defaultValue={(props.isDisabled? props.state : null) || (props.value) ? props.value : null }
          // className={`form-control ${check}`} id={props.id} placeholder={props.ph} 
          // onChange={() => {}} 
          />
        );
        break;
    case 'text-handler':
      inputElement = (
        <input type="text" 
        className={`form-control`} id={props.id} placeholder={props.ph}   disabled={props.isDisabled} defaultValue={(props.isDisabled? props.state : null) || (props.value) ? props.value : null }
        // className={`form-control ${check}`} id={props.id} placeholder={props.ph} 
        onChange={debounce((event) => {
          props.setState(event.target.value)
        }, 500)
      }
        
        />
      );
      break;
      
    case 'number-nohandle':
          inputElement = (
            <input type="number" 
            className={`form-control`} id={props.id} placeholder={props.ph}   disabled={props.isDisabled} defaultValue={(props.isDisabled? props.state : null) || (props.value) ? props.value : null }
            // className={`form-control ${check}`} id={props.id} placeholder={props.ph} 
            />
          )
          break
    case 'number':
      inputElement = (
        <input type="number" 
        className={`form-control`} id={props.id} placeholder={props.ph}   disabled={props.isDisabled} defaultValue={(props.isDisabled? props.state : null) || (props.value) ? props.value : null }
        // className={`form-control ${check}`} id={props.id} placeholder={props.ph} 
        onChange={(event) => {
            props.setState(event.target.value)
          }
          
        }
        />
      )
      break
    case 'date':
        inputElement = (
            <input type="date" className="form-control" id={props.id} disabled={props.isDisabled} defaultValue={(props.isDisabled? props.state : null) || (props.value) ? props.value : null }></input>
        );
        break;
    case 'date-handle':
    inputElement = (
        <input type="date" className="form-control" id={props.id} disabled={props.isDisabled} defaultValue={(props.isDisabled? props.state : null) || (props.value) ? props.value : null }
        onChange={(event) => {
          props.setState(event.target.value)
        }}></input>
    );
    break;
    case 'select-depend':
          inputElement = (
            // <select
            //   className={`form-control selectpicker border ${check}`}
            //   data-live-search="true"
            //   id={props.id}
            // >
            //   {props.list.map((item, index) => {
            //     return <option key={item.id}>{item.name}</option>;
            //   })}
            // </select>
            <Autocomplete
              defaultValue={props.defaultValue ? props.defaultValue : null}
              disablePortal
              id={props.id}
              options={props.list.map((options, index) => options.name)}
              size="small"
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
              disabled={props.isDisabled}
            />
          )
        break;
      case 'select':
          inputElement = (
            // <select
            //   className={`form-control selectpicker border ${check}`}
            //   data-live-search="true"
            //   id={props.id}
            // >
            //   {props.list.map((item, index) => {
            //     return <option key={item.id}>{item.name}</option>;
            //   })}
            // </select>
            <Autocomplete
              defaultValue={props.defaultValue ? props.defaultValue : null}
              disablePortal
              id={props.id}
              options={props.list.map((options, index) => options?.name)}
              size="small"
              renderInput={(params) => <TextField {...params}disabled={props.isDisabled}/>}
              disabled={props.isDisabled}
            />
          )
        break;
    default:
        inputElement = <input type="text" />;
      
}

  return (
    <>
      <div className="col-2">
        <label style={{ fontWeight: "600" }}>{props.label}</label>
        {props.required == true && <div style={{display: 'inline-block', fontWeight: "600", marginLeft: '5px', color:'red' }}>(*)</div>}
      </div>
      <div className={props.size ? props.size : "col-3"} 
        // onMouseOver={() => setIsShown(true)}
        // onMouseOut={() => setIsShown(false)}
        >{inputElement}</div>
    </>
  );
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}


