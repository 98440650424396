const CityList = [
    {"id": 0, "name": ""},
    {"id": 1, "name": "01 - Thành phố Hà Nội"},
    {"id": 2, "name": "02 - Tỉnh Hà Giang"},
    {"id": 3, "name": "04 - Tỉnh Cao Bằng"},
    {"id": 4, "name": "06 - Tỉnh Bắc Kạn"},
    {"id": 5, "name": "08 - Tỉnh Tuyên Quang"},
    {"id": 6, "name": "10 - Tỉnh Lào Cai"},
    {"id": 7, "name": "11 - Tỉnh Điện Biên"},
    {"id": 8, "name": "12 - Tỉnh Lai Châu"},
    {"id": 9, "name": "14 - Tỉnh Sơn La"},
    {"id": 10, "name": "15 - Tỉnh Yên Bái"},
    {"id": 11, "name": "17 - Tỉnh Hòa Bình"},
    {"id": 12, "name": "19 - Tỉnh Thái Nguyên"},
    {"id": 13, "name": "20 - Tỉnh Lạng Sơn"},
    {"id": 14, "name": "22 - Tỉnh Quảng Ninh"},
    {"id": 15, "name": "24 - Tỉnh Bắc Giang"},
    {"id": 16, "name": "25 - Tỉnh Phú Thọ"},
    {"id": 17, "name": "26 - Tỉnh Vĩnh Phúc"},
    {"id": 18, "name": "27 - Tỉnh Bắc Ninh"},
    {"id": 19, "name": "28 - Tỉnh Hà Tây"},
    {"id": 20, "name": "30 - Tỉnh Hải Dương"},
    {"id": 21, "name": "31 - Thành phố Hải Phòng"},
    {"id": 22, "name": "33 - Tỉnh Hưng Yên"},
    {"id": 23, "name": "34 - Tỉnh Thái Bình"},
    {"id": 24, "name": "35 - Tỉnh Hà Nam"},
    {"id": 25, "name": "36 - Tỉnh Nam Định"},
    {"id": 26, "name": "37 - Tỉnh Ninh Bình"},
    {"id": 27, "name": "38 - Tỉnh Thanh Hóa"},
      {
        "id": 28,
        "name": "40 - Tỉnh Nghệ An"
      },
      {
        "id": 29,
        "name": "42 - Tỉnh Hà Tĩnh"
      },
      {
        "id": 30,
        "name": "44 - Tỉnh Quảng Bình"
      },
      {
        "id": 31,
        "name": "45 - Tỉnh Quảng Trị"
      },
      {
        "id": 32,
        "name": "46 - Tỉnh Thừa Thiên Huế"
      },
      {
        "id": 33,
        "name": "48 - Thành phố Đà Nẵng"
      },
      {
        "id": 34,
        "name": "49 - Tỉnh Quảng Nam"
      },
      {
        "id": 35,
        "name": "51 - Tỉnh Quảng Ngãi"
      },
      {
        "id": 36,
        "name": "52 - Tỉnh Bình Định"
      },
      {
        "id": 37,
        "name": "54 - Tỉnh Phú Yên"
      },
      {
        "id": 38,
        "name": "56 - Tỉnh Khánh Hòa"
      },
      {
        "id": 39,
        "name": "58 - Tỉnh Ninh Thuận"
      },
      {
        "id": 40,
        "name": "60 - Tỉnh Bình Thuận"
      },
      {
        "id": 41,
        "name": "62 - Tỉnh Kon Tum"
      },
      {
        "id": 42,
        "name": "64 - Tỉnh Gia Lai"
      },
      {
        "id": 43,
        "name": "66 - Tỉnh Đăk Lăk"
      },
      {
        "id": 44,
        "name": "67 - Tỉnh Đăk Nông"
      },
      {
        "id": 45,
        "name": "68 - Tỉnh Lâm Đồng"
      },
      {
        "id": 46,
        "name": "70 - Tỉnh Bình Phước"
      },
      {"id":47,"name":"72 - Tỉnh Tây Ninh"},
    {"id":48,"name":"74 - Tỉnh Bình Dương"},
    {"id":49,"name":"75 - Tỉnh Đồng Nai"},
    {"id":50,"name":"77 - Tỉnh Bà Rịa - Vũng Tàu"},
    {"id":51,"name":"79 - Thành phố Hồ Chí Minh"},
    {"id":52,"name":"80 - Tỉnh Long An"},
    {"id":53,"name":"82 - Tỉnh Tiền Giang"},
    {"id":54,"name":"83 - Tỉnh Bến Tre"},
    {"id":55,"name":"84 - Tỉnh Trà Vinh"},
    {"id":56,"name":"86 - Tỉnh Vĩnh Long"},
    {"id":57,"name":"87 - Tỉnh Đồng Tháp"},
    {"id":58,"name":"89 - Tỉnh An Giang"},
    {"id":59,"name":"91 - Tỉnh Kiên Giang"},
    {"id":60,"name":"92 - Thành phố Cần Thơ"},
    {"id":61,"name":"93 - Tỉnh Hậu Giang"},
    {"id":62,"name":"94 - Tỉnh Sóc Trăng"},
    {"id":63,"name":"95 - Tỉnh Bạc Liêu"},
    {"id":64,"name":"96 - Tỉnh Cà Mau"}
      
]

export default CityList