import LayoutBasic from "../components/LayoutBasic.js/LayoutBasic"
import Login from "../components/Login"
import Home from "../pages/Home"
import CashDeposit from "../pages/TellerOperation/AccountManagement/AccountTransaction/CashDeposit"
import CashTransfer from "../pages/TellerOperation/AccountManagement/AccountTransaction/CashTransfer"
import CashWithdrawal from "../pages/TellerOperation/AccountManagement/AccountTransaction/CashWithdrawal"

import OpenAccount_MA from "../pages/TellerOperation/AccountManagement/MainAccount/OpenAccount_MA"
import EnquiryAccount_MA from "../pages/TellerOperation/AccountManagement/MainAccount/EnquiryAccount_MA"
import CloseAccount_MA from "../pages/TellerOperation/AccountManagement/MainAccount/CloseAccount_MA"
import BlockAccount_MA from "../pages/TellerOperation/AccountManagement/MainAccount/BlockAccount_MA"
import UnBlockAccount_MA from "../pages/TellerOperation/AccountManagement/MainAccount/UnBlockAccount_MA"


import Capture_SM from "../pages/TellerOperation/SignatureManagement/Capture_SM"
import Enquiry_SM from "../pages/TellerOperation/SignatureManagement/Enquiry_SM"
import Amend_SM from "../pages/TellerOperation/SignatureManagement/Amend_SM"

import Open_ArrearSA from "../pages/TellerOperation/AccountManagement/SavingAccount/Open_ArrearSA"
import Close_SA from "../pages/TellerOperation/AccountManagement/SavingAccount/Close_SA"
import Enquiry_SA from "../pages/TellerOperation/AccountManagement/SavingAccount/Enquiry_SA"

import ChequeIssuance from "../pages/TellerOperation/AccountManagement/ChequeManagement/ChequeIssuance"
import ChequeWithdrawal from "../pages/TellerOperation/AccountManagement/ChequeManagement/ChequeWithdrawal"
import ChequeTransfer from "../pages/TellerOperation/AccountManagement/ChequeManagement/ChequeTransfer"

import CreditCardPayment from "../pages/TellerOperation/AccountManagement/AccountTransaction/CreditCardPayment"
import CITransaction from "../pages/TellerOperation/AccountManagement/AccountTransaction/CITransaction"

import Frequency from "../pages/TellerOperation/AccountManagement/SalaryPayment/Frequency"
import NonFrequency from "../pages/TellerOperation/AccountManagement/SalaryPayment/NonFrequency"

import FromAccount_CC from "../pages/TellerOperation/AccountManagement/CollectCharges/FromAccount_CC"
import ByCash_CC from "../pages/TellerOperation/AccountManagement/CollectCharges/ByCash_CC"
import Enquiry_CC from "../pages/TellerOperation/AccountManagement/CollectCharges/Enquiry_CC"




import OpenCorporateCustomer from "../pages/TellerOperation/CustomerManagement/OpenCorporateCustomer"
import OpenIndividualCustomer from "../pages/TellerOperation/CustomerManagement/OpenIndividualCustomer" 
import ForeignExchange from "../pages/TellerOperation/ForeignExchange/ForeignExchange"
import EnquiryCustomer from "../pages/TellerOperation/CustomerManagement/EnquiryCustomer"
import BackupComponent from "../pages/TellerOperation/CustomerManagement/backup"
import Open_PeriodicSA from "../pages/TellerOperation/AccountManagement/SavingAccount/Open_PeriodicSA"
import Open_DiscountedSA from "../pages/TellerOperation/AccountManagement/SavingAccount/Open_DiscountedSA"
import SalaryPayment from "../pages/TellerOperation/AccountManagement/SalaryPayment/SalaryPayment"


// Public Routes
const publicRoutes = [
    { path: '/login', component: Login},
    // { path: '*', component: Login}
]

// Private Routes
const privateRoutes = [
    // { path: '/login', component: LoginPage},
    // { path: '/login', component: Login},

    { path: '/backup', component: BackupComponent},
    

    { path: '/', component: Home},
    { path: '/open_individual_customer', component: OpenIndividualCustomer},
    { path: '/open_corporate_customer', component: OpenCorporateCustomer},
    { path: '/enquiry_customer', component: EnquiryCustomer},
    
    //  Account Management
    { path: '/open_account_ma', component: OpenAccount_MA},
    { path: '/enquiry_account_ma', component: EnquiryAccount_MA},
    { path: '/close_account_ma', component: CloseAccount_MA},
    { path: '/block_account_ma', component: BlockAccount_MA},
    { path: '/unblock_account_ma', component: UnBlockAccount_MA},

    // Signature Management
    { path: '/capture_sm', component: Capture_SM},
    { path: '/enquiry_sm', component: Enquiry_SM},
    { path: '/amend_sm', component: Amend_SM},
    

    // Account Transaction
    { path: '/cash_deposit', component: CashDeposit},
    { path: '/cash_withdrawal', component: CashWithdrawal},
    { path: '/cash_transfer', component: CashTransfer},
    { path: '/ci_transaction_at', component: CITransaction},
    { path: '/credit_card_payment_at', component: CreditCardPayment},

    // Cheque Issuance
    { path: '/cheque_issuance_cm', component: ChequeIssuance},
    { path: '/cheque_withdrawal_cm', component: ChequeWithdrawal},
    { path: '/cheque_transfer_cm', component: ChequeTransfer},

    // Salary Payment
    { path: '/salary_payment', component: SalaryPayment},
    // { path: '/non_frequency_sp', component: NonFrequency},

    // Collect Charges
    { path: '/by_cash_cc', component: ByCash_CC},
    { path: '/enquiry_cc', component: Enquiry_CC},
    { path: '/from_account_cc', component: FromAccount_CC},

    // saving account
    { path: '/open_arrearsa', component: Open_ArrearSA},
    { path: '/open_periodicsa', component: Open_PeriodicSA},
    { path: '/open_discountedsa', component: Open_DiscountedSA},
    { path: '/close_sa', component: Close_SA},
    { path: '/enquiry_sa', component: Enquiry_SA},
 



    // Foreign Exchange
    // { path: '/foreign_exchange', component: ForeignExchange},
    { path: '/foreign_exchange_fe', component: ForeignExchange},
    { path: '/enquiry_foreign_exchange_fe', component: Enquiry_SA},

]


export { publicRoutes, privateRoutes }