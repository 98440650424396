import React, { useState, useEffect } from 'react'
import { Button, Modal, Overlay, Popover } from 'react-bootstrap';
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from '../../../../apis/mainAccountApi';
import savingAccountApi from '../../../../apis/savingAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountOfficers from '../../../../data/AccountOfficer';
import Category from '../../../../data/Category';
import ChargeCode from '../../../../data/ChargeCode';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import Currency_2 from '../../../../data/Currency_2';
import ProductLine from '../../../../data/ProductLine';
import RelationCode from '../../../../data/RelationCode';
import SavingTerm from '../../../../data/SavingTerm';
import TempData from '../../../../data/TempData';

export default function Open_ArrearSA_Modal(props) {
            // 1. State check required
        const [arrRe, setArrRe] = useState([])
        const handleSetArrRe = (item) => {setArrRe(item);};
        // 2. State show Error
        const [showErrorModal, setShowErrorModal] = useState(false);
        const [errorMessage, setErrorMessage] = useState("error");
        const handleCloseErrorModal = () => setShowErrorModal(false);
        const handleShowErrorModal = () => setShowErrorModal(true);
        // 3. State show Success
        const [showSuccessModal, setShowSuccessModal] = useState(false);
        const handleCloseSuccessModal = () => setShowSuccessModal(false);
        const handleShowSuccessModal = () => setShowSuccessModal(true);
        // 5. State show Backend Error
        const [showBEError, setShowBEError] = useState(false);
        const handleCloseBEErrorModal = () => setShowBEError(false);
        const handleShowBEErrorModal = () => setShowBEError(true);

        // State response
        const [OKMessage, setOKMessage] = useState(""); 

        const [activeTab, setActiveTab] = useState("active");
            // Loading
        const [isLoading, setIsLoading] = useState(true);
        const [isLoadingList, setIsLoadingList] = useState(true);

        const [existedAccountDB, setExistedAccountDB] = useState({})
        // 4. Select State
        const [getCurrency, setCurrency] = useState(0);
        const [getCategory, setCategory] = useState(0);
                // TERM
{       const [selectedTerm, setSelectedTerm] = useState(0)
        const [selectedDate, setSelectedDate] = useState("")
        // GET CUSTOMER LIST
        const [customerList, getCustomerList] = useState([])
        // GET WORKING ACCOUNT LIST
        const [iniFormatedAccounts, setIniFormatedAccounts] = useState([])
        const [workingAccountList, setWorkingAccountList] = useState([])
        const [selectedCustomer, setSelectedCustomer] = useState([])
        const [formatedAccounts, setFormatedAccounts] = useState([])
        const [selectedAccount, setSelectedAccount] = useState(0)
        const [getPrincipal, setPrincipal] = useState(0)
        const [paymentCCYState, setPaymentCCYState] = useState(0)

        //HANDLE BUTTON
        const [commitBtn, setCommitBtn] = useState(false)
        const [authorBtn, setAuthoBtn] = useState(false)
        const [editBtn, setEditBtn] = useState(true)
        const [searchBtn, setSearchBtn] = useState(false)
        const [printBtn, setPrintBtn] = useState(false)

        useEffect(() => {
            console.log("principal Amount")
            console.log(getPrincipal)
            if(document.getElementById("txtAccountLCY" + props.id) && document.getElementById("txtAccountFCY" + props.id) && document.getElementById("txtNarrative_3.3" + props.id) && document.getElementById("txtAccountInLCY" + props.id)){
                document.getElementById("txtAccountInLCY" + props.id).value=getPrincipal
                document.getElementById("txtNarrative_3.3" + props.id).value= "Amount = " + getPrincipal
                if(document.getElementById("txtAccountCurrency_3" + props.id).value === "VND"){
                     document.getElementById("txtAccountLCY" + props.id).value=getPrincipal
                     document.getElementById("txtAccountFCY" + props.id).value=0
                }
                else {
                    document.getElementById("txtAccountFCY" + props.id).value=getPrincipal
                    document.getElementById("txtAccountLCY" + props.id).value=0
                }
            }

        }, [getPrincipal]);
        

        // GET ACCOUNT FROM DB
        useEffect(() => {
            async function fetchAccount() {
                try {
                const response = await savingAccountApi.getAccount(props.modalInstanceID)
                let tempAccount = response.data.data
                setExistedAccountDB(tempAccount)
                
                // Set INI State
                setCurrency(getNameFromId(Currency_1, tempAccount.detailAccount?.Currency))
                setSelectedCustomer(tempAccount?.savingAccount?.CUSTOMER?.RefID + " - " + tempAccount?.savingAccount?.CUSTOMER?.GB_FullName)
                setPrincipal(tempAccount?.detailAccount?.PrincipalAmount)
                // Authourize State
                let iniStatus = tempAccount.savingAccount?.Status
                if(iniStatus === 1 && localStorage.getItem('whoami') === 'qtv'){
                    setAuthoBtn(true)
                }else 
                    setAuthoBtn(false)
                setIsLoading(false);
            } catch (error) {
    
                }
            }
            fetchAccount();
        }, [props.modalInstanceID]);
        console.log("account fetch")
        console.log(existedAccountDB)

        let test = getNameFromId(Category, existedAccountDB?.detailAccount?.Category ) || "test"
        



        useEffect(() => {
        async function fetchCustomerList() {
            try {
            const response = await cusApi.getAll()
            const customerListDB = response.data.data.customer
            getCustomerList(customerListDB)
            } catch (error) {

            }
        }
        fetchCustomerList();
        }, []);

        let customerFormatList = [{"id": 0, "name": ""}]
        if(customerList.length != 0){
        customerList.map((value, index) => {
            let temp = {"id": value.customer?.id, "name": value.customer?.RefID + " - " + value.customer?.GB_FullName}
            customerFormatList.push(temp)
        })
        }

        useEffect(() => {
        async function fetchWorkingAccount() {
            try {
            const response = await mainAccountApi.enquiry({})
            const accountListDB = response.data.data
            console.log("fetch account")
            console.log(accountListDB)
            // FORMAT
            let tempList = [{"id": 0, "name": ""}]
            accountListDB.map((value, index) => {
                let temp = {"id": value.id, "name": value.Account}
                tempList.push(temp)
            })
            setIniFormatedAccounts(tempList)
            setWorkingAccountList(accountListDB)
            

            // CURRENCY CUSTOMER ACCOUNT LIST
            let tempList2 = [{"id": 0, "name": ""}]
            accountListDB.map((value, index) => {
                if(value.CustomerID == getIdFromName(customerFormatList, selectedCustomer) && value.Currency == getIdFromName(Currency_1, getCurrency)){
                    let temp = {"id": value.id, "name": value.Account}
                    tempList.push(temp)
                }
                })
                setFormatedAccounts(tempList2)
                setIsLoadingList(false)
            } catch (error) {

            }
        }
        fetchWorkingAccount();
        }, []);
        // SELECTED CUSTOMER

        useEffect(() => {
            // hien thi cac truong thong tin customer

            let selectedCustomerObj = customerList.filter((value) => {
            return value.customer.id === getIdFromName(customerFormatList, selectedCustomer)
            })
            console.log('selectedCustomerObj')
            if(selectedCustomerObj[0]?.customer && document.getElementById("txtCustomer_2" + props.id) && document.getElementById("txtCategory_2" + props.id) && document.getElementById("txtCurrency_2" + props.id)){
            document.getElementById("txtCustomer_2" + props.id).value = selectedCustomerObj[0]?.customer?.RefID + " " + selectedCustomerObj[0]?.customer?.GB_FullName 
            
            
            document.getElementById("txtCategory_2" + props.id).value = getCategory !== 0 ? getCategory : null
            
            document.getElementById("txtCurrency_2" +  props.id).value = getCurrency !== 0 ? getCurrency : null
            }
            // filter mang account
            let tempList = [{"id": 0, "name": ""}]
            if(workingAccountList){
                workingAccountList.map((value, index) => {
                console.log(value)
                if(value.CustomerID == getIdFromName(customerFormatList, selectedCustomer) && value.Currency == getIdFromName(Currency_1, getCurrency)){
                    let temp = {"id": value.id, "name": value.Account}
                    tempList.push(temp)
                }
                })
                setFormatedAccounts(tempList)
            }
        }, [selectedCustomer, getCategory, getCurrency]);



        useEffect(() => {
            if(document.getElementById("dtMaturityDate_2" + props.id)){
                let tempTerm = SavingTerm.find(obj => obj.name === selectedTerm)
                const tempDate = addMonths(tempTerm?.value, selectedDate)
                let tempMonth = tempDate.getMonth() < 10 ? "0" + tempDate.getMonth() : tempDate.getMonth()
                let tempDay = tempDate.getDate() < 10 ? "0" + tempDate.getDate() : tempDate.getDate()
                let dateString = tempDate.getFullYear() + "-" + tempMonth + "-" + tempDay
                document.getElementById("dtMaturityDate_2"+props.id).value = dateString
            }
        }, [selectedTerm, selectedDate]);


        if(!isLoading && !isLoadingList)
        return (
        <div>
            <div
            className="row align-items-center text-center mb-3"
            style={{
                height: "50px",
                backgroundColor: "#eceff4",
                margin: "3px",
                position: 'sticky',
                top: '0',
                zIndex: '10',
                marginTop: '0',
                marginLeft: '0',
                marginRight: '0'
            }}
            >
                <div
                className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
                style={ commitBtn && {
                    borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 1)",
                    height: "100%",
                    lineHeight: "50px",
                } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                        fontWeight: "",
                        color: "rgba(107,107,107, 0.2)",
                        height: "100%",
                        lineHeight: "50px",}}
                onClick={ async (event)=> {
                    checkRequired(props.id);
                    if (arrRequired.length > 0) {
                    setShowErrorModal(true);
                    setArrRe(arrRequired);
                    } else if(!props.disabledState) {
                    const params = {

                        customerID: getIdFromName(customerFormatList, document.getElementById('sltCustomerID' + props.id)?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltCustomerID' + props.id)?.value),
                        valueDate: document.getElementById('dtValueDate_2' + props.id).value || null,
                        workingAccount: getIdFromName(formatedAccounts, document.getElementById('sltWorkingAccount_2' + props.id)?.value) == 0 ? null : getIdFromName(formatedAccounts, document.getElementById('sltWorkingAccount_2' + props.id)?.value),
                        workingAccountRefID: document.getElementById('sltWorkingAccount_2' + props.id)?.value,
                        narrative: document.getElementById('txtNarrative_3' + props.id).value || null,
                        teller: document.getElementById('txtForTeller_3' + props.id).value || null,
                        paymentCurrency:  getIdFromName(Currency_2, document.getElementById('sltPaymentCCY_3' + props.id)?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltPaymentCCY_3' + props.id)?.value),
                        currency:  getIdFromName(Currency_2, document.getElementById('sltCurrency' + props.id)?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltCurrency' + props.id)?.value),
                        debitAccount: getIdFromName(formatedAccounts, document.getElementById('sltDebitAccount_3' + props.id)?.value) == 0 ? null : getIdFromName(formatedAccounts, document.getElementById('sltDebitAccount_3' + props.id)?.value),
                        joinHolder: getIdFromName(customerFormatList, document.getElementById('sltJoinHolder' + props.id)?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltJoinHolder' + props.id)?.value),
                        productLine: getIdFromName(ProductLine, document.getElementById('sltProductLine' + props.id)?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProductLine' + props.id)?.value),
                        term: getIdFromName(SavingTerm, document.getElementById('sltTerm_2' + props.id)?.value) == 0 ? null : getIdFromName(SavingTerm, document.getElementById('sltTerm_2' + props.id)?.value),
                        accountOfficer: getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer' + props.id)?.value) == 0 ? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer' + props.id)?.value),
                        category: getIdFromName(Category, document.getElementById('sltCategory' + props.id)?.value) == 0 ? null : getIdFromName(Category, document.getElementById('sltCategory' + props.id)?.value),
                        accountTitle: document.getElementById('txtAccountTitle' + props.id).value || null,
                        shortTitle: document.getElementById('txtShortTitle' + props.id).value || null,
                        notes: document.getElementById('txtNotes' + props.id).value || null,
                        product: getIdFromName(TempData.SAProduct, document.getElementById('sltProduct_2' + props.id)?.value) == 0 ? null : getIdFromName(TempData.SAProduct, document.getElementById('sltProduct_2' + props.id)?.value),
                        principalAmount:  document.getElementById('txtPrincipal_2' + props.id).value || 0,
                        interestRate: document.getElementById('txtInterestRate_2' + props.id).value || 0,
                        maturityDate: document.getElementById('dtMaturityDate_2' + props.id).value || null,
                        accountNo: document.getElementById('txtAcctNo_3' + props.id).value || null,
                        paymentNo: document.getElementById('txtPaymentNo_3' + props.id).value || null,
                        rolloverPR: getIdFromName(TempData.BooleanData, document.getElementById('sltRolloverPRonly_2' + props.id)?.value) == 0 ? null : getIdFromName(TempData.BooleanData, document.getElementById('sltRolloverPRonly_2' + props.id)?.value),
                        relationShip: getIdFromName(RelationCode, document.getElementById('sltRelationship' + props.id)?.value) == 0 ? null : getIdFromName(RelationCode, document.getElementById('sltRelationship' + props.id)?.value),
                    };
                     
                    savingAccountApi.update(props.modalInstanceID, params)
                    .then(response => {
                        setOKMessage("Update Successfully")
                        setShowSuccessModal(true);
                    })
                    // .catch(error => {
                    //     // setErrorMessage(error.response)
                    //     // console.log(errorMessage)
                    //     // handleShowBEErrorModal(true);
                    //     console.log("here 2222")
                    //     OKMessage("Update Successfully")
                    //     setShowSuccessModal(true);
                    // });
                    
                    // recreateInput();
                    // OKMessage("Update Successfully")
                    // setShowSuccessModal(true);
                    }
                }}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-save-fill"
                    viewBox="0 0 16 16"
                    style={commitBtn && {
                        color: "rgba(107,107,107,.9)",
                        marginRight: "8px",
                        } || {
                            color: "rgba(107,107,107,.2)",
                            marginRight: "8px",
                        }}
                >
                    <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
                </svg>
                <div
                    style={{
                    display: "inline",
                    }}
                >
                    {" "}
                    Commit Data{" "}
                </div>
                </div>
                <div
                className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
                style={ authorBtn && {
                    borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 1)",
                    height: "100%",
                    lineHeight: "50px",
                } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                        fontWeight: "",
                        color: "rgba(107,107,107, 0.2)",
                        height: "100%",
                        lineHeight: "50px",}}
                onClick={async () => {
                    if(authorBtn){
                        console.log("Authorize")
                        let params = {
                            status: getIdFromName(TempData.Status, document.getElementById("sltAuthoStatus" + props.id).value || 1)
                        }
                        await savingAccountApi.validate(props.modalInstanceID, params)
                        .then(response => {
                            setOKMessage("Authorize Successfully")
                            setShowSuccessModal(true);
                        })
                        .catch(error => {
                            setErrorMessage(error.response.data.message)
                            console.log(errorMessage)
                            // errorMessage = error.Data
                            handleShowBEErrorModal();
                        });
                    }
                }}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                    style={authorBtn && {
                    color: "rgba(107,107,107,.9)",
                    marginRight: "8px",
                    } || {
                        color: "rgba(107,107,107,.2)",
                        marginRight: "8px",
                    }}
                >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                Authorize
                </div>
                <div
                className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
                style={ searchBtn && {
                    borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 1)",
                    height: "100%",
                    lineHeight: "50px",
                } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                        fontWeight: "",
                        color: "rgba(107,107,107, 0.2)",
                        height: "100%",
                        lineHeight: "50px",}}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                    style={searchBtn && {
                        color: "rgba(107,107,107,.9)",
                        marginRight: "8px",
                        } || {
                            color: "rgba(107,107,107,.2)",
                            marginRight: "8px",
                        }}
                >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
                Search
                </div>
                <div
                className="col-2 buttonAction noCopy"
                style={{
                    borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgb(107,107,107)",
                    height: "100%",
                    lineHeight: "50px",
                }}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-printer-fill"
                    viewBox="0 0 16 16"
                    style={{
                    color: "rgba(107,107,107,.9)",
                    marginRight: "8px",
                    }}
                >
                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg>
                Print Deal Slip
                </div>
    
                <div
                className="col-2 buttonAction noCopy"
                style={{
                    borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgb(107,107,107)",
                    height: "100%",
                    lineHeight: "50px",
                }}
                onClick={() => {
                    props.setDisabledState(false)
                    setCommitBtn(true)
                }}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-pencil-fill"
                    viewBox="0 0 16 16"
                    style={{
                    color: "rgba(107,107,107,.9)",
                    marginRight: "8px",
                    }}
                >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </svg>
                Edit data
                </div>
            </div>
    
            <hr></hr>
    
            <div
                className="row noCopy"
                style={{
                margin: "20px 5px",
                }}
            >
                <ul className="nav nav-pills">
                <li className="nav-item">
                    <a
                    className={`nav-link ${activeTab === "active" ? "active" : ""}`}
                    aria-current="page"
                    href="#"
                    onClick={() => setActiveTab("active")}
                    style={{
                        backgroundColor: activeTab === "active" ? "#d71921" : "",
                    }}
                    >
                    Arrear Saving Account
                    </a>
                    
                    
                </li>
                </ul>
            </div>
    
            <hr></hr>
    
            <div>
                <nav>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a
                    className="nav-item nav-link active"
                    id="nav-detailsUpdate-tab"
                    data-toggle="tab"
                    href="#details_update"
                    role="tab"
                    aria-controls="details_update"
                    aria-selected="true"
                    >
                    Details
                    </a>
                    <a
                    className="nav-item nav-link "
                    id="nav-accountUpdate-tab"
                    data-toggle="tab"
                    href="#account_update"
                    role="tab"
                    aria-controls="account_update"
                    aria-selected="false"
                    >
                    All in one Account
                    </a>
                    <a
                    className="nav-item nav-link "
                    id="nav-savingAccountUpdate-tab"
                    data-toggle="tab"
                    href="#saving-account_update"
                    role="tab"
                    aria-controls="saving-account_update"
                    aria-selected="false"
                    >
                    New Deposit - Term Savings
                    </a>
                </div>
                </nav>


    
                <div className="tab-content" id="nav-tabContent">
                    <FieldsetComponent label="Authorize">
                        <RowComponent>
                            <InputField
                                label="Status:"
                                type="select"
                                id={"sltAuthoStatus" + props.id}
                                list={TempData.Status.slice(1, 3)}
                                isDisabled={existedAccountDB?.savingAccount?.Status === 1 ? false : true}
                                defaultValue={getNameFromId(TempData.Status, existedAccountDB?.savingAccount?.Status)}
                            />
                        </RowComponent>
                    </FieldsetComponent>
                    <div
                        className="tab-pane fade show active"
                        id="details_update"
                        role="tabpanel"
                        aria-labelledby="nav-detailsUpdate-tab"
                    >
                        {/* Fieldset 1 */}
                    <FieldsetComponent label="Account Information">
                    {/* row 1 */}
                    <RowComponent>
                        <InputField
                                label="Customer ID:"
                                type="select-depend"
                                id={"sltCustomerID" + props.id}
                                list={customerFormatList}
                                required={true}
                                state={selectedCustomer}
                                setState={setSelectedCustomer}
                                isDisabled={props.disabledState}
                                defaultValue={existedAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + existedAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
                                
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Category:"
                                type="select-depend"
                                id={"sltCategory"  + props.id}
                                list={Category.slice(3,5)}
                                required={true}
                                state={getCategory}
                                setState={setCategory}
                                isDisabled={props.disabledState}
                                // value={Category[existedAccountDB.detailAccount?.Category ? existedAccountDB.detailAccount?.Category : 1].name}
                                defaultValue={`${getNameFromId(Category, existedAccountDB?.detailAccount?.Category)}`}

                            />
                    </RowComponent>
                    {/* row 2 */}
                    <RowComponent>
                            <InputField
                                label="Product Line:"
                                type="select"
                                id={"sltProductLine"  + props.id}
                                list={ProductLine.filter((instance) => {
                                    
                                    let value = getIdFromName(Category, getCategory)
                                    return instance.category == value
                                })}
                                defaultValue={getNameFromId(ProductLine,existedAccountDB?.detailAccount?.ProductLine) || null}
                                isDisabled={props.disabledState}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Account Title:"
                                type="text"
                                id={"txtAccountTitle"  + props.id}
                                required={true}
                                isDisabled={props.disabledState}
                                value={existedAccountDB?.detailAccount?.AccountTitle}

                            />
                    </RowComponent>
                    {/* row 3 */}
                    <RowComponent>
                            <InputField
                                label="Short Title:"
                                type="text"
                                id={"txtShortTitle" + props.id}
                                isDisabled={props.disabledState}
                                value={existedAccountDB?.detailAccount?.ShortTitle}
                            />
                            <div className="col-1"></div>
                            <InputField
                                    label="Currency:"
                                    type="select-depend"
                                    id={"sltCurrency" + props.id}
                                    list={Currency_2}
                                    required={true}
                                    state={getCurrency}
                                    setState={setCurrency}
                                    isDisabled={props.disabledState}
                                    defaultValue={getNameFromId(Currency_2,existedAccountDB?.detailAccount?.Currency ) || null}
                                    
                                />
                    </RowComponent>
                    {/* row 4 */}
                    <RowComponent>
                            <InputField
                                label="Account Officer:"
                                type="select"
                                id={"sltAccountOfficer" + props.id}
                                list={AccountOfficers}
                                isDisabled={props.disabledState}
                                defaultValue={getNameFromId(AccountOfficers,existedAccountDB?.detailAccount?.AccountOfficer ) || "" }
                                />
                                <div className="col-1"></div>
                    </RowComponent>
                    </FieldsetComponent>
    
                    {/* Fieldset 2 */}
                    <FieldsetComponent label="Join Account Information">
                        {/* row 1 */}
                    <RowComponent>
                        <InputField
                                label="Join A/c Holder:"
                                type="select"
                                id={"sltJoinHolder" + props.id}
                                list={customerFormatList}
                                isDisabled={props.disabledState}
                                defaultValue={existedAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + existedAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
                            />
                            <div className="col-1"></div>
                            <InputField
                            label="Relationship: "
                            type="select"
                            id={"sltRelationship" + props.id}
                            list={RelationCode}
                            isDisabled={props.disabledState}
                            defaultValue={getNameFromId(RelationCode,existedAccountDB?.detailAccount?.RelationShip ) || ""}
                        />
                    </RowComponent>
                    {/* row 2 */}
                    <RowComponent>
                        <InputField
                                label="Notes:"
                                type="text"
                                id={"txtNotes" + props.id}
                                isDisabled={props.disabledState}
                                value={existedAccountDB?.detailAccount?.Notes}
                            />
                            <div className="col-1"></div>
                            
                    </RowComponent>
                    </FieldsetComponent>
    
                    </div>
    
    
                    <div
                        className="tab-pane fade"
                        id="account_update"
                        role="tabpanel"
                        aria-labelledby="nav-accountUpdate-tab"
                    >
                        <FieldsetComponent label="Customer Information">
                        {/* row 1 */}
                        <RowComponent>
                                <InputField
                                    label="Customer:"
                                    type="text"
                                    id={"txtCustomer_2" + props.id}
                                    isDisabled={true}
                                    value={existedAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + existedAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Category:"
                                    type="text"
                                    id={"txtCategory_2" + props.id}
                                    isDisabled={true}
                                    value={getNameFromId(Category, existedAccountDB?.detailAccount?.Category) || ""}
                                />
                        </RowComponent>
                        {/* row 2 */}
                        <RowComponent>
                                <InputField
                                    label="Currency:"
                                    type="text"
                                    id={"txtCurrency_2" + props.id}
                                    isDisabled = {true}
                                    value={getNameFromId(Currency_2, existedAccountDB?.detailAccount?.Currency) || ""}

                                />
                                <div className="col-1"></div>
                        </RowComponent>
                        </FieldsetComponent>
    
    
                        <FieldsetComponent label="Product Information">
                        {/* row 1 */}
                        <RowComponent>
                                <InputField
                                    label="Product:"
                                    type="select"
                                    id={"sltProduct_2" + props.id}
                                    list={TempData.SAProduct.filter(option =>{ return option.saType == 1})}
                                    required={true}
                                    isDisabled={props.disabledState}
                                    defaultValue={getNameFromId(TempData.SAProduct,existedAccountDB?.detailAccount?.Product ) || ""}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Principal:"
                                    type="number"
                                    id={"txtPrincipal_2" + props.id}
                                    state={getPrincipal}
                                    setState={setPrincipal}
                                    required={true}
                                    isDisabled={props.disabledState}
                                    value={getPrincipal || 0}
                                />
                        </RowComponent>
                        {/* row 2 */}
                        <RowComponent>
                                <InputField
                                    label="Value Date:"
                                    type="date-handle"
                                    id={"dtValueDate_2" + props.id}
                                    state={selectedDate}
                                    setState={setSelectedDate}
                                    isDisabled={props.disabledState}
                                    value={existedAccountDB?.detailAccount?.ValueDate || null}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Term:"
                                    type="select-depend"
                                    id={"sltTerm_2" + props.id}
                                    list = {SavingTerm}
                                    state={selectedTerm}
                                    setState={setSelectedTerm}
                                    required={true}
                                    isDisabled={props.disabledState}
                                    defaultValue={getNameFromId(SavingTerm ,existedAccountDB?.detailAccount?.Term) || ""}
                                />
                        </RowComponent>
                        <RowComponent>
                                <InputField
                                    label="Maturity Date:"
                                    type="date"
                                    id={"dtMaturityDate_2" + props.id}
                                    isDisabled={true}
                                    value={existedAccountDB?.detailAccount?.MaturityDate || null}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Interest Rate:"
                                    type="number-nohandle"
                                    id={"txtInterestRate_2" + props.id}
                                    isDisabled={props.disabledState}
                                    value={existedAccountDB?.detailAccount?.InterestRate || 0}
                                />
                        </RowComponent>
                        </FieldsetComponent>
                            
                        <FieldsetComponent label="Payment Information">
                        <RowComponent>
                            <InputField
                                label="Working Account:"
                                type="select-depend"
                                id={"sltWorkingAccount_2" + props.id}
                                required={true}
                                list={formatedAccounts}
                                state={selectedAccount}
                                setState={setSelectedAccount}
                                isDisabled={props.disabledState}
                                defaultValue={getNameFromId( iniFormatedAccounts, existedAccountDB?.detailAccount?.WorkingAccount) || ""}
                                />
                            <div className="col-1"></div>
                                <InputField
                                    label="Maturity Instr:"
                                    type="text"
                                    id={"txtMaturityInstr_2" + props.id}
                                    isDisabled={true}
                                    state={"AUTOMATIC ROLLOVER"}
                                    defaultValue={"AUTOMATIC ROLLOVER"}
                                />
                        </RowComponent>
                        <RowComponent>
                            <InputField
                                label="Rollover PR only:"
                                type="select"
                                id={"sltRolloverPRonly_2" + props.id}
                                list={TempData.BooleanData}
                                isDisabled={props.disabledState}
                                defaultValue={getNameFromId( TempData.BooleanData, existedAccountDB?.detailAccount?.RolloverPR) || ""}
                                />
                            <div className="col-1"></div>
                            
                        </RowComponent>
                        </FieldsetComponent>
                    </div>
    
    
                    <div
                        className="tab-pane fade"
                        id="saving-account_update"
                        role="tabpanel"
                        aria-labelledby="nav-savingAccountUpdate-tab"
                    >
    
                    <FieldsetComponent label="">
                        <RowComponent>
                            <InputField
                                label="Acct No:"
                                type="text"
                                id={"txtAcctNo_3" + props.id}
                                isDisabled={true}
                                state={createAccountNo(selectedAccount)}
                                value={ existedAccountDB?.detailAccount?.AccountNo || ""}
                                />
                            <div className="col-1"></div>
                            <InputField
                                label="Payment No:"
                                type="text"
                                id={"txtPaymentNo_3" + props.id}
                                isDisabled={props.disabledState}
                                value={ existedAccountDB?.detailAccount?.PaymentNo || ""}
                            />
                        </RowComponent>
                        <RowComponent>
                            <InputField
                                label="Payment CCY:"
                                type="select-depend"
                                id={"sltPaymentCCY_3" + props.id}
                                list={Currency_2}
                                state ={paymentCCYState}
                                setState={setPaymentCCYState}
                                isDisabled={props.disabledState}
                                defaultValue={getNameFromId(Currency_2,  existedAccountDB?.detailAccount?.PaymentCurrency) || ""}
                                />
                            <div className="col-1"></div>
                            <InputField
                                label="For Teller:"
                                type="text"
                                id={"txtForTeller_3" + props.id}
                                value={"vietvictory"}   
                                isDisabled={props.disabledState}
                            />
                        </RowComponent>
                        <RowComponent>
                            <InputField
                                label="Debit Account:"
                                type="select"
                                id={"sltDebitAccount_3" + props.id}
                                list={paymentCCYState !== 0 ? formatedAccounts : []}
                                isDisabled={props.disabledState}
                                defaultValue={getNameFromId(iniFormatedAccounts,  existedAccountDB?.detailAccount?.DebitAccount) || ""}
                                />
                            <div className="col-1"></div>
                            <InputField
                                label="Narrative:"
                                type="text"
                                id={"txtNarrative_3" + props.id}
                                isDisabled={props.disabledState}
                                defaultValue={existedAccountDB?.detailAccount?.Narrative} 
                            />
                        </RowComponent>
                        </FieldsetComponent>
    
                        <FieldsetComponent label="">
                        <RowComponent>
                            <InputField
                                    label="Account Currency:"
                                    type="text"
                                    id={"txtAccountCurrency_3" + props.id}
                                    isDisabled={true}
                                    value={getNameFromId(Currency_2,  existedAccountDB?.detailAccount?.Currency ) || ""}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Customer ID:"
                                    type="text"
                                    id={"txtCustomerID_3" + props.id}
                                    isDisabled={true}
                                    // value={selectedAccount}
                                    value={existedAccountDB?.savingAccount?.CUSTOMER?.RefID}
                                />
                            </RowComponent>
                            <RowComponent>
                            <InputField
                                    label="Account No:"
                                    type="text"
                                    id={"txtAccountNo_3" + props.id}
                                    isDisabled={true}
                                    // value={createAccountNo(selectedAccount)}
                                    value={ existedAccountDB?.detailAccount?.AccountNo || ""}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Account LCY:"
                                    type="text"
                                    id={"txtAccountLCY" + props.id}
                                    isDisabled={true}
                                    value={getPrincipal}
                                />
                            </RowComponent>
                            <RowComponent>
                            <InputField
                                    label="Account FCY:"
                                    type="text"
                                    id={"txtAccountFCY" + props.id}
                                    isDisabled={true}
                                    // value={existedAccountDB?.detailAccount?.PrincipalAmount || ""}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Narrative:"
                                    type="text"
                                    id={"txtNarrative_3.1" + props.id}
                                    isDisabled={true}
                                    value={"AZ Deposit Credit"}
                                />
                            </RowComponent>
                            <RowComponent>
                            <InputField
                                    label="Narrative"
                                    type="text"
                                    id={"txtNarrative_3.2" + props.id}
                                    isDisabled={true}
                                    value={""}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Narrative:"
                                    type="text"
                                    id={"txtNarrative_3.3" + props.id}
                                    isDisabled={true}
                                    // value={"Amount = " + existedAccountDB?.detailAccount?.PrincipalAmount}
                                    value={"Amount = " + getPrincipal}
                                />
                            </RowComponent>
                            <RowComponent>
                            <InputField
                                    label="Narrative"
                                    type="text"
                                    id={"txtNarrative_3.4" + props.id}
                                    isDisabled={true}
                                    value={"Date = " + getToday()}
                                />
                                <div className="col-1"></div>
                                <InputField
                                    label="Deal Rate:"
                                    type="number-nohandle"
                                    id={"txtDealRate" + props.id}
                                    isDisabled={props.disabledState}
                                />
                            </RowComponent>
                            <RowComponent>
                            <InputField
                                    label="AccountInLCY:"
                                    type="text"
                                    id={"txtAccountInLCY" + props.id}
                                    isDisabled={true}
                                    // value={existedAccountDB?.detailAccount?.PrincipalAmount || ""}
                                    value={getPrincipal}
                                />
                                
                            </RowComponent>
                        </FieldsetComponent>
                    </div>
            
                </div>
            
            </div>
            
            
    
            {/* --------------------------------------- */}
            {/* <Backend Error Model /> */}
            <div>
                <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <b>{errorMessage}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseBEErrorModal}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
            {/* --------------------------------------- */}
            {/* <ErrorModal /> */}
            <div>
                <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {checkRequired(props.id)}
                    {arrRe.map((item, index) => {
                    return <>
                        <div>- {item}</div></>})}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
            {/* --------------------------------------- */}
            {/* Success Modal  */}
            <div>
                <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <b>{OKMessage}</b> <br />
              
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
    </div>
    )
    
}
}


function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
  
  function getNameFromId(list, id) {
    const obj = list.find(obj => obj.id === id);
    return obj ? obj.name : null;
  }
  
  let arrRequired = []
function checkRequired(id) {
    arrRequired = []
    let arr = [
      {type: "select",id: "sltCustomerID" + id, name: 'Customer ID'},
      {type: "text",id: "txtAccountTitle"  + id, name: 'Account Title'},
      {type: "select",id: "sltCategory"  + id, name: 'Category'},
      {type: "select",id: "sltCurrency" + id, name: 'Currency'},
      {type: "select",id: "sltProduct_2" + id, name: 'Product'},
      {type: "text",id: "txtPrincipal_2" + id, name: 'Principal'},
      {type: "select",id: "sltTerm_2"+  id, name: 'Term'},
      {type: "select",id: "sltWorkingAccount_2" + id, name: 'Working Account'},
    ];
    arr.map((item, index) => {
        if (document.getElementById(item.id)?.value.length == 0)
          arrRequired.push(item.name)
      })
}
  
  function currencyFormat(value, currency){
    let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
    console.log(resultValue.slice(0, -4))
    return resultValue.slice(0, -4)
  }

  function addMonths(number, date = new Date()){
  
    let tempDate = new Date(date)
    tempDate.setMonth(tempDate.getMonth() + number + 1)
    return tempDate
  }
  
  function getToday(){
    // CREATE ACCOUNT NO + PAYMENT NO
          // get current day
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = today.getFullYear();
  
          today = mm  + dd + yyyy;
          return today
  }
  
  function createAccountNo(selectedAccount){
    let today = getToday()
    let accountNoStr = selectedAccount + "*" + today + "*BO"
    
    return accountNoStr
  }