const SystemCurrency = [
    { "id": 0, "name": '', "currency": 0},
    { "id": 1, "name": 'AUD - 1001-1127-2002', "currency": 1},
    { "id": 2, "name": 'CAD - 1001-1133-2002', "currency": 2},
    { "id": 3, "name": 'CNY - 1001-1128-2002', "currency": 3},
    { "id": 4, "name": 'EUR - 1001-1122-2002', "currency": 4},
    { "id": 5, "name": 'GBP - 1001-1129-2002', "currency": 5},
    { "id": 6, "name": 'HKD - 1001-1130-2002', "currency": 7},
    { "id": 7, "name": 'JPY - 1001-1124-2002', "currency": 8},
    { "id": 8, "name": 'NZD - 1001-1131-2002', "currency": 9},
    { "id": 9, "name": 'SGD - 1001-1132-2002', "currency": 10},
    { "id": 10, "name": 'USD - 1001-1125-2002', "currency": 11},
    { "id": 11, "name": 'VND - 1001-1126-2002', "currency": 12}
  ];

  export default SystemCurrency;