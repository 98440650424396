import React, { useState ,useEffect} from 'react'
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';

export default function CashTransfer(props) {
  const [activeTab, setActiveTab] = useState("active");


  //STATE HANDLE
  const [currencyState, setCurrencyState] = useState(0);
  const [creditCurrencyState, setCreditCurrencyState] = useState(0);
  const [amountState, setAmountState] = useState(0);
  const [dealRateState, setDealRateState] = useState(0);
  //AMOUNT STATE HANDLE
  useEffect(() => {
    // DEBIT 
    let amount = currencyFormat( parseInt(document.getElementById(`txtCustBal_`+props.id).value.replaceAll(".", "")) - amountState, "VND")
    document.getElementById(`txtNewBal_`+props.id).value = amount
    // CREDIT
    let dealRateTemp = 1

    if(currencyState !== creditCurrencyState){
      dealRateTemp = parseFloat(document.getElementById(`txtDealRate_`+props.id).value) || dealRateState
    }else{
      dealRateTemp = 1
      setDealRateState(1)
    }
    console.log("deal rate")
    console.log(dealRateTemp)
    let custBal = parseFloat(document.getElementById(`txtCustBal_`+props.id).value.replaceAll(".", ""))
    let checkAmt = custBal - parseFloat(amountState)
    if(checkAmt >= 0){
      document.getElementById(`txtNewBal_`+props.id).value= currencyFormat(checkAmt, "VND") || 0
      document.getElementById(`txtAmtCredit_`+props.id).value= currencyFormat(parseFloat(amountState) * parseFloat(dealRateTemp), "VND") || 0
    }else{
      document.getElementById(`txtAmtCredit_`+props.id).value = 0
      document.getElementById(`txtNewBal_`+props.id).value = 0
    }
  }, [amountState, dealRateState, currencyState, creditCurrencyState ]);

  useEffect(() => {
      // document.getElementById(`sltCashAccount_`+props.id).value = null
  }, [currencyState]);

  // GET ACCOUNT
  const [accountsDB, setAccountsDB] = useState([])
  useEffect(() => {
  async function fetchAccounts() {
  try {
    const response = await mainAccountApi.enquiry({})
    setAccountsDB(response.data.data)
  } catch (error) {
  }
  }
  fetchAccounts();
  }, []);

  //DEBIT ACCOUNT STATE
  const [debitAccountState, setDebitAccountState] = useState("") // customerID
  useEffect(() => {
    if(accountsDB){
      let count = 0
      accountsDB.map((value, index) => {
            if(value.Account === debitAccountState){
                count = 1
                props.setAccountState(value)
                console.log(value)
                document.getElementById('txtDebitCurrency_'+ props.id).value = value.CURRENCY.Name
                document.getElementById('txtDebitAccountInfo_'+ props.id).value = "TKTT" + " - " + value.CURRENCY.Name + " - " +  value.Customer.GB_FullName
                document.getElementById('txtDebitCustomer_'+ props.id).value = value.Customer.RefID + "   " + value.Customer.GB_FullName
                document.getElementById('txtCustBal_'+ props.id).value = currencyFormat(value.WorkingAmount, "VND")
                setCurrencyState(value.CURRENCY.Name)
            }
        })
      if(count == 0){
        document.getElementById('txtDebitCurrency_'+ props.id).value = null
        document.getElementById('txtDebitAccountInfo_'+ props.id).value = null
        document.getElementById('txtDebitCustomer_'+ props.id).value = null
      }
    }
}, [debitAccountState]);

  // CREDIT ACCOUNT STATE
  const [creditAccountState, setCreditAccountState] = useState("") // customerID
  useEffect(() => {
    if(accountsDB){
      let count = 0
      accountsDB.map((value, index) => {
            if(value.Account === creditAccountState){
                count = 1
                props.setCreditAccountState(value)
                console.log(value)
                document.getElementById('txtCrebitCurrency_'+ props.id).value = value.CURRENCY.Name
                document.getElementById('txtCreditAccountInfo_'+ props.id).value = "TKTT" + " - " + value.CURRENCY.Name + " - " +  value.Customer.GB_FullName
                document.getElementById('txtCreditCustomer_'+ props.id).value = value.Customer.RefID + "   " + value.Customer.GB_FullName
                setCreditCurrencyState(value.CURRENCY.Name)
            }
        })
      if(count == 0){
        document.getElementById('txtCrebitCurrency_'+ props.id).value = null
        document.getElementById('txtCreditAccountInfo_'+ props.id).value = null
        document.getElementById('txtCreditCustomer_'+ props.id).value = null
      }
    }
}, [creditAccountState]);

  return (
    <div>
<hr></hr>
  <div>
    <nav>
      <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
        <a
          className="nav-item nav-link active"
          id="nav-customerInfo-tab"
          data-toggle="tab"
          href="#customer-info"
          role="tab"
          aria-controls="customer-info"
          aria-selected="true"
        >
          Transfer Withdrawal Infomation
        </a>
      </div>
    </nav>


    <div className="tab-content" id="nav-tabContent">
        <div
            className="tab-pane fade show active"
            id="customer-info"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
        >
         {/* Fieldset 1 */}
        <FieldsetComponent label="Debit Information">
            {/* row 1 */}
            <RowComponent>
              <InputField
                  label="Account Type:"
                  type="select"
                  // id="sltAccountType"
                  id={`sltAccountType_`+props.id}
                  list={AccountType}
                  required={true}
                  defaultValue={"Current & Non-Term Saving Account"}
              />
              <div className="col-1"></div>
              <InputField
                  label="Debit Account:"
                  type="text-handler"
                  // id="txtDebitAccount"
                  id={`txtDebitAccount_`+props.id}
                  required={true}
                  state={debitAccountState}
                  setState={setDebitAccountState}
              />
            </RowComponent>
            <RowComponent>
                <InputField
                    label="Debit Amt:"
                    type="text-handler"
                    // id="txtDebitAmt"
                    id={`txtDebitAmt_`+props.id}
                    state={amountState}
                    setState={setAmountState}
                />
              <div className="col-1"></div>
                <InputField
                  label="Value Date:"
                  type="date"
                  // id="dtDebitValueDate"
                  id={`dtDebitValueDate_`+props.id}
              />
            </RowComponent>
            {/* row 2 */}
            <hr></hr>
            <RowComponent>
               <InputField
                  label="Customer Account:"
                  type="text"
                  // id="txtDebitCurrency"
                  id={`txtDebitAccountInfo_`+props.id}
                  size={"col-4"}
                  isDisabled={true}
              />
              <div className="col-1"></div>
              
            </RowComponent>
            <RowComponent>
              <InputField
                  label="Customer:"
                  type="text"
                  // id="txtDebitCustomer"
                  id={`txtDebitCustomer_`+props.id}
                  isDisabled={true}
              />
              <div className="col-1"></div>
              <InputField
                  label="Currency:"
                  type="text"
                  // id="txtDebitCurrency"
                  id={`txtDebitCurrency_`+props.id}
                  isDisabled={true}
              />
            </RowComponent>
            {/* row 3 */}
          
            {/* row 4 */}
            <RowComponent>
                <InputField
                    label="Cust Bal:"
                    type="text-handler"
                    // id="txtCustBal"
                    id={`txtCustBal_`+props.id}
                    isDisabled={true}
                />
                <div className="col-1"></div>
                <InputField
                    label="New Cust Bal:"
                    type="text"
                    // id="txtNewBal"
                    id={`txtNewBal_`+props.id}
                    isDisabled={true}
                />
            </RowComponent>
          </FieldsetComponent>

         {/* Fieldset 2 */}
         <FieldsetComponent label="Credit Information">
             {/* row 1 */}
             <RowComponent>
              <InputField
                    label="Credit Account:"
                    type="text-handler"
                    // id="txtCreditAccount"
                    id={`txtCreditAccount_`+props.id}
                    state={creditAccountState}
                    setState={setCreditAccountState}
                    required={true}
                />
                <div className="col-1"></div>
                
            </RowComponent>
            <RowComponent>
                <InputField
                  label="Customer:"
                  type="text"
                  // id="txtCreditCustomer"
                  id={`txtCreditCustomer_`+props.id}
                  isDisabled={true}
                />
                <div className="col-1"></div>
                <InputField
                      label="Currency:"
                      type="text-handler"
                      // id="txtDebitCurrency"
                      id={`txtCrebitCurrency_`+props.id}
                      isDisabled={true}
                      state={creditCurrencyState}
                      setState={setCreditCurrencyState}
                  />
                
            </RowComponent>
            {/* row 2 */}
            <RowComponent>
                <InputField
                  label="Credit Account:"
                  type="text"
                  // id="txtCreditCustomer"
                  id={`txtCreditAccountInfo_`+props.id}
                  isDisabled={true}
                  size={"col-4"}
                />
                <div className="col-1"></div>
            </RowComponent>
            {/* row 3 */}
            <hr></hr>
            <RowComponent>
                <InputField
                      label="Value Date:"
                      type="date"
                      // id="dtCreditValueDate"
                      id={`dtCreditValueDate_`+props.id}
                  />
                <div className="col-1"></div>
                <InputField
                  label="Deal Rate:"
                  type="text-handler"
                  // id="txtDealRate"
                  id={`txtDealRate_`+props.id}
                  state={dealRateState}
                  setState={setDealRateState}
                />
            </RowComponent>
            <RowComponent>
                <InputField
                      label="Amt Credit for Cust:"
                      type="text"
                      // id="txtAmtCredit"
                      id={`txtAmtCredit_`+props.id}
                      isDisabled={true}
                  />
                <div className="col-1"></div>
            </RowComponent>
         </FieldsetComponent>

         <FieldsetComponent label="">
                {/* row 1 */}
              <RowComponent>
                    <InputField
                        label=" Waive Charges?"
                        type="select"
                        // id="sltWaiveCharges"
                        id={`sltWaiveCharges_`+props.id}
                        list={[{"id": 1, "name": "No"}, {"id": 2, "name": "Yes"} ]}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Narrative:"
                        type="text"
                        // id="txtNarrative"
                        id={`txtNarrative_`+props.id}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label="Print LnNo of PS:"
                        type="text"
                        // id="txtPrint"
                        id={`txtPrint_`+props.id}
                    />
                    <div className="col-1"></div>
              </RowComponent>
             </FieldsetComponent>
        </div>
    </div>
</div>
</div>
  )
}

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

