import React from 'react'

export default function TabChangeActive(props) {
  return (
    <div
      className="tab-pane fade show active"
      id={`${props.id}`}
      role="tabpanel"
      // aria-labelledby="nav-home-tab"
    >
        {props.children}
    </div>
  );
}
