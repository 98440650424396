const Currency_2 = [
    { "id": 0, "name": ''},
    { "id": 1, "name": 'AUD'},
    { "id": 2, "name": 'CAD'},
    { "id": 3, "name": 'CNY'},
    { "id": 4, "name": 'EUR'},
    { "id": 5, "name": 'GBP'},
    { "id": 6, "name": 'GOLD'},
    { "id": 7, "name": 'HKD'},
    { "id": 8, "name": 'JPY'},
    { "id": 9, "name": 'NZD'},
    { "id": 10, "name": 'SGD'},
    { "id": 11, "name": 'USD'},
    { "id": 12, "name": 'VND'},
  ];

  export default Currency_2;