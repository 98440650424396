import React, { useState ,useEffect} from 'react'
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import CloseSystemCurrency from '../../../../data/CloseSystemCurrency';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import SystemCurrency from '../../../../data/SystemAccount';
import TempData from '../../../../data/TempData';

// const uploadAPI = 

export default function Frequency(props) {
    //STATE HANDLE
    const [activeTab, setActiveTab] = useState("active");

    //GET COMPANY LIST 
    const [companiesDB, setCompaniesDB] = useState([]);
    const [companiesFormat, setCompaniesFormat] = useState([]);
    useEffect(() => {
      async function fetchCompanies() {
        try {
          const response = await mainAccountApi.getAll()
          const customerArr = response.data.data
          console.log('customerArr')
          console.log(customerArr)
          let corporateArr = []
          let formatArr = []
          customerArr.map(value => {
            if(value.Customer?.CustomerType === 2){
              corporateArr.push(value)
              let temp = {
                id: value.id,
                name: value.Account + " - " + value.Customer?.GB_FullName,
                currency: value.CURRENCY?.Name
              }
              formatArr.push(temp)
            }     
          })
          setCompaniesDB(corporateArr)
          setCompaniesFormat(formatArr)
        } catch (error) {
        }
      }
      fetchCompanies();
    }, []);

    // SELECTED ACCOUNT 
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedAccountOBJ, setSelectedAccountOBJ] = useState({});
    useEffect(() => {
        console.log("selected Account")
        console.log(selectedAccount)
        companiesFormat.map((value) => {
          if(value.name === selectedAccount){
            setSelectedAccountOBJ(value)
            document.getElementById("txtCurrency_" + props.id).value=value.currency
          }
          
        })
    }, [selectedAccount])
  return (
    <div>

      <hr></hr>

      <div>
        <nav>
          <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Salary Frequency
            </a>
          </div>
        </nav>


        <div className="tab-content" id="nav-tabContent">
            <div
                className="tab-pane fade show active"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
             {/* Fieldset 1 */}
              <FieldsetComponent label="Company">
                {/* row 1 */}
                <RowComponent>
                      <InputField
                          label="Company:"
                          type="select-depend"
                          id={`sltCompany_`+props.id}
                          list={companiesFormat}
                          size={"col-6"}
                          state={selectedAccount}
                          setState={setSelectedAccount}
                      />
                      <div className="col-1"></div>
                </RowComponent>
                <RowComponent>
                      <InputField
                          label="Currency:"
                          type="text"
                          // id="txtCustomerAccount"
                          id={`txtCurrency_`+props.id}  
                          isDisabled={true}
                      />
                      <div className="col-1"></div>
                </RowComponent>
              </FieldsetComponent>

              {/* Fieldset 2 */}
              <FieldsetComponent label="Salary Payment">
                {/* row 1 */}
                <RowComponent>
                    <form 
                      method="post" 
                      action="https://api-newcore.vietvictory.vn/signature/upload" 
                      enctype="multipart/form-data"
                      id="idFormAmend" >
                        <InputField
                            label="GB_IMPORT FILE:"
                            type="text"
                            id={`fileImport_`+props.id}
                          
                        />
                        <div className="col-1"></div>
                      </form>
                </RowComponent>
                <hr></hr>
                <RowComponent>
                      <InputField
                          label="Total Debit Amt:"
                          type="number-nohandle"
                          id={`txtTotalDebitAmt_`+props.id}
                      />
                      <div className="col-1"></div>
                      <InputField
                          label="Frequency:"
                          type="date"
                          id={`dtFrequency_`+props.id}
                      />
                </RowComponent>
                <RowComponent>
                      <InputField
                          label="Term:"
                          type="select"
                          id={`sltTerm_`+props.id}
                          list={TempData.SalaryPaymentTerm.slice(1,3)}
                      />
                      <div className="col-1"></div>
                      <InputField
                          label="End Date:"
                          type="date"
                          id={`dtEndDate_`+props.id}
                      />
                </RowComponent>
                <RowComponent>
                      <InputField
                          label="Ordering Cust:"
                          type="text"
                          id={`txtOrderingCust_`+props.id}
                      />
                      <div className="col-1"></div>
                </RowComponent>
              </FieldsetComponent>

              <FieldsetComponent label="Credit Information">

              </FieldsetComponent>
            </div>
        </div>
    </div>
    </div>
  )
}

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}