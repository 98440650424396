const CloseSystemCurrency = [
    { "id": 0, "name": '', "currency": 0},
    { "id": 1, "name": 'USD - 1001-1125-2002', "currency": 2},
    { "id": 2, "name": 'EUR - 1001-1122-2002', "currency": 1},
    { "id": 3, "name": 'GBP - 1001-1129-2002', "currency": 3},
    { "id": 4, "name": 'JPY - 1001-1124-2002', "currency": 4},
    { "id": 5, "name": 'VND - 1001-1126-2002', "currency": 5},

  ];

  export default CloseSystemCurrency;