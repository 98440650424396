import React, { useState ,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap';
import cusApi from '../../../apis/cusApi';
import AuthorizeIcon from '../../../components/ActionBar/AuthorizeIcon';
import ClearIcon from '../../../components/ActionBar/ClearIcon';
import CommitDataIcon from '../../../components/ActionBar/CommitDataIcon';
import EditDataIcon from '../../../components/ActionBar/EditDataIcon';
import PrintDealSlipIcon from '../../../components/ActionBar/PrintDealSlipIcon';
import SearchIcon from '../../../components/ActionBar/SearchIcon';
import FieldsetComponent from '../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../components/InputField/InputField';
import RowComponent from '../../../components/RowComponent/RowComponent';
import AccountOfficers from '../../../data/AccountOfficer';
import CityList from '../../../data/CityList';
import CountryList from '../../../data/CountryList';
import DocTypeList from '../../../data/DocTypeList';
import Industry from '../../../data/Industry';
import RelationCode from '../../../data/RelationCode';
import Sector from '../../../data/Sector';
import SubIndustry from '../../../data/SubIndustry';
import SubSector from '../../../data/SubSector';

export default function OpenCorporateCustomer(props) {


    const [statusPopup, setStatusPopup] = useState(props.popup ? true : false)
    const [valueID, setValueID] = useState(props.popup ? '_popup'  : '_open')

    // 1. State check required
    const [arrRe, setArrRe] = useState([])
    const handleSetArrRe = (item) => {setArrRe(item);};
    // 2. State show Error
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("error");
    const handleCloseErrorModal = () => setShowErrorModal(false);
    const handleShowErrorModal = () => setShowErrorModal(true);
    // 3. State show Success
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
      // 5. State show Backend Error
      const [showBEError, setShowBEError] = useState(false);
      const handleCloseBEErrorModal = () => setShowBEError(false);
      const handleShowBEErrorModal = () => setShowBEError(true);

  
  // 4. Select State
  const [getSector, setSector] = useState(0);
  useEffect(() => {
      clearDependentValue("sltSubSector"+valueID)
  }, [getSector]);
  const [getIndustry, setIndustry] = useState(0);
  useEffect(() => {
    clearDependentValue("sltIndustry"+valueID)
  }, [getIndustry]);

  
  const [activeTab, setActiveTab] = useState("active");

  return (
    <div>
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{
          height: "50px",
          backgroundColor: "#eceff4",
          margin: "3px",
        }}
      >
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={async (event)=> {
            checkRequired();
            if (arrRequired.length > 0) {
              setShowErrorModal(true);
              setArrRe(arrRequired);
            } else {
            const params = {
              sector: getIdFromName(SubSector, document.getElementById('sltSubSector'+valueID)?.value) == 0 ? null : getIdFromName(SubSector, document.getElementById('sltSubSector'+valueID)?.value),
              totalRevenue: document.getElementById('txtTotalRevenue'+valueID).value || null,
              totalAssets: document.getElementById('txtTotalAssets'+valueID).value || null,
              totalCapital: document.getElementById('txtTotalCapital'+valueID).value || "",
              telephone: document.getElementById('txtTelephone'+valueID).value || "",
              remarks: document.getElementById('txtRemarks'+valueID).value || "",
              residence: getIdFromName(CountryList, document.getElementById('sltResidence'+valueID)?.value) == 0 ? null : getIdFromName(CountryList, document.getElementById('sltResidence'+valueID)?.value),
              relationCode: getIdFromName(RelationCode, document.getElementById('sltRelationCode'+valueID)?.value) == 0 ? null : getIdFromName(RelationCode, document.getElementById('sltRelationCode'+valueID)?.value),
              position: document.getElementById('txtPosition'+valueID).value || "",
              // officeNumber: document.getElementById('txtOfficeNumber'+valueID).value || "",
              officeNumber: "",
              nationality: getIdFromName(CountryList, document.getElementById('sltNationality'+valueID)?.value) == 0 ? null : getIdFromName(CountryList, document.getElementById('sltNationality'+valueID)?.value),
              mainSector: getIdFromName(Sector, document.getElementById('sltMainSector'+valueID)?.value) == 0 ? null : getIdFromName(Sector, document.getElementById('sltMainSector'+valueID)?.value),
              mainIndustry: getIdFromName(Industry, document.getElementById('sltMainIndustry'+valueID)?.value) == 0 ? null : getIdFromName(Industry, document.getElementById('sltMainIndustry'+valueID)?.value),
              liability: document.getElementById('txtCustomerLiability'+valueID).value || "",
              incorpDate: document.getElementById('dtIncorpDate'+valueID).value || null,
              industry: getIdFromName(SubIndustry, document.getElementById('sltIndustry'+valueID)?.value) == 0 ? null : getIdFromName(SubIndustry, document.getElementById('sltIndustry'+valueID)?.value),
              GB_Towndist: document.getElementById('txtGBTownDist'+valueID).value || "",
              GB_Street: document.getElementById('txtGBStreet'+valueID).value || "",
              GB_ShortName: document.getElementById('txtGBShortName'+valueID).value || "",
              GB_FullName: document.getElementById('txtGBFullName'+valueID).value || "",
              GB_Country: getIdFromName(CountryList, document.getElementById('sltGBCountry'+valueID)?.value) == 0 ? null : getIdFromName(CountryList, document.getElementById('sltGBCountry'+valueID)?.value),
              // fax: document.getElementById('txtFax'+valueID).value || "",
              fax: "",
              employeesNo: document.getElementById('txtNoEmployees'+valueID).value || "",
              docType: getIdFromName(DocTypeList, document.getElementById('sltDocType'+valueID)?.value) == 0 ? null : getIdFromName(DocTypeList, document.getElementById('sltDocType'+valueID)?.value),
              docIssuePlace: document.getElementById('txtDocIssuePlace'+valueID).value || "",
              docIssueDate: document.getElementById('dtDocIssueDate'+valueID).value || null,
              docExpiryDate: document.getElementById('dtDocExpiryDate'+valueID).value || null,
              docID: document.getElementById('txtDocID'+valueID).value || "",
              contactPerson: document.getElementById('txtContactPerson'+valueID).value || "",
              contactDate: document.getElementById('dtContactDate'+valueID).value || null,
              companyBook: document.getElementById('txtCompanyBook'+valueID).value || "",
              cityProvince: getIdFromName(CityList, document.getElementById('sltCityProvince'+valueID)?.value) == 0 ? null : getIdFromName(CityList, document.getElementById('sltCity/+valueIDProvince')?.value),
              accountOfficer: getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer'+valueID)?.value) == 0 ? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer'+valueID)?.value),

            };
            console.log('params')
            console.log(params)
            cusApi.postCorporate(params)
              .then(response => {
                setShowSuccessModal(true);
              })
              .catch(error => {
                setErrorMessage(error.response.data.message)
                console.log(errorMessage)
                handleShowBEErrorModal();
              });
              
            }
          }}
        >
          <CommitDataIcon  status={true}/>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className="col-2"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 0.2)",
          height: "100%",
          lineHeight: "50px",}}
        >
          <AuthorizeIcon  status={false}/>
          Authorize
        </div>
        <div
          className="col-2"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 0.2)",
          height: "100%",
          lineHeight: "50px",}}
        >
          <SearchIcon status={false}/>
          Search
        </div>
        <div
          className={`col-2 ${statusPopup ? 'buttonAction' : ""}`}
          style={ statusPopup && {
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 1)",
            height: "100%",
            lineHeight: "50px",
        } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 0.2)",
                height: "100%",
                lineHeight: "50px",}}
        >
          <PrintDealSlipIcon status={statusPopup}/>
          Print Deal Slip
        </div>

        <div
          className={`col-2 ${statusPopup ? 'buttonAction' : ""}`}
          style={ statusPopup && {
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 1)",
            height: "100%",
            lineHeight: "50px",
        } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 0.2)",
                height: "100%",
                lineHeight: "50px",}}
        >
          <EditDataIcon status={statusPopup}/>
          Edit data
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={() => {
            recreateInput();
          }}
        >
          <ClearIcon status={true}/>
          Clear
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Open Corporate Customer
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>

      <div>
        <nav>
          <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Customer Info
            </a>
            <a
              className="nav-item nav-link"
              id="nav-details-tab"
              data-toggle="tab"
              href="#details"
              role="tab"
              aria-controls="nav-details"
              aria-selected="false"
            >
              Details
            </a>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="customer-info"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            {/* Fieldset 1 */}
            <FieldsetComponent label="Corporate Information">
             {/* row 1 */}
              <RowComponent>
                <InputField label="GB Short Name:" type="text" id={'txtGBShortName'+valueID} required={true} />
                <div className="col-1"></div>
                <InputField label="GB Full Name:" type="text" id={'txtGBFullName'+valueID} required={true}/>
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
              <InputField label="Incorp Date:" type="date" id={'dtIncorpDate'+valueID}  required={true} />
                <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>

            {/* Fieldset 2 */}
            <FieldsetComponent label="Identification Details">
             {/* row 1 */}
              <RowComponent>
                <InputField label="GB Street:" type="text" id={'txtGBStreet'+valueID} required={true} />
                <div className="col-1"></div>
                <InputField label="GB Town/Dist:" type="text" id={'txtGBTownDist'+valueID} required={true}/>
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                    label="GB Country"
                    type="select"
                    id={'sltGBCountry'+valueID}
                    list={CountryList}
                    required={true}
                  />
                  <div className="col-1"></div>
                  <InputField
                    label="City/Province:"
                    type="select"
                    id={'sltCityProvince'+valueID}
                    list={CityList}
                    required={true}
                  />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                    label="Nationality"
                    type="select"
                    id={'sltNationality'+valueID}
                    list={CountryList}
                  />
                  <div className="col-1"></div>
                  <InputField
                    label="Residence"
                    type="select"
                    id={'sltResidence'+valueID}
                    list={CountryList}
                  />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                  label="Doc Type:"
                  type="select"
                  id={'sltDocType'+valueID}
                  list={DocTypeList}
                  required={true}
                />
                  <div className="col-1"></div>
                  <InputField
                  label="Doc ID:"
                  type="text"
                  id={'txtDocID'+valueID}
                  required={true}
                />
              </RowComponent>
              {/* row 5 */}
              <RowComponent>
                <InputField
                  label="Doc Issue Place:"
                  type="text"
                  id={'txtDocIssuePlace'+valueID}
                  required={true}
                />
                  <div className="col-1"></div>
                  <InputField
                  label="Doc Issue Date:"
                  type="date"
                  id={'dtDocIssueDate'+valueID}
                  required={true}
                />
              </RowComponent>
              {/* row 6 */}
              <RowComponent>
                <InputField
                  label="Doc Expiry Date:"
                  type="date"
                  id={'dtDocExpiryDate'+valueID}
                />
                  <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>


            {/* Fieldset 3 */}
            <FieldsetComponent label="Professional Information">
             {/* row 1 */}
             <RowComponent>
                <InputField
                  label="Main Sector:"
                  type="select-depend"
                  id={'sltMainSector'+valueID}
                  list={Sector}
                  state={getSector}
                  setState={setSector}
                  required={true}
                />
                <div className="col-1"></div>
                <InputField
                  label="Sub Sector:"
                  type="select"
                  id={'sltSubSector'+valueID}
                  list={SubSector.filter((option) => {
                    let value = getIdFromName(Sector, getSector)
                    return option.sector == value
                  })}
                  required={true}
                />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                  label="Main Industry:"
                  type="select-depend"
                  id={'sltMainIndustry'+valueID}
                  list={Industry}
                  required={true}
                  state={getIndustry}
                  setState={setIndustry}
                />
                <div className="col-1"></div>
                <InputField
                  label="Industry:"
                  type="select"
                  id={'sltIndustry'+valueID}
                  list={SubIndustry.filter((option) => {
                    let value = getIdFromName(Industry, getIndustry)
                    return option.industry == value
                  })}
                  required={true}
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="Account Officer:"
                  type="select"
                  id={'sltAccountOfficer'+valueID}
                  list={AccountOfficers}
                />
                <div className="col-1"></div>
                <InputField
                  label="Company Book:"
                  type="text"
                  id={'txtCompanyBook'+valueID}
                />
              </RowComponent>
            </FieldsetComponent>

            {/* Fieldset 4 */}
            <FieldsetComponent label="Contact Information">
             {/* row 1 */}
              <RowComponent>
                <InputField label="Contact Person:" type="text" id={'txtContactPerson'+valueID}  />
                <div className="col-1"></div>
                <InputField label="Position:" type="text" id={'txtPosition'+valueID} />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
              <InputField label="Telephone:" type="text" id={'txtTelephone'+valueID} />
                <div className="col-1"></div>
              <InputField label="Email Address:" type="text" id={'txtEmailAddress'+valueID} />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
              <InputField label="Remarks:" type="text" id={'txtRemarks'+valueID} />
                <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>
          </div>





          {/* Details */}
          <div
            className="tab-pane fade"
            id={'details'+valueID}
            role="tabpanel"
            aria-labelledby="nav-details-tab"
          >
            {/* Fieldset 1 */}
            <FieldsetComponent label="Financial Information">
             {/* row 1 */}
              <RowComponent>
                <InputField label="Total Capital:" type="text" id={'txtTotalCapital'+valueID}  />
                <div className="col-1"></div>
                <InputField label="Total Assets:" type="text" id={'txtTotalAssets'+valueID} />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
              <InputField label="Total Revenue:" type="text" id={'txtTotalRevenue'+valueID} />
                <div className="col-1"></div>
              <InputField label="Customer Liability:" type="text" id={'txtCustomerLiability'+valueID} />
              </RowComponent>
            </FieldsetComponent>

            {/* Fieldset 2 */}
            <FieldsetComponent label="Business Information">
             {/* row 1 */}
              <RowComponent>
                <InputField label="No. of Employees" type="text" id={'txtNoEmployees'+valueID}  />
                <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>

            {/* Fieldset 3 */}
            <FieldsetComponent label="Contact Information">
             {/* row 1 */}
              <RowComponent>
                <InputField label="Contact Date" type="date" id={'dtContactDate'+valueID}  />
                <div className="col-1"></div>
                <InputField label="Office Number" type="type" id={'txtOfficeNumber'+valueID}  />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
              <InputField
                    label="Relation Code"
                    type="select"
                    id={'sltRelationCode'+valueID}
                    list={RelationCode}
                  />
                <div className="col-1"></div>
                
              </RowComponent>
            </FieldsetComponent>
          </div>
        </div>
      </div>


       {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <b>Corporate Customer Account</b> opening was successful! <br />
          - Name: <b>{document.getElementById('txtGBFullName')?.value}</b> <br />
          - Doc ID: <b>{document.getElementById('txtDocID')?.value}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      

    </div>
  );
}


// check 
let arrRequired = []
function checkRequired() {
  arrRequired = []
  let arr = [
    {type: "text",id: "txtGBShortName",name: 'GB Short Name'},
    {type: "text",id: "txtGBFullName",name: 'GB Full Name'},
    {type: "text",id: "dtIncorpDate",name: 'Incorp Date'},
    {type: "text",id: "txtGBStreet",name: 'GB Street'},
    {type: "text",id: "txtGBTownDist",name: 'GB Town/Dist'},
    {type: "select",id: "sltGBCountry",name: 'GB Country'},
    {type: "select",id: "sltCity/Province",name: 'City/Province'},
    {type: "select",id: "sltDocType",name: 'Doc Type'},
    {type: "text",id: "txtDocID",name: 'Doc ID'},
    {type: "date",id: "dtDocIssueDate",name: 'Doc Issue Date'},
    {type: "select",id: "sltMainSector",name: 'Main Sector'},
    {type: "select",id: "sltSubSector",name: 'Sub Sector'},
    {type: "select",id: "sltMainIndustry",name: 'Main Industry'},
    {type: "select",id: "sltIndustry",name: 'Industry'},
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  let numberArr = [
    {type:"text", id:"txtTotalAssets", name: 'Total Assets'},
    {type:"text", id:"txtTotalRevenue", name: 'Total Revenue'},
  ]
  numberArr.map((item, index) => {
    if (document.getElementById(item.id)?.value && document.getElementById(item.id)?.value.length != 0 && !parseInt(document.getElementById(item.id)?.value)){
      arrRequired.push(item.name +": is number")
    }
  })
    // check dependent value
    let sectorID_ = document.getElementById("sltSubSector")?.value
    let obj = SubSector.find(obj => obj.name === sectorID_)
    if(document.getElementById("sltSubSector")?.value.length != 0 && document.getElementById("sltMainSector")?.value.length != 0 && obj?.sector != getIdFromName(Sector, document.getElementById("sltMainSector")?.value)){
      arrRequired.push("SubSector is not belonged to Sector")
    }
    let industryID_ = document.getElementById("sltIndustry")?.value
    let obj2 = SubIndustry.find(obj => obj.name === industryID_)
    if(document.getElementById("sltIndustry")?.value.length != 0 && document.getElementById("sltMainIndustry")?.value.length != 0 && obj2?.industry != getIdFromName(Industry, document.getElementById("sltMainIndustry")?.value)){
      arrRequired.push("SubIndustry is not belonged to Industry")
    }
  if (arrRequired.length > 0) return true; else return false;
}


function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function clearDependentValue(id){
  if(document.getElementById(id).value){
   document.getElementById(id).value = ""
  }
}

function recreateInput() {
  let arr = [
    // Required
    {id: "txtGBShortName"},
    {id: "txtGBFullName"},
    {id: "txtGBStreet"},
    {id: "txtGBTown/Dist"},
    {id: "sltCity/Province"},
    {id: "sltDocType"},
    {id: "txtDocID"},
    {id: "txtDocIssuePlace"},
    {id: "sltMainSector"},
    {id: "sltSubSector"},
    {id: "sltMainIndustry"},
    {id: "sltIndustry"},
    // Optional
    {id: "dtBirthday"},
    {id: "txtLastName"},
    {id: "txtFirstName"},
    {id: "txtMiddleName"},
    {id: "txtMobilePhone"},
    {id: "sltGBCountry"},
    {id: "sltNationality"},
    {id: "sltResidence"},
    {id: "dtDocIssueDate"},
    {id: "dtDocExpiryDate"},
    {id: "txtEmailAddress"},
    {id: "sltAccountOfficer"},
    // Page 2 - Details
    {id: "sltGender"},
    {id: "sltTitle"},
    {id: "sltMaritalStatus"},
    {id: "sltRelationCode"},
    {id: "txtContactDate"},
    {id: "txtOfficeNumber"},
    {id: "txtFaxNumber"},
    {id: "txtNoDependants"},
    {id: "txtNoChild_15"},
    {id: "txtNoChild15_25"},
    {id: "txtNoChild25_"},
    {id: "sltHomeOwnership"},
    {id: "sltResidenceType"},
    {id: "sltEmploymentStatus"},
    {id: "txtCompanyName"},
    {id: "sltCurrency"},
    {id: "txtMonthlyIncome"},
    {id: "txtOfficeAddress"},
    {id: "txtCustomerLiability"},


  ];
  arr.map((item, index) => {
    const element = document.getElementById(item.id);
    if (element) {
      element.value = '';
  }
  })
}