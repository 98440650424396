import React, { useState , useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap';
import foreignExchangeApi from '../../../apis/foreignExchangeApi';
import FieldsetComponent from '../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../components/InputField/InputField';
import RowComponent from '../../../components/RowComponent/RowComponent';
import AccountType from '../../../data/AccountType';
import Currency_2 from '../../../data/Currency_2';
import SystemCurrency from '../../../data/SystemAccount';

export default function ForeignExchange() {
    const [activeTab, setActiveTab] = useState("active");

    // 1. State check required
    const [arrRe, setArrRe] = useState([])
    const handleSetArrRe = (item) => {setArrRe(item);};
    // 2. State show Error
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("error");
    const handleCloseErrorModal = () => setShowErrorModal(false);
    const handleShowErrorModal = () => setShowErrorModal(true);
    // 3. State show Success
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
    // 5. State show Backend Error
    const [showBEError, setShowBEError] = useState(false);
    const handleCloseBEErrorModal = () => setShowBEError(false);
    const handleShowBEErrorModal = () => setShowBEError(true);

    // State response
    const [refIDRes, setRefIDRes] = useState(""); 
    //*-----------------------------------*
    //State Control
    const [LCYState, setLCYState] = useState(false); // false => VND true=>!=VND
    const [debitCurrencyState, setDebitCurrencyState] = useState("");
    const [debitAmtLCYState , setDebitAmtLCYState] = useState(0.00);
    const [debitAmtFCYState , setDebitAmtFCYState] = useState(0.00);
    const [debitDealRateState , setDebitDealRateState] = useState(0.00);
    const [creditDealRateState , setCreditDealRateState] = useState(0.00);
    useEffect(() => {
        console.log("debit Currency")
        console.log(debitCurrencyState)
        if(debitCurrencyState == "VND"){
            console.log("VND is chosen")
            setLCYState(false)
            setDebitAmtFCYState(0.00)
            setCreditDealRateState(0.00)
        }else{
            console.log("VND is not chosen")
            setLCYState(true)
            setDebitAmtLCYState(0.00)
            setDebitDealRateState(0.00)
        }
    }, [debitCurrencyState]);

    useEffect(() => {
        console.log("read 1")
        console.log(debitAmtLCYState)
        console.log(debitDealRateState)
        if(debitAmtLCYState && debitDealRateState){
            let tempValue = parseFloat(debitAmtLCYState) / parseFloat(debitDealRateState)
            setDebitAmtFCYState(tempValue)
            document.getElementById('txtAmtPaid').value = tempValue
        }
    }, [debitAmtLCYState, debitDealRateState]);
    useEffect(() => {
        console.log("read 2")
        console.log(debitAmtFCYState)
        console.log(creditDealRateState)
        if(debitAmtFCYState && creditDealRateState){
            let tempValue = parseFloat(debitAmtFCYState) * parseFloat(creditDealRateState)
            setDebitAmtLCYState(tempValue)
            document.getElementById('txtAmtPaid').value = tempValue
        }
    }, [debitAmtFCYState, creditDealRateState]);


    return (
    <div>
        <div><div
            className="row align-items-center text-center mb-3"
            style={{
            height: "50px",
            backgroundColor: "#eceff4",
            margin: "3px",
            }}
        >
            <div
            className="col-2 buttonAction"
            style={{
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgb(107,107,107)",
                height: "100%",
                lineHeight: "50px",
            }}
            onClick={async (event)=> {
                checkRequired();
                if (arrRequired.length > 0) {
                  setShowErrorModal(true);
                  setArrRe(arrRequired);
                } else {
                const params = {
                    customerName: document.getElementById('txtCustomerName').value || "",
                    address: document.getElementById('txtAddress').value || "",
                    phoneNo: document.getElementById('txtPhoneNo').value || "",
                    tellerIDst: document.getElementById('txtTellerID_1').value || "",
                    debitCurrency: getIdFromName(Currency_2, document.getElementById('sltDebitCurrency')?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltDebitCurrency')?.value),
                    debitAccount: getIdFromName(SystemCurrency, document.getElementById('sltDebitAccount')?.value) == 0 ? null : getIdFromName(SystemCurrency, document.getElementById('sltDebitAccount')?.value),
                    debitAmtLCY:  document.getElementById('txtDebitAmtLCY').value || 0,
                    debitAmtFCY:  document.getElementById('txtDebitAmtFCY').value || 0,
                    debitDealRate:  document.getElementById('txtDebitDealRate').value || 0,
                    currencyPaid: getIdFromName(Currency_2, document.getElementById('sltCurrencyPaid')?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltCurrencyPaid')?.value),
                    tellerIDnd: document.getElementById('txtTellerID_2').value || "",
                    creditAccount: getIdFromName(SystemCurrency, document.getElementById('sltCreditAccount')?.value) == 0 ? null : getIdFromName(SystemCurrency, document.getElementById('sltCreditAccount')?.value),
                    creditDealRate: document.getElementById('txtCreditDealRate').value || 0,
                    narrative: document.getElementById('txtNarrative').value || "",
                    paidAmount: document.getElementById('txtAmtPaid').value || "",
                };
                console.log('params')
                console.log(params)
                foreignExchangeApi.exchange(params)
                  .then(response => {
                    setShowSuccessModal(true);
                    console.log(response)
                    setRefIDRes(response.data.data.RefID)
                  })
                  .catch(error => {
                    setErrorMessage(error.response.data.message)
                    console.log(errorMessage)
                    // errorMessage = error.Data
                    handleShowBEErrorModal();
                  });
                  
                  // recreateInput();
                }
              }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-save-fill"
                viewBox="0 0 16 16"
                style={{
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                }}
            >
                <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
            </svg>
            <div
                style={{
                display: "inline",
                }}
            >
                {" "}
                Commit Data{" "}
            </div>
            </div>
            <div
            className="col-2 buttonAction"
            style={{
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgb(107,107,107)",
                height: "100%",
                lineHeight: "50px",
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
                style={{
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                }}
            >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            Authorize
            </div>
            <div
            className="col-2 buttonAction"
            style={{
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgb(107,107,107)",
                height: "100%",
                lineHeight: "50px",
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
                style={{
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                }}
            >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            Search
            </div>
            <div
            className="col-2 buttonAction"
            style={{
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgb(107,107,107)",
                height: "100%",
                lineHeight: "50px",
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-printer-fill"
                viewBox="0 0 16 16"
                style={{
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                }}
            >
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            Print Deal Slip
            </div>

            <div
            className="col-2 buttonAction"
            style={{
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgb(107,107,107)",
                height: "100%",
                lineHeight: "50px",
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-pencil-fill"
                viewBox="0 0 16 16"
                style={{
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                }}
            >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>
            Edit data
            </div>
        </div>

        <hr></hr>

        <div
            className="row noCopy"
            style={{
            margin: "20px 5px",
            }}
        >
            <ul className="nav nav-pills">
            <li className="nav-item">
                <a
                className={`nav-link ${activeTab === "active" ? "active" : ""}`}
                aria-current="page"
                href="#"
                onClick={() => setActiveTab("active")}
                style={{
                    backgroundColor: activeTab === "active" ? "#d71921" : "",
                }}
                >
                Foreign Exchange
                </a>
                
            </li>
            </ul>
        </div>

        <hr></hr>

        <div>
            <nav>
            <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
                <a
                className="nav-item nav-link active"
                id="nav-customerInfo-tab"
                data-toggle="tab"
                href="#customer-info"
                role="tab"
                aria-controls="customer-info"
                aria-selected="true"
                >
                Foreign Exchange Infomation
                </a>
            </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="customer-info"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                >
                    {/* Fieldset 1 */}
                    <FieldsetComponent label="Customer Information">
                        {/* row 1 */}
                        <RowComponent>
                            <InputField
                                label="Customer Name:"
                                type="text"
                                id="txtCustomerName"
                                required={true}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Address :"
                                type="text"
                                id="txtAddress"
                                required={true}
                            />
                        </RowComponent>
                        {/* row 2 */}
                        <RowComponent>
                            <InputField
                                label="Phone No:"
                                type="text"
                                id="txtPhoneNo"
                            />
                            <div className="col-1"></div>
                        </RowComponent>
                    </FieldsetComponent>

                    {/* Fieldset 2 */}
                    <FieldsetComponent label="Exchange Information">
                        {/* row 1 */}
                        <RowComponent>
                            <InputField
                                label="Teller ID:"
                                type="text"
                                id="txtTellerID_1"
                                required={true}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Debit Currency:"
                                type="select-depend"
                                id="sltDebitCurrency"
                                list={Currency_2}
                                required={true}
                                state={debitCurrencyState}
                                setState={setDebitCurrencyState}
                            />
                        </RowComponent>
                        {/* row 2 */}
                        <RowComponent>
                            <InputField
                                label="Debit Account :"
                                type="select"
                                id="sltDebitAccount"
                                list={SystemCurrency}
                                required={true}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Debit Amt LCY:"
                                type="number"
                                id="txtDebitAmtLCY"
                                required={true}
                                isDisabled={LCYState}
                                state={debitAmtLCYState}
                                setState={setDebitAmtLCYState}
                            />
                        </RowComponent>
                        {/* row 3 */}
                        <RowComponent>
                            <InputField
                                label="Debit Amt FCY:"
                                type="number"
                                id="txtDebitAmtFCY"
                                isDisabled={!LCYState}
                                state={debitAmtFCYState}
                                setState={setDebitAmtFCYState}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Debit Deal Rate:"
                                type="number"
                                id="txtDebitDealRate"
                                isDisabled={LCYState}
                                state={debitDealRateState}
                                setState={setDebitDealRateState}
                            />
                        </RowComponent>
                        <hr></hr>
                        <RowComponent>
                            <InputField
                                label="Currency Paid:"
                                type="select"
                                id="sltCurrencyPaid"
                                list={Currency_2}
                                required={true}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Teller ID:"
                                type="text"
                                id="txtTellerID_2"
                            />
                        </RowComponent>
                        <RowComponent>
                            <InputField
                                label="Credit Account:"
                                type="select"
                                id="sltCreditAccount"
                                list={SystemCurrency}
                                required={true}
                            />
                            <div className="col-1"></div>
                            <InputField
                                label="Credit Deal Rate:"
                                type="number"
                                id="txtCreditDealRate"
                                isDisabled={!LCYState}
                                state={creditDealRateState}
                                setState={setCreditDealRateState}
                            />
                        </RowComponent>
                        <RowComponent>
                            <InputField
                                label="Amount Paid to Cust:"
                                type="text"
                                id="txtAmtPaid"
                                isDisabled={true}
                            />
                            <div className="col-1"></div>
                        </RowComponent>

                    </FieldsetComponent>
                    {/* Fieldset 3 */}
                    <FieldsetComponent label="">
                        <RowComponent>
                        <InputField
                                label="Narrative:"
                                type="text"
                                id="txtNarrative"
                            />
                            <div className="col-1"></div>
                        </RowComponent>
                    </FieldsetComponent>
                </div>
            </div>
        </div>


        {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <b>Foreign exchange</b> opening was successful! <br />
          - Customer: <b>{document.getElementById('txtCustomerName')?.value}</b> <br />
          - RefID: <b>{refIDRes}</b> <br/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>


    </div>
    
    </div>
  )
}

// check 
let arrRequired = []
function checkRequired() {
  arrRequired = []
  let arr = [
    {type: "text",id: "txtCustomerName",name: 'Customer Name'},
    {type: "text",id: "txtAddress",name: 'Address'},
    {type: "text",id: "txtTellerID_1",name: 'Teller ID'},
    {type: "select",id: "sltDebitCurrency",name:'Debit Currency'},
    {type: "select",id: "sltDebitAccount",name: 'Debit Account'},
    {type: "number",id: "txtDebitAmtLCY",name: 'Debit Amt LCY'},
    {type: "select",id: "sltCurrencyPaid",name: 'Currency Paid'},
    {type: "select",id: "sltCurrencyPaid",name: 'Currency Paid'},
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  // check value
  let currencyPaid = document.getElementById("sltCurrencyPaid")?.value
  let debitCurrency = document.getElementById("sltDebitCurrency")?.value
  if( currencyPaid && debitCurrency && currencyPaid == debitCurrency ) {
    arrRequired.push('Debit Currency must be different from Currency Paid')
  }

  if (arrRequired.length > 0) return true; else return false;
}


function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
