const DocTypeList = [
        {
        "id": 1,
        "name": ""
        },
        {
        "id": 2,
        "name": "COM.CONT.PER.INFO"
        },
        {
        "id": 3,
        "name": "DRIVING.LICENSE"
        },
        {
        "id": 4,
        "name": "ESTAB.LIC.CODE"
        },
        {
        "id": 5,
        "name": "NATIONAL.ID"
        },
        {
        "id": 6,
        "name": "OTHER.DOC"
        },
        {
        "id": 6,
        "name": "PASSPORT"
        },
        {
        "id": 7,
        "name": "TAX CODE"
        },
        {
        "id": 8,
        "name": "CITYZEN IDENTIFICATION"
        }
]

export default DocTypeList