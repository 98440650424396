import logo from './logo.svg';
import './App.css';
import LayoutBasic from './components/LayoutBasic.js/LayoutBasic';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import LoginLayout from './components/layouts/LoginLayout';
import { useEffect, useState } from 'react';



function App() {

  if (window.location.pathname !== '/login' && ((!localStorage.getItem('isLoggedIn')))) window.location.replace('/login'); 


  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(isLoggedIn);
    if (isLoggedIn) {
    }
  }, []);
  if (isLoggedIn) {
    return (
      <div className="App">
          {/* <LayoutBasic /> */}
          <Router>
          <Routes>
          {
            privateRoutes.map((route, index)=> {
              const Page = route.component
              let Layout = LayoutBasic
              return <Route key={index} path={route.path} element={
                <Layout>
                    {/* <Page /> */}
                    <Page />
                </Layout>} />
            })
          }
          </Routes>
      </Router>
      </div>
    );
  }
  else
  {
    return (
      <div className="App">
          {/* <LayoutBasic /> */}
          <Router>
          <Routes>
          {
            publicRoutes.map((route, index)=> {
              const Page = route.component
              let Layout =LoginLayout
              return <Route key={index} path={route.path} element={
                <Layout>
                    {/* <Page /> */}
                    <Page isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
                </Layout>} />
            })
          }
          </Routes>
      </Router>
      </div>
    );
  }
}

export default App;
