import React, { useState , useEffect} from "react";
import { Button, Modal } from 'react-bootstrap';
// import { Form } from 'react-bootstrap';
import dataSelect from "../../../data/select.json";
// import "react-datepicker/dist/react-datepicker.css";
import CityList from "../../../data/CityList";
import CountryList from "../../../data/CountryList";
import DocTypeList from "../../../data/DocTypeList";

import "./CustomerManagement.css";
import InputField from "../../../components/InputField/InputField";
import FieldsetComponent from "../../../components/FieldsetComponent/FieldsetComponent";
import RowComponent from "../../../components/RowComponent/RowComponent";
import customerApi from "../../../apis/customerApi";
import AccountOfficers from "../../../data/AccountOfficer";
import cusApi from "../../../apis/cusApi";
import RelationCode from "../../../data/RelationCode";
import ResidenceType from "../../../data/ResidenceType";
import Currency_1 from "../../../data/Currency_1";
import Sector from "../../../data/Sector";
import SubSector from "../../../data/SubSector";
import Industry from "../../../data/Industry";
import SubIndustry from "../../../data/SubIndustry";
import TempData from "../../../data/TempData";
import CommitDataIcon from "../../../components/ActionBar/CommitDataIcon";
import AuthorizeIcon from "../../../components/ActionBar/AuthorizeIcon";
import SearchIcon from "../../../components/ActionBar/SearchIcon";
import PrintDealSlipIcon from "../../../components/ActionBar/PrintDealSlipIcon";
import EditDataIcon from "../../../components/ActionBar/EditDataIcon";
import ClearIcon from "../../../components/ActionBar/ClearIcon";



export default function OpenIndividualCustomer() {

  
  // 1. State check required
  const [arrRe, setArrRe] = useState([])
  const handleSetArrRe = (item) => {setArrRe(item);};
  // 2. State show Error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  // 3. State show Success
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
    // 5. State show Backend Error
    const [showBEError, setShowBEError] = useState(false);
    const handleCloseBEErrorModal = () => setShowBEError(false);
    const handleShowBEErrorModal = () => setShowBEError(true);
    // State response
    const [refIDRes, setRefIDRes] = useState(""); 

  const [activeTab, setActiveTab] = useState("active");
  const [options, setOptions] = useState(dataSelect);

  // 5. Select State
  const [getSector, setSector] = useState(0);
  const [getIndustry, setIndustry] = useState(0);
  useEffect(() => {
    clearDependentValue("sltIndustry")
  }, [getIndustry]);

  const handleChange = (event) => {
    setOptions(event.target.value);
  };

  const [activeTab01, setActiveTab01] = useState("home");

  const handleTabClick = (tab) => {
    setActiveTab01(tab);
  };
  return (
    <div>
      {/* button action  */}
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{
          height: "50px",
          backgroundColor: "#eceff4",
          margin: "3px",
        }}
      >
        <div
          className="col-2 buttonAction"
          // data-bs-toggle="modal"
          // data-bs-target="#exampleModal"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={async (event)=> {
            checkRequired();
            if (arrRequired.length > 0) {
              setShowErrorModal(true);
              setArrRe(arrRequired);
            } else {
              const params = {
                firstName: document.getElementById('txtFirstName').value || "",
                lastName: document.getElementById('txtLastName').value || "",
                middleName: document.getElementById('txtMiddleName').value || "",    
                GB_ShortName: document.getElementById('txtGBShortName').value || "",
                GB_FullName: document.getElementById('txtGBFullName').value || "",
                GB_Street: document.getElementById('txtGBStreet').value || "",
                GB_Towndist: document.getElementById('txtGBTown/Dist').value || "",
                mobilePhone: document.getElementById('txtMobilePhone').value || "",
                docID: document.getElementById('txtDocID').value || "",
                emailAddress: document.getElementById('txtEmailAddress').value || "",
                birthday: document.getElementById('dtBirthday').value || null,
                subSector: 1, // tp kinh te ca the
                industry: getIdFromName(SubIndustry, document.getElementById('sltIndustry')?.value) == 0 ? null : getIdFromName(SubIndustry, document.getElementById('sltIndustry')?.value),
                docIssuePlace: document.getElementById('txtDocIssuePlace').value || "",
                docIssueDate: document.getElementById('dtDocIssueDate').value || null,
                docExpiryDate: document.getElementById('dtDocExpiryDate').value || null,
                cityProvince: getIdFromName(CityList, document.getElementById('sltCity/Province')?.value) == 0 ? null :  getIdFromName(CityList, document.getElementById('sltCity/Province')?.value) ,
                GB_Country: getIdFromName(CountryList, document.getElementById('sltGBCountry')?.value) == 0? null : getIdFromName(CountryList, document.getElementById('sltGBCountry')?.value),
                nationality: getIdFromName(CountryList, document.getElementById('sltNationality')?.value) == 0? null: getIdFromName(CountryList, document.getElementById('sltNationality')?.value) ,
                residence: getIdFromName(CountryList, document.getElementById('sltResidence')?.value) == 0? null: getIdFromName(CountryList, document.getElementById('sltResidence')?.value),
                doctype: getIdFromName(DocTypeList, document.getElementById('sltDocType')?.value) == 0? null: getIdFromName(DocTypeList, document.getElementById('sltDocType')?.value),
                mainSector: 15, //individual
                mainIndustry: getIdFromName(Industry, document.getElementById('sltMainIndustry')?.value) == 0? null: getIdFromName(Industry, document.getElementById('sltMainIndustry')?.value),
                accountOfficer: getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer')?.value) == 0? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer')?.value),
                currency: getIdFromName(Currency_1, document.getElementById('sltCurrency')?.value) == 0 ? null: getIdFromName(Currency_1, document.getElementById('sltCurrency')?.value),
                gender: getIdFromName(TempData.Gender, document.getElementById('sltGender')?.value),
                title: getIdFromName(TempData.CusTitle, document.getElementById('sltTitle')?.value),
                maritalStatus: getIdFromName(TempData.CusMaritalStatus, document.getElementById('sltMaritalStatus')?.value),
                contactDate: document.getElementById('txtContactDate').value || null,
                relationCode:  getIdFromName(RelationCode, document.getElementById('sltRelationCode')?.value) == 0 ? null:getIdFromName(RelationCode, document.getElementById('sltRelationCode')?.value) ,
                officeNumber: document.getElementById('txtOfficeNumber').value || "",
                faxNumber:  document.getElementById('txtFaxNumber').value || "",
                dependantsNo: document.getElementById('txtNoDependants').value || null,
                childUnder15: document.getElementById('txtNoChild_15').value || null,
                childfr15to25: document.getElementById('txtNoChild15_25').value || null,
                childOver25: document.getElementById('txtNoChild25_').value || null,
                homeOwnership:  getIdFromName(TempData.BooleanData, document.getElementById('sltHomeOwnership')?.value),
                residenceType: getIdFromName(ResidenceType, document.getElementById('sltResidenceType')?.value) == 0? null :  getIdFromName(ResidenceType, document.getElementById('sltResidenceType')?.value),
                employmentStatus: getIdFromName(TempData.BooleanData, document.getElementById('sltEmploymentStatus')?.value),
                companyName: document.getElementById('txtCompanyName').value || "",
                monthlyIncome:  document.getElementById('txtMonthlyIncome').value || null,
                officeAddress: document.getElementById('txtOfficeAddress').value || "",
                customerLiability: document.getElementById('txtCustomerLiability').value || "",
            };

            cusApi.postIndividual(params)
              .then(response => {
                setShowSuccessModal(true);
                console.log(response)
                setRefIDRes(response.data.data.customer.RefID)
              })
              .catch(error => {
                setErrorMessage(error.response.data.message)
                console.log(errorMessage)
                // errorMessage = error.Data
                handleShowBEErrorModal();
              });
              
              // recreateInput();
            }
          }}
        >
          <CommitDataIcon status={true}/>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className="col-2 "
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 0.2)",
          height: "100%",
          lineHeight: "50px",}}
          onClick={() => {
            console.log(document.getElementById('sltCity/Province').value)
            console.log(getIdFromName(CityList,document.getElementById('sltCity/Province').value))
          }}
        >
          <AuthorizeIcon status={false}/>
          Authorize
        </div>
        <div
          className="col-2"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 0.2)",
          height: "100%",
          lineHeight: "50px",}}
        >
          <SearchIcon status={false}/>
          Search
        </div>
        <div
          className="col-2"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 0.2)",
          height: "100%",
          lineHeight: "50px",}}
        >
          <PrintDealSlipIcon />
          Print Deal Slip
        </div>

        <div
          className="col-2"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 0.2)",
          height: "100%",
          lineHeight: "50px",}}
        >
          <EditDataIcon status={false}/>
          Edit data
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={() => {
            recreateInput();
          }}
        >
          <ClearIcon status={true}/>
          Clear
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Open Individual Customer
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>

      <div
          onChange={() => {
            let middleName = '';
            if (document.getElementById('txtMiddleName').value.length != 0) middleName = ' ' + document.getElementById('txtMiddleName').value;
            document.getElementById('txtGBShortName').value = document.getElementById('txtGBFullName').value =  (document.getElementById('txtLastName').value + middleName + ' ' +document.getElementById('txtFirstName').value).trim()
          }}
      >
        <nav>
          <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Customer Info
            </a>
            <a
              className="nav-item nav-link"
              id="nav-details-tab"
              data-toggle="tab"
              href="#details"
              role="tab"
              aria-controls="nav-details"
              aria-selected="false"
            >
              Details
            </a>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="customer-info"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
              {/* Fieldset 1 */}
            <FieldsetComponent label="Personal Information">
              {/* row 1 */}
              <RowComponent>
                <InputField label="First Name:" type="text" id="txtFirstName" />
                <div className="col-1"></div>
                <InputField label="Last Name:" type="text" id="txtLastName" />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                  label="Middle Name:"
                  type="text"
                  id="txtMiddleName"
                />
                <div className="col-1"></div>
                <InputField label="Birthday:" type="date" id="dtBirthday" />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="GB Short Name:"
                  type="text"
                  id="txtGBShortName"
                  required={true}
                />
                <div className="col-1"></div>
                <InputField
                  label="GB Full Name:"
                  type="text"
                  id="txtGBFullName"
                  required={true}
                />
              </RowComponent>
            </FieldsetComponent>
            {/* Fieldset 2 */}
            <FieldsetComponent label="Identification Details">
              {/* row 1 */}
              <RowComponent>
                <InputField
                  label="GB Street:"
                  type="text"
                  id="txtGBStreet"
                  required={true}
                />
                <div className="col-1"></div>
                <InputField
                  label="GB Town/Dist:"
                  type="text"
                  id="txtGBTown/Dist"
                  required={true}
                />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                  label="Mobile Phone:"
                  type="text"
                  id="txtMobilePhone"
                  ph="-- Enter Phone Number --"
                />
                <div className="col-1"></div>
                <InputField
                  label="City/Province:"
                  type="select"
                  id="sltCity/Province"
                  list={CityList}
                  required={true}
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="GB Country:"
                  type="select"
                  id="sltGBCountry"
                  list={CountryList}
                />
                <div className="col-1"></div>
                <InputField
                  label="Nationality:"
                  type="select"
                  id="sltNationality"
                  list={CountryList}
                />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                  label="Residence:"
                  type="select"
                  id="sltResidence"list={CountryList}/>
                <div className="col-1"></div>
                <InputField
                  label="Doc Type:"
                  type="select"
                  id="sltDocType"
                  list={DocTypeList}
                  required={true}
                />
              </RowComponent>
              {/* row 5 */}
              <RowComponent>
                <InputField
                  label="Doc ID:"
                  type="text"
                  id="txtDocID"
                  required={true}
                />
                <div className="col-1"></div>
                <InputField
                  label="Doc Issue Place:"
                  type="text"
                  id="txtDocIssuePlace"
                  required={true}
                />
              </RowComponent>
              {/* row 6 */}
              <RowComponent>
                <InputField
                  label="Doc Issue Date:"
                  type="date"
                  id="dtDocIssueDate"
                />
                <div className="col-1"></div>
                <InputField
                  label="Doc Expiry Date:"
                  type="date"
                  id="dtDocExpiryDate"
                />
              </RowComponent>
              {/* row 8 */}
              <RowComponent>
                <InputField
                  label="Email Address:"
                  type="text"
                  id="txtEmailAddress"
                />
              </RowComponent>
            </FieldsetComponent>
            {/* Fieldset 3 */}
            <FieldsetComponent label="Professional Information">
              {/* row 1 */}
              <RowComponent>
                <InputField
                  label="Main Sector:"
                  type="select"
                  id="sltMainSector"
                  list={[Sector[15]]}
                  state={getSector}
                  setState={setSector}
                  required={true}
                />
                <div className="col-1"></div>
                <InputField
                  label="Sub Sector:"
                  type="select"
                  id="sltSubSector"
                  list={[SubSector[1]]}
                  required={true}
                />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                  label="Main Industry:"
                  type="select-depend"
                  id="sltMainIndustry"
                  list={Industry}
                  required={true}
                  state={getIndustry}
                  setState={setIndustry}
                />
                <div className="col-1"></div>
                <InputField
                  label="Industry:"
                  type="select"
                  id="sltIndustry"
                  list={SubIndustry.filter((option) => {
                    let value = getIdFromName(Industry, getIndustry)
                    return option.industry == value
                  })}
                  required={true}
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="Account Officer:"
                  type="select"
                  id="sltAccountOfficer"
                  list={AccountOfficers}
                />
                <div className="col-1"></div>
                <InputField
                  label="Company Book:"
                  type="text"
                  id="txtCompanyBook"
                />
              </RowComponent>
            </FieldsetComponent>
          </div>

          

          {/* Details */}
          <div
            className="tab-pane fade"
            id="details"
            role="tabpanel"
            aria-labelledby="nav-details-tab"
          >
              {/* Fieldset 1 */}
            <FieldsetComponent label="Personal Details ">
              {/* row 1 */}
              <RowComponent>
                <InputField
                    label="Gender:"
                    type="select"
                    id="sltGender"
                    list={TempData.Gender}
                />
                <div className="col-1"></div>
                <InputField
                    label="Title:"
                    type="select"
                    id="sltTitle"
                    list={TempData.CusTitle}
                />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                      label="Marital Status:"
                      type="select"
                      id="sltMaritalStatus"
                      list={TempData.CusMaritalStatus}
                  />
                <div className="col-1"></div>
                <InputField
                      label="Relation Code:"
                      type="select"
                      id="sltRelationCode"
                      list={RelationCode}
                  />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="Contact Date:"
                  type="date"
                  id="txtContactDate"
                />
                <div className="col-1"></div>
              </RowComponent>
              {/* row 1 */}
              <hr></hr>
              <RowComponent>
                <InputField
                  label="Office Number:"
                  type="text"
                  id="txtOfficeNumber"
                />
                <div className="col-1"></div>
                <InputField
                  label="Fax Number:"
                  type="text"
                  id="txtFaxNumber"
                />
              </RowComponent>

              <hr></hr>
              {/* row 2 */}
              <RowComponent>
                <InputField
                  label="No. of dependants:"
                  type="text"
                  id="txtNoDependants"
                />
                <div className="col-1"></div>
                <InputField
                  label="No. Child (< 15 yrs old):"
                  type="number-nohandle"
                  id="txtNoChild_15"
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="No. Child (15-25 yrs old):"
                  type="number-nohandle"
                  id="txtNoChild15_25"
                />
                <div className="col-1"></div>
                <InputField
                  label="No. Child (>25 yrs old):"
                  type="number-nohandle"
                  id="txtNoChild25_"
                />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                  label="Home Ownership:"
                  type="select"
                  id="sltHomeOwnership"
                  list={TempData.BooleanData}/>
                <div className="col-1"></div>
                <InputField
                  label="Residence Type:"
                  type="select"
                  id="sltResidenceType"
                  list={ResidenceType}

                />
              </RowComponent>
              {/* row 5 */}
              <RowComponent>
                <InputField
                    label="Employment Status:"
                    type="select"
                    id="sltEmploymentStatus"
                    list={TempData.BooleanData}

                  />
                <div className="col-1"></div>
                <InputField
                  label="Company's Name:"
                  type="text"
                  id="txtCompanyName"
                />
              </RowComponent>
              {/* row 6 */}
              <RowComponent>
              <InputField
                    label="Currency:"
                    type="select"
                    id="sltCurrency"
                    list={Currency_1}

                  />
                <div className="col-1"></div>
                <InputField
                  label="Monthly Income:"
                  type="text"
                  id="txtMonthlyIncome"
                />
              </RowComponent>
              {/* row 8 */}
              <RowComponent>
                <InputField
                  label="Office Address:"
                  type="text"
                  id="txtOfficeAddress"
                />
              </RowComponent>
              <hr></hr>
              {/* row 1 */}
              <RowComponent>
                <InputField
                  label="Customer Liability:"
                  type="text"
                  id="txtCustomerLiability"
                />
                <div className="col-1"></div>
                
              </RowComponent>
              
            </FieldsetComponent>
          </div>
        </div>
      </div>

       {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <b>Individual Customer Account</b> opening was successful! <br />
          - Name: <b>{document.getElementById('txtGBFullName')?.value}</b> <br />
          - Doc ID: <b>{document.getElementById('txtDocID')?.value}</b> <br/>
          - Customer ID:  <b>{refIDRes}</b> 
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
// check 
let arrRequired = []
function checkRequired() {
  arrRequired = []
  let arr = [
    {type: "text",id: "txtGBShortName",name: 'GB Short Name'},
    {type: "text",id: "txtGBFullName",name: 'GB Full Name'},
    {type: "text",id: "txtGBStreet",name: 'GB Street'},
    {type: "text",id: "txtGBTown/Dist",name: 'GB Town/Dist'},
    {type: "select",id: "sltCity/Province",name: 'City/Province'},
    {type: "select",id: "sltDocType",name: 'Doc Type'},
    {type: "text",id: "txtDocID",name: 'Doc ID'},
    {type: "text",id: "txtDocIssuePlace",name: 'Doc Issue Place'},
    {type: "select",id: "sltMainSector",name: 'Main Sector'},
    {type: "select",id: "sltSubSector",name: 'Sub Sector'},
    {type: "select",id: "sltMainIndustry",name: 'Main Industry'},
    {type: "select",id: "sltIndustry",name: 'Industry'},
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  // check dependent value
  let sectorID_ = document.getElementById("sltSubSector")?.value
  let obj = SubSector.find(obj => obj.name === sectorID_)
  if(document.getElementById("sltSubSector")?.value.length != 0 && document.getElementById("sltMainSector")?.value.length != 0 && obj?.sector != getIdFromName(Sector, document.getElementById("sltMainSector")?.value)){
    arrRequired.push("SubSector is not belonged to Sector")
  }
  let industryID_ = document.getElementById("sltIndustry")?.value
  let obj2 = SubIndustry.find(obj => obj.name === industryID_)
  if(document.getElementById("sltIndustry")?.value.length != 0 && document.getElementById("sltMainIndustry")?.value.length != 0 && obj2?.industry != getIdFromName(Industry, document.getElementById("sltMainIndustry")?.value)){
    arrRequired.push("SubIndustry is not belonged to Industry")
  }
  if (arrRequired.length > 0) return true; else return false;
}
function recreateInput() {
  let arr = [
    // Required
    {id: "txtGBShortName"},
    {id: "txtGBFullName"},
    {id: "txtGBStreet"},
    {id: "txtGBTown/Dist"},
    {id: "sltCity/Province"},
    {id: "sltDocType"},
    {id: "txtDocID"},
    {id: "txtDocIssuePlace"},
    {id: "sltMainSector"},
    {id: "sltSubSector"},
    {id: "sltMainIndustry"},
    {id: "sltIndustry"},
    // Optional
    {id: "dtBirthday"},
    {id: "txtLastName"},
    {id: "txtFirstName"},
    {id: "txtMiddleName"},
    {id: "txtMobilePhone"},
    {id: "sltGBCountry"},
    {id: "sltNationality"},
    {id: "sltResidence"},
    {id: "dtDocIssueDate"},
    {id: "dtDocExpiryDate"},
    {id: "txtEmailAddress"},
    {id: "sltAccountOfficer"},
    // Page 2 - Details
    {id: "sltGender"},
    {id: "sltTitle"},
    {id: "sltMaritalStatus"},
    {id: "sltRelationCode"},
    {id: "txtContactDate"},
    {id: "txtOfficeNumber"},
    {id: "txtFaxNumber"},
    {id: "txtNoDependants"},
    {id: "txtNoChild_15"},
    {id: "txtNoChild15_25"},
    {id: "txtNoChild25_"},
    {id: "sltHomeOwnership"},
    {id: "sltResidenceType"},
    {id: "sltEmploymentStatus"},
    {id: "txtCompanyName"},
    {id: "sltCurrency"},
    {id: "txtMonthlyIncome"},
    {id: "txtOfficeAddress"},
    {id: "txtCustomerLiability"},


  ];
  arr.map((item, index) => {
    const element = document.getElementById(item.id);
    if (element) {
      element.value = '';
  }
  })
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

const handlePostIndividualCustomer = (event) => {
  event.preventDefault();
  
};

function clearDependentValue(id){
   document.getElementById(id).value = null
}