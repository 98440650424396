import React, { useState ,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap';
import mainAccountApi from '../../../../apis/mainAccountApi';
import transaction_maApi from '../../../../apis/transaction_maApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import TempData from '../../../../data/TempData';

export default function TransferWithdrawal_Modal(props) {
  const [activeTab, setActiveTab] = useState("active");
 // 1. State check required
 const [arrRe, setArrRe] = useState([])
 const handleSetArrRe = (item) => {setArrRe(item);};
 // 2. State show Error
 const [showErrorModal, setShowErrorModal] = useState(false);
 const [errorMessage, setErrorMessage] = useState("error");
 const handleCloseErrorModal = () => setShowErrorModal(false);
 const handleShowErrorModal = () => setShowErrorModal(true);
 // 3. State show Success
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const handleCloseSuccessModal = () => setShowSuccessModal(false);
 const handleShowSuccessModal = () => setShowSuccessModal(true);
 // 5. State show Backend Error
 const [showBEError, setShowBEError] = useState(false);
 const handleCloseBEErrorModal = () => setShowBEError(false);
 const handleShowBEErrorModal = () => setShowBEError(true);
 // BUTTON HANDLE
 const [commitBtn, setCommitBtn] = useState(false)
 const [authorBtn, setAuthoBtn] = useState(false)
 const [editBtn, setEditBtn] = useState(false)
 const [searchBtn, setSearchBtn] = useState(false)
 const [printBtn, setPrintBtn] = useState(false)

 //STATE HANDLE
 const [okMessage, setOKMessage] = useState("active");
 

 // INFORMATION HANDLE
 // GET ACCOUNT FROM DB
 const [isLoading, setIsLoading] = useState(true);
 const [existedAccountDB, setExistedAccountDB] = useState({})
 useEffect(() => {
     async function fetchAccount() {
         try {
         const response = await transaction_maApi.enquiry({refID: props.modalInstanceID, transactionType: 3})
         let tempAccount = response.data.data[0]
         setExistedAccountDB(tempAccount)

         let iniStatus = tempAccount?.Transaction?.Status
         if(iniStatus === 1 && localStorage.getItem('whoami') === 'qtv'){
             setAuthoBtn(true)
         }else 
             setAuthoBtn(false)
         setIsLoading(false);
     } catch (error) {

         }
     }
     fetchAccount();
 }, [props.modalInstanceID]);
 console.log("account fetch")
 console.log(existedAccountDB)

if(!isLoading)
  return (
    <div>
         <div
      className="row align-items-center text-center mb-3"
      style={{
        height: "50px",
        backgroundColor: "#eceff4",
        margin: "3px",
        position: 'sticky',
        top: '0px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'
      }}
      >
        <div
            className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ commitBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
          
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-save-fill"
            viewBox="0 0 16 16"
            style={commitBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
          </svg>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
          style={ authorBtn && {
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 1)",
              height: "100%",
              lineHeight: "50px",
          } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",}}
            onClick={async () => {
            if(authorBtn){
                console.log("Authorize")
                let params = {
                    status: getIdFromName(TempData.Status, document.getElementById("sltAuthoStatus_" + props.id).value || 1)
                }
                await transaction_maApi.validateTransfer(existedAccountDB?.Transaction?.id, params)
                .then(response => {
                    setOKMessage("Authorize Successfully")
                    setShowSuccessModal(true);
                })
                .catch(error => {
                    setErrorMessage(error.response.data.message)
                    console.log(errorMessage)
                    // errorMessage = error.Data
                    handleShowBEErrorModal();
                });
            }
        }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
            style={authorBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          Authorize
        </div>
        <div
         className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
         style={ searchBtn && {
             borderRight: "solid 1px rgba(0,0,0,.2)",
             fontWeight: "",
             color: "rgba(107,107,107, 1)",
             height: "100%",
             lineHeight: "50px",
         } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                 fontWeight: "",
                 color: "rgba(107,107,107, 0.2)",
                 height: "100%",
                 lineHeight: "50px",}}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
            style={searchBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          Search
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 1)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={async () => {
            const response = await transaction_maApi.exportTransfer(existedAccountDB.Transaction?.id)
            const link = response.data.data
            let a = document.createElement('a')
            a.href = link
            a.download = `${props.modalInstanceID}.docx`
            a.click()
        }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107, 1)",
              marginRight: "8px",
            }}
          >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg>
          Print Deal Slip
        </div>

        <div
           className={editBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
           style={ editBtn && {
               borderRight: "solid 1px rgba(0,0,0,.2)",
               fontWeight: "",
               color: "rgba(107,107,107, 1)",
               height: "100%",
               lineHeight: "50px",
           } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                   fontWeight: "",
                   color: "rgba(107,107,107, 0.2)",
                   height: "100%",
                   lineHeight: "50px",}}
    
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            style={editBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          Edit data
        </div>
      </div>
<hr></hr>
  <div>
    <nav>
      <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
        <a
          className="nav-item nav-link active"
          id="nav-customerInfo-tab"
          data-toggle="tab"
          href="#customer-info"
          role="tab"
          aria-controls="customer-info"
          aria-selected="true"
        >
          Transfer Withdrawal Infomation
        </a>
      </div>
    </nav>

    <FieldsetComponent label="Authorize">
            <RowComponent>
                <InputField
                    label="Status:"
                    type="select"
                    id={"sltAuthoStatus_" + props.id}
                    list={TempData.Status.slice(1, 3)}
                    isDisabled={existedAccountDB?.Transaction?.Status === 1 ? false : true}
                    defaultValue={getNameFromId(TempData.Status, existedAccountDB?.Transaction?.Status)}
                />
            </RowComponent>
    </FieldsetComponent>


    <div className="tab-content" id="nav-tabContent">
        <div
            className="tab-pane fade show active"
            id="customer-info"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
        >
         {/* Fieldset 1 */}
        <FieldsetComponent label="Debit Information">
            {/* row 1 */}
            <RowComponent>
              <InputField
                  label="Account Type:"
                  type="select"
                  // id="sltAccountType"
                  id={`sltAccountType_`+props.id}
                  list={AccountType}
                  required={true}
                  isDisabled={true}
                  defaultValue={"Current & Non-Term Saving Account"}
              />
              <div className="col-1"></div>
              <InputField
                  label="Debit Account:"
                  type="text"
                  // id="txtDebitAccount"
                  id={`txtDebitAccount_`+props.id}
                  required={true}
                  isDisabled={true}
                  value={existedAccountDB.Account?.Account}
              />
            </RowComponent>
            <RowComponent>
                <InputField
                    label="Debit Amt:"
                    type="text"
                    // id="txtDebitAmt"
                    id={`txtDebitAmt_`+props.id}
                    isDisabled={true}
                    value={currencyFormat( existedAccountDB.Transaction?.TransferAmount, "VND")}
                />
              <div className="col-1"></div>
                <InputField
                  label="Value Date:"
                  type="date"
                  // id="dtDebitValueDate"
                  id={`dtDebitValueDate_`+props.id}
                  isDisabled={true}
                  value={existedAccountDB.Transaction?.ValueDate}
              />
            </RowComponent>
            {/* row 2 */}
            <hr></hr>
            <RowComponent>
               <InputField
                  label="Customer Account:"
                  type="text"
                  // id="txtDebitCurrency"
                  id={`txtDebitAccountInfo_`+props.id}
                  size={"col-4"}
                  isDisabled={true}
                  value= {"TKTT" + " - " + getNameFromId(Currency_1, existedAccountDB.Account?.Currency) + " - " + existedAccountDB.Account?.Customer?.GB_FullName}
              />
              <div className="col-1"></div>
              
            </RowComponent>
            <RowComponent>
              <InputField
                  label="Customer:"
                  type="text"
                  // id="txtDebitCustomer"
                  id={`txtDebitCustomer_`+props.id}
                  isDisabled={true}
                  value={existedAccountDB.Account?.Customer?.RefID + " - " + existedAccountDB.Account?.Customer?.GB_FullName}
              />
              <div className="col-1"></div>
              <InputField
                  label="Currency:"
                  type="text"
                  // id="txtDebitCurrency"
                  id={`txtDebitCurrency_`+props.id}
                  isDisabled={true}
                  value={existedAccountDB.Account?.Currency}
              />
            </RowComponent>
            {/* row 3 */}
          
            {/* row 4 */}
            <RowComponent>
                <InputField
                    label="Cust Bal:"
                    type="text-handler"
                    // id="txtCustBal"
                    id={`txtCustBal_`+props.id}
                    isDisabled={true}
                    value={currencyFormat( existedAccountDB.Transaction?.InitialAmount, "VND")}
                />
                <div className="col-1"></div>
                <InputField
                    label="New Cust Bal:"
                    type="text"
                    // id="txtNewBal"
                    id={`txtNewBal_`+props.id}
                    isDisabled={true}
                    value={currencyFormat( existedAccountDB.Transaction?.NewAmount, "VND")}
                />
                
            </RowComponent>
          </FieldsetComponent>

         {/* Fieldset 2 */}
         <FieldsetComponent label="Credit Information">
             {/* row 1 */}
             <RowComponent>
              <InputField
                    label="Credit Account:"
                    type="text"
                    // id="txtCreditAccount"
                    id={`txtCreditAccount_`+props.id}
                    isDisabled={true}
                    required={true}
                    value={existedAccountDB.Transaction?.CreditAccountRefID}
                />
                <div className="col-1"></div>
                
            </RowComponent>
            <RowComponent>
                <InputField
                  label="Customer:"
                  type="text"
                  // id="txtCreditCustomer"
                  id={`txtCreditCustomer_`+props.id}
                  isDisabled={true}
                  value={existedAccountDB.Transaction?.CreditCustomer}
                />
                <div className="col-1"></div>
                <InputField
                      label="Currency:"
                      type="text"
                      // id="txtDebitCurrency"
                      id={`txtCrebitCurrency_`+props.id}
                      isDisabled={true}
                      value={existedAccountDB.Transaction?.CreditCurrency}
                  />
                
            </RowComponent>
            {/* row 2 */}
            <RowComponent>
                <InputField
                  label="Credit Account:"
                  type="text"
                  // id="txtCreditCustomer"
                  id={`txtCreditAccountInfo_`+props.id}
                  isDisabled={true}
                  size={"col-4"}
                  value={"TKTT" + " - " + (existedAccountDB.Transaction?.CreditCurrency || "") + " - " + (existedAccountDB.Transaction?.CreditCustomer || "")}
                />
                <div className="col-1"></div>
            </RowComponent>
            {/* row 3 */}
            <hr></hr>
            <RowComponent>
                <InputField
                      label="Value Date:"
                      type="date"
                      // id="dtCreditValueDate"
                      id={`dtCreditValueDate_`+props.id}
                      isDisabled={true}
                      value={existedAccountDB.Transaction?.ValueDate}

                  />
                <div className="col-1"></div>
                <InputField
                  label="Deal Rate:"
                  type="text"
                  // id="txtDealRate"
                  id={`txtDealRate_`+props.id}
                  isDisabled={true}
                  value={existedAccountDB.Transaction?.DealRate}
                />
            </RowComponent>
            <RowComponent>
                <InputField
                      label="Amt Credit for Cust:"
                      type="text"
                      // id="txtAmtCredit"
                      id={`txtAmtCredit_`+props.id}
                      isDisabled={true}
                      value={currencyFormat( existedAccountDB.Transaction?.CreditAmount, "VND")}
                  />
                <div className="col-1"></div>
            </RowComponent>
         </FieldsetComponent>

         <FieldsetComponent label="">
                {/* row 1 */}
              <RowComponent>
                    <InputField
                        label=" Waive Charges?"
                        type="text"
                        // id="sltWaiveCharges"
                        id={`sltWaiveCharges_`+props.id}
                        isDisabled={true}
                        value={existedAccountDB.Transaction?.WaiveCharges === true ? "Yes" : "No"}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Narrative:"
                        type="text"
                        // id="txtNarrative"
                        id={`txtNarrative_`+props.id}
                        isDisabled={true}
                        value={existedAccountDB.Transaction?.Narrative}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label="Print LnNo of PS:"
                        type="text"
                        // id="txtPrint"
                        id={`txtPrint_`+props.id}
                        isDisabled={true}
                        value={existedAccountDB.Transaction?.Narrative}
                    />
                    <div className="col-1"></div>
              </RowComponent>
             </FieldsetComponent>
        </div>
    </div>
</div>

          {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {checkRequired(activeTab, "open")}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})} */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {okMessage} <br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
</div>
  )
}

function getNameFromId(list, id) {
    const obj = list.find(obj => obj.id === id);
    return obj ? obj.name : null;
  }
  

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

