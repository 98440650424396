
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import savingAccountApi from "../../../../apis/savingAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import Currency_1 from "../../../../data/Currency_1";
import Currency_2 from "../../../../data/Currency_2";
import ProductLine from "../../../../data/ProductLine";
import SavingCurrencyAccount from "../../../../data/SavingCurrencyAccount";
import TempData from "../../../../data/TempData";
import Close_ArrearPeriodic_SA from "./Close_ArrearPeriodic_SA";
import Close_Discounted_SA from "./Close_Discounted_SA";

export default function Close_SA() {

  // 1. State check required
  const [arrRe, setArrRe] = useState([])
  const handleSetArrRe = (item) => { setArrRe(item); };
  // 2. State show Error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  // 3. State show Success
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  // 5. State show Backend Error
  const [showBEError, setShowBEError] = useState(false);
  const handleCloseBEErrorModal = () => setShowBEError(false);
  const handleShowBEErrorModal = () => setShowBEError(true);
  const [activeTab, setActiveTab] = useState("active");
  const [closeType, setCloseType] = useState(0);
  const [accountIsExisted, setAccountIsExisted] = useState(false)
  const [accountIsExisted2, setAccountIsExisted2] = useState(false)
  const [rowState_1, setRowState_1] = useState([]);
  const handleSetRowState_1 = (row) => {
    setRowState_1(row);
  };
  const [rowState_2, setRowState_2] = useState([]);
  const handleSetRowState_2 = (row) => {
    setRowState_2(row);
  };

    //HANDLE BUTTON
    const [commitBtn, setCommitBtn] = useState(true)
    const [authorBtn, setAuthoBtn] = useState(false)
    const [editBtn, setEditBtn] = useState(false)
    const [searchBtn, setSearchBtn] = useState(false)
    const [printBtn, setPrintBtn] = useState(false)

    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [disabledState, setDisabledState] = useState(false);
    const [accountStatusDB, setAccountStatusDB] = useState("Active")
    const [closureAccount, setClosureAccount] = useState({})

  // Arrear - Periodic Close
  const [savingAccountDB, setSavingAccountDB] = useState({});
  const [savingAccountDB2, setSavingAccountDB2] = useState({});
  const [accountID, setAccountID] = useState(0);
  const [accountIDSub, setAccountIDSub] = useState(0);
  const [accountListDB, setAccountListDB] = useState([]);
  useEffect( () => {
    async function fetchAccountList() {
      try {
        const response = await savingAccountApi.getAll()
        let accountListTemp = response.data.data
        console.log("fetch account")
        console.log(accountListTemp)
        setAccountListDB(accountListTemp)
      } catch (error) {

      }
    }
    fetchAccountList();
  }, [])

 
  useEffect(() => {
     if(accountListDB && accountID){
        let inputAccount = document.getElementById("txtAccountRefID").value
        console.log(inputAccount)
        const obj = accountListDB.find(obj =>
          obj.Account === inputAccount
        )
        console.log(obj)
        if(obj?.id){
          setAccountIDSub(obj?.id)
        }
        
      }
  }, [accountID, accountListDB])



  const Cell = ({ row }) => (
    <button className="btn btn-sm btn-primary me-2">Edit</button>

  );

  return (
    <div>
      {/* button action  */}
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{ height: "50px", backgroundColor: "#eceff4", margin: "3px" ,
        position: 'sticky',
        top: '48.2px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'}}
      >
        {/* Button: Commit Data */}
        <div
          className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
          style={ commitBtn && {
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 1)",
              height: "100%",
              lineHeight: "50px",
          } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",}}
          onClick={() => {
            if (closeType === 0) {
              let propsID = "close"
              let params = {
                customerID: savingAccountDB?.savingAccount?.CustomerID || null,
                customerName: savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName || "",
                valueDate: document.getElementById("dtValueDate_Close1_2_"   + propsID).value || null,
                endDate: document.getElementById("txtMaturityDate_Close1_2_"   + propsID).value || null,
                originPrincipal: document.getElementById("txtOriginalPrincipal_Close1_2_"   + propsID).value || 0,
                principal: document.getElementById("txtPrincipal_Close1_2_"   + propsID).value || 0,
                interestRate: document.getElementById("txtInterestRate_Close1_2_"   + propsID).value || 0,
                totalAmountLCY: document.getElementById("txtAmountLCY_Close1_3_"   + propsID).value || 0,
                totalAmountFCY: document.getElementById("txtAmountFCY_Close1_3_"   + propsID).value || 0,
                narrative: document.getElementById("txtNarrative_Close1_3_"   + propsID).value || 0,
                dealRate: document.getElementById("txtDealRate_Close1_3_"   + propsID).value || 0,
                tellerID: document.getElementById("txtForTeller_Close1_3_"   + propsID).value || 0,
                creditCCY: getIdFromName(Currency_2, document.getElementById('sltCreditCCY_Close1_3_'   + propsID)?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltCreditCCY_Close1_3_'   + propsID)?.value),
                WorkingAccount: savingAccountDB?.detailAccount?.WorkingAccount,
                customerBalance: document.getElementById("txtPrincipal_Close1_2_"   + propsID).value || 0,
              }

              savingAccountApi.closeArrearPeriodic(accountIDSub, params)
                .then(response => {
                  console.log("response")
                  console.log(response.data.data)
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log(errorMessage)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });

            } else if (closeType === 1) {
            
              let propsID = "close"
              let params = {
              
                depositNo: document.getElementById("txtDepositNo_Close2_2_"   + propsID).value || null,
                customerID: savingAccountDB2?.savingAccount?.CUSTOMER?.id || null,
                customerName: savingAccountDB2?.savingAccount?.CUSTOMER?.GB_FullName || "",
                valueDate: document.getElementById("txtValueDate_Close2_1_"   + propsID).value || null,
                newMatDate: document.getElementById("dtNewMatDate_Close2_1_"   + propsID).value || null,
                intPymtMethod: document.getElementById("txtPaymentMethod_Close2_1_"   + propsID).value || null,
                interestBasic: document.getElementById("txtInterestBasis_Close2_1_"   + propsID).value || null,
                interestRate: document.getElementById("txtInterestedRate_Close2_1_"   + propsID).value || null,
                totalIntAmount: document.getElementById("txtTotalIntAmt_Close2_1_"   + propsID).value || null,
                eligibleInterest: document.getElementById("txtEligibleInterest_Close2_1_"   + propsID).value || null,
                intRateVDate: document.getElementById("dtIntRateDate_Close2_1_"   + propsID).value || null,
                amountLCY: document.getElementById("txtAmountLCY_Close2_2_"   + propsID).value || null,
                amountFCY: document.getElementById("txtAmountFCY_Close2_2_"   + propsID).value || null,
                narrative: document.getElementById("txtNarrative2_Close2_2_"   + propsID).value || null,
                dealRate: document.getElementById("txtDealRate_Close2_2_"   + propsID).value || null,
                teller: document.getElementById("txtForTeller_Close2_2_"   + propsID).value || null,
                waiveCharge: getIdFromName(TempData.BooleanData, document.getElementById('txtWaiveCharge_Close2_2_'   + propsID)?.value) == 0 ? null : getIdFromName(TempData.BooleanData, document.getElementById('txtWaiveCharge_Close2_2_'   + propsID)?.value),
                newCustomerBalance: document.getElementById("txtNewCustBal_Close2_2_"   + propsID).value || null,
                customerBalance: document.getElementById("txtAmtPaid_Close2_2_"   + propsID).value || null,
                creditAccount: document.getElementById("txtCreditAccount_Close2_2_"   + propsID).value || null,
              }

              savingAccountApi.closeDiscounted(accountIDSub, params)
                .then(response => {
                  console.log("response")
                  console.log(response.data.data)
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log(errorMessage)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });

            } else {

            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-save-fill" viewBox="0 0 16 16" 
            style={commitBtn && {
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
              } || {
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
              }}><path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" /></svg>
          <div style={{ display: "inline" }}> Commit Data </div>
        </div>
        {/* Button: Authorize */}
        <div
         className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
         style={ authorBtn && {
             borderRight: "solid 1px rgba(0,0,0,.2)",
             fontWeight: "",
             color: "rgba(107,107,107, 1)",
             height: "100%",
             lineHeight: "50px",
         } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                 fontWeight: "",
                 color: "rgba(107,107,107, 0.2)",
                 height: "100%",
                 lineHeight: "50px",}}
          onClick={() => { }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16" 
          style={authorBtn && {
            color: "rgba(107,107,107,.9)",
            marginRight: "8px",
            } || {
                color: "rgba(107,107,107,.2)",
                marginRight: "8px",
            }}><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>
          Authorize
        </div>
        {/* Button: Search */}
        <div
         className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
         style={ searchBtn && {
             borderRight: "solid 1px rgba(0,0,0,.2)",
             fontWeight: "",
             color: "rgba(107,107,107, 1)",
             height: "100%",
             lineHeight: "50px",
         } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                 fontWeight: "",
                 color: "rgba(107,107,107, 0.2)",
                 height: "100%",
                 lineHeight: "50px",}}

        >
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16" 
          style={searchBtn && {
            color: "rgba(107,107,107,.9)",
            marginRight: "8px",
            } || {
                color: "rgba(107,107,107,.2)",
                marginRight: "8px",
            }}><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
          Search
        </div>
        {/* Button: Print Deal Slip */}
        <div
          className={printBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
          style={ printBtn && {
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 1)",
              height: "100%",
              lineHeight: "50px",
          } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-printer-fill" viewBox="0 0 16 16" 
            style={printBtn && {
            color: "rgba(107,107,107,.9)",
            marginRight: "8px",
            } || {
                color: "rgba(107,107,107,.2)",
                marginRight: "8px",
            }}><path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" /><path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /></svg>
          Print Deal Slip
        </div>
        {/* Button: Edit Data  */}
        <div
         className={editBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
         style={ editBtn && {
             borderRight: "solid 1px rgba(0,0,0,.2)",
             fontWeight: "",
             color: "rgba(107,107,107, 1)",
             height: "100%",
             lineHeight: "50px",
         } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                 fontWeight: "",
                 color: "rgba(107,107,107, 0.2)",
                 height: "100%",
                 lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16" style={editBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" /></svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item mr-3">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              id="close_1Tab"
              href="#close_1"
              data-toggle="pill"
              aria-controls="close_1"
              role="tab"
              aria-selected="true"
              onClick={() => {
                setActiveTab("active")
                setCloseType(0)
              }}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Close Arrear/Periodic Saving Account
            </a>
          </li>
          <li>
            <a
              className={`nav-link ${activeTab === "close2" ? "active" : ""}`}
              data-toggle="pill"
              id="close_2Tab"
              href="#close_2"
              aria-controls="close_2"
              role="tab"
              aria-selected="false"
              onClick={() => {
                setActiveTab("close2")
                setCloseType(1)
              }}
              style={{
                backgroundColor: activeTab === "close2" ? "#d71921" : "",
              }}
            >
              Close Discounted Saving Account
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>
      <FieldsetComponent label="Account Infomation">
        <RowComponent>
            <InputField
                label="Account Ref ID:"
                type="text-handler"
                id="txtAccountRefID"
                state={accountID}
                setState={setAccountID}
                ph={"Enter Account Ref ID"}
            />
        </RowComponent>
    </FieldsetComponent>

      <div class="tab-content" id="CloseContent">
          {activeTab === "active" && (
            <div id="close_1" className="tab-pane fade show active" role="tabpanel" aria-labelledby="close_1Tab">
                  <Close_ArrearPeriodic_SA account={accountIDSub} id={"close"} state={savingAccountDB} setState={setSavingAccountDB} checkAccountState={accountIsExisted} setCheckAccountState={setAccountIsExisted}/>
            </div>
          )}
         {activeTab === "close2" && (
            <div id="close_2"
              className="tab-pane fade show active" role="tabpanel" aria-labelledby="close_2Tab">
                <Close_Discounted_SA account={accountIDSub} id={"close"} state={savingAccountDB2} setState={setSavingAccountDB2} checkAccountState={accountIsExisted2} setCheckAccountState={setAccountIsExisted2}/>
            </div>
         )}
      </div>


        


        {/* --------------------------------------- */}
        {/* <Backend Error Model /> */}
        <div>
          <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: '#d71921', fontSize: '18px' }}>ERROR: Request failed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <b>{errorMessage}</b>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseBEErrorModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* --------------------------------------- */}
        {/* <ErrorModal /> */}
        <div>
          <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: '#d71921', fontSize: '18px' }}>ERROR: Required fields missing</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              {arrRe.map((item, index) => {
                return <>
                  <div>- {item}</div></>
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseErrorModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* --------------------------------------- */}
        {/* Success Modal  */}
        <div>
          <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: 'green', fontSize: '18px' }}>Registration Successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <b>Close Saving Account</b> was successful! <br />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" style={{ backgroundColor: 'green', }} onClick={handleCloseSuccessModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>


  );
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function getNameFromId(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}


function currencyFormat(value, currency) {
  let resultValue = value ? value.toLocaleString('it-IT', { style: 'currency', currency: currency }) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}


function amountCal(origin, rate) {
  console.log(origin)
  console.log(rate)
  return origin * 0.0004316238 * rate
}
