
import React, { useEffect, useState } from "react";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import Currency_1 from "../../../../data/Currency_1";
import ProductLine from "../../../../data/ProductLine";
import TempData from "../../../../data/TempData";
import InfoIcon from "../../../../public/icon/InfoIcon";
import OpenAccount_MA_Modal from "./OpenAccount_MA_Modal";
import DeleteIcon from "../../../../public/icon/deleteIcon";
import BlockAccount_Modal from "./BlockAccount_Modal";
import UnblockAccount_Modal from "./UnblockAccount_Modal";
import CloseAccount_MA from "./CloseAccount_MA";
import CloseAccount_Modal from "./CloseAccount_Modal";
import GetCloseAccount_Modal from "./GetCloseAccount_Modal";
import CloseIcon from "../../../../public/icon/closeIcon";

export default function EnquiryAccount_MA() {
  const [activeTab, setActiveTab] = useState("active");
  const [rowState, setRowState] = useState([]);
  const handleSetRowState = (row) => {
    setRowState(row);
  };

  const [getCategory, setCategory] = useState(0);
  const Cell = ({ row }) => (
      <button className="btn btn-sm btn-primary me-2">Edit</button>

  );

  // MODAL STATE
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalDisabled, setmodalDisabled] = useState(true);
  // 5. key
  const [key, setKey] = useState(0);
  const resetComponent = () => {
    setKey(prevKey => prevKey + 1);
  };
  const [modalInstanceID, setModalInstanceID] = useState(null);
  const handleSetModel = (item) => {
    setModalInstanceID(item);
  };
 
  return (
    <div>
      {/* button action  */}
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{ height: "50px", backgroundColor: "#eceff4", margin: "3px" ,
        position: 'sticky',
        top: '48.2px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'}}
      >
        {/* Button: Commit Data */}
        <div
          className="col-2 noCopy"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
          onClick={() => {}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-save-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" /></svg>
          <div style={{ display: "inline" }}> Commit Data </div>
        </div>
        {/* Button: Authorize */}
        <div
          className="col-2 noCopy"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
          // on Click
          onClick={() => {}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-check-circle-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>
          Authorize
        </div>
        {/* Button: Search */}
        <div
          className="col-2 buttonAction noCopy"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          onClick={ ()=> {

              let params = {
                account: document.getElementById("txtAccountCode")?.value || null,
                customerType: getIdFromName(TempData.CustomerType, document.getElementById('sltCustomerType')?.value) == 0 ? null : getIdFromName(TempData.CustomerType, document.getElementById('sltCustomerType')?.value),
                customerID: document.getElementById("txtCustomerID")?.value || null,
                docID: document.getElementById("txtDocID")?.value || null,
                GB_FullName: document.getElementById("txtGBFullName")?.value || null,
                productLine: getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value),
                category:  getIdFromName(Category, document.getElementById('sltCategory')?.value) == 0 ? null : getIdFromName(Category, document.getElementById('sltCategory')?.value),
                currency:  getIdFromName(Currency_1, document.getElementById('sltCurrency')?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById('sltCurrency')?.value),
                isBlocked:   getIdFromName(TempData.AccountStatus, document.getElementById('sltStatus')?.value) == 2 ? true :false,
                isClosed: getIdFromName(TempData.AccountStatus, document.getElementById('sltStatus')?.value) == 3 ? true :false,
                isActive: getIdFromName(TempData.AccountStatus, document.getElementById('sltStatus')?.value) == 1 ? true :false,
                // isActive: true
              }
            
              mainAccountApi.enquiry(params)
              .then(response => {
                console.log(response.data.data)
                let tempRow = []
                response.data.data.map((item, index) => {
                  let tempObject = {
                    AccountCode: item.Account,
                    CustomerID: item.Customer.RefID,
                    CustomerName: item.Customer.GB_FullName,
                    DocID: item.Customer.DocID,
                    Category:  getNameFromId(Category, item.Category),
                    ProductLine: getNameFromId(ProductLine, item.ProductLine),
                    Currency: getNameFromId(Currency_1, item.Currency),
                    ActualBallance: currencyFormat(item.ActualBalance, getNameFromId(Currency_1, item.Currency)),
                    WorkingAmount: currencyFormat(item.WorkingAmount, getNameFromId(Currency_1, item.Currency)),
                    LockedAmount: currencyFormat(item.BlockedAmount, getNameFromId(Currency_1, item.Currency)) ,
                    Button: <div><button 
                    onClick={()=> {
                      resetComponent()
                      handleSetModel(item?.id)
                      setModalType("GetAccount")
                      setModalTitle("Main Account - Information - RefID: " + item?.Account)
                    }}
                    type="button" className="btn" 
                    style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                    {
                      item?.Status === "Active" && <button 
                      onClick={()=> {
                        resetComponent()
                        handleSetModel(item?.id)
                        setModalType("BlockAccount")
                        setModalTitle("Block Main Account - Information - RefID: " +item?.Account)
                      }}
                      type="button" className="btn" 
                      style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<DeleteIcon />}</button>
                    }
                    {
                      item?.Status === "Blocked" && <button 
                      onClick={()=> {
                        resetComponent()
                        handleSetModel(item?.id)
                        setModalType("UnblockAccount")
                        setModalTitle("Unblock Main Account - Information - RefID: " +item?.Account)
                      }}
                      type="button" className="btn" 
                      style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<CloseIcon />}</button>
                    }
                    {
                      (item?.Status === "Blocked" ||  item?.Status === "Active") && <button 
                      onClick={()=> {
                        resetComponent()
                        handleSetModel(item?.id)
                        setModalType("CloseAccount")
                        setModalTitle("Close Main Account - Information - RefID: " +item?.Account)
                      }}
                      type="button" className="btn" 
                      style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<CloseIcon />}</button>
                    }
                    {
                      (item?.Status === 'closed') && <button 
                      onClick={()=> {
                        resetComponent()
                        handleSetModel(item?.id)
                        setModalType("GetClose")
                        setModalTitle("Close Main Account - Information - RefID: " +item?.Account)
                      }}
                      type="button" className="btn" 
                      style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<CloseIcon />}</button>
                    }
                  
                    </div>
                  }
                  tempRow.push(tempObject)
                })
                handleSetRowState(tempRow)
              })
              .catch(error => {
                console.log(error)
              });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-search"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
          Search
        </div>
        {/* Button: Print Deal Slip */}
        <div
          className="col-2 noCopy"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-printer-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" /><path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /></svg>
          Print Deal Slip
        </div>
        {/* Button: Edit Data  */}
        <div
          className="col-2 noCopy"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-pencil-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" /></svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Enquiry Account
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>

      {/* Fieldset 1 */}
      <FieldsetComponent label="">
        {/* row 1 */}
        <RowComponent>
          <InputField label="Account Code:" type="text" id="txtAccountCode"/>
          <div className="col-1"></div>
          <InputField label="Currency:" type="select" id="sltCurrency" list={Currency_1} />
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField label="Customer Type:" type="select" id="sltCustomerType" list={TempData.CustomerType} />
          <div className="col-1"></div>
          <InputField label="Customer ID:" type="text" id="txtCustomerID"/>
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField
            label="GB Full Name:"
            type="text"
            id="txtGBFullName"
          />
          <div className="col-1"></div>
          <InputField
            label="Doc ID:"
            type="text"
            id="txtDocID"
          />
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField
            label="Category:"
            type="select-depend"
            id="sltCategory"
            list={Category.slice(0, 3)}
            state={getCategory}
            setState={setCategory}
          />
          <div className="col-1"></div>
          <InputField
            label="Product Line:"
            type="select"
            id="sltProductLine"
            list={ProductLine.filter((instance) => {
                              
              let value = getIdFromName(Category, getCategory)
              return instance.category == value
          })}
          />
        </RowComponent>
        {/* row 5 */}
        <RowComponent>
          <InputField
            label="Account Status:"
            type="select"
            id="sltStatus"
            list={TempData.AccountStatus}
            require={true}
            defaultValue={"Active"}
          />
          <div className="col-1"></div>
        </RowComponent>
      </FieldsetComponent>

      <DataTable columnsTemp={columns} rowsTemp={rowState}/>


            {/* Modal Arrear_SA Modal*/}
      <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
          <div className="modal-content"
                style={{
                  overflow: 'auto',
                  maxWidth: 'none',
                  width: '1222px',
                  // height: '1222px'
                }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{modalTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" 
                onClick={() => {
                if(!modalDisabled){
                  setmodalDisabled(true)
                  // setModalInstanceID(0)
                }
                  
              }}>

                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{marginTop:'0', paddingTop: '0'}} key={key}>
                {modalType === "GetAccount" && <OpenAccount_MA_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"get"}/>}
                {modalType === "BlockAccount" && <BlockAccount_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"block"}/>}
                {modalType === "UnblockAccount" && <UnblockAccount_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"unblock"}/>}
                {modalType === "CloseAccount" && <CloseAccount_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"close"}/>}
                {modalType === "GetClose" && <GetCloseAccount_Modal modalInstanceID={modalInstanceID} disabledState={true} setDisabledState={setmodalDisabled} id={"getClose"}/>}
                  {/* setInstanceState={setExistedAccountDB} instanceState={existedAccountDB}/> */}
                
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                if(!modalDisabled)
                  setmodalDisabled(true)
                  // setModalInstanceID(0)
                //  setExistedAccountDB(null)
              }} >Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
    </div>
    </div>
  );
}


const columns = [
  {
    label: 'Account Code',
    field: 'AccountCode',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Customer ID',
    field: 'CustomerID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Customer Name',
    field: 'CustomerName',
    sort: 'asc',
    width: 200
  },
  {
    label: 'Doc ID',
    field: 'DocID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Category',
    field: 'Category',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Product Line',
    field: 'ProductLine',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Currency',
    field: 'Currency',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Actual Ballance',
    field: 'ActualBallance',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Working Amount',
    field: 'WorkingAmount',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Locked Amount	',
    field: 'LockedAmount	',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Button',
    field: 'Button',
    sort: 'asc',
    width: 200,
    // cell: 
      
  },
]
const rows =  [
  {
    name: 'Tiger Nixon',
    position: 'System Architect',
    office: 'Edinburgh',
    age: '61',
    date: '2011/04/25',
    salary: '$320'
  },
  
]


function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function getNameFromId(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}


function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}