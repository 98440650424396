const SavingTerm = [
    { "id": 0, "name": '', "value": 0},
    { "id": 1, "name": '1 month', "value": 1},
    { "id": 2, "name": '2 months', "value": 2},
    { "id": 3, "name": '3 months', "value": 3},
    { "id": 4, "name": '6 months', "value": 6},
    { "id": 5, "name": '9 months', "value": 9},
    { "id": 6, "name": '12 months', "value": 12},
    { "id": 7, "name": '24 months', "value": 24},
    { "id": 8, "name": '36 months', "value": 36},
    { "id": 9, "name": '48 months', "value": 48},
    { "id": 10, "name": '60 months', "value": 60}

  ];

  export default SavingTerm;