import React, { useState ,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap'
import mainAccountApi from '../../../../apis/mainAccountApi'
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField'
import RowComponent from '../../../../components/RowComponent/RowComponent'
import AccountType from '../../../../data/AccountType'
import CloseSystemCurrency from '../../../../data/CloseSystemCurrency'
import Currency_1 from '../../../../data/Currency_1'
import PaymentTypeCloseAcc from '../../../../data/PaymentTypeCloseAcc'
import SystemCurrency from '../../../../data/SystemAccount'
import TempData from '../../../../data/TempData'

export default function GetCloseAccount_Modal(props) {
  // 1. State check required
  const [arrRe, setArrRe] = useState([])
  const handleSetArrRe = (item) => {setArrRe(item);};
  // 2. State show Error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  // 3. State show Success
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
    // 5. State show Backend Error
    const [showBEError, setShowBEError] = useState(false);
    const handleCloseBEErrorModal = () => setShowBEError(false);
    const handleShowBEErrorModal = () => setShowBEError(true);

    const [creditCurrencyState, setCreditCurrencyState] = useState(0)

  //HANDLE BUTTON
  const [commitBtn, setCommitBtn] = useState(false)
  const [authorBtn, setAuthoBtn] = useState(false)
  const [editBtn, setEditBtn] = useState(false)
  const [searchBtn, setSearchBtn] = useState(false)
  const [printBtn, setPrintBtn] = useState(false)

// Props: id (id that của account)
const closedAccountRefID = 1
// GET ACCOUNT
  // GET ACCOUNT
  const [isLoading, setIsLoading] = useState(true) 
  
  const [accountID, setAccountID] = useState(0) 
  const [accountDB, setAccountDB] = useState({})
  const [closedAccountDB, setClosedAccountDB] = useState({})
  const [accountExisted, setAccountExisted] = useState(false)

  useEffect(() => {
    async function fetchAccount() {
      try {
        setAccountExisted(false)
        setAccountDB(0)
        const response1 = await mainAccountApi.enquiry({account: props.modalInstanceID, isClosed: true})
        
        if(response1.data.data[0]){
            
            const customerTemp = response1.data.data[0]
            setAccountDB(customerTemp)
            const response2 = await mainAccountApi.getCloseAccount(props.modalInstanceID)
            const customerTemp2 = response2.data.data
            setClosedAccountDB(customerTemp2)

            setAccountExisted(true)
        }else{
            setAccountExisted(false)
        }
        setIsLoading(false)
      } catch (error) {
      }
    }
    fetchAccount();
  }, []);
  console.log('closedAccountDB')
console.log(closedAccountDB)


// GET ALL CUSTOMER'S ACCOUNT
const [accountsDB, setAccountsDB] = useState([])

useEffect(() => {
    async function fetchAccountsDB() {
      try {
        if(accountDB.CustomerID){
          const response = await mainAccountApi.enquiry({customerID: accountDB.CustomerID})
          const customerTemp = response.data.data
          setAccountsDB(customerTemp)
        }
        
      } catch (error) {

      }
    }
    fetchAccountsDB();
}, [accountDB]);
console.log("accountDB")
console.log(accountDB)
// MAKE PAID ACCOUNT 
let tempAccount = []
let customerAccount =[]
let id = 6
if(accountsDB && accountDB){
  accountsDB.map((value, index) => {
    console.log(accountDB.Account)
    if(value.Account == accountDB.Account){
      
    }else{
      let temp = {"id": id, "name": value.Account + "-" + " TKTT " + value.CURRENCY?.Name + " " + value.Customer?.GB_FullName, "currency": value.Currency, "customerAccount": value.id}
      id++;
      tempAccount.push(temp)
    }
    
  })
  customerAccount = CloseSystemCurrency.map((value) => {
    value.customerAccount = 0
    return value
  }).concat(tempAccount)
}

console.log("customer account")
console.log(customerAccount)

const [activeTab, setActiveTab] = useState("active");

if(!isLoading)
return (
<div>
  <div><div
        className="row align-items-center text-center mb-3"
        style={{
        height: "50px",
        backgroundColor: "#eceff4",
        margin: "3px",
        position: 'sticky',
        top: '0px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'
        }}
    >
        <div
        className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
              style={ commitBtn && {
                  borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 1)",
                  height: "100%",
                  lineHeight: "50px",
              } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                      fontWeight: "",
                      color: "rgba(107,107,107, 0.2)",
                      height: "100%",
                      lineHeight: "50px",}}

        onClick={async (event)=> {
          checkRequired();
          if (arrRequired.length > 0) {
            setShowErrorModal(true);
            setArrRe(arrRequired);
          } else {
          const params = {
            paymentType: document.getElementById('sltPaymentType')?.value || null,
            transferredAccount: checkTransferAccount(customerAccount) || null, 
            closeDate: document.getElementById('dtDebitDate').value || null,
            notes: document.getElementById('txtNarrative').value || null,
            creditCurrency: getIdFromName(Currency_1, document.getElementById('sltCreditCurrency')?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById('sltCreditCurrency')?.value),
          }
          console.log('params')
          console.log(params)
          mainAccountApi.closeAccount(accountDB.id, params)
            .then(response => {
              console.log("response")
              console.log(response)
              setShowSuccessModal(true);
            })
            .catch(error => {
              setErrorMessage(error.response.data.message)
              console.log('errorMessage')
              console.log(error)
              // errorMessage = error.Data
              handleShowBEErrorModal();
            });
            
          }
        }}
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-save-fill"
            viewBox="0 0 16 16"
            style={commitBtn && {
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
              } || {
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
              }}
            
        >
            <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
        </svg>
        <div
            style={{
            display: "inline",
            }}
        >
            {" "}
            Commit Data{" "}
        </div>
        </div>
        <div
        className="col-2 noCopy"
        style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
        }}
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
            style={{
            color: "rgba(107,107,107,.2)",
            marginRight: "8px",
            }}
        >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
        Authorize
        </div>
        <div
        className="col-2 noCopy"
        style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
        }}
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
            style={{
            color: "rgba(107,107,107,.2)",
            marginRight: "8px",
            }}
        >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
        Search
        </div>
        <div
        className="col-2 noCopy"
        style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
        }}
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer-fill"
            viewBox="0 0 16 16"
            style={{
            color: "rgba(107,107,107,.2)",
            marginRight: "8px",
            }}
        >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
        </svg>
        Print Deal Slip
        </div>

        <div
        className="col-2 noCopy"
        style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
        }}
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            style={{
            color: "rgba(107,107,107,.2)",
            marginRight: "8px",
            }}
        >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
        </svg>
        Edit data
        </div>
    </div>

    <hr></hr>

    <div
        className="row noCopy"
        style={{
        margin: "20px 5px",
        }}
    >
        <ul className="nav nav-pills">
        <li className="nav-item">
            <a
            className={`nav-link ${activeTab === "active" ? "active" : ""}`}
            aria-current="page"
            href="#"
            onClick={() => setActiveTab("active")}
            style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
            }}
            >
            Close Account
            </a>
            
        </li>
        </ul>
    </div>

    <hr></hr>

    <div>
        <nav>
          <div className="nav nav-tabs nav-fill " id="nav-tab" role="tablist">
            <a
            className="nav-item nav-link active"
            id="nav-customerInfo-tab"
            data-toggle="tab"
            href="#customer-info"
            role="tab"
            aria-controls="customer-info"
            aria-selected="true"
            >
            Close Account: {accountDB?.Account}
            </a>
            <a
              className="nav-item nav-link"
              id="nav-details-tab"
              data-toggle="tab"
              href="#FTAccClose"
              role="tab"
              aria-controls="nav-details"
              aria-selected="false"
            >
              FT Acc Close
            </a>
        </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
            <div
                className="tab-pane fade show active"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
                {/* Fieldset 1 */}
                <FieldsetComponent label="">
                    {/* row 1 */}
                    <RowComponent>
                      <InputField
                          label="Close Online:"
                          type="select"
                          id="sltCloseOnline"
                          isDisabled={true}
                          list={TempData.BooleanData}
                          // state={accountDB?.Customer?.RefID}

                      />
                      <div className="col-1"></div>
                      <InputField
                          label="Currency:"
                          type="text"
                          id="txtCurrency_1"
                          isDisabled={true}
                          value={accountDB?.CURRENCY?.Name}
                      />
                    </RowComponent>
                     {/* row 2 */}
                     <RowComponent>
                      <InputField
                            label="Working Amount:"
                            type="text"
                            id="txtWorkingAmount_1"
                            isDisabled={true}
                            value={currencyFormat(accountDB?.WorkingAmount, accountDB?.CURRENCY?.Name)}
                        />
                        <div className="col-1"></div>
                        <InputField
                          label="Standing Orders:"
                          type="text"
                          id="txtStandingOrders"
                          isDisabled={true}     
                          value={"NO"}                
                        />
                    </RowComponent>
                    {/* row 2 */}
                    <RowComponent>
                      <InputField
                            label="UnclearedEntries: "
                            type="text"
                            id="txtUnclearedEntries"
                            isDisabled={true}
                            value={"NO"}
                        />
                        <div className="col-1"></div>
                        <InputField
                          label="Cheques OS:"
                          type="text"
                          id="txtChequesOS"
                          isDisabled={true}     
                          value={"NO"}                
                        />
                    </RowComponent>
                    {/* row 3 */}
                    <RowComponent>
                      <InputField
                            label="Bank Cards:"
                            type="text"
                            id="txtBankCards"
                            isDisabled={true}
                            value={"NO"}
                        />
                        <div className="col-1"></div>
                        <InputField
                          label="CC Chgs OS: "
                          type="text"
                          id="txtCCChgsOS"
                          isDisabled={true}     
                          value={"0"}                
                        />
                    </RowComponent>
                    {/* row 4 */}
                    <RowComponent>
                      <InputField
                            label="Total Credit Interest:"
                            type="number"
                            id="txtTotalCreditInterest"
                            isDisabled={true}
                            value={"0.00"}
                      />
                        <div className="col-1"></div>
                        <InputField
                            label="Total Debit Interest:"
                            type="number"
                            id="txtTotalDebitInterest"
                            isDisabled={true}
                            value={"0.00"}
                      />
                    </RowComponent>
                    {/* row 5 */}
                    <RowComponent>
                      <InputField
                            label="Total Charges:"
                            type="number"
                            id="txtTotalCharges"
                            isDisabled={true}
                            value={"0.00"}
                      />
                        <div className="col-1"></div>
                        <InputField
                            label="Total VAT:"
                            type="number"
                            id="txtTotalVAT"
                            isDisabled={true}
                            value={"0.00"}
                      />
                    </RowComponent>
                    {/* row 6 */}
                    <RowComponent>
                      <InputField
                            label="Int.CAP to AC:"
                            type="number"
                            id="txtIntCAPtoAC"
                            isDisabled={true}
                            value={"0.00"}
                      />
                        <div className="col-1"></div>
                        <InputField
                            label="Trans Ref Next:"
                            type="text"
                            id="txtTransRefNext"
                            isDisabled={true}
                            value={""}
                      />
                    </RowComponent>
                </FieldsetComponent>  
            </div>


            {/* Details */}
          <div
            className="tab-pane fade"
            id="FTAccClose"
            role="tabpanel"
            aria-labelledby="nav-details-tab"
          >
              {/* Fieldset 1 */}
            <FieldsetComponent label="Debit Information">
              {/* row 1 */}
              <RowComponent>
                <InputField
                    label="Customer:"
                    type="text"
                    id="txtCustomer_2"
                    value={accountDB?.Customer?.RefID}
                    isDisabled={true}
                />
                <div className="col-1"></div>
                <InputField
                    label="Currency:"
                    type="text"
                    id="txtCurrency_2"
                    value={accountDB?.CURRENCY?.Name}
                    isDisabled={true}
                />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                    label="Customer Name:"
                    type="text"
                    id="txtCustomerName"
                    value={accountDB?.Customer?.GB_FullName}
                    isDisabled={true}
                />
                <div className="col-1"></div>
                <InputField
                    label="Close Account:"
                    type="text"
                    id="txtCloseAccount"
                    value={accountDB?.Account}
                    isDisabled={true}
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                    label="Debit Amount:"
                    type="text"
                    id="txtDebitAmount"
                    value={currencyFormat(accountDB?.WorkingAmount, accountDB?.CURRENCY?.Name) || currencyFormat(accountDB?.BlockedAmount, accountDB?.CURRENCY?.Name)}
                    isDisabled={true}
                />
                <div className="col-1"></div>
                <InputField
                    label="Debit Date:"
                    type="date"
                    id="dtDebitDate"
                    value={getToday()}
                    isDisabled={true}
                />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                    label="Payment Type:"
                    type="text"
                    id="sltPaymentType"
                    list={PaymentTypeCloseAcc}
                    isDisabled={true}
                    value={closedAccountDB?.PaymentType}
                    required ={true}
                />
                <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>


            <FieldsetComponent label="Credit Information">
              {/* row 1 */}
              <RowComponent>
                <InputField
                    label="Credit Currency:"
                    type="text"
                    id="sltCreditCurrency"
                    isDisabled={true}
                    value={getNamefromID(Currency_1, closedAccountDB?.CreditCurrency)}
                />
                <div className="col-1"></div>
                <InputField
                    label="Account Paid:"
                    type="text"
                    id="sltAccountPaid"
                    isDisabled={true}
                    value={closedAccountDB?.Transferred?.Account || getNamefromID(CloseSystemCurrency,closedAccountDB?.CreditCurrency )}
                />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField
                    label="Credit Amount:"
                    type="text"
                    id="txtCreditAmount"
                    isDisabled={true}
                    value={currencyFormat(closedAccountDB?.RemainingAmount, accountDB?.CURRENCY?.Name)}
                />
                <div className="col-1"></div>
                <InputField
                    label="Narrative:"
                    type="text"
                    id="txtNarrative"
                    isDisabled={true}
                    value={closedAccountDB?.Notes}
                />
              </RowComponent>
            
            </FieldsetComponent>


            <FieldsetComponent label="Audit Information">
              {/* row 1 */}
              <RowComponent>
                <InputField
                    label="Override:"
                    type="text"
                    id="txtOverride"
                    isDisabled={true}
                />
                <div className="col-1"></div>
                <InputField
                    label="Record Status:"
                    type="text"
                    id="txtRecordStatus"
                    isDisabled={true}
                    value={"IHLD   INPUT Held"}
                />
              </RowComponent>

              {/* row 2 */}
              <RowComponent>
                <InputField
                    label="Current Number:"
                    type="text"
                    id="txtCurrentNumber"
                    isDisabled={true}
                    value={"1"}
                />
                <div className="col-1"></div>
                <InputField
                    label="Inputter:"
                    type="text"
                    id="txtInputter_1"
                    isDisabled={true}
                    value={"112_ID2054_I_INAU"}
                />
              </RowComponent>

              {/* row 3 */}
              <RowComponent>
                <InputField
                    label="Inputter:"
                    type="text"
                    id="txtInputter_2"
                    isDisabled={true}
                    value={"1"}
                />
                <div className="col-1"></div>
                <InputField
                    label="Inputter:"
                    type="text"
                    id="txtInputter"
                    isDisabled={true}
                    value={"112_SYSTEM"}
                />
              </RowComponent>

              
              {/* row 3 */}
              <RowComponent>
                <InputField
                    label="Authorised:"
                    type="text"
                    id="txtAuthorised"
                    isDisabled={true}
                    value={""}
                />
                <div className="col-1"></div>
                <InputField
                    label="Date Time:"
                    type="text"
                    id="txtDateTime"
                    isDisabled={true}
                    value={getCurrentTime()}
                />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                    label="Company Code:"
                    type="text"
                    id="txtCompanyCode"
                    isDisabled={true}
                    value={"VN-001-1221	CHI NHANH CHO LON"}
                />
                <div className="col-1"></div>
                <InputField
                    label="Department Code:"
                    type="text"
                    id="txtDepartmentCode"
                    isDisabled={true}
                    value={"1"}
                />
              </RowComponent>
            </FieldsetComponent>
        </div>
    </div>

        {/* --------------------------------------- */}
  {/* <Backend Error Model /> */}
  <div>
    <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <b>{errorMessage}</b>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseBEErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* <ErrorModal /> */}
  <div>
    <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* Success Modal  */}
  <div>
    <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <b> Account {accountDB?.Account}</b> was closed successfully! <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>


</div>

</div>
</div>
)
}


let arrRequired = []
function checkRequired() {
  arrRequired = []
  let arr = [
    {type: "select",id: "sltPaymentType",name: 'Payment Type'},
  ]
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  if (arrRequired.length > 0) return true; else return false;
} 

function checkTransferAccount(customerAccount){
  let paymentType = document.getElementById('sltPaymentType')?.value || null
  let transferAccount =  document.getElementById('sltAccountPaid')?.value
  let returnVal
  const obj = customerAccount.find(obj => obj.name === transferAccount);
  if(paymentType == 'Cash' || paymentType == 'Intermediary Account'){
    console.log("cash Intermediary Account")
    returnVal = null
  }else if(paymentType == 'Account'){
    returnVal = obj.customerAccount
    console.log(returnVal)
  }
  return returnVal
}


function currencyFormat(value, currency){
  return value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
}

function getNamefromID(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}


function getToday(){
  let today = new Date()
  console.log(today)
  let day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
  let month = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth()
  return today.getFullYear() + '-' + month + '-' + day
}

function getCurrentTime(){
  let today = new Date()
  console.log(today)
  return today.getDate() + '-' + (today.getMonth()+1) + '-' + today.getFullYear() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() 
}