const ProductLine = [
    { "id": 1, "name": 'Tiền gửi thanh toán cá nhân', "category": 1 },
    { "id": 2, "name": 'Tiền gửi thanh toán doanh nghiệp', "category": 1 },
    { "id": 3, "name": 'Tiền gửi thanh toán bậc thang cá nhân', "category": 1 },
    { "id": 4, "name": 'Tiền gửi thanh toán bậc thang doanh nghiệp', "category": 1 },
    { "id": 5, "name": 'Tiền gửi tuần năng động', "category": 1 },
    { "id": 6, "name": 'Tiền gửi sinh viên', "category": 1 },
    { "id": 7, "name": 'Tài khoản chi lãi tiết kiệm', "category": 1 },
    { "id": 8, "name": 'Tiết kiệm không kỳ hạn', "category": 2 },
    { "id": 9, "name": 'Tiết kiệm tích lũy', "category": 2 },
    { "id": 10, "name": 'Tiết kiệm không kỳ hạn bậc thang', "category": 2 },
    { "id": 11, "name": 'Tiết kiệm không kỳ hạn dự thường', "category": 2 },
    { "id": 12, "name": "30001 - Tiết kiệm có kỳ hạn", "category": 3},
    { "id": 13, "name": "30002 - Tiết kiệm có kỳ hạn tích lũy", "category": 3},
    { "id": 14, "name": "30003 - Tiết kiệm có kỳ hạn bậc thang", "category": 3},
    { "id": 15, "name": "30004 - Tiết kiệm có kỳ hạn dự thưởng", "category": 3},
    { "id": 16, "name": "30005 - Tiết kiệm có kỳ hạn bội thu", "category": 3},
    { "id": 17, "name": "30006 - Tiết kiệm có kỳ hạn bảo hiểm", "category": 3},
    { "id": 18, "name": "30007 - Tiết kiệm có kỳ hạn tích lũy bảo hiểm", "category": 3},
    { "id": 19, "name": "30008 - Tiết kiệm có kỳ hạn sinh hoạt", "category": 3},
    { "id": 20, "name": "30009 - Tiết gửi có kỳ hạn", "category": 4},
    { "id": 21, "name": "50001 - Tiết kiệm lãi trả trước", "category": 5},
  ];

  export default ProductLine;