const Industry = [
    {
        "id": 0,
        "name": '',
        "code": null
    },
    {
      "id": 1,
      "name": 'Nông nghiệp',
      "code": null
    },
    {
      "id": 2,
      "name": 'Lâm nghiệp',
      "code": null
    },
    {
      "id": 3,
      "name": 'Thủy sản',
      "code": null
    },
    {
      "id": 4,
      "name": 'Công nghiệp khai thác',
      "code": null
    },
    {
      "id": 5,
      "name": 'Công nghiệp chế biến',
      "code": null
    },
    {
      "id": 6,
      "name": 'Thực phẩm',
      "code": null
    },
    {
      "id": 7,
      "name": 'Dệt, đan len...',
      "code": null
    },
    {
      "id": 8,
      "name": 'May mặc',
      "code": null
    },
    {
      "id": 9,
      "name": 'Gỗ, tre, nứa,...',
      "code": null
    },
    {
      "id": 10,
      "name": 'Giấy',
      "code": null
    },
    {
      "id": 11,
      "name": 'Hóa chất và vật liệu xây dựng',
      "code": null
    },
    {
      "id": 12,
      "name": 'Sản xuất kim loại',
      "code": null
    },
    {
      "id": 13,
      "name": 'Thiết bị điện',
      "code": null
    },
    {
      "id": 14,
      "name": 'Xe cộ động cơ',
      "code": null
    },
    {
      "id": 15,
      "name": 'Sản xuất và cung cấp năng lượng',
      "code": null
    },
    {
      "id": 16,
      "name": 'Điện năng, khí đốt, hơi nước',
      "code": null
    },
    {
      "id": 17,
      "name": 'Nước',
      "code": null
    },
    {
      "id": 18,
      "name": 'Xây dựng',
      "code": null
    },
    {
      "id": 19,
      "name": 'Thương nghiệp',
      "code": null
    },
    {
      "id": 20,
      "name": 'Sửa chữa xe, đồ dùng',
      "code": null
    },
    {
      "id": 21,
      "name": 'Nhà hàng - khách sạn',
      "code": null
    },
    {
      "id": 22,
      "name": 'Tài chính tín dụng',
      "code": null
    },
    {
      "id": 23,
      "name": 'Hoạt động tài chính',
      "code": null
    },
    {
      "id": 24,
      "name": 'Bất động sản',
      "code": null
    },
    {
      "id": 25,
      "name": 'Cho thuê tài sản',
      "code": null
    },
    { "id": 26, "name": 'Giáo dục - đào tạo', "code" : null },
    { "id": 27, "name": 'Y tế, cứu trợ xã hội', "code" : null },
    { "id": 28, "name": 'Văn hóa, thể thao', "code" : null },
    { "id": 29, "name": 'Dịch vụ vệ sinh môi trường', "code" : null },
    { "id": 30, "name": 'Hoạt động khác', "code" : null },
    { "id": 31, "name": 'Ngành khác', "code" : null },
]

  export default Industry;