import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import Currency_1 from '../../../../data/Currency_1';
import TempData from '../../../../data/TempData';

export default function Enquiry_CI(props) {
  const [rowState, setRowState] = useState([]);
  useEffect(() => {
    setRowState(props.rowState)
  }, [props.rowState])
  let rowArray =[]
  console.log("props.rowState")
  console.log(rowState)
  console.log(typeof(rowState))
  return (
    <div>
      <hr></hr>

      {/* Fieldset 1 */}
      <FieldsetComponent label="">
         {/* row 1 */}
         <RowComponent>
          <InputField label="Transaction Type:" type="select" id="sltTransactionType" list={TempData.TransactionType}
          defaultValue={"Cash Deposit"} required={true}/>
          <div className="col-1"></div>
          <InputField label="Account Type:" type="select" id="sltAccountType" list={AccountType}
          defaultValue={"Current & Non-Term Saving Account"} required={true}/>

        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField label="Ref ID:" type="text" id="txtRefID" />
          <div className="col-1"></div>
          <InputField label="Currency:" type="select" id="sltCurrency" list={Currency_1}/>
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField label="Customer Type:" type="select" id="sltCustomerType" list={TypeCustomer}/>
          <div className="col-1"></div>
          <InputField label="Customer ID:" type="text" id="txtCustomerID" list={TypeCustomer}/>
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField label="GB Full Name:" type="text" id="txtGBFullName"/>
          <div className="col-1"></div>
          <InputField label="Customer Account:" type="text" id="txtCustomerAccount"/>
        </RowComponent>
        {/* row 5 */}
        <RowComponent>
          <InputField label="Amount From:" type="text" id="txtAmountFrom"/>
          <div className="col-1"></div>
          <InputField label="To:" type="text" id="txtAmountTo"/>
        </RowComponent>
        {/* row 6 */}
        <RowComponent>
          <InputField label="Date:" type="date" id="dtDate"/>
          <div className="col-1"></div>
        </RowComponent>
      </FieldsetComponent>

      <FieldsetComponent>
        <DataTable columnsTemp={columns} rowsTemp={rowState}/>
      </FieldsetComponent>
    </div>
  );
} 


const TypeCustomer = [{id:"1",name:"P - Person"},{id:"2",name:"C - Corporate"}]


const columns = [
    {
      label: 'Account Type',
      field: 'AccountType',
      sort: 'asc',
      width: 220
    },
    {
      label: 'Ref ID',
      field: 'RefID',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Account Code',
      field: 'AccountCode',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Customer Name',
      field: 'CustomerName',
      sort: 'asc',
      width: 250
    },
    {
      label: 'Currency',
      field: 'Currency',
      sort: 'asc',
      width: 70
    },
    {
        label: 'Transaction Amount',
        field: 'TransactionAmount',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Status',
        field: 'Status',
        sort: 'asc',
        width: 100
    },
    {
      label: 'View/Edit',
      field: 'Button',
      sort: 'asc',
      width: 150,
      // cell: 
        
    },
  ]
  const rows =  [
    {
      name: 'Tiger Nixon',
      position: 'System Architect',
      office: 'Edinburgh',
      age: '61',
      date: '2011/04/25',
      salary: '$320'
    },
    
  ]
  
  
  function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
  
  
  function getNameFromID(list, idTemp) {
    const obj = list.find(obj => obj.id === idTemp);
    return obj ? obj.name : null;
  }