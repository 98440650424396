const CountryList =[
    {"id": 0, "name": ""},
    { "id": 1, "code": "AU", "name": "AU - Australia" },
    { "id": 2, "code": "BE", "name": "BE - Belgium" },
    { "id": 3, "code": "BR", "name": "BR - Brazil" },
    { "id": 4, "code": "CA", "name": "CA - Canada" },
    { "id": 5, "code": "CH", "name": "CH - Switzerland" },
    { "id": 6, "code": "CN", "name": "CN - China" },
    { "id": 7, "code": "EN", "name": "EN - England" },
    { "id": 8, "code": "EU", "name": "EU - European" },
    { "id": 9, "code": "FR", "name": "FR - France" },
    { "id": 10, "code": "GE", "name": "GE - Germany" },
    { "id": 11, "code": "HK", "name": "HK - Hong Kong" },
    { "id": 12, "code": "IT", "name": "IT - Italy" },
    { "id": 13, "code": "JP", "name": "JP - Japan" },
    { "id": 14, "code": "KR", "name": "KR - Korea" },
    { "id": 15, "code": "LU", "name": "LU - Luxembourg" },
    { "id": 16, "code": "MM", "name": "MM - Myanmar" },
    { "id": 17, "code": "NZ", "name": "NZ - New Zealand" },
    { "id": 18, "code": "QA", "name": "QA - Qatar" },
    { "id": 19, "code": "RU", "name": "RU - Russian Federation" },
    { "id": 20, "code": "SG", "name": "SG - Singapore" },
    { "id": 21, "code": "TH", "name": "TH - ThaiLand" },
    { "id": 22, "code": "UA", "name": "UA - Ukraine" },
    { "id": 23, "code": "US", "name": "US - United States of America" },
    { "id": 24, "code": "VN", "name": "VN - Vietnam" },
    { "id": 25, "code": "ZA", "name": "ZA - South Africa" }
    ]


export default CountryList