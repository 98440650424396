const AccountOfficers = [
    { "id": 0, "name": '' },
    { "id": 1, "name": 'Phạm Vũ Thanh Hà' },
    { "id": 2, "name": 'Nguyễn Thị Thúy Kiều' },
    { "id": 3, "name": 'Thái Thị Xuân Diễm' },
    { "id": 4, "name": 'Nguyễn Thúy An' },
    { "id": 5, "name": 'Nguyễn Thị Hoài Thương' },
    { "id": 6, "name": 'Nguyễn Phương Thảo' },
    { "id": 7, "name": 'Phạm Thị Mỹ Oanh' }
  ];

  export default AccountOfficers;