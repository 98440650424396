import React, { useState } from "react";
import Enquiry_CI from "./Enquiry_CI";
import IssueCheque_CI from "./IssueCheque_CI";



export default function ChequeIssuance() {
  const [activeTab, setActiveTab] = useState("active");

  return (
    <div>
      <div
        className="row align-items-center text-center mb-3"
        style={{
          height: "50px",
          backgroundColor: "#eceff4",
          margin: "3px",
        }}
      >
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-save-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
          </svg>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          Authorize
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          Search
        </div>
        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg>
          Print Deal Slip
        </div>

        <div
          className="col-2 buttonAction"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Issue Cheque
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "link1" ? "active" : ""}`}
              href="#"
              onClick={() => setActiveTab("link1")}
              style={{
                backgroundColor: activeTab === "link1" ? "#d71921" : "",
              }}
            >
              Enquiry
            </a>
          </li>
        </ul>
      </div>

      <form>
        {activeTab === "active" && (
          <div>
              <IssueCheque_CI id="open"/>
          </div>
        )}
        {activeTab === "link1" && (
          <div>
              <Enquiry_CI id="open"/>
          </div>
        )}

      </form>
    </div>
  );
}
