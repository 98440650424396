import React, { useState ,useEffect} from 'react'
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import CloseSystemCurrency from '../../../../data/CloseSystemCurrency';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import SystemCurrency from '../../../../data/SystemAccount';
import TempData from '../../../../data/TempData';
export default function NonFrequency(props) {
    //STATE HANDLE
    const [currencyState, setCurrencyState] = useState(0);
    const [amountState, setAmountState] = useState(0);
    const [dealRateState, setDealRateState] = useState(0);
    const [activeTab, setActiveTab] = useState("active");
   
  return (
    <div>

      <hr></hr>

      <div>
        <nav>
          <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Salary Non-Frequency
            </a>
          </div>
        </nav>


        <div className="tab-content" id="nav-tabContent">
            <div
                className="tab-pane fade show active"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
             {/* Fieldset 1 */}
              <FieldsetComponent label="Account Information">
                {/* row 1 */}
                <RowComponent>
                      <InputField
                          label="Account Type:"
                          type="text"
                          id={`sltAccountType_`+props.id}
                        
                      />
                      <div className="col-1"></div>
                      <InputField
                          label="Customer Account:"
                          type="text"
                          // id="txtCustomerAccount"
                          id={`txtCustomerAccount_`+props.id}
                          
                      />
                </RowComponent>
              </FieldsetComponent>
            </div>
        </div>
    </div>
    </div>
  )
}

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}