
const Currency_1 = [
    { "id": 0, "name": ''},
    { "id": 1, "name": 'EUR'},
    { "id": 2, "name": 'USD'},
    { "id": 3, "name": 'GBP'},
    { "id": 4, "name": 'JPY'},
    { "id": 5, "name": 'VND'},
  ];

  export default Currency_1;