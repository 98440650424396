import axios from 'axios';

const savingAccountApi = {
  openArrear: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/open_arrear`, params, { mode: 'no-cors' });
  },

 openPeriodic:  (params) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/open_periodic`, params, { mode: 'no-cors' });
},

  enquiryArrear: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/enquiry_arrear`, params, { mode: 'no-cors' });
  },

  enquiryPeriodic: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/enquiry_periodic`, params, { mode: 'no-cors' });
  },
  enquiryDiscounted: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/enquiry_discounted`, params, { mode: 'no-cors' });
  },

  openDiscounted: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/open_discounted`, params, { mode: 'no-cors' });
  },

  getAccount: (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/saving_account/get/` + id);
  },
  closeArrearPeriodic: (id, params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/close/` + id, params, { mode: 'no-cors' });
  },
  closeDiscounted: (id, params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/saving_account/close_discounted/` + id, params, { mode: 'no-cors' });
  },

  validate: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/saving_account/validate/` + id, params, { mode: 'no-cors' });
  },

  update: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/saving_account/update/` + id, params, { mode: 'no-cors' });
  },
  getAll: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/saving_account/get_all`, { mode: 'no-cors' });
  },
  getClosure: (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/saving_account/get_closure/` + id, { mode: 'no-cors' });
    
  },
  validateClose: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/saving_account/validate_closure/` + id, params, { mode: 'no-cors' });
  },

  ValidateCloseDiscounted: (id, params) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/account/saving_account/validate_closurediscounted/` + id, params, { mode: 'no-cors' });
      },
  
  
};

export default savingAccountApi;