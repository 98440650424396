import { getImageListItemBarUtilityClass } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Button, Modal, Overlay, Popover } from 'react-bootstrap';
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from '../../../../apis/mainAccountApi';
import savingAccountApi from '../../../../apis/savingAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountOfficers from '../../../../data/AccountOfficer';
import Category from '../../../../data/Category';
import ChargeCode from '../../../../data/ChargeCode';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import Currency_2 from '../../../../data/Currency_2';
import ProductLine from '../../../../data/ProductLine';
import RelationCode from '../../../../data/RelationCode';
import SavingTerm from '../../../../data/SavingTerm';
import TempData from '../../../../data/TempData';


const tempRate = 2.0444444

export default function Open_DiscountedSA_Modal(props) {
// 1. State check required
const [arrRe, setArrRe] = useState([])
const handleSetArrRe = (item) => {setArrRe(item);};
// 2. State show Error
const [showErrorModal, setShowErrorModal] = useState(false);
const [errorMessage, setErrorMessage] = useState("error");
const handleCloseErrorModal = () => setShowErrorModal(false);
const handleShowErrorModal = () => setShowErrorModal(true);
// 3. State show Success
const [showSuccessModal, setShowSuccessModal] = useState(false);
const handleCloseSuccessModal = () => setShowSuccessModal(false);
const handleShowSuccessModal = () => setShowSuccessModal(true);
  // 5. State show Backend Error
  const [showBEError, setShowBEError] = useState(false);
  const handleCloseBEErrorModal = () => setShowBEError(false);
  const handleShowBEErrorModal = () => setShowBEError(true);


// State response
const [OKMessage, setOKMessage] = useState(""); 

const [activeTab, setActiveTab] = useState("active");


  // 4. Select State
  const [existedAccountDB, setExistedAccountDB] = useState({})
  const [getCurrency, setCurrency] = useState(0);
const [getCategory, setCategory] = useState(0);
const [customerList, getCustomerList] = useState([])
const [workingAccountList, setWorkingAccountList] = useState([])
const [formatedAccounts, setFormatedAccounts] = useState([])
const [selectedAccount, setSelectedAccount] = useState(0)
const [debitAccountList, setDebitAccountList] = useState([])
const [selectedAccountObject, setSelectedAccountObject] = useState({})
const [LCYDisabled, setLCYDisables] = useState(true)
const [amtLCYState, setAmtLCYState] = useState(0)
const [amtFCYState, setAmtFCYState] = useState(0)
const [dealRateState, setDealRateState] = useState(0)
const [selectedTerm, setSelectedTerm] = useState([])
const [getPrincipal, setPrincipal] = useState(0)
const [selectedDate, setSelectedDate] = useState(new Date())


  //HANDLE BUTTON
  const [commitBtn, setCommitBtn] = useState(false)
  const [authorBtn, setAuthoBtn] = useState(false)
  const [editBtn, setEditBtn] = useState(true)
  const [searchBtn, setSearchBtn] = useState(false)
  const [printBtn, setPrintBtn] = useState(false)


// Loading
const [isLoading, setIsLoading] = useState(true);
const [isLoadingList, setIsLoadingList] = useState(true);

useEffect(() => {
  clearDependentValue("sltProductLine")

}, [getCategory]);



// GET ACCOUNT FROM DB
useEffect(() => {
  async function fetchAccount() {
      try {
      const response = await savingAccountApi.getAccount(props.modalInstanceID)
      let tempAccount = response.data.data
      setExistedAccountDB(tempAccount)
      console.log('fetch')
      console.log(tempAccount)
      // // Set INI State
      setCurrency(getNameFromId(Currency_1, tempAccount.detailAccount?.Currency) || "")
      setSelectedAccount(tempAccount.detailAccount?.WorkingAccountRefID)
      setPrincipal(tempAccount?.detailAccount?.PrincipalAmount)
      setCategory(getNameFromId(Category, tempAccount.detailAccount?.Category) || 0)
      setLCYDisables(getNameFromId(Currency_1, tempAccount.detailAccount?.Currency) === "VND" ? false : true)
      setAmtFCYState(tempAccount.detailAccount?.AmountFCY)
      setAmtLCYState(tempAccount.detailAccount?.AmountLCY)
      setInterestRateState(tempAccount.detailAccount?.InterestRate)
      setSelectedTerm(getNameFromId(SavingTerm, tempAccount.detailAccount?.Term))
      setSelectedDate(tempAccount.detailAccount?.ValueDate)
      // // Authourize State
      let iniStatus = tempAccount.savingAccount?.Status
      if(iniStatus === 1  && localStorage.getItem('whoami') === 'qtv'){
          setAuthoBtn(true)
      }else 
          setAuthoBtn(false)
      setIsLoading(false);
      } catch (error) {

      }
  }
  fetchAccount();
}, [props.modalInstanceID]);


// GET CUSTOMER LIST

useEffect(() => {
  async function fetchCustomerList() {
    try {
      const response = await cusApi.getAll()
      const customerListDB = response.data.data.customer
      getCustomerList(customerListDB)
    } catch (error) {

    }
  }

  fetchCustomerList();
}, []);
let customerFormatList = [{"id": 0, "name": ""}]
if(customerList.length != 0){
  customerList.map((value, index) => {
    let temp = {"id": value.customer?.id, "name": value.customer?.RefID + " - " + value.customer?.GB_FullName}
    customerFormatList.push(temp)
  })
}

console.log(customerFormatList)
// GET WORKING ACCOUNT LIST

useEffect(() => {
  async function fetchWorkingAccount() {
    try {
      const response = await mainAccountApi.enquiry({})
      const accountListDB = response.data.data
      console.log("fetch account")
      console.log(accountListDB)
      setWorkingAccountList(accountListDB)

      let tempList = []
      accountListDB.map((value, index) => {
          console.log(value)
        
          let temp = {"id": value.id, "name": getNameFromId(Currency_1, value.Currency)  + " - " + value.Account + " - " + value.Customer?.GB_FullName}
          tempList.push(temp)
      })
      setFormatedAccounts(tempList)
      
      setIsLoadingList(false)
    } catch (error) {

    }
  }

  fetchWorkingAccount();
}, []);


// useEffect(() => {

//   let tempList = [{"id": 0, "name": ""}]
//   if(workingAccountList){
//     workingAccountList.map((value, index) => {
//         console.log(value)
      
//         let temp = {"id": value.id, "name": getNameFromId(Currency_1, value.Currency)  + " - " + value.Account + " - " + value.Customer?.GB_FullName}
//         tempList.push(temp)
//     })
//     setFormatedAccounts(tempList)
    
//     setIsLoadingList(false)
//   }
    
// }, [workingAccountList]);

console.log("formated Account")
console.log(formatedAccounts)
// SELECTED CUSTOMER

useEffect(()=>{
    // selected Account Obj 
    console.log("selected Account ")
    console.log(selectedAccount)
    let selectedAccObj = workingAccountList.find(obj => {
      return obj.id === getIdFromName(formatedAccounts, selectedAccount)
    })
    console.log('selectedAccObj')
    console.log(selectedAccObj)
    // hien thi thong tin account
    if( document.getElementById("txtCustomerName" + props.id) &&  document.getElementById("txtWorkingCurrency" + props.id) &&
    document.getElementById("txtCustomer_2" + props.id) && document.getElementById("txtCurrency_2" + props.id) &&
    document.getElementById("txtWorkingAccount_2" + props.id) &&  document.getElementById("txtCustomer_3" + props.id) && 
    document.getElementById("txtCurrency_3" + props.id) && document.getElementById("txtDrAccount_3" + props.id) &&
    document.getElementById("txtPaymentCCY_3" + props.id) ){
      document.getElementById("txtCustomerName" + props.id ).value = selectedAccObj?.Customer?.GB_FullName || ""
      document.getElementById("txtWorkingCurrency" + props.id ).value= getNameFromId(Currency_1,selectedAccObj?.Currency ) 
      document.getElementById("txtCustomer_2" + props.id).value= selectedAccObj?.Customer?.GB_FullName || ""
      document.getElementById("txtCurrency_2" + props.id).value= getNameFromId(Currency_1,selectedAccObj?.Currency ) 
      // document.getElementById("txtCustomer_2").value= selectedAccObj?.Customer?.GB_FullName || ""
      document.getElementById("txtWorkingAccount_2" + props.id).value= selectedAccount
      document.getElementById("txtCustomer_3" + props.id).value=  selectedAccObj?.Customer?.GB_FullName || ""
      document.getElementById("txtCurrency_3" + props.id).value= getNameFromId(Currency_1,selectedAccObj?.Currency ) 
      document.getElementById("txtDrAccount_3" + props.id).value= selectedAccount
      document.getElementById("txtPaymentCCY_3" + props.id).value= getNameFromId(Currency_1,selectedAccObj?.Currency ) 
    }
   
    setSelectedAccountObject(selectedAccObj)
    
    // check currency
    if(getNameFromId(Currency_1,selectedAccObj?.Currency ) === "VND"){
        setAmtFCYState(0)
        if(document.getElementById("txtAmountFCY" + props.id))
          document.getElementById("txtAmountFCY" + props.id).value = 0
        setAmtLCYState(0)
        // if(document.getElementById("txtAmountLCY" + props.id))
        //   document.getElementById("txtAmountLCY" + props.id).value = 0
        setLCYDisables(false)
    }else{
        setAmtFCYState(0)
        // if(document.getElementById("txtAmountFCY" + props.id))
        //   document.getElementById("txtAmountFCY" + props.id).value = 0
        setAmtLCYState(0)
        if(document.getElementById("txtAmountLCY" + props.id))
          document.getElementById("txtAmountLCY" + props.id).value = 0
        setLCYDisables(true)
    }
    // create debit account

    let tempList = [{"id": 0, "name": ""}]
      workingAccountList.map((value, index) => {
        if(value.CustomerID == selectedAccObj?.CustomerID && value.Currency == selectedAccObj.Currency){
          let temp = {"id": value.id, "name": value.Account}
          tempList.push(temp)
        }
      })
      setDebitAccountList(tempList)

  }, [selectedAccount, formatedAccounts])

  console.log('selectedAccountObject')
console.log(selectedAccountObject)

// AMOUNT

useEffect(()=>{
  console.log("change")
  if(document.getElementById("txtAmtCredit" + props.id ) && document.getElementById("txtAmount_2" + props.id ) && document.getElementById("txtNewCustBal" + props.id )){
    if(LCYDisabled && selectedAccountObject) {
      document.getElementById("txtAmtCredit" + props.id ).value = currencyFormat(parseFloat(amtFCYState * dealRateState), "VND")
      document.getElementById("txtAmount_2" + props.id ).value = amtFCYState
      document.getElementById("txtNewCustBal" + props.id ).value = currencyFormat(parseFloat(amtFCYState * dealRateState) + selectedAccountObject.WorkingAmount, "VND")
      console.log(currencyFormat(parseFloat(amtFCYState) + selectedAccountObject.WorkingAmount, "VND"))
    }else if(!LCYDisabled && selectedAccountObject){
      document.getElementById("txtAmtCredit" + props.id ).value = currencyFormat(parseFloat(amtLCYState), "VND")
      document.getElementById("txtAmount_2" + props.id ).value = amtLCYState
      document.getElementById("txtNewCustBal" + props.id ).value = currencyFormat(parseFloat(amtLCYState) + selectedAccountObject.WorkingAmount, "VND")
      console.log(currencyFormat(parseFloat(amtLCYState) + selectedAccountObject.WorkingAmount, "VND"))
    }
  }
  
    
}, [amtLCYState, amtFCYState,dealRateState])

// TERM


useEffect(() => {
  let tempTerm = SavingTerm.find(obj => obj.name === selectedTerm)
  const tempDate = addMonths(tempTerm?.value, selectedDate)
  let tempMonth = tempDate.getMonth() < 10 ? "0" + tempDate.getMonth() : tempDate.getMonth()
  let tempDay = tempDate.getDate() < 10 ? "0" + tempDate.getDate() : tempDate.getDate()
  let dateString = tempDate.getFullYear() + "-" + tempMonth + "-" + tempDay
  if(document.getElementById("dtFinalMatDate_2" + props.id )){
    document.getElementById("dtFinalMatDate_2" + props.id ).value = dateString
  }
  
}, [selectedTerm, selectedDate]);


// INTEREST RATE

const [interestRateState, setInterestRateState] = useState(0)
useEffect(() => {
  let temp = SavingTerm.find(obj => obj.name === selectedTerm)
  let tempMonth = temp?.value || 0
  let rate = Math.round(interestRateState * tempRate * 1000) / 1000 || ""
  let tempLCY = amtLCYState !== 0 ? amtLCYState : existedAccountDB.detailAccount?.AmountLCY
  let tempFCY = amtFCYState !== 0 ? amtFCYState : existedAccountDB.detailAccount?.AmountFCY
  console.log('amtLCYState')
  console.log(amtLCYState)
  if(LCYDisabled && document.getElementById("txtAmountFCY_3" + props.id) && document.getElementById("txtNarrative_3" + props.id)  ){
       document.getElementById("txtAmountFCY_3" + props.id).value = rate
       document.getElementById("txtNarrative_3" + props.id).value= "ID: [LD/23060/34323]-P: [" + tempFCY + "]-R: [" + rate +"]-T: [" +tempMonth + "M]"
  }else if(!LCYDisabled && document.getElementById("txtAmountLCY_3" + props.id) && document.getElementById("txtNarrative_3" + props.id)){
    document.getElementById("txtAmountLCY_3" + props.id).value = rate
    document.getElementById("txtNarrative_3" + props.id).value= "ID: [LD/23060/34323]-P: [" + tempLCY + "]-R: [" + rate +"]-T: [" + tempMonth  + "M]"
  } else{
  }
  if(document.getElementById("txtNewCustBal_3" + props.id) && document.getElementById("txtAmtPaid_3" + props.id)){
    document.getElementById("txtNewCustBal_3" + props.id).value = "-" + rate
    document.getElementById("txtAmtPaid_3" + props.id).value = rate
  }
  

}, [interestRateState, amtLCYState, amtFCYState, selectedTerm])

if(!isLoading && !isLoadingList)
return (
<div>
  <div
  className="row align-items-center text-center mb-3"
  style={{
    height: "50px",
    backgroundColor: "#eceff4",
    margin: "3px",
    position: 'sticky',
    top: '0',
    zIndex: '10',
    marginTop: '0',
    marginLeft: '0',
    marginRight: '0'
  }}
  >
    <div
       className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
       style={ commitBtn && {
        borderRight: "solid 1px rgba(0,0,0,.2)",
        fontWeight: "",
        color: "rgba(107,107,107, 1)",
        height: "100%",
        lineHeight: "50px",
    } || {borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",}}
      onClick={ async (event)=> {
        checkRequired(props.id);
        if (arrRequired.length > 0) {
          setShowErrorModal(true);
          setArrRe(arrRequired);
        } else {
          const params = {            
            customerID: selectedAccountObject?.CustomerID,
            valueDate: document.getElementById('dtValueDate_2' + props.id).value || null,
            workingAccount: getIdFromName(formatedAccounts, document.getElementById('sltWorkingAccount' + props.id)?.value) == 0 ? null : getIdFromName(formatedAccounts, document.getElementById('sltWorkingAccount' + props.id)?.value),
            amountLCY: document.getElementById('txtAmountLCY' + props.id).value || 0,
            amountFCY: document.getElementById('txtAmountFCY' + props.id).value || 0,
            narrativeInterest: document.getElementById('txtNarrative_3' + props.id).value || null,
            narrative: document.getElementById('txtNarrative' + props.id).value || null,
            teller: document.getElementById('txtForTeller_3' + props.id).value || null,
            ecxhRate: document.getElementById('txtExchRate_3' + props.id).value || null,
            paymentCurrency: getIdFromName(Currency_2, document.getElementById('sltPaymentCCY' + props.id)?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltPaymentCCY' + props.id)?.value),
            currency: getIdFromName(Currency_1, document.getElementById('txtCurrency_2' + props.id)?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById('txtCurrency_2' + props.id)?.value),
            debitAccount: getIdFromName(debitAccountList, document.getElementById('sltDebitAccount' + props.id)?.value) == 0 ? null : getIdFromName(debitAccountList, document.getElementById('sltDebitAccount' + props.id)?.value),
            creditAccount: getIdFromName(debitAccountList, document.getElementById('sltCreditAccount_3' + props.id)?.value) == 0 ? null : getIdFromName(debitAccountList, document.getElementById('sltCreditAccount_3' + props.id)?.value),
            joinHolder: getIdFromName(customerFormatList, document.getElementById('sltJoinHolder' + props.id)?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltJoinHolder' + props.id)?.value),
            productLine: getIdFromName(ProductLine, document.getElementById('sltProduct_2' + props.id)?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProduct_2' + props.id)?.value),
            term: getIdFromName(SavingTerm, document.getElementById('sltTerm_2' + props.id)?.value) == 0 ? null : getIdFromName(SavingTerm, document.getElementById('sltTerm_2' + props.id)?.value),
            accountOfficer: getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer_2' + props.id)?.value) == 0 ? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer_2' + props.id)?.value),
            dealRate: document.getElementById('txtDealRate' + props.id).value || 0,
            amountLCYInterest: document.getElementById('txtAmountLCY_3' + props.id).value || 0,
            amountFCYInterest: document.getElementById('txtAmountFCY_3' + props.id).value || 0,
            interestRate: document.getElementById('txtInterestRate' + props.id).value || 0,
            maturityDate: document.getElementById('dtFinalMatDate_2' + props.id).value || null,
            custBal: document.getElementById('txtNewCustBal_3' + props.id).value || null,
            amtPaid: document.getElementById('txtAmtPaid_3' + props.id).value || null,
            workingAccountRefID: document.getElementById('sltWorkingAccount' + props.id)?.value || null,
            customerName: document.getElementById('txtCustomerName' + props.id).value || "",
            amount: document.getElementById("txtNewCustBal" + props.id).value.replaceAll(".", "")  || null,
          };

        savingAccountApi.update(props.modalInstanceID, params)
          .then(response => {
            console.log('response')
            console.log(response)
            setOKMessage("Update Successfully")
            setShowSuccessModal(true);
          })
          .catch(error => {
            setErrorMessage(error.response.data.message)
            console.log(errorMessage)
            // errorMessage = error.Data
            handleShowBEErrorModal();
          });
          
          // recreateInput();
        }
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-save-fill"
        viewBox="0 0 16 16"
        style={commitBtn && {
          color: "rgba(107,107,107,.9)",
          marginRight: "8px",
          } || {
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
          }}
      >
        <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
      </svg>
      <div
        style={{
          display: "inline",
        }}
      >
        {" "}
        Commit Data{" "}
      </div>
    </div>
    <div
      className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
      style={ authorBtn && {
          borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 1)",
          height: "100%",
          lineHeight: "50px",
      } || {borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 0.2)",
              height: "100%",
              lineHeight: "50px",}}
      onClick={async () => {
        if(authorBtn){
            console.log("Authorize")
            let params = {
                status: getIdFromName(TempData.Status, document.getElementById("sltAuthoStatus" + props.id).value || 1)
            }
            await savingAccountApi.validate(props.modalInstanceID, params)
            .then(response => {
                setOKMessage("Authorize Successfully")
                setShowSuccessModal(true);
            })
            .catch(error => {
                setErrorMessage(error.response.data.message)
                console.log(errorMessage)
                // errorMessage = error.Data
                handleShowBEErrorModal();
            });
        }
    }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-check-circle-fill"
        viewBox="0 0 16 16"
        style={authorBtn && {
          color: "rgba(107,107,107,.9)",
          marginRight: "8px",
          } || {
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
          }}
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
      Authorize
    </div>
    <div
      className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
      style={ searchBtn && {
          borderRight: "solid 1px rgba(0,0,0,.2)",
          fontWeight: "",
          color: "rgba(107,107,107, 1)",
          height: "100%",
          lineHeight: "50px",
      } || {borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 0.2)",
              height: "100%",
              lineHeight: "50px",}}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-search"
        viewBox="0 0 16 16"
        style={searchBtn && {
          color: "rgba(107,107,107,.9)",
          marginRight: "8px",
          } || {
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
          }}
      >
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
      </svg>
      Search
    </div>
    <div
      className="col-2 buttonAction noCopy"
      style={{
        borderRight: "solid 1px rgba(0,0,0,.2)",
        fontWeight: "",
        color: "rgb(107,107,107)",
        height: "100%",
        lineHeight: "50px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-printer-fill"
        viewBox="0 0 16 16"
        style={{
          color: "rgba(107,107,107,.9)",
          marginRight: "8px",
        }}
      >
        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
      </svg>
      Print Deal Slip
    </div>

    <div
      className="col-2 buttonAction noCopy"
      style={{
        borderRight: "solid 1px rgba(0,0,0,.2)",
        fontWeight: "",
        color: "rgb(107,107,107)",
        height: "100%",
        lineHeight: "50px",
      }}
      onClick={() => {
        props.setDisabledState(false)
        setCommitBtn(true)
        console.log('LCYDisabled')
        console.log(LCYDisabled)
    }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        className="bi bi-pencil-fill"
        viewBox="0 0 16 16"
        style={{
          color: "rgba(107,107,107,.9)",
          marginRight: "8px",
        }}
      >
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
      </svg>
      Edit data
    </div>
  </div>

  <hr></hr>

  <div
    className="row noCopy"
    style={{
      margin: "20px 5px",
    }}
  >
    <ul className="nav nav-pills">
      <li className="nav-item">
        <a
          className={`nav-link ${activeTab === "active" ? "active" : ""}`}
          aria-current="page"
          href="#"
          onClick={() => setActiveTab("active")}
          style={{
            backgroundColor: activeTab === "active" ? "#d71921" : "",
          }}
        >
            Discounted Saving Account
        </a>
        
        
      </li>
    </ul>
  </div>

  <hr></hr>

  <div>
    <nav>
      <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
        <a
          className="nav-item nav-link active"
          id="nav-details-tab"
          data-toggle="tab"
          href="#details"
          role="tab"
          aria-controls="details"
          aria-selected="true"
        >
          Deposit - Term Savings
        </a>
        <a
          className="nav-item nav-link "
          id="nav-account-tab"
          data-toggle="tab"
          href="#account"
          role="tab"
          aria-controls="account"
          aria-selected="false"
        >
          Tran Sav - Deposited
        </a>
        <a
          className="nav-item nav-link "
          id="nav-savingAccount-tab"
          data-toggle="tab"
          href="#saving-account"
          role="tab"
          aria-controls="saving-account"
          aria-selected="false"
        >
          Disc Interest Payment
        </a>
      </div>
    </nav>

    <FieldsetComponent label="Authorize">
        <RowComponent>
            <InputField
                label="Status:"
                type="select"
                id={"sltAuthoStatus" + props.id}
                list={TempData.Status.slice(1, 3)}
                isDisabled={existedAccountDB?.savingAccount?.Status === 1 ? false : true}
                defaultValue={getNameFromId(TempData.Status, existedAccountDB?.savingAccount?.Status)}
            />
        </RowComponent>
    </FieldsetComponent>

    <div className="tab-content" id="nav-tabContent">
        <div
            className="tab-pane fade show active"
            id="details"
            role="tabpanel"
            aria-labelledby="nav-details-tab"
        >
            {/* Fieldset 1 */}
        <FieldsetComponent label="">
          {/* row 1 */}
          <RowComponent>
              <InputField
                      label="Customer Name:"
                      type="text"
                      id={"txtCustomerName" + props.id}
                      isDisabled={true}
                      value={existedAccountDB.savingAccount?.CUSTOMER?.RefID + ' - ' + existedAccountDB.savingAccount?.CUSTOMER?.GB_FullName}
                  />
                <div className="col-1"></div>
                <InputField
                    label="Working AC Currency:"
                    type="text"
                    id={"txtWorkingCurrency" + props.id}
                    isDisabled={true}
                    value={getNameFromId(Currency_1, existedAccountDB.detailAccount.Currency )}
                />
          </RowComponent>
          {/* row 2 */}
          <hr />
          <RowComponent>
                <InputField
                    label="Working Account:"
                    type="select-depend"
                    id={"sltWorkingAccount" + props.id}
                    list={formatedAccounts}
                    required={true}
                    size={"col-6"}
                    state={selectedAccount}
                    setState={setSelectedAccount}
                    isDisabled={props.disabledState}
                    defaultValue= {existedAccountDB.detailAccount?.WorkingAccountRefID}
                  />
                <div className="col-1"></div>
          </RowComponent>
          <hr />
          {/* row 3 */}
          <RowComponent>
                <InputField
                    label="Amount LCY:"
                    type="number"
                    id={"txtAmountLCY" + props.id}
                    isDisabled={!((LCYDisabled === false) && (props.disabledState === false))}
                    state={amtLCYState}
                    setState={setAmtLCYState}
                    value={existedAccountDB.detailAccount?.AmountLCY}
                />
                <div className="col-1"></div>
                <InputField
                    label="Amount FCY:"
                    type="number"
                    id={"txtAmountFCY" + props.id}
                    isDisabled={!((LCYDisabled === true) && (props.disabledState === false))}
                    state={amtFCYState}
                    setState={setAmtFCYState}
                    value={existedAccountDB.detailAccount?.AmountFCY}
                />
          </RowComponent>
          {/* row 4 */}
          <RowComponent>
                  <InputField
                    label="Narrative:"
                    type="text"
                    id={"txtNarrative" + props.id}
                    isDisabled={props.disabledState}
                    value={existedAccountDB.detailAccount?.Narrative}
                    />
                    <div className="col-1"></div>
                    <InputField
                    label="Deal Rate:"
                    type="number"
                    id={"txtDealRate" + props.id}
                    state={dealRateState}

                    setState={setDealRateState}
                    isDisabled={props.disabledState}
                    value={existedAccountDB.detailAccount?.DealRate}
                    />
          </RowComponent>
          {/* row 5 */}
          <RowComponent>
                  <InputField
                    label="Amt Credit for Cust:"
                    type="text"
                    id={"txtAmtCredit" + props.id}
                    isDisabled={true}
                    value={existedAccountDB.detailAccount?.AmountLCY}
                    />
                    <div className="col-1"></div>
                    <InputField
                    label="New Cust Bal:"
                    type="text"
                    id={"txtNewCustBal" + props.id}
                    isDisabled={true}
                    value={currencyFormat(existedAccountDB.detailAccount?.Amount, "VND")}
                    />
          </RowComponent>
        </FieldsetComponent>

          {/* Fieldset 2 */}
        <FieldsetComponent label="">
            {/* row 1 */}
          <RowComponent>
            <InputField
                    label="Payment CCY:"
                    type="select"
                    id={"sltPaymentCCY" + props.id}
                    list={Currency_2}
                    isDisabled={props.disabledState}
                    defaultValue={getNameFromId(Currency_2,existedAccountDB.detailAccount?.PaymentCurrency)}
                />
                <div className="col-1"></div>
                <InputField
                label="For Teller: "
                type="text"
                id={"txtForTeller" + props.id}
                isDisabled={props.disabledState}
                value={existedAccountDB.detailAccount?.Teller}
              />
          </RowComponent>
           {/* row 2 */}
           <RowComponent>
            <InputField
                    label="Debit Account:"
                    type="select"
                    id={"sltDebitAccount" + props.id}
                    list={debitAccountList}
                    isDisabled={props.disabledState}
                    defaultValue={getNameFromId(formatedAccounts,existedAccountDB.detailAccount?.DebitAccount)}
                    size={"col-6"}
                />
                <div className="col-1"></div>
                
          </RowComponent>
        </FieldsetComponent>

        </div>


        <div
            className="tab-pane fade"
            id="account"
            role="tabpanel"
            aria-labelledby="nav-account-tab"
        >
            <FieldsetComponent label="CUSTOMER DETAILS">
            {/* row 1 */}
              <RowComponent>
                    <InputField
                        label="Customer:"
                        type="text"
                        id={"txtCustomer_2" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.savingAccount?.CUSTOMER?.RefID + ' - ' + existedAccountDB.savingAccount?.CUSTOMER?.GB_FullName}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="ID Join Holder:"
                        type="select"
                        id={"sltJoinHolder" + props.id}
                        isDisabled={props.disabledState}
                        list={customerFormatList}
                        // value={existedAccountDB.savingAccount?.CUSTOMER?.RefID + ' - ' + existedAccountDB.savingAccount?.CUSTOMER?.GB_FullName}
                    />
              </RowComponent>
            </FieldsetComponent>


            <FieldsetComponent label="Account Information">
            {/* row 1 */}
              <RowComponent>
                    <InputField
                        label="Product Line:"
                        type="select"
                        id={"sltProduct_2" + props.id}
                        list={ProductLine.slice(20, 21)}
                        isDisabled={props.disabledState}
                        defaultValue={getNameFromId(ProductLine, existedAccountDB.detailAccount?.ProductLine)}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Currency:"
                        type="text"
                        id={"txtCurrency_2" + props.id}
                        isDisabled={true}
                        value={getNameFromId(Currency_1, existedAccountDB.detailAccount?.Currency)}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label="Amount:"
                        type="text"
                        id={"txtAmount_2" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.detailAccount?.AmountFCY ? existedAccountDB.detailAccount?.AmountFCY : existedAccountDB.detailAccount?.AmountLCY}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Value Date:"
                        type="date"
                        id={"dtValueDate_2" + props.id}
                        isDisabled={props.disabledState}
                        state={selectedDate}
                        setState={setSelectedDate}
                        value={existedAccountDB.detailAccount?.ValueDate}
                    />
              </RowComponent>
              <RowComponent>
                    <InputField
                        label="Term:"
                        type="select-depend"
                        id={"sltTerm_2" + props.id}
                        list={SavingTerm}
                        state={selectedTerm}
                        setState={setSelectedTerm}
                        isDisabled={props.disabledState}
                        defaultValue={getNameFromId(SavingTerm, existedAccountDB.detailAccount?.Term)}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Final Mat Date:"
                        type="date"
                        id={"dtFinalMatDate_2" + props.id}
                        required={true}
                        isDisabled={props.disabledState}
                        value={existedAccountDB.detailAccount?.FinalDate}
                    />
              </RowComponent>
              <RowComponent>
                    <InputField
                        label="Interest Rate:"
                        type="number"
                        id={"txtInterestRate" + props.id}
                        state={interestRateState}
                        setState={setInterestRateState}
                        isDisabled={props.disabledState}
                        value={existedAccountDB.detailAccount?.InterestRate}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Total Int Amt:"
                        type="number-nohandle"
                        id={"txtTotalIntAmt_2" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.detailAccount?.TotalIntAmount}
                    />
              </RowComponent>
              <hr />
              <RowComponent>
                    <InputField
                        label="Working Account:"
                        type="text"
                        id={"txtWorkingAccount_2" + props.id}
                        isDisabled={true}
                        size={"col-6"}
                        value={existedAccountDB.detailAccount?.WorkingAccountRefID}
                    />
                    <div className="col-1"></div>
              </RowComponent>
              <hr />
              <RowComponent>
                    <InputField
                        label="Account Officer:"
                        type="select"
                        id={"sltAccountOfficer_2" + props.id}
                        list={AccountOfficers}
                        isDisabled={props.disabledState}
                        defaultValue={getNameFromId(AccountOfficers, existedAccountDB.detailAccount?.AccountOfficer)}
                    />
                    <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>
                
            
        </div>


        <div
            className="tab-pane fade"
            id="saving-account"
            role="tabpanel"
            aria-labelledby="nav-savingAccount-tab"
        >

          <FieldsetComponent label="Customer Information">
              <RowComponent>
                <InputField
                      label="Customer:"
                      type="text"
                      id={"txtCustomer_3" + props.id}
                      isDisabled={true}
                      value={existedAccountDB.savingAccount?.CUSTOMER?.RefID + ' - ' + existedAccountDB.savingAccount?.CUSTOMER?.GB_FullName}
                    />
                  <div className="col-1"></div>
                    <InputField
                        label="Currency:"
                        type="text"
                        id={"txtCurrency_3"  + props.id}
                        isDisabled={true}
                        value={getNameFromId(Currency_1,existedAccountDB.detailAccount?.Currency )}
                    />
              </RowComponent>
              <RowComponent>
                <InputField
                      label="Dr Account:"
                      type="text"
                      id={"txtDrAccount_3"  + props.id}
                      isDisabled={true}
                      size={"col-6"}
                      value={existedAccountDB.detailAccount?.WorkingAccountRefID}
                    />
                  <div className="col-1"></div>
                  
              </RowComponent>
            </FieldsetComponent>

            <FieldsetComponent label="Account Information">
              <RowComponent>
                  <InputField
                        label="Amount LCY:"
                        type="text"
                        id={"txtAmountLCY_3" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.detailAccount?.AmountLCY}
                      />
                    <div className="col-1"></div>
                    <InputField
                        label="Amount FCY:"
                        type="text"
                        id={"txtAmountFCY_3" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.detailAccount?.AmountFCY}
                    />
                </RowComponent>
                <hr />
                <RowComponent>
                    <InputField
                        label="Narrative:"
                        type="text"
                        id={"txtNarrative_3" + props.id}
                        isDisabled={true}
                        size={"col-6"}
                        value={existedAccountDB.detailAccount?.NarrativeInterest}
                      />
                    <div className="col-1"></div>
                </RowComponent>
                <hr />
                <RowComponent>
                    <InputField
                        size={"col-6"}
                        label="Credit Account:"
                        type="select"
                        id={"sltCreditAccount_3" + props.id}
                        list={debitAccountList}
                        isDisabled={props.disabledState}
                        defaultValue={getNameFromId(formatedAccounts,existedAccountDB.detailAccount?.CreditAccount ) || ""}
                    />
                    
                </RowComponent>
                <RowComponent>
                  <InputField
                        label="For Teller:"
                        type="text"
                        id={"txtForTeller_3" + props.id}
                        isDisabled={props.disabledState}
                        value={existedAccountDB.detailAccount?.Teller || ""}
                      />
                    <div className="col-1"></div>
                    <InputField
                        label="Payment CCY:"
                        type="text"
                        id={"txtPaymentCCY_3" + props.id}
                        isDisabled={true}
                        value={getNameFromId(Currency_1, existedAccountDB.detailAccount?.Currency)}
                    />
                </RowComponent>
                <RowComponent>
                  <InputField
                        label="Exch Rate:"
                        type="number-nohandle"
                        id={"txtExchRate_3" + props.id}
                        isDisabled={props.disabledState}
                        value={existedAccountDB.detailAccount?.EcxhRate || ""}
                      />
                    <div className="col-1"></div>
                    <InputField
                        label="New Cust Bal:"
                        type="text"
                        id={"txtNewCustBal_3" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.detailAccount?.CustBal || ""}
                    />
                </RowComponent>
                <RowComponent>
                  <InputField
                        label="Amt Paid:"
                        type="text"
                        id={"txtAmtPaid_3" + props.id}
                        isDisabled={true}
                        value={existedAccountDB.detailAccount?.AmtPaid || ""}
                      />
                    <div className="col-1"></div>
                </RowComponent>
                
            </FieldsetComponent>
        </div>
    </div>
</div>


 {/* --------------------------------------- */}
  {/* <Backend Error Model /> */}
  <div>
    <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <b>{errorMessage}</b>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseBEErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* <ErrorModal /> */}
  <div>
    <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {checkRequired(props.id)}
        {arrRe.map((item, index) => {
          return <>
            <div>- {item}</div></>})}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* Success Modal  */}
  <div>
    <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {OKMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
</div>
)
}


function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}


function clearDependentValue(id){
//   document.getElementById(id).value = null
}


let arrRequired = []
function checkRequired(id) {
  arrRequired = []
  let arr = [
    {type: "select",id: "sltWorkingAccount" + id,name: 'Working Account'},
    {type: "date",id: "dtFinalMatDate_2" + id,name: 'Final Mat Date'},
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  if (arrRequired.length > 0) return true; else return false;
}

function getNameFromId(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}

function addMonths(number, date = new Date()){
  
  let tempDate = new Date(date)
  tempDate.setMonth(tempDate.getMonth() + number + 1)
  return tempDate
}

function getToday(){
  // CREATE ACCOUNT NO + PAYMENT NO
        // get current day
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = mm  + dd + yyyy;
        return today
}

function createAccountNo(selectedAccount){
  let today = getToday()
  let accountNoStr = selectedAccount + "*" + today + "*BO"
  
  return accountNoStr
}

// function createPaymentNo(){
//   let today = getToday()
//   let paymentNoStr = "TT/" + today + "/" + newAccount.getDataValue('id')
//   return paymentNoStr
// }

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}