import axios from 'axios';

const foreignExchangeApi = {
  exchange: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/exchange/create`, params);
  },

};

export default foreignExchangeApi;



