import React from 'react'

export default function ClearIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-x-circle-fill"
      viewBox="0 0 16 16"
      style={props.status && {
        color: "rgba(107,107,107,.9)",
        marginRight: "8px",
      } || {
        color: "rgba(107,107,107,.2)",
        marginRight: "8px",
      }}
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
    </svg>
  );
}
