import React from 'react'

export default function TabChange(props) {
  return (
    <div>
        {props.count == 2 ? (
            <div>
                <nav>
          <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a
            //   className="nav-item nav-link active"
              className={`nav-item nav-link active`}
            //   id="nav-customerInfo-tab"
              id={`nav-${props.id1}-tab`}
              data-toggle="tab"
            //   href="#customer-info"
              href={`#${props.id1}`}
              role="tab"
            //   aria-controls="customer-info"
              aria-controls={`${props.id1}`}
              aria-selected="true"
            >
              {props.name1}
            </a>
            <a
              className="nav-item nav-link"
              id={`nav-${props.id2}-tab`}
              data-toggle="tab"
            //   href="#customer-info"
              href={`#${props.id2}`}
              role="tab"
            //   aria-controls="customer-info"
              aria-controls={`${props.id2}`}
              aria-selected="false"
            >
                {props.name2}
            </a>
          </div>
        </nav>
            </div>
        ) : ""}
    </div>
  )
}
