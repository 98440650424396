const ChargeCode = [
    { "id": 0, "name": ''},
    { "id": 1, "name": 'Phí quản lý tài khoản'},
    { "id": 2, "name": 'Phí sử dụng Mobile banking'},
    { "id": 3, "name": 'Phí cấp sổ phụ qua Email'},
    { "id": 4, "name": 'Phí QLTK + SMS'},
    { "id": 5, "name": 'Phí QLTK + SMA'},
    { "id": 6, "name": 'Phí QLTK + SMS + MB'},
    { "id": 7, "name": 'Phí SMS + SMA'}
  ];

  export default ChargeCode