
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import savingAccountApi from "../../../../apis/savingAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import CloseSystemCurrency from "../../../../data/CloseSystemCurrency";
import Currency_1 from "../../../../data/Currency_1";
import Currency_2 from "../../../../data/Currency_2";
import ProductLine from "../../../../data/ProductLine";
import SavingCurrencyAccount from "../../../../data/SavingCurrencyAccount";
import TempData from "../../../../data/TempData";

export default function Close_Discounted_Modal(props) {
  const [arrRe, setArrRe] = useState([])
  const handleSetArrRe = (item) => {setArrRe(item);};
  // 2. State show Error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  // 3. State show Success
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  // 5. State show Backend Error
  const [showBEError, setShowBEError] = useState(false);
  const handleCloseBEErrorModal = () => setShowBEError(false);
  const handleShowBEErrorModal = () => setShowBEError(true);
  const [activeTab, setActiveTab] = useState("active");
      //HANDLE BUTTON
    const [commitBtn, setCommitBtn] = useState(true)
    const [authorBtn, setAuthoBtn] = useState(false)
    const [editBtn, setEditBtn] = useState(false)
    const [searchBtn, setSearchBtn] = useState(false)
    const [printBtn, setPrintBtn] = useState(false)

    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [disabledState, setDisabledState] = useState(false);
    const [accountStatusDB, setAccountStatusDB] = useState("Active")
    const [closureAccount, setClosureAccount] = useState({})
    const [savingAccountDB, setAccountDB] = useState(true);

    const [OKMessage, setOKMessage] = useState("");
    // GET ACCOUNT
    useEffect(() => {
      async function fetchAccount() {
        try {
          const response = await savingAccountApi.getAccount(props.modalInstanceID)
          const accountDB = response.data.data
          console.log("fetch account")
          console.log(accountDB)
          setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
          console.log(accountDB.savingAccount?.AccountStatus)
          setAccountDB(accountDB)
          // const accountType = accountDB.savingAccount?.Type
          if(accountDB.savingAccount?.AccountStatus != "Active")
          {
            setDisabledState(true)
            setCommitBtn(false)
            
          }
          setIsLoading(false)
        } catch (error) {
  
        }
      }
      fetchAccount();
    }, []);

      // CLOSURE INFO
      useEffect(() => {
      async function fetchClosure() {
        try {
          if(accountStatusDB === "Closed"){
            const response = await savingAccountApi.getClosure(props.modalInstanceID)
            const closureDB = response.data.data
            console.log("fetch closure account")
            console.log(closureDB)
            setClosureAccount(closureDB)
            // FILL DATA
            if(closureDB?.Status === 1){
              setAuthoBtn(true)
            }
            if(response)
            setIsLoading2(false)
          }
          
          // console.log(closureDB?.Status)
          // setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
        
        } catch (error) {
  
        }
      }
      fetchClosure();
    }, [savingAccountDB]);
    if((!isLoading && !disabledState) || (!isLoading2 && disabledState))
  return (
    <>

        <div
        className="row align-items-center text-center mb-3"
        style={{
            height: "50px",
            backgroundColor: "#eceff4",
            margin: "3px",
            position: 'sticky',
            top: '0',
            zIndex: '10',
            marginTop: '0',
            marginLeft: '0',
            marginRight: '0'
        }}
        >
            <div
            className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ commitBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            onClick={ async (event)=> {
                
              let propsID = props.id
              let params = {
              
                depositNo: document.getElementById("txtDepositNo_Close2_2_"   + propsID).value || null,
                customerID: savingAccountDB?.savingAccount?.CUSTOMER?.id || null,
                customerName: savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName || "",
                valueDate: document.getElementById("txtValueDate_Close2_1_"   + propsID).value || null,
                newMatDate: document.getElementById("dtNewMatDate_Close2_1_"   + propsID).value || null,
                intPymtMethod: document.getElementById("txtPaymentMethod_Close2_1_"   + propsID).value || null,
                interestBasic: document.getElementById("txtInterestBasis_Close2_1_"   + propsID).value || null,
                interestRate: document.getElementById("txtInterestedRate_Close2_1_"   + propsID).value || null,
                totalIntAmount: document.getElementById("txtTotalIntAmt_Close2_1_"   + propsID).value || null,
                eligibleInterest: document.getElementById("txtEligibleInterest_Close2_1_"   + propsID).value || null,
                intRateVDate: document.getElementById("dtIntRateDate_Close2_1_"   + propsID).value || null,
                amountLCY: document.getElementById("txtAmountLCY_Close2_2_"   + propsID).value || null,
                amountFCY: document.getElementById("txtAmountFCY_Close2_2_"   + propsID).value || null,
                narrative: document.getElementById("txtNarrative2_Close2_2_"   + propsID).value || null,
                dealRate: document.getElementById("txtDealRate_Close2_2_"   + propsID).value || null,
                teller: document.getElementById("txtForTeller_Close2_2_"   + propsID).value || null,
                waiveCharge: getIdFromName(TempData.BooleanData, document.getElementById('txtWaiveCharge_Close2_2_'   + propsID)?.value) == 0 ? null : getIdFromName(TempData.BooleanData, document.getElementById('txtWaiveCharge_Close2_2_'   + propsID)?.value),
                newCustomerBalance: document.getElementById("txtNewCustBal_Close2_2_"   + propsID).value || null,
                customerBalance: document.getElementById("txtAmtPaid_Close2_2_"   + propsID).value || null,
                creditAccount: document.getElementById("txtCreditAccount_Close2_2_"   + propsID).value || null,
              }

              savingAccountApi.closeDiscounted(props.modalInstanceID, params)
                .then(response => {
                  console.log("response")
                  console.log(response.data.data)
                  setOKMessage("Close Successfully")
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log(errorMessage)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });
                // recreateInput();
                
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-save-fill"
                viewBox="0 0 16 16"
                style={commitBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
            >
                <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
            </svg>
            <div
                style={{
                display: "inline",
                }}
            >
                {" "}
                Commit Data{" "}
            </div>
            </div>
            <div
            className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ authorBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            onClick={async () => {
                if(authorBtn){
                    console.log("Authorize")
                    let params = {
                        status: getIdFromName(TempData.Status, document.getElementById("sltAuthoStatus" + props.id).value || 1)
                    }
                    await savingAccountApi.ValidateCloseDiscounted(closureAccount?.id, params)
                    .then(response => {
                        setOKMessage("Authorize Successfully")
                        setShowSuccessModal(true);
                    })
                    .catch(error => {
                        setErrorMessage(error.response.data.message)
                        console.log(errorMessage)
                        // errorMessage = error.Data
                        handleShowBEErrorModal();
                    });
                }
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
                style={authorBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
            >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            Authorize
            </div>
            <div
            className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ searchBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
                style={searchBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
            >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            Search
            </div>
            <div
             className={printBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
             style={ printBtn && {
                 borderRight: "solid 1px rgba(0,0,0,.2)",
                 fontWeight: "",
                 color: "rgba(107,107,107, 1)",
                 height: "100%",
                 lineHeight: "50px",
             } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                     fontWeight: "",
                     color: "rgba(107,107,107, 0.2)",
                     height: "100%",
                     lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-printer-fill"
                viewBox="0 0 16 16"
                style={printBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            Print Deal Slip
            </div>

            <div
            className={editBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ editBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}

            onClick={() => {
                props.setDisabledState(false)
                
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-pencil-fill"
                viewBox="0 0 16 16"
                style={editBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>
            Edit data
            </div>
        </div>
    <nav>
    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
      <a
        className="nav-item nav-link active"
        id="nav-travSav-tab"
        data-toggle="tab"
        href="#travSav"
        role="tab"
        aria-controls="nav-trad"
        aria-selected="true"
      >
        Trav Sav - Deposited
      </a>
      <a
        className="nav-item nav-link"
        id="nav-Interest-tab"
        data-toggle="tab"
        href="#interest"
        role="tab"
        aria-controls="nav-interest"
        aria-selected="false"
      >
        Disc Interest Payment
      </a>
    </div>
  </nav>

  <FieldsetComponent label="">
          <b>Account Status: 
            <span
            style={accountStatusDB === "Active" &&{
               color: "Green"
            }|| {
              color: "Red"
            }}>  {accountStatusDB}</span> </b>
    </FieldsetComponent>
  {disabledState && !isLoading2 && <FieldsetComponent>
      <RowComponent>
        <InputField
            label="Closing Status:"
            type="select"
            id={"sltAuthoStatus" + props.id}
            list={TempData.Status.slice(1, 3)}
            isDisabled={closureAccount?.Status === 1 ? false : true}
            defaultValue={getNameFromId(TempData.Status, closureAccount?.Status)}
            // defaultValue={closureAccount?.Status}
        />
    </RowComponent>
  </FieldsetComponent>}

  <div className="tab-content" id="nav-tabContent">
    <div
      className="tab-pane fade show active"
      id="travSav"
      role="tabpanel"
      aria-labelledby="nav-travSav-tab"
    >

      {/* Fieldset 1 */}
      <FieldsetComponent label="">
        {/* row 1 */}
        <RowComponent>
          <InputField label="Customer No:" type="text" id={"txtCustomer_Close2_1_" + props.id} isDisabled={true}
            value={savingAccountDB.savingAccount?.CUSTOMER?.RefID + " - " + savingAccountDB.savingAccount?.CUSTOMER?.GB_FullName} />
          <div className="col-1"></div>
          <InputField label="Currency / Amount:" type="text" id={"txtCurrencyAmount_Close2_1_" + props.id} isDisabled={true}
            value={getNameFromId(Currency_1, savingAccountDB.detailAccount?.Currency )} />
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField label="Value Date:" type="text" id={"txtValueDate_Close2_1_"+props.id} isDisabled={true}
            value={savingAccountDB.detailAccount?.ValueDate} />
          <div className="col-1"></div>
          <InputField label="New Mat Date:" type="date" id={"dtNewMatDate_Close2_1_" + props.id}
           value={savingAccountDB.detailAccount?.MaturityDate} 
           isDisabled={disabledState}/>
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField
            label="Int Pymt Method:"
            type="text"
            id={"txtPaymentMethod_Close2_1_" + props.id}
            isDisabled={true}
            value={""}
          />
          <div className="col-1"></div>
          <InputField
            label="Interest Basis:"
            type="text"
            id={"txtInterestBasis_Close2_1_" + props.id}
            isDisabled={true}
            value={""}
          />
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField
            label="Interest Rate:"
            type="text"
            id={"txtInterestedRate_Close2_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.InterestRate}
          />
          <div className="col-1"></div>
          <InputField
            label="Total Int Amt:"
            type="text"
            id={"txtTotalIntAmt_Close2_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.Amount}
          />
        </RowComponent>
      </FieldsetComponent>

      <FieldsetComponent label="Eligible Interest">
        <RowComponent>
            <InputField
              label="Eligible Interest:"
              type="number-nohandle"
              id={"txtEligibleInterest_Close2_1_" + props.id}
              isDisabled={disabledState}
            />
            <div className="col-1"></div>
            <InputField
              label="Int Rate V Date:"
              type="date"
              id={"dtIntRateDate_Close2_1_" + props.id}
              isDisabled={disabledState}
            />
          </RowComponent>
      </FieldsetComponent>
      <FieldsetComponent label="Account Information">
        <RowComponent>
            <InputField
              label="Debit Account:"
              type="text"
              id={"txtDebitAccount_Close2_1_" + props.id}
              isDisabled={true}
              size={"col-6"}
              value={getNameFromCurrency(CloseSystemCurrency,savingAccountDB.detailAccount?.Currency )}
              
            />
            <div className="col-1"></div>
          
          </RowComponent>
          <RowComponent>
            <InputField
                label="Cr Acc for Principal:"
                type="text"
                id={"txtAccforPrincipal_Close2_1_" + props.id}
                isDisabled={true}
                size={"col-6"}
                value={savingAccountDB.detailAccount?.WorkingAccountRefID}
              />
              <div className="col-1"></div>
          </RowComponent>
          <RowComponent>
            <InputField
                label="Cr Acc for Interest:"
                type="text"
                id={"txtAccforInterest_Close2_1_" + props.id}
                isDisabled={true}
                size={"col-6"}
                value={savingAccountDB.detailAccount?.WorkingAccountRefID}
              />
              <div className="col-1"></div>
          </RowComponent>
      </FieldsetComponent>
    </div>
    <div
      className="tab-pane fade "
      id="interest"
      role="tabpanel"
      aria-labelledby="nav-Interest-tab"
    >
      <FieldsetComponent label="">
        <RowComponent>
          <InputField
            label="Deposit No:"
            type="text"
            id={"txtDepositNo_Close2_2_" + props.id}
            isDisabled={true}
            // value={savingAccountDB.detailAccount?.}
            value={"LD/23060/34323"}
          />
          <div className="col-1"></div>
           <InputField
              label="Currency:"
              type="text"
              id={"txtCurrency_Close2_2_" + props.id}
              isDisabled={true}
              value={getNameFromId(Currency_1,savingAccountDB.detailAccount?.Currency )} />
        </RowComponent>
        <hr />
    </FieldsetComponent>
    <FieldsetComponent >

    <RowComponent>
          <InputField
              label="Customer:"
              type="text"
              id={"txtCustomer_Close2_2_" + props.id}
              isDisabled={true}
              size={"col-6"}
              value={savingAccountDB.detailAccount?.WorkingAccountRefID} />
        </RowComponent>

        <RowComponent>
          <InputField
            label="Dr Account:"
            type="text"
            id={"txtDrAccount_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.WorkingAccountRefID}
            size={"col-6"}
          />
        </RowComponent>
        <RowComponent>
        <InputField
            label="Narrative:"
            type="text"
            id={"txtNarrative_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.NarrativeInterest}
            size={"col-6"}
          />
        </RowComponent>
        

        <hr />
    </FieldsetComponent>
       
    <FieldsetComponent>
        <RowComponent>
          <InputField
            label="Deal Rate:"
            type="number-nohandle"
            id={"txtDealRate_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Amount LCY:"
            type="text"
            id={"txtAmountLCY_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.AmountLCY}
          />
          <div className="col-1"></div>
          <InputField
            label="Amount FCY:"
            type="text"
            id={"txtAmountFCY_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.AmountFCY}
          />
        </RowComponent>

        <RowComponent>
          <InputField
            label="Currency Paid:"
            type="text"
            id={"txtCurrencyPaid_Close2_2_" + props.id}
            isDisabled={true}
            value={"VND"}
          />
          <div className="col-1"></div>
          <InputField
            label="For Teller:"
            type="text"
            id={"txtForTeller_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>

        <RowComponent>
          <InputField
            label="Credit Account:"
            type="select"
            id={"txtCreditAccount_Close2_2_" + props.id}
           list={CloseSystemCurrency.slice(5, 6)}
           isDisabled={disabledState}
          />
          <div className="col-1"></div>
          <InputField
            label="Narrative:"
            type="text"
            id={"txtNarrative2_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Waive Charge:"
            type="select"
            id={"txtWaiveCharge_Close2_2_" + props.id}
            list={TempData.BooleanData}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>
          
        </RowComponent>
        <hr />
        <RowComponent>
          <InputField
            label="New Cust Bal:"
            type="text"
            id={"txtNewCustBal_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.CustBal}
          />
          <div className="col-1"></div>
          <InputField
            label="Amt Paid:"
            type="text"
            id={"txtAmtPaid_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.AmtPaid}
          />
        </RowComponent>
        <hr />
        <RowComponent>
          <InputField
            label="Print Ln No of:"
            type="number-nohandle"
            id={"txtPrintNo_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>
        </RowComponent>
      </FieldsetComponent>
    </div>
  </div>

    {/* --------------------------------------- */}
  {/* <Backend Error Model /> */}
  <div>
    <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <b>{errorMessage}</b>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseBEErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* <ErrorModal /> */}
  <div>
    <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {arrRe.map((item, index) => {
          return <>
            <div>- {item}</div></>})}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* Success Modal  */}
  <div>
    <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {OKMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  </>
  
  )
}



function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
  
  function getNameFromId(list, id) {
    const obj = list.find(obj => obj.id === id);
    return obj ? obj.name : null;
  }
  

   function getNameFromCurrency(list, currency) {
    const obj = list.find(obj => obj.currency === currency);
    return obj ? obj.name : null;
  }
  
  function currencyFormat(value, currency) {
    let resultValue = value ? value.toLocaleString('it-IT', { style: 'currency', currency: currency }) : "";
    console.log(resultValue.slice(0, -4))
    return resultValue.slice(0, -4)
  }
  
  
  function amountCal(origin, rate) {
    console.log(origin)
    console.log(rate)
    return origin * 0.0004316238 * rate
  }