
import React, { useState , useEffect} from "react";
import { Button, Modal } from 'react-bootstrap';
// import { Form } from 'react-bootstrap';
import dataSelect from "../../../data/select.json";
// import "react-datepicker/dist/react-datepicker.css";
import CityList from "../../../data/CityList";
import CountryList from "../../../data/CountryList";
import DocTypeList from "../../../data/DocTypeList";

import "./CustomerManagement.css";
import InputField from "../../../components/InputField/InputField";
import FieldsetComponent from "../../../components/FieldsetComponent/FieldsetComponent";
import RowComponent from "../../../components/RowComponent/RowComponent";
import customerApi from "../../../apis/customerApi";
import AccountOfficers from "../../../data/AccountOfficer";
import cusApi from "../../../apis/cusApi";
import RelationCode from "../../../data/RelationCode";
import ResidenceType from "../../../data/ResidenceType";
import Currency_1 from "../../../data/Currency_1";
import Sector from "../../../data/Sector";
import SubSector from "../../../data/SubSector";
import Industry from "../../../data/Industry";
import SubIndustry from "../../../data/SubIndustry";
import TempData from "../../../data/TempData";
import EditDataIcon from "../../../components/ActionBar/EditDataIcon";
import AuthorizeIcon from "../../../components/ActionBar/AuthorizeIcon";
import CommitDataIcon from "../../../components/ActionBar/CommitDataIcon"; 
import PrintDealSlipIcon from "../../../components/ActionBar/PrintDealSlipIcon"; 
import SearchIcon from "../../../components/ActionBar/SearchIcon"; 
import TabChange from "../../../components/TabChange/TabChange";
import TabChangeActive from "../../../components/TabChangeSub/TabChangeActive";
import TabChangeNonActive from "../../../components/TabChangeSub/TabChangeNonActive";

 

export default function OpenIndividualCustomer_Update(props) {

  
  // 1. State check required
  const [arrRe, setArrRe] = useState([])
  const handleSetArrRe = (item) => {setArrRe(item);};
  // 2. State show Error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  // 3. State show Success
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
    // 5. State show Backend Error
    const [showBEError, setShowBEError] = useState(false);
    const handleCloseBEErrorModal = () => setShowBEError(false);
    const handleShowBEErrorModal = () => setShowBEError(true);
    // State response
    const [refIDRes, setRefIDRes] = useState(""); 
    // Loading
    const [isLoading, setIsLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("active");
  const [options, setOptions] = useState(dataSelect);

  // 5. Select State
  const [getSector, setSector] = useState(0);
  const [getIndustry, setIndustry] = useState(0);
  useEffect(() => {
    // clearDependentValue("sltIndustry_Update")
  }, [getIndustry]);

  const handleChange = (event) => {
    setOptions(event.target.value);
  };

  const [activeTab01, setActiveTab01] = useState("home");

  const handleTabClick = (tab) => {
    setActiveTab01(tab);
  };

 
  // fetch data id nhe
  const [customer_DB, getCustomer_DB] = useState([])
  const [customer_DB_Detail, getCustomer_DB_Detail] = useState([])
  useEffect(() => {
    async function fetchCustomer_DB() {
      try {
        // const response = await cusApi.enquiry(params)
        const response = await cusApi.getIndividual(props.childID)
        // getCustomer_DB(response.data.data[0])
        getCustomer_DB(response.data.data.CUSTOMER)
        getCustomer_DB_Detail(response.data.data)
        setIsLoading(false);
      } catch (error) {
      }
    }
    fetchCustomer_DB();
  }, [props.childID]);


  const [disableState, setDisableState] = useState(true);
  const handleSetDisableState = (boolean) => {
    setDisableState(boolean);
  };
  
  console.log(customer_DB_Detail)
  return (
    <div>
      {isLoading ? (
        <div style={{marginTop: '20px'}}>
            <div className="text-center">
                <div className="spinner-border" role="status">
                    <span span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
      ) : (
        <div>
      {/* button action  */}
      <div>{props.childID}</div>
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{
          height: "50px",
          backgroundColor: "#eceff4",
          margin: "3px",
          position: 'sticky',
          top: '0',
          zIndex: '10',
          marginTop: '0',
          marginLeft: '0',
          marginRight: '0'
        }}
      >
        <div
          className="col-2 buttonAction"
          // data-bs-toggle="modal"
          // data-bs-target="#exampleModal"
          style={styles.buttonAction}
          onClick={async (event)=> {
            checkRequired();
            if (arrRequired.length > 0) {
              setShowErrorModal(true);
              setArrRe(arrRequired);
            } else { 
              const params = {
                firstName: document.getElementById('txtFirstName_Update').value || "",
                lastName: document.getElementById('txtLastName_Update').value || "",
                middleName: document.getElementById('txtMiddleName_Update').value || "",    
                GB_ShortName: document.getElementById('txtGBShortName_Update').value || "",
                GB_FullName: document.getElementById('txtGBFullName_Update').value || "",
                GB_Street: document.getElementById('txtGBStreet_Update').value || "",
                GB_Towndist: document.getElementById('txtGBTown/Dist_Update').value || "",
                mobilePhone: document.getElementById('txtMobilePhone_Update').value || "",
                docID: document.getElementById('txtDocID_Update').value || "",
                emailAddress: document.getElementById('txtEmailAddress_Update').value || "",
                birthday: document.getElementById('dtBirthday_Update').value || null,
                subSector: 1, // tp kinh te ca the
                industry: getIdFromName(SubIndustry, document.getElementById('sltIndustry_Update')?.value) == 0 ? null : getIdFromName(SubIndustry, document.getElementById('sltIndustry_Update')?.value),
                docIssuePlace: document.getElementById('txtDocIssuePlace_Update').value || "",
                docIssueDate: document.getElementById('dtDocIssueDate_Update').value || null,
                docExpiryDate: document.getElementById('dtDocExpiryDate_Update').value || null,
                cityProvince: getIdFromName(CityList, document.getElementById('sltCity/Province_Update')?.value) == 0 ? null :  getIdFromName(CityList, document.getElementById('sltCity/Province_Update')?.value) ,
                GB_Country: getIdFromName(CountryList, document.getElementById('sltGBCountry_Update')?.value) == 0? null : getIdFromName(CountryList, document.getElementById('sltGBCountry_Update')?.value),
                nationality: getIdFromName(CountryList, document.getElementById('sltNationality_Update')?.value) == 0? null: getIdFromName(CountryList, document.getElementById('sltNationality_Update')?.value) ,
                residence: getIdFromName(CountryList, document.getElementById('sltResidence_Update')?.value) == 0? null: getIdFromName(CountryList, document.getElementById('sltResidence_Update')?.value),
                doctype: getIdFromName(DocTypeList, document.getElementById('sltDocType_Update')?.value) == 0? null: getIdFromName(DocTypeList, document.getElementById('sltDocType_Update')?.value),
                mainSector: 15, //individual
                mainIndustry: getIdFromName(Industry, document.getElementById('sltMainIndustry_Update')?.value) == 0? null: getIdFromName(Industry, document.getElementById('sltMainIndustry_Update')?.value),
                accountOfficer: getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer_Update')?.value) == 0? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer_Update')?.value),
                currency: getIdFromName(Currency_1, document.getElementById('sltCurrency_Update')?.value) == 0 ? null: getIdFromName(Currency_1, document.getElementById('sltCurrency_Update')?.value),
                gender: getIdFromName(TempData.Gender, document.getElementById('sltGender_Update')?.value),
                title: getIdFromName(TempData.CusTitle, document.getElementById('sltTitle_Update')?.value),
                maritalStatus: getIdFromName(TempData.CusMaritalStatus, document.getElementById('sltMaritalStatus_Update')?.value),
                contactDate: document.getElementById('txtContactDate_Update').value || null,
                relationCode:  getIdFromName(RelationCode, document.getElementById('sltRelationCode_Update')?.value) == 0 ? null:getIdFromName(RelationCode, document.getElementById('sltRelationCode_Update')?.value) ,
                officeNumber: document.getElementById('txtOfficeNumber_Update').value || "",
                faxNumber:  document.getElementById('txtFaxNumber_Update').value || "",
                dependantsNo: document.getElementById('txtNoDependants_Update').value || null,
                childUnder15: document.getElementById('txtNoChild_15_Update').value || null,
                childfr15to25: document.getElementById('txtNoChild15_25_Update').value || null,
                childOver25: document.getElementById('txtNoChild25_Update').value || null,
                homeOwnership:  getIdFromName(TempData.BooleanData, document.getElementById('sltHomeOwnership_Update')?.value),
                residenceType: getIdFromName(ResidenceType, document.getElementById('sltResidenceType_Update')?.value) == 0? null :  getIdFromName(ResidenceType, document.getElementById('sltResidenceType_Update')?.value),
                employmentStatus: getIdFromName(TempData.BooleanData, document.getElementById('sltEmploymentStatus_Update')?.value),
                companyName: document.getElementById('txtCompanyName_Update').value || "",
                monthlyIncome:  document.getElementById('txtMonthlyIncome_Update').value || null,
                officeAddress: document.getElementById('txtOfficeAddress_Update').value || "",
                customerLiability: document.getElementById('txtCustomerLiability_Update').value || "",
            };

            cusApi.postIndividual(params)
              .then(response => {
                setShowSuccessModal(true);
                console.log(response)
                setRefIDRes(response.data.data.customer.RefID)
              })
              .catch(error => {
                setErrorMessage(error.response.data.message)
                console.log(errorMessage)
                // errorMessage = error.Data
                handleShowBEErrorModal();
              });
              
              // recreateInput();
            }
          }}
        >
          <CommitDataIcon />
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className="col-2 buttonAction"
          style={styles.buttonAction}
          onClick={() => {
            console.log(document.getElementById('sltCity/Province_Update').value)
            console.log(getIdFromName(CityList,document.getElementById('sltCity/Province_Update').value))
          }}
        >
          <AuthorizeIcon />
          Authorize
        </div>
        <div
          className="col-2 buttonAction"
          style={styles.buttonAction}
        >
          <SearchIcon />
          Search
        </div>
        <div
          className="col-2 buttonAction"
          style={styles.buttonAction}
        >
          <PrintDealSlipIcon />
          Print Deal Slip
        </div>

        <div
          className="col-2 buttonAction"  style={styles.buttonAction}
          onClick={() => {
            if (disableState == true) handleSetDisableState(false)
          }}
        >
          <EditDataIcon />
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Open Individual Customer
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>

      <div
          onChange={() => {
            let middleName = '';
            if (document.getElementById('txtMiddleName_Update').value.length != 0) middleName = ' ' + document.getElementById('txtMiddleName_Update').value;
            document.getElementById('txtGBShortName_Update').value = document.getElementById('txtGBFullName_Update').value =  (document.getElementById('txtLastName_Update').value + middleName + ' ' +document.getElementById('txtFirstName_Update').value).trim()
          }}
      >
        <TabChange 
          count={2}
          id1={`customer-info`}
          name1={`Customer Info`}
          id2={`details`}
          name2={`Details`}
        />
        <div className="tab-content" id="nav-tabContent">
          <TabChangeActive id={`customer-info`}>
            {/* Fieldset 1 */}
            <FieldsetComponent label="Personal Information">
              {/* row 1 */}
              <RowComponent>
                <InputField label="First Name:"type="text"id="txtFirstName_Update"isDisabled={disableState}value={customer_DB_Detail.FirstName}/>
                <div className="col-1"></div>
                <InputField label="Last Name:"type="text"id="txtLastName_Update"isDisabled={disableState}value={customer_DB_Detail.LastName}/>
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField label="Middle Name:"type="text"id="txtMiddleName_Update"isDisabled={disableState}value={customer_DB_Detail.MiddleName}/>
                <div className="col-1"></div>
                <InputField label="Birthday:" type="date" id="dtBirthday_Update" 
                  isDisabled={disableState} 
                  value={customer_DB_Detail.Birthday}
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField label="GB Short Name:"type="text"id="txtGBShortName_Update"required={!0}isDisabled={disableState}value={customer_DB.GB_ShortName}/>
                <div className="col-1"></div>
                <InputField label="GB Full Name:"type="text"id="txtGBFullName_Update"required={!0}isDisabled={disableState}value={customer_DB.GB_FullName}/>
              </RowComponent>
            </FieldsetComponent>
            {/* Fieldset 2 */}
            <FieldsetComponent label="Identification Details">
              {/* row 1 */}
              <RowComponent>
                <InputField label="GB Street:"type="text"id="txtGBStreet_Update"required={!0}isDisabled={disableState}value={customer_DB.GB_Street}/>
                <div className="col-1"></div>
                <InputField label="GB Town/Dist:"type="text"id="txtGBTown/Dist_Update"required={!0}isDisabled={disableState}value={customer_DB.GB_Towndist}/>
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField label="Mobile Phone:" type="text" id="txtMobilePhone_Update" ph="-- Enter Phone Number --" isDisabled={disableState} value={customer_DB.PhoneNumber}/>
                <div className="col-1"></div>
                <InputField label="City/Province:"type="select"id="sltCity/Province_Update"list={CityList}required={!0}isDisabled={disableState} defaultValue={CityList[customer_DB.CityProvince?customer_DB.CityProvince:0].name}/>
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField label="GB Country:"type="select"id="sltGBCountry_Update"list={CountryList}isDisabled={disableState} defaultValue={CountryList[customer_DB.GB_Country?customer_DB.GB_Country:0].name}/>
                <div className="col-1"></div>
                <InputField label="Nationality:"type="select"id="sltNationality_Update"list={CountryList}isDisabled={disableState} defaultValue={CountryList[customer_DB.Nationality?customer_DB.Nationality:0].name}/>
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField label="Residence:"type="select"id="sltResidence_Update"list={CountryList}isDisabled={disableState}defaultValue={CountryList[customer_DB.Residence?customer_DB.Residence:0].name}/>
                <div className="col-1"></div>
                <InputField label="Doc Type:"type="select"id="sltDocType_Update"list={DocTypeList}isDisabled={disableState}required={!0}defaultValue={DocTypeList[customer_DB.Doctype?customer_DB.Doctype:0].name}/>
              </RowComponent>
              {/* row 5 */}
              <RowComponent>
                <InputField label="Doc ID:"type="text"id="txtDocID_Update"required={!0}isDisabled={disableState}value={customer_DB.DocID}/>
                <div className="col-1"></div>
                <InputField label="Doc Issue Place:"type="text"id="txtDocIssuePlace_Update"required={!0}isDisabled={disableState}value={customer_DB.DocIssuePlace}/>
              </RowComponent>
              {/* row 6 */}
              <RowComponent>
                <InputField label="Doc Issue Date:"type="date"id="dtDocIssueDate_Update"isDisabled={disableState}value={customer_DB_Detail.CUSTOMER.DocIssueDate}/>
                <div className="col-1"></div>
                <InputField label="Doc Expiry Date:"type="date"id="dtDocExpiryDate_Update"isDisabled={disableState}value={customer_DB_Detail.CUSTOMER.DocExpiryDate}/>
              </RowComponent>
              {/* row 8 */}
              <RowComponent>
                <InputField label="Email Address:"type="text"id="txtEmailAddress_Update"isDisabled={disableState}value={customer_DB_Detail.EmailAddress}/>
              </RowComponent>
            </FieldsetComponent>
            {/* Fieldset 3 */}
            <FieldsetComponent label="Professional Information">
              {/* row 1 */}
              <RowComponent>
                <InputField label="Main Sector:"type="select"id="sltMainSector_Update"list={[Sector[15]]}state={getSector}setState={setSector}required={!0}isDisabled={disableState}defaultValue={Sector[15].name}/>
                <div className="col-1"></div>
                <InputField label="Sub Sector:"type="select"id="sltSubSector_Update"list={[SubSector[1]]}required={!0}isDisabled={disableState}defaultValue={SubSector[1].name}/>
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField label="Main Industry:"type="select-depend"id="sltMainIndustry_Update"list={Industry}required={!0}state={getIndustry}setState={setIndustry}isDisabled={disableState}defaultValue={Industry[customer_DB.MainIndustry?customer_DB.MainIndustry:0].name}/>
                <div className="col-1"></div>
                <InputField label="Industry:"type="select"id="sltIndustry_Update"list={SubIndustry.filter(t=>{let r=getIdFromName(Industry,getIndustry);return t.industry==r})}required={!0}isDisabled={disableState}defaultValue={SubIndustry[customer_DB.Industry?customer_DB.Industry:0].name}/>
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField label="Account Officer:"type="select"id="sltAccountOfficer_Update"list={AccountOfficers}isDisabled={disableState}defaultValue={AccountOfficers[customer_DB.AccountOfficer?customer_DB.AccountOfficer:0].name}/>
                <div className="col-1"></div>
                <InputField label="Company Book:"type="text"id="txtCompanyBook_Update"/>
              </RowComponent>
            </FieldsetComponent>
          </TabChangeActive>

          <TabChangeNonActive id={`details`}>
          <FieldsetComponent label="Personal Details ">
              {/* row 1 */}
              <RowComponent>
                <InputField label="Gender:"type="select"id="sltGender_Update"list={TempData.Gender}isDisabled={disableState}defaultValue={TempData.Gender[customer_DB_Detail.Gender?customer_DB_Detail.Gender:0].name}/>
                <div className="col-1"></div>
                <InputField label="Title:"type="select"id="sltTitle_Update"list={TempData.CusTitle}isDisabled={disableState}defaultValue={TempData.CusTitle[customer_DB_Detail.Title?customer_DB_Detail.Title:0].name}/>
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField label="Marital Status:"type="select"id="sltMaritalStatus_Update"list={TempData.CusMaritalStatus}isDisabled={disableState}defaultValue={TempData.CusMaritalStatus[customer_DB_Detail.MaritalStatus?customer_DB_Detail.MaritalStatus:0].name}/>
                <div className="col-1"></div>
                <InputField
                      label="Relation Code:"
                      type="select"
                      id="sltRelationCode_Update"
                      list={RelationCode}
                      isDisabled={disableState}
                      defaultValue={RelationCode[customer_DB_Detail.CUSTOMER.RelationCode?customer_DB_Detail.CUSTOMER.RelationCode:0].name}
                  />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="Contact Date:"
                  type="date"
                  id="txtContactDate_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.ContactDate}
                />
                <div className="col-1"></div>
              </RowComponent>
              {/* row 1 */}
              <hr></hr>
              <RowComponent>
                <InputField
                  label="Office Number:"
                  type="text"
                  id="txtOfficeNumber_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.CUSTOMER.PhoneNumber}
                />
                <div className="col-1"></div>
                <InputField
                  label="Fax Number:"
                  type="text"
                  id="txtFaxNumber_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.FaxNumber}
                />
              </RowComponent>

              <hr></hr>
              {/* row 2 */}
              <RowComponent>
                <InputField
                  label="No. of dependants:"
                  type="text"
                  id="txtNoDependants_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.DependantsNo}
                />
                <div className="col-1"></div>
                <InputField
                  label="No. Child (< 15 yrs old):"
                  type="number-nohandle"
                  id="txtNoChild_15_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.ChildUnder15}
                />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="No. Child (15-25 yrs old):"
                  type="number-nohandle"
                  id="txtNoChild15_25_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.Childfr15to25}
                />
                <div className="col-1"></div>
                <InputField
                  label="No. Child (>25 yrs old):"
                  type="number-nohandle"
                  id="txtNoChild25_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.ChildOver25}
                />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                  label="Home Ownership:"
                  type="select"
                  id="sltHomeOwnership_Update"
                  list={TempData.BooleanData}
                  isDisabled={disableState}
                  defaultValue={TempData.BooleanData[customer_DB_Detail.HomeOwnership?(customer_DB_Detail.HomeOwnership == true ? 1 : 2):0].name}
                />
                <div className="col-1"></div>
                <InputField
                  label="Residence Type:"
                  type="select"
                  id="sltResidenceType_Update"
                  list={ResidenceType}
                  // isDisabled={disableState}
                  defaultValue={ResidenceType[customer_DB_Detail.ResidenceType? customer_DB_Detail.ResidenceType : 0].name}
                />
              </RowComponent>
              {/* row 5 */}
              <RowComponent>
                <InputField
                    label="Employment Status:"
                    type="select"
                    id="sltEmploymentStatus_Update"
                    list={TempData.BooleanData}
                    isDisabled={disableState}
                    defaultValue={TempData.BooleanData[customer_DB_Detail.EmploymentStatus?(customer_DB_Detail.EmploymentStatus == true ? 1 : 2):0].name}
                  />
                <div className="col-1"></div>
                <InputField
                  label="Company's Name:"
                  type="text"
                  id="txtCompanyName_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.CompanyName}
                />
              </RowComponent>
              {/* row 6 */}
              <RowComponent>
              <InputField
                    label="Currency:"
                    type="select"
                    id="sltCurrency_Update"
                    list={Currency_1}
                    isDisabled={disableState}
                    defaultValue={Currency_1[customer_DB_Detail.Currency ? customer_DB_Detail.Currency : 0].name}
                  />
                <div className="col-1"></div>
                <InputField
                  label="Monthly Income:"
                  type="text"
                  id="txtMonthlyIncome_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.MonthlyIncome}
                />
              </RowComponent>
              {/* row 8 */}
              <RowComponent>
                <InputField
                  label="Office Address:"
                  type="text"
                  id="txtOfficeAddress_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.OfficeAddress}
                />
              </RowComponent>
              <hr></hr>
              {/* row 1 */}
              <RowComponent>
                <InputField
                  label="Customer Liability:"
                  type="text"
                  id="txtCustomerLiability_Update"
                  isDisabled={disableState}
                  value={customer_DB_Detail.OfficeAddress}
                />
                <div className="col-1"></div>
                
              </RowComponent>
              
            </FieldsetComponent>
          </TabChangeNonActive>


        </div>
      </div>

       {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <b>Individual Customer Account</b> updating was successful! <br />
          - Name: <b>{document.getElementById('txtGBFullName_Update')?.value}</b> <br />
          - Doc ID: <b>{document.getElementById('txtDocID_Update')?.value}</b> <br/>
          - Customer ID:  <b>{refIDRes}</b> 
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
      )}
    </div>
  )
}
// check 
let arrRequired = []
function checkRequired() {
  arrRequired = []
  let arr = [
    {type: "text",id: "txtGBShortName_Update",name: 'GB Short Name'},
    {type: "text",id: "txtGBFullName_Update",name: 'GB Full Name'},
    {type: "text",id: "txtGBStreet_Update",name: 'GB Street'},
    {type: "text",id: "txtGBTown/Dist_Update",name: 'GB Town/Dist'},
    {type: "select",id: "sltCity/Province_Update",name: 'City/Province'},
    {type: "select",id: "sltDocType_Update",name: 'Doc Type'},
    {type: "text",id: "txtDocID_Update",name: 'Doc ID'},
    {type: "text",id: "txtDocIssuePlace_Update",name: 'Doc Issue Place'},
    {type: "select",id: "sltMainSector_Update",name: 'Main Sector'},
    {type: "select",id: "sltSubSector_Update",name: 'Sub Sector'},
    {type: "select",id: "sltMainIndustry_Update",name: 'Main Industry'},
    {type: "select",id: "sltIndustry_Update",name: 'Industry'},
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  // check dependent value
  let sectorID_ = document.getElementById("sltSubSector_Update")?.value
  let obj = SubSector.find(obj => obj.name === sectorID_)
  if(document.getElementById("sltSubSector_Update")?.value.length != 0 && document.getElementById("sltMainSector_Update")?.value.length != 0 && obj?.sector != getIdFromName(Sector, document.getElementById("sltMainSector_Update")?.value)){
    arrRequired.push("SubSector is not belonged to Sector")
  }
  let industryID_ = document.getElementById("sltIndustry_Update")?.value
  let obj2 = SubIndustry.find(obj => obj.name === industryID_)
  if(document.getElementById("sltIndustry_Update")?.value.length != 0 && document.getElementById("sltMainIndustry_Update")?.value.length != 0 && obj2?.industry != getIdFromName(Industry, document.getElementById("sltMainIndustry_Update")?.value)){
    arrRequired.push("SubIndustry is not belonged to Industry")
  }
  if (arrRequired.length > 0) return true; else return false;
}
function recreateInput() {
  let arr = [
    {type: "text",id: "txtGBShortName_Update",name: 'GB Short Name'},
    {type: "text",id: "txtGBFullName_Update",name: 'GB Full Name'},
    {type: "text",id: "txtGBStreet_Update",name: 'GB Street'},
    {type: "text",id: "txtGBTown/Dist_Update",name: 'GB Town/Dist'},
    {type: "select",id: "sltCity/Province_Update",name: 'City/Province'},
    {type: "select",id: "sltDocType_Update",name: 'Doc Type'},
    {type: "text",id: "txtDocID_Update",name: 'Doc ID'},
    {type: "text",id: "txtDocIssuePlace_Update",name: 'Doc Issue Place'},
    {type: "select",id: "sltMainSector_Update",name: 'Main Sector'},
    {type: "select",id: "sltSubSector_Update",name: 'Sub Sector'},
    {type: "select",id: "sltMainIndustry_Update",name: 'Main Industry'},
    {type: "select",id: "sltIndustry_Update",name: 'Industry'},
  ];
  arr.map((item, index) => {
    const element = document.getElementById(item.id);
    if (element) {
      element.value = '';
  }
  })
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function getNameFromID(list, idTemp) {
  const obj = list.find(obj => obj.id === idTemp);
  return obj ? obj.name : null;
}

const handlePostIndividualCustomer = (event) => {
  event.preventDefault();
  
};

function clearDependentValue(id){
   document.getElementById(id).value = null
}


const styles = {
  buttonAction: {
    borderRight: "solid 1px rgba(0,0,0,.2)",
    fontWeight: "",
    color: "rgb(107,107,107)",
    height: "100%",
    lineHeight: "50px",
  },

};

function convertDateString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
  
    return `${day}/${month}/${year}`;
  }