
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import savingAccountApi from "../../../../apis/savingAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import Currency_1 from "../../../../data/Currency_1";
import Currency_2 from "../../../../data/Currency_2";
import ProductLine from "../../../../data/ProductLine";
import SavingCurrencyAccount from "../../../../data/SavingCurrencyAccount";
import TempData from "../../../../data/TempData";

export default function Close_ArrearPeriodic_Modal(props) {
  // 1. State check required
const [arrRe, setArrRe] = useState([])
const handleSetArrRe = (item) => {setArrRe(item);};
// 2. State show Error
const [showErrorModal, setShowErrorModal] = useState(false);
const [errorMessage, setErrorMessage] = useState("error");
const handleCloseErrorModal = () => setShowErrorModal(false);
const handleShowErrorModal = () => setShowErrorModal(true);
// 3. State show Success
const [showSuccessModal, setShowSuccessModal] = useState(false);
const handleCloseSuccessModal = () => setShowSuccessModal(false);
const handleShowSuccessModal = () => setShowSuccessModal(true);
// 5. State show Backend Error
const [showBEError, setShowBEError] = useState(false);
const handleCloseBEErrorModal = () => setShowBEError(false);
const handleShowBEErrorModal = () => setShowBEError(true);
const [activeTab, setActiveTab] = useState("active");
    // State response
const [OKMessage, setOKMessage] = useState(""); 

    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [disabledState, setDisabledState] = useState(false);
    const [accountStatusDB, setAccountStatusDB] = useState("Active")
    const [closureAccount, setClosureAccount] = useState({})
    //HANDLE BUTTON
    const [commitBtn, setCommitBtn] = useState(true)
    const [authorBtn, setAuthoBtn] = useState(false)
    const [editBtn, setEditBtn] = useState(false)
    const [searchBtn, setSearchBtn] = useState(false)
    const [printBtn, setPrintBtn] = useState(false)

    // ACCOUNT INFO 
    const [savingAccountDB, setAccountDB] = useState(true);
    useEffect(() => {
        async function fetchAccount() {
          try {
            const response = await savingAccountApi.getAccount(props.modalInstanceID)
            const accountDB = response.data.data
            console.log("fetch account")
            console.log(accountDB)
            setAccountDB(accountDB)
            setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
            if(accountDB.savingAccount?.AccountStatus != "Active")
                {
                  setDisabledState(true)
                  setCommitBtn(false)
                  
                  
                }

            
            setIsLoading(false)
          } catch (error) {
    
          }
        }
        fetchAccount();
      }, []);

      // CLOSURE INFO
    useEffect(() => {
        async function fetchClosure() {
          try {
            if(accountStatusDB === "Closed"){
              const response = await savingAccountApi.getClosure(props.modalInstanceID)
              const closureDB = response.data.data
              console.log("fetch closure account")
              console.log(closureDB)
              setClosureAccount(closureDB)
              // FILL DATA
              if(closureDB?.Status === 1){
                setAuthoBtn(true)
              }
              if(response)
              setIsLoading2(false)
            }
            
            // console.log(closureDB?.Status)
            // setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
          
          } catch (error) {
    
          }
        }
        fetchClosure();
      }, [savingAccountDB]);
    
    
    if((!isLoading && !disabledState) || (!isLoading2 && disabledState))
      return (
      <>
            <div
        className="row align-items-center text-center mb-3"
        style={{
            height: "50px",
            backgroundColor: "#eceff4",
            margin: "3px",
            position: 'sticky',
            top: '0',
            zIndex: '10',
            marginTop: '0',
            marginLeft: '0',
            marginRight: '0'
        }}
        >
            <div
            className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ commitBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            onClick={ async (event)=> {
                if(accountStatusDB === 'Active'){
                  let propsID = "close"
                  let params = {
                    customerID: savingAccountDB?.savingAccount?.CustomerID || null,
                    customerName: savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName || "",
                    valueDate: document.getElementById("dtValueDate_Close1_2_"   + propsID).value || null,
                    endDate: document.getElementById("txtMaturityDate_Close1_2_"   + propsID).value || null,
                    originPrincipal: document.getElementById("txtOriginalPrincipal_Close1_2_"   + propsID).value || 0,
                    principal: document.getElementById("txtPrincipal_Close1_2_"   + propsID).value || 0,
                    interestRate: document.getElementById("txtInterestRate_Close1_2_"   + propsID).value || 0,
                    totalAmountLCY: document.getElementById("txtAmountLCY_Close1_3_"   + propsID).value || 0,
                    totalAmountFCY: document.getElementById("txtAmountFCY_Close1_3_"   + propsID).value || 0,
                    narrative: document.getElementById("txtNarrative_Close1_3_"   + propsID).value || 0,
                    dealRate: document.getElementById("txtDealRate_Close1_3_"   + propsID).value || 0,
                    tellerID: document.getElementById("txtForTeller_Close1_3_"   + propsID).value || 0,
                    creditCCY: getIdFromName(Currency_2, document.getElementById('sltCreditCCY_Close1_3_'   + propsID)?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltCreditCCY_Close1_3_'   + propsID)?.value),
                    WorkingAccount: savingAccountDB?.detailAccount?.WorkingAccount,
                    customerBalance: document.getElementById("txtPrincipal_Close1_2_"   + propsID).value || 0,
                  }
    
                  savingAccountApi.closeArrearPeriodic(props.modalInstanceID, params)
                    .then(response => {
                      console.log("response")
                      console.log(response.data.data)
                      setOKMessage("Close Successfully")
                      setShowSuccessModal(true);
                    })
                    .catch(error => {
                      setErrorMessage(error.response.data.message)
                      console.log(errorMessage)
                      // errorMessage = error.Data
                      handleShowBEErrorModal();
                    });
                }
                
                
                // recreateInput();
                
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-save-fill"
                viewBox="0 0 16 16"
                style={commitBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
            >
                <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
            </svg>
            <div
                style={{
                display: "inline",
                }}
            >
                {" "}
                Commit Data{" "}
            </div>
            </div>
            <div
            className={authorBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ authorBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            onClick={async () => {
                if(authorBtn){
                    console.log("Authorize")
                    let params = {
                        status: getIdFromName(TempData.Status, document.getElementById("sltAuthoStatus" + props.id).value || 1)
                    }
                    await savingAccountApi.validateClose(closureAccount?.id, params)
                    .then(response => {
                        setOKMessage("Authorize Successfully")
                        setShowSuccessModal(true);
                    })
                    .catch(error => {
                        setErrorMessage(error.response.data.message)
                        console.log(errorMessage)
                        // errorMessage = error.Data
                        handleShowBEErrorModal();
                    });
                }
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
                style={authorBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
            >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            Authorize
            </div>
            <div
            className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ searchBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
                style={searchBtn && {
                color: "rgba(107,107,107,.9)",
                marginRight: "8px",
                } || {
                    color: "rgba(107,107,107,.2)",
                    marginRight: "8px",
                }}
            >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            Search
            </div>
            <div
            className={printBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ printBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-printer-fill"
                viewBox="0 0 16 16"
                style={printBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            Print Deal Slip
            </div>

            <div
            className={editBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
            style={ editBtn && {
                borderRight: "solid 1px rgba(0,0,0,.2)",
                fontWeight: "",
                color: "rgba(107,107,107, 1)",
                height: "100%",
                lineHeight: "50px",
            } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                    fontWeight: "",
                    color: "rgba(107,107,107, 0.2)",
                    height: "100%",
                    lineHeight: "50px",}}
            onClick={() => {
                props.setDisabledState(false)
            }}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-pencil-fill"
                viewBox="0 0 16 16"
                style={editBtn && {
                  color: "rgba(107,107,107,.9)",
                  marginRight: "8px",
                  } || {
                      color: "rgba(107,107,107,.2)",
                      marginRight: "8px",
                  }}
            >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>
            Edit data
            </div>
        </div>
      <nav>
      <div
    className="row noCopy"
    style={{
      margin: "20px 5px",
    }}
  >
      </div>

      <div>
        <ul className="nav nav-pills">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "active" ? "active" : ""}`}
                aria-current="page"
                href="#"
                onClick={() => setActiveTab("active")}
                style={{
                  backgroundColor: activeTab === "active" ? "#d71921" : "",
                }}
              >
                Close Arrear/Periodic Saving Account
              </a>
              
              
            </li>
          </ul>
      </div>
      <hr />
      <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
        <a
          className="nav-item nav-link active"
          id="nav-customerInfo-tab"
          data-toggle="tab"
          href="#customer-info"
          role="tab"
          aria-controls="customer-info"
          aria-selected="true"
        >
          Pre Close
        </a>
        <a
          className="nav-item nav-link"
          id="nav-1-2-tab"
          data-toggle="tab"
          href="#tab1-2"
          role="tab"
          aria-controls="nav-tab1.2"
          aria-selected="false"
        >
          Pre close View
        </a>
        <a
          className="nav-item nav-link"
          id="nav-1-3-tab"
          data-toggle="tab"
          href="#tab1-3"
          role="tab"
          aria-controls="nav-tab1.3"
          aria-selected="false"
        >
          Withdrawal - Term Savings
        </a>
      </div>
    </nav>

    <FieldsetComponent label="">
          <b>Account Status: 
            <span
            style={accountStatusDB === "Active" &&{
               color: "Green"
            }|| {
              color: "Red"
            }}>  {accountStatusDB}</span> </b>
    </FieldsetComponent>
   {disabledState && !isLoading2 && <FieldsetComponent>
       <RowComponent>
          <InputField
              label="Closing Status:"
              type="select"
              id={"sltAuthoStatus" + props.id}
              list={TempData.Status.slice(1, 3)}
              isDisabled={closureAccount?.Status === 1 ? false : true}
              defaultValue={getNameFromId(TempData.Status, closureAccount?.Status)}
              // defaultValue={closureAccount?.Status}
          />
      </RowComponent>
    </FieldsetComponent>}
    <div className="tab-content" id="nav-tabContent">
      <div
        className="tab-pane fade show active"
        id="customer-info"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
       
        {/* Fieldset 1 */}
        <FieldsetComponent label="Customer Information">
          {/* row 1 */}
          <RowComponent>
            <InputField label="Customer:" type="text" id={`txtCustomer_Close1_1_` + props.id} isDisabled={true}
              value={savingAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName} />
            <div className="col-1"></div>
            <InputField label="Category:" type="text" id={"txtCategory_Close1_1_"  + props.id} isDisabled={true}
              value={getNameFromId(Category, savingAccountDB?.detailAccount?.Category)} />
          </RowComponent>
          {/* row 2 */}
          <RowComponent>
            <InputField label="Currency:" type="text" id={"txtCurrency_Close1_1_" + props.id} isDisabled={true}
              value={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)} />
            <div className="col-1"></div>
            <InputField label="Product Code:" type="text" id={"txtProductCode_Close1_1_" + props.id} isDisabled={true}
              value={"100"} />
          </RowComponent>
          {/* row 3 */}
          <RowComponent>
            <InputField
              label="Principal:"
              type="text"
              id={"txtPrincipal_Close1_1_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.PrincipalAmount}
            />
            <div className="col-1"></div>
            <InputField
              label="Maturity Date:"
              type="text"
              id={"txtMaturityDate_Close1_1_" + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.MaturityDate}
            />
          </RowComponent>
          {/* row 4 */}
          <RowComponent>
            <InputField
              label="Interested Rate:"
              type="text"
              id={"txtInterestedRate_Close1_1_" + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.InterestRate}
            />
            <div className="col-1"></div>
          </RowComponent>
        </FieldsetComponent>
        <FieldsetComponent label="Pre Close">
          <RowComponent>
            <InputField
              label="Preclose :"
              type="select"
              id={"sltPreClose_Close1_1_"  + props.id}
              isDisabled={true}
              list={TempData.BooleanData}
              defaultValue={"Yes"}
            />
            <div className="col-1"></div>
            <InputField
              label="Working Account :"
              type="text"
              id={"txtWorkingAccount_Close1_1_" + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.WorkingAccountRefID}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Waive Charges:"
              type="select"
              id={"sltWaivesChargeClose1_1_" + props.id}
              isDisabled={true}
              list={TempData.BooleanData}
              defaultValue={"Yes"}
            />
            <div className="col-1"></div>
          </RowComponent>
        </FieldsetComponent>
      </div>
      <div
        className="tab-pane fade "
        id="tab1-2"
        role="tabpanel"
        aria-labelledby="nav-1-2-tab"
      >
        <FieldsetComponent label="">
          <RowComponent>
            <InputField
              label="Customer:"
              type="text"
              id={"txtCustomer_Close1_2_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " + savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
            />
            <div className="col-1"></div>
            <InputField
              label="Category:"
              type="text"
              id={"txtCategory_Close1_2_"  + props.id}
              isDisabled={true}
              value={getNameFromId(Category, savingAccountDB?.detailAccount?.Category)} />
  
          </RowComponent>
          <RowComponent>
            <InputField
              label="Currency:"
              type="text"
              id={"txtCurrency_Close1_2_"  + props.id}
              isDisabled={true}
              value={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)} />
  
            <div className="col-1"></div>
            <InputField
              label="Product Code:"
              type="text"
              id={"txtProductCode_Close1_2_"  + props.id}
              isDisabled={true}
              value={"100"}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Open Date:"
              type="text"
              id={"txtOpenDate_Close1_2_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.ValueDate}
            />
            <div className="col-1"></div>
            <InputField
              label="Maturity Date:"
              type="text"
              id={"txtMaturityDate_Close1_2_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.MaturityDate}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Principal:"
              type="text"
              id={"txtPrincipal_Close1_2_"  + props.id}
              isDisabled={true}
              value={parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
            />
            <div className="col-1"></div>
            <InputField
              label="Original Principal:"
              type="text"
              id={"txtOriginalPrincipal_Close1_2_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.PrincipalAmount}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Interest Rate:"
              type="text"
              id={"txtInterestRate_Close1_2_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.InterestRate}
            />
            <div className="col-1"></div>
            <InputField
              label="Value Date:"
              type="date"
              id={"dtValueDate_Close1_2_"  + props.id}
              isDisabled={disabledState}
              value={closureAccount?.ValueDate}
            />
          </RowComponent>
  
          <hr />
          <RowComponent>
            <DataTable columnsTemp={[
              {
                label: "Start Date",
                field: "StartDate",
                width: 150
              },
              {
                label: "End Date",
                field: "EndDate",
                width: 150
              },
              {
                label: "Interest Rate",
                field: "InterestRate",
                width: 150
              },
              {
                label: "Interest Amount",
                field: "InterestAmount",
                width: 150
              }
            ]} rowsTemp={[{
              StartDate: savingAccountDB?.detailAccount?.ValueDate,
              EndDate: savingAccountDB?.detailAccount?.MaturityDate,
              InterestRate: "0.1",
              InterestAmount: Math.round(amountCal(savingAccountDB?.detailAccount?.PrincipalAmount, savingAccountDB?.detailAccount?.InterestRate) * 100) / 100
            }]}
              id="EnquiryTable_1">
  
            </DataTable>
          </RowComponent>
          <hr />
  
          <RowComponent>
            <InputField
              label="Total In Amt:"
              type="text"
              id={"txtTotalInAmt_Close1_2_"  + props.id}
              isDisabled={true}
              value={Math.round(amountCal(savingAccountDB?.detailAccount?.PrincipalAmount,savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
            />
            <div className="col-1"></div>
            <InputField
              label="Next Teller Tran:"
              type="text"
              id={"txtNextTellerTran_Close1_2_"  + props.id}
              isDisabled={disabledState}
              // value={closureAccount?.ValueDate}
            />
          </RowComponent>
        </FieldsetComponent>
      </div>
      <div
        className="tab-pane fade"
        id="tab1-3"
        role="tabpanel"
        aria-labelledby="nav-1-3-tab"
      >
        <FieldsetComponent label="">
          <RowComponent>
            <InputField
              label="Payment No:"
              type="text"
              id={"txtPaymentNo_Close1_3_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.detailAccount?.PaymentNo}
            />
            <div className="col-1"></div>
            <InputField
              label="Currency:"
              type="text"
              id={"txtCurrency_Close1_3_" +  + props.id}
              isDisabled={true}
              value={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Customer:"
              type="text"
              id={"txtCustomer_Close1_3_"  + props.id}
              isDisabled={true}
              value={savingAccountDB?.savingAccount?.CUSTOMER?.RefID + " - " +savingAccountDB?.savingAccount?.CUSTOMER?.GB_FullName}
            />
            <div className="col-1"></div>
            <InputField
              label="Dr Account:"
              type="text"
              id={"txtDrAccount_Close1_3_" +  + props.id}
              isDisabled={disabledState}
              value={savingAccountDB?.detailAccount?.WorkingAccountRefID}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Amount LCY:"
              type="number-nohandle"
              id={"txtAmountLCY_Close1_3_"  + props.id}
              value={savingAccountDB?.detailAccount?.Currency === 12 ? parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100 : null}
              isDisabled={disabledState}
            />
            <div className="col-1"></div>
            <InputField
              label="Amount FCY:"
              type="number-nohandle"
              id={"txtAmountFCY_Close1_3_"  + props.id}
              value={savingAccountDB?.detailAccount?.Currency !== 12 ? parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100 : null}
              isDisabled={disabledState}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Narrative:"
              type="text"
              id={"txtNarrative_Close1_3_"  + props.id}
              isDisabled={disabledState}
              value={closureAccount?.Narrative}
            />
            <div className="col-1"></div>
  
          </RowComponent>
          <RowComponent>
            <InputField
              label="New Customer Balance:"
              type="text"
              id={"txtNewCustomerBalance_Close1_3_" + props.id}
              isDisabled={true}
              value={"-" + parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
            />
            <div className="col-1"></div>
            <InputField
              label="Amount Paid to Customer:"
              type="text"
              id={"txtAmountPaidCustomer_Close1_3_"  + props.id}
              isDisabled={true}
              value={parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount) + Math.round(amountCal(parseFloat(savingAccountDB?.detailAccount?.PrincipalAmount), savingAccountDB?.detailAccount?.InterestRate) * 100) / 100}
            />
          </RowComponent>
  
        </FieldsetComponent>
        <FieldsetComponent label="">
          <RowComponent>
            <InputField
              label="Deal Rate:"
              type="number-nohandle"
              id={"txtDealRate_Close1_3_"  + props.id}
              isDisabled={disabledState}
              value={closureAccount.DealRate}
            />
            <div className="col-1"></div>
  
          </RowComponent>
        </FieldsetComponent>
        <FieldsetComponent label="">
          <RowComponent>
            <InputField
              label="For Teller:"
              type="text"
              id={"txtForTeller_Close1_3_"  + props.id}
              isDisabled={disabledState}
              value={closureAccount?.TellerID}
            />
            <div className="col-1">{savingAccountDB?.detailAccount?.Teller || ""}</div>
            <InputField
              label="Credit CCY:"
              type="select"
              id={"sltCreditCCY_Close1_3_"  + props.id}
              list={Currency_2}
              defaultValue={getNameFromId(Currency_2, savingAccountDB?.detailAccount?.Currency)}
              isDisabled={disabledState}
            />
          </RowComponent>
          <RowComponent>
            <InputField
              label="Credit Account:"
              type="select"
              id={"sltCreditAccount_Close1_3_"  + props.id}
              list={SavingCurrencyAccount}
              isDisabled={disabledState}
            />
            <div className="col-1"></div>
            <InputField
              label="Print Ln No of:"
              type="text"
              id={"txtPrintNo_Close1_3_"  + props.id}
              isDisabled={disabledState}
            />
          </RowComponent>
        </FieldsetComponent>
      </div>
    </div>
    {/* --------------------------------------- */}
  {/* <Backend Error Model /> */}
  <div>
    <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
              <b>{errorMessage}</b>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseBEErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* <ErrorModal /> */}
  <div>
    <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {checkRequired(props.id)}
        {arrRe.map((item, index) => {
          return <>
            <div>- {item}</div></>})}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseErrorModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  {/* --------------------------------------- */}
  {/* Success Modal  */}
  <div>
    <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {OKMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
    </>
    )
  }
  
  function checkRequired(){}
  
  function getIdFromName(list, nameTemp) {
      const obj = list.find(obj => obj.name === nameTemp);
      return obj ? obj.id : null;
    }
    
    function getNameFromId(list, id) {
      const obj = list.find(obj => obj.id === id);
      return obj ? obj.name : null;
    }
    
    
    function currencyFormat(value, currency) {
      let resultValue = value ? value.toLocaleString('it-IT', { style: 'currency', currency: currency }) : "";
      console.log(resultValue.slice(0, -4))
      return resultValue.slice(0, -4)
    }
    
    
    function amountCal(origin, rate) {
      console.log(origin)
      console.log(rate)
      return origin * 0.0004316238 * rate
    }