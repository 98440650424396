import React, { useState ,useEffect} from 'react'
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import CloseSystemCurrency from '../../../../data/CloseSystemCurrency';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import SystemCurrency from '../../../../data/SystemAccount';
import TempData from '../../../../data/TempData';
export default function CashDeposit(props) {
    //STATE HANDLE
    const [currencyState, setCurrencyState] = useState(0);
    const [amountState, setAmountState] = useState(0);
    const [dealRateState, setDealRateState] = useState(0);
    const [activeTab, setActiveTab] = useState("active");
    //AMOUNT STATE HANDLE
    useEffect(() => {
        let dealRateTemp = 1
        if(currencyState !== "VND"){
          dealRateTemp = parseInt(document.getElementById(`txtDealRate_`+props.id).value) || dealRateState
        }else{
          dealRateTemp = 1
          setDealRateState(1)
        }
        let custBal = parseInt(document.getElementById(`txtCustBal_`+props.id).value.replaceAll(".", ""))
        document.getElementById(`txtNewBal_`+props.id).value= currencyFormat(parseInt(amountState) * parseInt(dealRateTemp) + custBal, "VND") || 0
        document.getElementById(`txtAmtPaid_`+props.id).value= currencyFormat(parseInt(amountState) * parseInt(dealRateTemp), "VND") || 0
      
  }, [amountState, currencyState, dealRateState]);
  useEffect(() => {
      document.getElementById(`sltCashAccount_`+props.id).value = null
  }, [currencyState]);


    // GET ACCOUNT
    const [accountsDB, setAccountsDB] = useState([])
    useEffect(() => {
    async function fetchAccounts() {
    try {
      const response = await mainAccountApi.enquiry({})
      setAccountsDB(response.data.data)
    } catch (error) {
    }
    }
    fetchAccounts();
    }, []);

    // ACCOUNT STATE
    const [accountState, setAccountState] = useState("") // customerID



    useEffect(() => {
        if(accountsDB){
          let count = 0
          accountsDB.map((value, index) => {
                if(value.Account === accountState){
                    count = 1
                    props.setAccountState(value)
                    console.log(value)
                    document.getElementById('txtCurrency_'+ props.id).value = value.CURRENCY.Name
                    document.getElementById('txtAccount_'+ props.id).value = "TKTT" + " - " + value.CURRENCY.Name + " - " +  value.Customer.GB_FullName
                    document.getElementById('txtCustomerID_'+ props.id).value = value.Customer.RefID + "   " + value.Customer.GB_FullName
                    document.getElementById('txtCustBal_'+ props.id).value = currencyFormat(value.WorkingAmount, "VND")
                    // setCurrencyState(value.CURRENCY.Name)
                }
            })
          if(count == 0){
            document.getElementById('txtCurrency_'+ props.id).value = null
            document.getElementById('txtAccount_'+ props.id).value = null
            document.getElementById('txtCustomerID_'+ props.id).value = null
            document.getElementById('txtCustBal_'+ props.id).value = null
          }
        }
    }, [accountState]);


  return (
    <div>

      <hr></hr>

      <div>
        <nav>
          <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Cash Deposits Infomation
            </a>
          </div>
        </nav>


        <div className="tab-content" id="nav-tabContent">
            <div
                className="tab-pane fade show active"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
             {/* Fieldset 1 */}
            <FieldsetComponent label="Account Information">
              {/* row 1 */}
              <RowComponent>
                    <InputField
                        label="Account Type:"
                        type="select"
                        id={`sltAccountType_`+props.id}
                        list={AccountType}
                        required={true}
                        defaultValue={"Current & Non-Term Saving Account"}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Customer Account:"
                        type="text-handler"
                        // id="txtCustomerAccount"
                        id={`txtCustomerAccount_`+props.id}
                        required={true}
                        state={accountState}
                        setState={setAccountState}
                    />
              </RowComponent>
            </FieldsetComponent>
            <FieldsetComponent label="">
              {/* row 2 */}
              <RowComponent>
                  <InputField
                      label="Customer ID:"
                      type="text"
                      // id="txtCustomerID"
                      id={`txtCustomerID_`+props.id}
                      isDisabled={true}
                  />
                  <div className="col-1"></div>
                  <InputField
                        label="Customer Account:"
                        type="text"
                        // id="txtCustomerAccount"
                        id={`txtAccount_`+props.id}
                        isDisabled={true}
                    />
                </RowComponent>
              <hr></hr>
                {/* row 3 */}
                <RowComponent>
                    <InputField
                      label="Currency:"
                      type="text"
                      // id="txtCurrency"
                      id={`txtCurrency_`+props.id}
                      isDisabled={true}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Cust Bal:"
                        type="text"
                        // id="txtCustBal"
                        id={`txtCustBal_`+props.id}
                        isDisabled={true}
                        value={0}
                    />
              </RowComponent>
              <RowComponent>
                    <InputField
                        label="Amt Paid to Cust:"
                        type="text"
                        // id="txtAmtPaid"
                        id={`txtAmtPaid_`+props.id}
                        isDisabled={true}
                        value={0}
                    />
                    <div className="col-1"></div>
                    
                    <InputField
                        label="New Cust Bal:"
                        type="text"
                        // id="txtNewBal"
                        id={`txtNewBal_`+props.id}
                        isDisabled={true}
                        value={0}
                    />
              </RowComponent>
            </FieldsetComponent>

             {/* Fieldset 2 */}
             <FieldsetComponent label="Deposits Information">
                {/* row 1 */}
                <RowComponent>
                    <InputField
                        label="Currency Deposited:"
                        type="select-depend"
                        // id="sltCurrencyDeposited"
                        id={`sltCurrencyDeposited_`+props.id}
                        state={currencyState}
                        setState={setCurrencyState}
                        list={Currency_1}
                        required={true}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Cash Account:"
                        type="select"
                        // id="sltCashAccount"
                        id={`sltCashAccount_`+props.id}
                        list={CloseSystemCurrency.filter((option) => {
                          let value = getIdFromName(Currency_1, currencyState)
                          return option.currency == value
                        })}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label="Amount Deposited:"
                        type="text-handler"
                        // id="txtAmountDeposited"
                        id={`txtAmountDeposited_`+props.id}
                        state={amountState}
                        setState={setAmountState}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Deal Rate:"
                        type="text-handler"
                        // id="txtDealRate"
                        id={`txtDealRate_`+props.id}
                        state={dealRateState}
                        setState={setDealRateState}
                    />
              </RowComponent>
             </FieldsetComponent>
             {/* Fieldset 3 */}
             <FieldsetComponent label="">
                {/* row 1 */}
              <RowComponent>
                    <InputField
                        label="Teller ID:"
                        type="text"
                        // id="txtTellerID"
                        id={`txtTellerID_`+props.id}
                        required={true}
                        value="vietvictory"
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Narrative:"
                        type="text"
                        // id="txtNarrative"
                        id={`txtNarrative_`+props.id}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label=" Waive Charges?"
                        type="select"
                        // id="sltWaiveCharges"
                        id={`sltWaiveCharges_`+props.id}
                        list={TempData.BooleanData}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Print LnNo of PS:"
                        type="text"
                        // id="txtPrint"
                        id={`txtPrint_`+props.id}
                    />
              </RowComponent>
             </FieldsetComponent>
            </div>
        </div>
    </div>
    </div>
  )
}

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}