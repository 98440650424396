import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import mainAccountApi from '../../../../apis/mainAccountApi';
import transaction_maApi from '../../../../apis/transaction_maApi';
import AccountType from '../../../../data/AccountType';
import ChargeCategory from '../../../../data/ChargeCategory';
import Currency_1 from '../../../../data/Currency_1';
import TempData from '../../../../data/TempData';
import InfoIcon from '../../../../public/icon/InfoIcon';
import CashDeposit from './CashDeposit';
import CashDeposit_Modal from './CashDeposit_Modal';
import CashTransfer from './CashTransfer';
import CashWithdrawal from './CashWithdrawal';
import CashWithdrawal_Modal from './CashWithdrawal_Modal';
import Enquiry_CI from './Enquiry_CI';
import TransferWithdrawal_Modal from './TransferWithdrawal_Modal';

export default function CITransaction() {

  const [rowState, setRowState] = useState([]);
    const handleSetRowState = (row) => {
      setRowState(row);
    };
  // 1. State check required
  const [arrRe, setArrRe] = useState([])
  const handleSetArrRe = (item) => {setArrRe(item);};
  // 2. State show Error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("error");
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  // 3. State show Success
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  // 5. State show Backend Error
  const [showBEError, setShowBEError] = useState(false);
  const handleCloseBEErrorModal = () => setShowBEError(false);
  const handleShowBEErrorModal = () => setShowBEError(true);
  const [okMessage, setOkMessage] = useState(""); 
  const [refIDRes, setRefIDRes] = useState(""); 
  // MODAL STATE
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalDisabled, setmodalDisabled] = useState(true);
  // 5. key
  const [key, setKey] = useState(0);
  const resetComponent = () => {
      setKey(prevKey => prevKey + 1);
  };
  const [modalInstanceID, setModalInstanceID] = useState(null);
  const handleSetModel = (item) => {
    setModalInstanceID(item);
  };
    
  //HANDLE BUTTON
  const [commitBtn, setCommitBtn] = useState(true)
  const [authorBtn, setAuthoBtn] = useState(false)
  const [editBtn, setEditBtn] = useState(false)
  const [searchBtn, setSearchBtn] = useState(false)
  const [printBtn, setPrintBtn] = useState(false)
  const [activeTab, setActiveTab] = useState("active");

  // GET ACCOUNT
  const [selectedAccount, setSelectedAccount] = useState({})
  const [selectedCreditAccount, setSelectedCreditAccount] = useState({})

  return (
    <div>
      <div
        className="row align-items-center text-center mb-3"
        style={{
          height: "50px",
          backgroundColor: "#eceff4",
          margin: "3px",
          position: 'sticky',
          top: '48.2px',
          zIndex: '10',
          marginTop: '0',
          marginLeft: '0',
          marginRight: '0'
        }}
      >
        <div
          className={commitBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
          style={ commitBtn && {
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 1)",
              height: "100%",
              lineHeight: "50px",
          } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",}}

          onClick={() => {
            if(activeTab === "active"){
              console.log("activeTab")
              console.log(activeTab)

              checkRequired(activeTab, "open");
              if (arrRequired.length > 0) {
                setShowErrorModal(true);
                setArrRe(arrRequired);
              } else {
                let id = "open"
                const params = {    
                    accountType: getIdFromName(AccountType, document.getElementById(`sltAccountType_`+ id)?.value) == 0 ? null : getIdFromName(AccountType, document.getElementById(`sltAccountType_`+ id)?.value),
                    account: selectedAccount.id,
                    amount: document.getElementById(`txtAmountDeposited_`+ id).value || 0,
                    dealRate: document.getElementById(`txtDealRate_`+ id).value || 0,
                    waiveCharges: getIdFromName(TempData.BooleanData, document.getElementById(`sltWaiveCharges_`+ id)?.value) == 0 ? null : getIdFromName(TempData.BooleanData, document.getElementById(`sltWaiveCharges_`+ id)?.value),
                    currencyDeposited: getIdFromName(Currency_1, document.getElementById(`sltCurrencyDeposited_`+ id)?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById(`sltCurrencyDeposited_`+ id)?.value),
                    narrative: document.getElementById(`txtNarrative_`+ id).value || null,
                    tellerID: document.getElementById(`txtTellerID_`+ id).value || null,
                    cashAccount:  document.getElementById(`sltCashAccount_`+ id).value || null
                }


                transaction_maApi.deposit(params)
                .then(response => {
                  console.log('response')
                  console.log(response)
                  setRefIDRes(response.data.data.RefID)
                  setOkMessage("Deposit Transaction was successful.")
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log(errorMessage)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });
              }



            }else if(activeTab === "link1"){
              console.log("activeTab")
              console.log(activeTab)
              checkRequired(activeTab, "open", selectedAccount.WorkingAmount);
              if (arrRequired.length > 0) {
                setShowErrorModal(true);
                setArrRe(arrRequired);
              } else {
                let id = "open"
                const params = {   
                    accountType:  getIdFromName(AccountType, document.getElementById(`sltAccountType_`+ id)?.value) == 0 ? null : getIdFromName(AccountType, document.getElementById(`sltAccountType_`+ id)?.value),
                    account:  selectedAccount.id,
                    amount: document.getElementById(`txtAmount_`+ id).value || 0,
                    narrative: document.getElementById(`txtNarrative_`+ id).value || null,
                    tellerID: document.getElementById(`txtTellerID_`+ id).value || null,
                    cashAccount:document.getElementById(`sltCashAccount_`+ id).value || null,
                    dealRate: document.getElementById(`txtDealRate_`+ id).value || 0,
                    waiveCharges: getIdFromName(TempData.BooleanData, document.getElementById(`sltWaiveCharges_`+ id)?.value) == 0 ? null : getIdFromName(TempData.BooleanData, document.getElementById(`sltWaiveCharges_`+ id)?.value),
                    print: document.getElementById(`txtPrint_`+ id).value || null,
              
              }

              
                transaction_maApi.withdrawal(params)
                .then(response => {
                  console.log('response')
                  console.log(response)
                  setRefIDRes(response.data.data.RefID)
                  setOkMessage("Withdrawal Transaction was successful.")
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log(errorMessage)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });
              }

            }else if(activeTab === "link2"){
              console.log("activeTab")
              console.log(activeTab)

              checkRequired(activeTab, "open", selectedAccount.WorkingAmount);
              if (arrRequired.length > 0) {
                setShowErrorModal(true);
                setArrRe(arrRequired);
              } else {
                let id = "open"
                const params = {  
                    accountType: getIdFromName(AccountType, document.getElementById(`sltAccountType_`+ id)?.value) == 0 ? null : getIdFromName(AccountType, document.getElementById(`sltAccountType_`+ id)?.value),
                    debitAccount: selectedAccount.id,
                    transferAmount: document.getElementById(`txtDebitAmt_`+ id).value || 0,
                    paidAmount: document.getElementById(`txtAmtCredit_`+ id).value.replaceAll(".", "") || 0,
                    newAmount: document.getElementById(`txtNewBal_`+ id).value.replaceAll(".", "") || 0,
                    creditAccount: selectedCreditAccount.id,
                    dealRate: document.getElementById(`txtDealRate_`+ id).value || 0,
                    valueDate: document.getElementById(`dtCreditValueDate_`+ id).value || null,
                    waiveCharges: getIdFromName(TempData.BooleanData, document.getElementById(`sltWaiveCharges_`+ id)?.value) == 0 ? null : getIdFromName(TempData.BooleanData, document.getElementById(`sltWaiveCharges_`+ id)?.value),
                    narrative: document.getElementById(`txtNarrative_`+ id).value || null,
                    creditAccountRefID: document.getElementById(`txtCreditAccount_`+ id).value || null,
                    creditCustomer: document.getElementById(`txtCreditCustomer_`+ id).value || null,
                    creditCurrency: document.getElementById(`txtCrebitCurrency_`+ id).value || null,
              }

              
                transaction_maApi.transfer(params)
                .then(response => {
                  console.log('response')
                  console.log(response)
                  setRefIDRes(response.data.data.RefID)
                  setOkMessage("Transfer Transaction was successful.")
                  setShowSuccessModal(true);
                })
                .catch(error => {
                  setErrorMessage(error.response.data.message)
                  console.log(errorMessage)
                  // errorMessage = error.Data
                  handleShowBEErrorModal();
                });
              }

              
            }else {

            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-save-fill"
            viewBox="0 0 16 16"
            style={commitBtn && {
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
              } || {
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
              }}
          >
            <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
          </svg>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className="col-2 noCopy"
          style={{
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 0.2)",
              height: "100%",
              lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
              }}
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          Authorize
        </div>
        <div
          className={searchBtn == true ? "col-2 buttonAction noCopy" : "col-2 noCopy"}
          style={ searchBtn && {
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 1)",
              height: "100%",
              lineHeight: "50px",
          } || {borderRight: "solid 1px rgba(0,0,0,.2)",
                  fontWeight: "",
                  color: "rgba(107,107,107, 0.2)",
                  height: "100%",
                  lineHeight: "50px",}}
          onClick={async () => {

              const params = {  
                  transactionType: getIdFromName(TempData.TransactionType, document.getElementById(`sltTransactionType`)?.value) == 0 ? null : getIdFromName(TempData.TransactionType, document.getElementById(`sltTransactionType`)?.value),
                  refID: document.getElementById(`txtRefID`).value || null,
                  customerType: getIdFromName(TypeCustomer, document.getElementById(`sltCustomerType`)?.value) == 0 ? null : getIdFromName(TypeCustomer, document.getElementById(`sltCustomerType`)?.value),
                  GB_FullName: document.getElementById(`txtGBFullName`).value || null,
                  amountFr: document.getElementById(`txtAmountFrom`).value || null,
                  amountTo: document.getElementById(`txtAmountTo`).value || null,
                  accountType: getIdFromName(AccountType, document.getElementById(`sltAccountType`)?.value) == 0 ? null : getIdFromName(AccountType, document.getElementById(`sltAccountType`)?.value),
                  currency:  getIdFromName(Currency_1, document.getElementById(`sltCurrency`)?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById(`sltCurrency`)?.value),
                  customerID:  document.getElementById(`txtCustomerID`).value || null,
                  customerAccount: document.getElementById(`txtCustomerAccount`).value || null,
                  date: document.getElementById(`dtDate`).value || null,
                }

            
              transaction_maApi.enquiry(params)
              .then(response => {
                console.log('response')
                console.log(response.data.data)

                let tempRow = []
                let tempObject = {}
                let type =getIdFromName(TempData.TransactionType, document.getElementById(`sltTransactionType`)?.value)
                if(type === 1){
                  response.data.data.map((item, index) => {

                      tempObject = {
                              AccountType: "Non Term Saving Account",
                              RefID: item.Transaction?.RefID,
                              AccountCode: item.Account?.Account,
                              CustomerName:  item.Account?.Customer?.GB_FullName,
                              Currency: getNameFromId(Currency_1, item.Transaction?.CurrencyDeposited) || "",
                              TransactionAmount: currencyFormat(item.Transaction?.DepositAmount, "VND"),
                              Status: getNameFromId(TempData.Status, item.Transaction?.Status),
                              Button: <div><button 
                              onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item?.Transaction?.RefID)
                                  setModalType("Deposit")
                                  setModalTitle("Cash Deposit - Information - RefID: " + item?.Transaction?.RefID)
                                }}
                                type="button" className="btn" 
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                              </div>
                            }
                          tempRow.push(tempObject)
                    })
                    handleSetRowState(tempRow)  
                } else if(type === 2){
                  response.data.data.map((item, index) => {
                  
                    tempObject = {
                            AccountType: "Non Term Saving Account",
                            RefID: item.Transaction?.RefID,
                            AccountCode: item.Account?.Account,
                            CustomerName:  item.Account?.Customer?.GB_FullName,
                            Currency: getNameFromId(Currency_1, item.Transaction?.Currency) || "",
                            TransactionAmount: currencyFormat(item.Transaction?.WithdrawalAmount, "VND"),
                            Status: getNameFromId(TempData.Status, item.Transaction?.Status),
                            Button: <div><button 
                            onClick={()=> {
                                resetComponent()
                                handleSetModel(item?.Transaction?.RefID)
                                setModalType("Withdrawal")
                                setModalTitle("Cash Withdrawal - Information - RefID: " + item?.Transaction?.RefID)
                              }}
                              type="button" className="btn" 
                              style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                            </div>
                          }
                        tempRow.push(tempObject)
                  })
                  handleSetRowState(tempRow)  
                }  else if(type === 3){
                  response.data.data.map((item, index) => {
                  
                    tempObject = {
                            AccountType: "Non Term Saving Account",
                            RefID: item.Transaction?.RefID,
                            AccountCode: item.Account?.Account,
                            CustomerName:  item.Account?.Customer?.GB_FullName,
                            Currency: getNameFromId(Currency_1, item.Account?.Currency) || "",
                            TransactionAmount: currencyFormat(item.Transaction?.TransferAmount, "VND"),
                            Status: getNameFromId(TempData.Status, item.Transaction?.Status),
                            Button: <div><button 
                            onClick={()=> {
                                resetComponent()
                                handleSetModel(item?.Transaction?.RefID)
                                setModalType("Transfer")
                                setModalTitle("Transfer Withdrawal - Information - RefID: " + item?.Transaction?.RefID)
                              }}
                              type="button" className="btn" 
                              style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                            </div>
                          }
                        tempRow.push(tempObject)
                  })
                  handleSetRowState(tempRow)  
                }
              })
              .catch(error => {
                console.log(error)
              });
            
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
            style={searchBtn && {
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
              } || {
                  color: "rgba(107,107,107,.2)",
                  marginRight: "8px",
              }}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          Search
        </div>
        <div
          className="col-2 noCopy"
          style={{
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 0.2)",
              height: "100%",
              lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
              }}
          >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg>
          Print Deal Slip
        </div>

        <div
          className="col-2 noCopy"
          style={{
              borderRight: "solid 1px rgba(0,0,0,.2)",
              fontWeight: "",
              color: "rgba(107,107,107, 0.2)",
              height: "100%",
              lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => {
                setActiveTab("active")
                setCommitBtn(true)
                setSearchBtn(false)
              }}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Cash Deposits
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "link1" ? "active" : ""}`}
              href="#"
              onClick={() => {
                setActiveTab("link1")
                setCommitBtn(true)
                setSearchBtn(false)
              }}
              style={{
                backgroundColor: activeTab === "link1" ? "#d71921" : "",
              }}
            >
              Cash Withdrawal
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "link2" ? "active" : ""}`}
              href="#"
              onClick={() => {
                setActiveTab("link2")
                setCommitBtn(true)
                setSearchBtn(false)
              }}
              style={{
                backgroundColor: activeTab === "link2" ? "#d71921" : "",
              }}
            >
              Transfer Withdrawal
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "link3" ? "active" : ""}`}
              href="#"
              onClick={() => {
                setActiveTab("link3")
                setSearchBtn(true)
                setCommitBtn(false)
              }}
              style={{
                backgroundColor: activeTab === "link3" ? "#d71921" : "",
              }}
            >
              Enquiry
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>
      <form>
        {activeTab === "active" && (
          <div>
              <CashDeposit id="open" accountState={selectedAccount} setAccountState={setSelectedAccount}/>
          </div>
        )}
        {activeTab === "link1" && (
          <div>
              <CashWithdrawal id="open" accountState={selectedAccount} setAccountState={setSelectedAccount}/>
          </div>
        )}
        {activeTab === "link2" && (
          <div>
              <CashTransfer id="open"  accountState={selectedAccount} setAccountState={setSelectedAccount}
              creditAccountState={selectedCreditAccount} setCreditAccountState={setSelectedCreditAccount}/>
            
          </div>
        )}
        {activeTab === "link3" && (
          <div>
            <Enquiry_CI rowState={rowState} />
          </div>
        )}
      </form>



      {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired(activeTab, "open")}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {okMessage} <br />
          RefID: <b>{refIDRes}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

            {/* Modal*/}
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
          <div className="modal-content"
                style={{
                  overflow: 'auto',
                  maxWidth: 'none',
                  width: '1222px',
                  // height: '1222px'
                }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{modalTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" 
                onClick={() => {
                if(!modalDisabled){
                  setmodalDisabled(true)
                  // setModalInstanceID(0)
                }
                  
              }}>

                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{marginTop:'0', paddingTop: '0'}} key={key}>
                {modalType === "Deposit" && <CashDeposit_Modal modalInstanceID={modalInstanceID} id={"deposit"}/>}
                {modalType === "Withdrawal" && <CashWithdrawal_Modal modalInstanceID={modalInstanceID} id={"withdrawal"}/>}
                {modalType === "Transfer" && <TransferWithdrawal_Modal modalInstanceID={modalInstanceID} id={"transfer"}/>}
                  {/* setInstanceState={setExistedAccountDB} instanceState={existedAccountDB}/> */}
                
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                if(!modalDisabled)
                  setmodalDisabled(true)
                  // setModalInstanceID(0)
                //  setExistedAccountDB(null)
              }} >Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TypeCustomer = [{id:"1",name:"P - Person"},{id:"2",name:"C - Corporate"}]
let arrRequired = []
function checkRequired(activeTab, id, custBalAmt = 0) {
  arrRequired = []
  let arr = []
  if(activeTab === "active"){
    arr = [
      {type: "select",id: "sltAccountType_" + id ,name: 'Account Type'},
      {type: "text",id: "txtCustomerAccount_" + id,name: 'Customer Account'},
      {type: "select",id: "sltCurrencyDeposited_" + id ,name: 'Currency Deposited'},
      {type: "text",id: "txtTellerID_" + id,name: 'Teller ID'},
    ];
  }else if(activeTab === "link1"){
    arr = [
      {type: "select",id: "sltAccountType_" + id ,name: 'Account Type'},
      {type: "text",id: "txtCustomerAccount_" + id,name: 'Customer Account'},
      {type: "select",id: "sltCurrencyDeposited_" + id ,name: 'Currency Deposited'},
      {type: "text",id: "txtTellerID_" + id,name: 'Teller ID'},
    ];
    if(document.getElementById("txtAmount_" + id) && custBalAmt !== 0)
      if(parseInt(document.getElementById("txtAmount_" + id).value) > custBalAmt){
        arrRequired.push("Invalid Withdrawal Amount")
      }
  }else if(activeTab === "link2"){
    arr = [
      {type: "select",id: "sltAccountType_" + id ,name: 'Account Type'},
      {type: "select",id: "txtDebitAccount_" + id,name: 'Debit Account'},
      {type: "select",id: "txtCreditAccount_" + id ,name: 'Credit Account '},
    ];
    if(document.getElementById("txtDebitAmt_" + id) && custBalAmt !== 0)
      if(parseInt(document.getElementById("txtDebitAmt_" + id).value) > custBalAmt){
        arrRequired.push("Invalid Transfer Amount")
      }
  }else if(activeTab === "link3"){
    arr = [
      {type: "select",id: "sltTransactionType" ,name: 'Transaction Type'},
      {type: "select",id: "sltAccountType",name: 'Account Account'},
    ];
  }else{

  }
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  if (arrRequired.length > 0) return true; else return false;
}

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function getNameFromId(list, idTemp) {
  const obj = list.find(obj => obj.id === idTemp);
  return obj ? obj.name : null;
}

function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}