import React from 'react'
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField'
import RowComponent from '../../../../components/RowComponent/RowComponent'

export default function IssueCheque_CI(props) {
  return (
    <div>
        <hr></hr>
      {/* Fieldset 1 */}
      <FieldsetComponent>
        {/* row 1 */}
        <RowComponent>
          <InputField
            label="Cheque Status:"
            type="select"
            id={`sltChequeStatus_` + props.id}
            list={AccountType}
            required={true}
          />
          <div className="col-1"></div>
          <InputField
            label="Currency Account:"
            type="select"
            id={`sltCurrencyAccount_` + props.id}
            list={AccountType}
          />
        </RowComponent>

        {/* row 2 */}
        <RowComponent>
          <InputField
            label="Issue Date:"
            type="date"
            id={`dtIssueDate_` + props.id}
          />
          <div className="col-1"></div>
          <InputField
            label="Quantity of Issued:"
            type="text"
            id={`txtQuantityofIssued_`+props.id}
            required={true}
          />
        </RowComponent>

        {/* row 3 */}
        <RowComponent>
          <InputField
            label="Cheque No Start:"
            type="text"
            id={`txtChequeNoStart_` + props.id}
            required={true}
          />
        </RowComponent>
      </FieldsetComponent>

      

    </div>
  )
}


const AccountType = [
    { "id": 1, "name": 'Current & Non-Term Saving Account'},
    { "id": 2, "name": 'Saving Account - Arrear'},
    { "id": 3, "name": 'Saving Account - Periodic'},
    { "id": 4, "name": 'Saving Account - Discounted'},
    { "id": 5, "name": 'Loan Working Account'},
  ];
