const ChargeCategory = [
    {
      "id": 1,
      "code": 'PL737869',
      "name": 'PL737869 - Phí phát hành LC Nhập, Xuất khẩu',
      "value": 0.1
    },
    {
      "id": 2,
      "code": 'PL77107',
      "name": 'PL77107 - Phí rút TM từ TK khác địa bàn',
      "value": 0.1
    },
    {
      "id": 3,
      "code": 'PL77108',
      "name": 'PL77108 - Phí nộp TM vào TK khác địa bàn',
      "value": 0.1
    },
    {
      "id": 4,
      "code": 'PL77110',
      "name": 'PL77110 - Phí CK, điều chỉnh UNC trong hệ thống',
      "value": 0.1
    },
    {
      "id": 5,
      "code": 'PL77152',
      "name": 'PL77152 - Phí đổi tiền không đủ tiêu chuẩn lưu thông',
      "value": 0.1
    },
    {
      "id": 6,
      "code": 'PL77153',
      "name": 'PL77153 - Phí nhận tiền CMND đến ngoài hệ thống',
      "value": 0.1
    },
    {
      "id": 7,
      "code": 'PL77221',
      "name": 'PL77221 - Phí cấp hạn mức tín dụng dự phòng',
      "value": 0.1
    },
    {
      "id": 8,
      "code": 'PL77226',
      "name": 'PL77226 - Phí tất toán trước hạn, giảm vốn trước hạn',
      "value": 0.1
    },
    {
      "id": 9,
      "code": 'PL77291',
      "name": 'PL77291 - Phí tất toán trước hạn tiền gửi tiết kiệm KKH, CKH, CCDH',
      "value": 0.1
    },
    {
      "id": 10,
      "code": 'PL77803',
      "name": 'PL77803 - Phí CK, điều chỉnh UNC ngoài hệ thống',
      "value": 0.1
    },
    {
      "id": 11,
      "code": 'PL77855',
      "name": 'PL77855 - Phí chuyển tiền, điều chỉnh LCT mặt trong hệ thống',
      "value": 0.1
    },
    {
      "id": 12,
      "code": 'PL77856',
      "name": 'PL77856 - Phí chuyển tiền, điều chỉnh LCT mặt ngoài hệ thống',
      "value": 0.1
    },
    {
      "id": 13,
      "code": 'PL77861',
      "name": 'PL77861 - Chuyển điện TT khách hàng Doanh nghiệp',
      "value": 0.1
    },
    {
      "id": 14,
      "code": 'PL77862',
      "name": 'PL77862 - Chuyển điện TT khách hàng Cá nhân',
      "value": 0.1
    },
    {
      "id": 15,
      "code": 'PL77890',
      "name": 'PL77890 - Phí truy lục chứng từ, cấp SP, sao kê, xác nhận số dư, phát hành Sec',
      "value": 0.1
    },
    {
      "id": 16,
      "code": 'PL77891',
      "name": 'PL77891 - Phí thanh toán DA, DP, LC nhập khẩu',
      "value": 0.1
    },
    {
      "id": 17,
      "code": 'PL77982',
      "name": 'PL77892 - LC xuất khẩu',
      "value": 0.1
    },
    {
      "id": 18,
      "code": 'PL77900',
      "name": 'PL77900 - Phí tất toán trước hạn TK TGTT',
      "value": 0.1
    },
    {
      "id": 19,
      "code": 'PL837870',
      "name": 'PL837870 - Phí điện',
      "value": 0.1
    },
    {
      "id": 20,
      "code": 'PL90304',
      "name": 'PL90304 - Phí VAT',
      "value": 0.1
    },
    {
      "id": 21,
      "code": 'PL99999',
      "name": 'PL99999 - Phí khác',
      "value": 0.1
    }
]

export default ChargeCategory;