import React, { useState ,useEffect} from 'react'
import { Button, Modal } from 'react-bootstrap'
import chargeCollectionApi from '../../../../apis/chargeCollectionApi';
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from '../../../../data/AccountType';
import ChargeCategory from '../../../../data/ChargeCategory';
import Currency_2 from '../../../../data/Currency_2';
import SystemCurrency from '../../../../data/SystemAccount';
import TempData from '../../../../data/TempData';

export default function FromAccount_CC() {
  // 1. State check required
 const [arrRe, setArrRe] = useState([])
 const handleSetArrRe = (item) => {setArrRe(item);};
 // 2. State show Error
 const [showErrorModal, setShowErrorModal] = useState(false);
 const [errorMessage, setErrorMessage] = useState("error");
 const handleCloseErrorModal = () => setShowErrorModal(false);
 const handleShowErrorModal = () => setShowErrorModal(true);
 // 3. State show Success
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const handleCloseSuccessModal = () => setShowSuccessModal(false);
 const handleShowSuccessModal = () => setShowSuccessModal(true);
   // 5. State show Backend Error
   const [showBEError, setShowBEError] = useState(false);
   const handleCloseBEErrorModal = () => setShowBEError(false);
   const handleShowBEErrorModal = () => setShowBEError(true);

   
   const [refIDRes, setRefIDRes] = useState("")
// GET ACCOUNT
const [accountsDB, setAccountsDB] = useState([])
useEffect(() => {
async function fetchAccounts() {
 try {
   const response = await mainAccountApi.enquiry({})
   setAccountsDB(response.data.data)
 } catch (error) {
 }
}
fetchAccounts();
}, []);

// HANDLE ACCOUNT
const [accountState, setAccountState] = useState("") // customerID

//STATE HANDLER
const [selectedAccount, setSelectedAccount] = useState({})

useEffect(() => {
    if(accountsDB){
      let count = 0
      accountsDB.map((value, index) => {
            if(value.Account === accountState){
                count = 1
                setSelectedAccount(value)
                document.getElementById('txtCurrency').value = value.CURRENCY.Name
                document.getElementById('txtAccount').value = "TKTT" + " - " + value.CURRENCY.Name + " - " +  value.Customer.GB_FullName
                document.getElementById('txtCustomerID').value = value.Customer.RefID
                document.getElementById('txtCustomerName').value = value.Customer.GB_FullName
                setCurrencyState(value.CURRENCY.Name)
            }
        })
      if(count == 0){
        document.getElementById('txtCurrency').value = null
        document.getElementById('txtAccount').value = null
        document.getElementById('txtCustomerID').value = null
        document.getElementById('txtCustomerName').value = null
      }
    }
}, [accountState]);

const [activeTab, setActiveTab] = useState("active");
const [currencyState, setCurrencyState] = useState(0);
const [LCYState, setLCYState] = useState(true);
const [chrgAmtLCYState, setChrgAmtLCYState] = useState(0.00)
const [chrgAmtFCYState, setChrgAmtFCYState] = useState(0.00)
const [dealRateState, setDealRateState] = useState(0.00)
useEffect(() => {
    if(currencyState == "VND"){
        setLCYState(false)
        setChrgAmtFCYState(0)
        setChrgAmtLCYState(0)
        setDealRateState(1)
        document.getElementById("txtChrgAmtFCY").value = ""
        document.getElementById("txtVatAmountFCY").value = ""
        document.getElementById("txtTotalAmountFCY").value = ""
        document.getElementById("txtChrgAmtLCY").value = ""
        document.getElementById("txtVatAmountLCY").value = ""
        document.getElementById("txtTotalAmountLCY").value = ""
    }else{
        setLCYState(true)
        setChrgAmtFCYState(0)
        setChrgAmtLCYState(0)
        document.getElementById("txtChrgAmtLCY").value = ""
        document.getElementById("txtVatAmountLCY").value = ""
        document.getElementById("txtTotalAmountLCY").value = ""

        document.getElementById("txtChrgAmtFCY").value = ""
        document.getElementById("txtVatAmountFCY").value = ""
        document.getElementById("txtTotalAmountFCY").value = ""
    }
}, [currencyState]);

useEffect(() => {

    if(LCYState == false){
      setChrgAmtFCYState(0)
      setDealRateState(1)
      document.getElementById("txtVatAmountLCY").value = currencyFormat(parseFloat(chrgAmtLCYState * 0.1 * parseFloat(dealRateState)) , "VND")
      document.getElementById("txtTotalAmountLCY").value = currencyFormat((parseFloat(chrgAmtLCYState * 0.1) + parseFloat(chrgAmtLCYState)) * parseFloat(dealRateState), "VND")

    }
    

}, [chrgAmtLCYState]);

useEffect(() => {
    setChrgAmtLCYState(parseFloat(chrgAmtFCYState) * parseFloat(dealRateState))
    document.getElementById("txtVatAmountFCY").value = currencyFormat(parseFloat(chrgAmtFCYState * 0.1), "VND")
    document.getElementById("txtTotalAmountFCY").value = currencyFormat(parseFloat(chrgAmtFCYState * 0.1) + parseFloat(chrgAmtFCYState), "VND")
    document.getElementById("txtVatAmountLCY").value = currencyFormat(parseFloat(chrgAmtFCYState) * 0.1 * parseFloat(dealRateState), "VND")
    document.getElementById("txtTotalAmountLCY").value = currencyFormat((parseFloat(chrgAmtFCYState * 0.1) + parseFloat(chrgAmtFCYState)) * parseFloat(dealRateState), "VND")

}, [chrgAmtFCYState, dealRateState]);
return (
<div>
 <div><div
       className="row align-items-center text-center mb-3"
       style={{
       height: "50px",
       backgroundColor: "#eceff4",
       margin: "3px",
       }}
   >
       <div
       className="col-2 buttonAction"
       style={{
           borderRight: "solid 1px rgba(0,0,0,.2)",
           fontWeight: "",
           color: "rgb(107,107,107)",
           height: "100%",
           lineHeight: "50px",
       }}

       onClick={async (event)=> {
         checkRequired();
         if (arrRequired.length > 0) {
           setShowErrorModal(true);
           setArrRe(arrRequired);
         } else {
         const params = {
            ccAmount: document.getElementById('txtChrgAmtLCY').value.replace(".", "") || 0,
            ccDealRate: document.getElementById('txtDealRate').value || 0,
            ccVatSerialNo: document.getElementById('txtVATSerialNo').value || "",
            ccNarrative: document.getElementById('txtNarrative').value || "",
            ccCategory: getIdFromName(ChargeCategory, document.getElementById('sltCategoryPL')?.value) == 0 ? null : getIdFromName(ChargeCategory, document.getElementById('sltCategoryPL')?.value),
            account: document.getElementById('txtDebitAccount').value || 0,
            accountType: getIdFromName(AccountType, document.getElementById('sltAccountType')?.value) == 0 ? null : getIdFromName(AccountType, document.getElementById('sltAccountType')?.value),
            ccAmountFCY: document.getElementById('txtChrgAmtFCY').value.replace(".", "") || 0,
            vatAmountLCY: document.getElementById('txtVatAmountLCY').value.replace(".", "") || 0,
            vatAmountFCY: document.getElementById('txtVatAmountFCY').value.replace(".", "") || 0,
            totalAmountLCY: document.getElementById('txtTotalAmountLCY').value.replace(".", "") || 0,
            totalAmountFCY: document.getElementById('txtTotalAmountFCY').value.replace(".", "") || 0,
         }
         console.log('params')
         console.log(params)
         chargeCollectionApi.fromAccount(params)
           .then(response => {
            setRefIDRes(response.data.data.RefID)
             setShowSuccessModal(true);
           })
           .catch(error => {
             setErrorMessage(error.response.data.message)
             console.log('errorMessage')
             console.log(error)
             // errorMessage = error.Data
             handleShowBEErrorModal();
           });
           
           // recreateInput();
         }
       }}
       >
       <svg
           xmlns="http://www.w3.org/2000/svg"
           width={16}
           height={16}
           fill="currentColor"
           className="bi bi-save-fill"
           viewBox="0 0 16 16"
           style={{
           color: "rgba(107,107,107,.9)",
           marginRight: "8px",
           }}
       >
           <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
       </svg>
       <div
           style={{
           display: "inline",
           }}
       >
           {" "}
           Commit Data{" "}
       </div>
       </div>
       <div
       className="col-2 buttonAction"
       style={{
           borderRight: "solid 1px rgba(0,0,0,.2)",
           fontWeight: "",
           color: "rgb(107,107,107)",
           height: "100%",
           lineHeight: "50px",
       }}
       >
       <svg
           xmlns="http://www.w3.org/2000/svg"
           width={16}
           height={16}
           fill="currentColor"
           className="bi bi-check-circle-fill"
           viewBox="0 0 16 16"
           style={{
           color: "rgba(107,107,107,.9)",
           marginRight: "8px",
           }}
       >
           <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
       </svg>
       Authorize
       </div>
       <div
       className="col-2 buttonAction"
       style={{
           borderRight: "solid 1px rgba(0,0,0,.2)",
           fontWeight: "",
           color: "rgb(107,107,107)",
           height: "100%",
           lineHeight: "50px",
       }}
       >
       <svg
           xmlns="http://www.w3.org/2000/svg"
           width={16}
           height={16}
           fill="currentColor"
           className="bi bi-search"
           viewBox="0 0 16 16"
           style={{
           color: "rgba(107,107,107,.9)",
           marginRight: "8px",
           }}
       >
           <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
       </svg>
       Search
       </div>
       <div
       className="col-2 buttonAction"
       style={{
           borderRight: "solid 1px rgba(0,0,0,.2)",
           fontWeight: "",
           color: "rgb(107,107,107)",
           height: "100%",
           lineHeight: "50px",
       }}
       >
       <svg
           xmlns="http://www.w3.org/2000/svg"
           width={16}
           height={16}
           fill="currentColor"
           className="bi bi-printer-fill"
           viewBox="0 0 16 16"
           style={{
           color: "rgba(107,107,107,.9)",
           marginRight: "8px",
           }}
       >
           <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
           <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
       </svg>
       Print Deal Slip
       </div>

       <div
       className="col-2 buttonAction"
       style={{
           borderRight: "solid 1px rgba(0,0,0,.2)",
           fontWeight: "",
           color: "rgb(107,107,107)",
           height: "100%",
           lineHeight: "50px",
       }}
       >
       <svg
           xmlns="http://www.w3.org/2000/svg"
           width={16}
           height={16}
           fill="currentColor"
           className="bi bi-pencil-fill"
           viewBox="0 0 16 16"
           style={{
           color: "rgba(107,107,107,.9)",
           marginRight: "8px",
           }}
       >
           <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
       </svg>
       Edit data
       </div>
   </div>

   <hr></hr>

   <div
       className="row noCopy"
       style={{
       margin: "20px 5px",
       }}
   >
       <ul className="nav nav-pills">
       <li className="nav-item">
           <a
           className={`nav-link ${activeTab === "active" ? "active" : ""}`}
           aria-current="page"
           href="#"
           onClick={() => setActiveTab("active")}
           style={{
               backgroundColor: activeTab === "active" ? "#d71921" : "",
           }}
           >
           Collect Charges From Account
           </a>
           
       </li>
       </ul>
   </div>

   <hr></hr>

   <div>
       <nav>
       <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
           <a
           className="nav-item nav-link active"
           id="nav-customerInfo-tab"
           data-toggle="tab"
           href="#customer-info"
           role="tab"
           aria-controls="customer-info"
           aria-selected="true"
           >
           Collect Charges
           </a>
       </div>
       </nav>

       <div className="tab-content" id="nav-tabContent">
           <div
               className="tab-pane fade show active"
               id="customer-info"
               role="tabpanel"
               aria-labelledby="nav-home-tab"
           >
               {/* Fieldset 1 */}
               <FieldsetComponent label="Account Information">
                   {/* row 1 */}
                   <RowComponent>
                    <InputField
                        label="Account Type:"
                        type="select"
                        id="sltAccountType"
                        list={AccountType}
                        required={true}
                    />
                     <div className="col-1"></div>
                     <InputField
                         label="Debit Account:"
                         type="text-handler"
                         id="txtDebitAccount"
                         required={true}
                         state={accountState}
                         setState={setAccountState}
                     />
                    
                   </RowComponent>
                    {/* row 1 */}

                    <hr></hr>
                    <RowComponent>
                       <InputField
                           label="Currency:"
                           type="text"
                           id="txtCurrency"
                           isDisabled = {true}
                       />
                       <div className="col-1"></div>
                       <InputField
                         label="Account"
                         type="text"
                         id="txtAccount"
                         isDisabled = {true}
                     />
                   </RowComponent>
                   {/* row 1 */}

                   
                   <RowComponent>
                       <InputField
                           label="Customer ID:"
                           type="text"
                           id="txtCustomerID"
                           isDisabled = {true}
                       />
                       <div className="col-1"></div>
                       <InputField
                         label="Customer Name:"
                         type="text"
                         id="txtCustomerName"
                         isDisabled = {true}
                     />
                   </RowComponent>
               </FieldsetComponent>

               {/* Fieldset 1 */}
               <FieldsetComponent label="Charges Collection Information">
                   {/* row 1 */}
                   <RowComponent>
                    <InputField
                          label="Category PL:"
                          type="select"
                          id="sltCategoryPL"
                          list={ChargeCategory}
                          required={true}
                      />
                    <div className="col-1"></div>
                    <InputField
                            label="Value Date:"
                            type="date"
                            id="dtValueDate"
                            state={getToday}
                            />
                   </RowComponent>
                   {/* row 1 */}
                   <RowComponent>
                        <InputField
                            label="VAT Serial No:"
                            type="text"
                            id="txtVATSerialNo"
                        />
                    <div className="col-1"></div>
                    <InputField
                            label="Narrative:"
                            type="text"
                            id="txtNarrative"
                            />
                        <div className="col-1"></div>
                   
                   </RowComponent>
               </FieldsetComponent>

               <FieldsetComponent label="Charges Amount">
                    <RowComponent>
                        <InputField
                            label="Chrg Amt LCY:"
                            type="number"
                            id="txtChrgAmtLCY"
                            state={chrgAmtLCYState}
                            setState={setChrgAmtLCYState}
                            isDisabled={LCYState}
                        />
                        <div className="col-1"></div>
                        <InputField
                        label="Chrg Amt FCY:"
                        type="number"
                        id="txtChrgAmtFCY"
                        state={chrgAmtFCYState}
                        setState={setChrgAmtFCYState}
                        isDisabled={!LCYState}
                        />
                    </RowComponent>
                    <RowComponent>
                        <InputField
                        label="Deal Rate:"
                        type="number"
                        id="txtDealRate"
                        state={dealRateState}
                        setState={setDealRateState}
                        isDisabled={!LCYState}
                        />
                        <div className="col-1"></div>
                    </RowComponent>

                    <RowComponent>
                        <InputField
                            label="Vat Amount LCY:"
                            type="text"
                            id="txtVatAmountLCY"
                            isDisabled={true}
                        />
                        <div className="col-1"></div>
                        <InputField
                            label="Vat Amount FCY:"
                            type="text"
                            id="txtVatAmountFCY"
                            isDisabled={true}
                            />
                    </RowComponent>

                    {/* row 1 */}
                    <RowComponent>
                        <InputField
                            label="Total Amount LCY:"
                            type="text"
                            id="txtTotalAmountLCY"
                            isDisabled={true}
                        />
                        <div className="col-1"></div>
                        <InputField
                            label="Total Amount FCY:"
                            type="text"
                            id="txtTotalAmountFCY"
                            isDisabled={true}
                            />
                    </RowComponent>

                    
               
               </FieldsetComponent>
           </div>
       </div>
   </div>

       {/* --------------------------------------- */}
 {/* <Backend Error Model /> */}
 <div>
   <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
     <Modal.Header closeButton>
       <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
     </Modal.Header>
     <Modal.Body>
             <b>{errorMessage}</b>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={handleCloseBEErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
 </div>
 {/* --------------------------------------- */}
 {/* <ErrorModal /> */}
 <div>
   <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
     <Modal.Header closeButton>
       <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       {checkRequired()}
       {arrRe.map((item, index) => {
         return <>
           <div>- {item}</div></>})}
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={handleCloseErrorModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
 </div>
 {/* --------------------------------------- */}
 {/* Success Modal  */}
 <div>
   <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
     <Modal.Header closeButton>
       <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
     </Modal.Header>
     <Modal.Body>
     <b>Collection Charges </b> was successful! <br />
     - Name: <b>{document.getElementById('txtCustomerName')?.value}</b> <br />
     - RefID: <b> {refIDRes}</b> <br />
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
 </div>


</div>

</div>
)
}


let arrRequired = []
function checkRequired() {
    arrRequired = []
    let arr = [
        {type: "text",id: "txtDebitAccount",name: 'Debit Account'},
        {type: "select",id: "sltCategoryPL",name: 'Category PL'},

      ];
      arr.map((item, index) => {
        if (document.getElementById(item.id)?.value.length == 0)
          arrRequired.push(item.name)
      })
  if (arrRequired.length > 0) return true; else return false;
}

function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
  
function getToday(){
    let today = new Date()
    return today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate()
  }

  function currencyFormat(value, currency){
    let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
    console.log(resultValue.slice(0, -4))
    return resultValue.slice(0, -4)
  }
