
import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import savingAccountApi from "../../../../apis/savingAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import CloseSystemCurrency from "../../../../data/CloseSystemCurrency";
import Currency_1 from "../../../../data/Currency_1";
import Currency_2 from "../../../../data/Currency_2";
import ProductLine from "../../../../data/ProductLine";
import SavingCurrencyAccount from "../../../../data/SavingCurrencyAccount";
import TempData from "../../../../data/TempData";

export default function Close_Discounted_SA(props) {
    //HANDLE BUTTON
    const [commitBtn, setCommitBtn] = useState(true)
    const [authorBtn, setAuthoBtn] = useState(false)
    const [editBtn, setEditBtn] = useState(false)
    const [searchBtn, setSearchBtn] = useState(false)
    const [printBtn, setPrintBtn] = useState(false)

    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [disabledState, setDisabledState] = useState(false);
    const [accountStatusDB, setAccountStatusDB] = useState("Active")
    const [closureAccount, setClosureAccount] = useState({})
    useEffect(() => {
      async function fetchAccount() {
        try {
          setIsLoading(true)
          props.setCheckAccountState(false)
          console.log(props.account)
          const response = await savingAccountApi.getAccount(props.account)
          const accountDB = response.data.data
          console.log("fetch account")
          console.log(accountDB)
          props.setState(accountDB)
          setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
          const accountType = accountDB.savingAccount?.Type
          if(accountDB.savingAccount?.AccountStatus != "Active")
          {
            setDisabledState(true)
            setCommitBtn(false)
            
          }
    
          if(accountType === 4){
            props.setCheckAccountState(true)
          }
          setIsLoading(false)
        } catch (error) {
  
        }
      }
      fetchAccount();
    }, [props.account]);

    console.log("fetch account xxxxxxxxxxx")
    
    let savingAccountDB = props.state
    console.log(savingAccountDB)


     // CLOSURE INFO
    useEffect(() => {
      async function fetchClosure() {
        try {
          if(accountStatusDB === "Closed"){
            const response = await savingAccountApi.getClosure(props.account)
            const closureDB = response.data.data
            console.log("fetch closure account")
            console.log(closureDB)
            setClosureAccount(closureDB)
            // FILL DATA
            if(response)
            setIsLoading2(false)
          }
          
          // console.log(closureDB?.Status)
          // setAccountStatusDB(accountDB.savingAccount?.AccountStatus)
        
        } catch (error) {

        }
      }
      fetchClosure();
    }, [savingAccountDB]);

  if((!isLoading && !disabledState) || (!isLoading2 && disabledState))
    if (props.checkAccountState)
  return (
    <>
    <nav>
    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
      <a
        className="nav-item nav-link active"
        id="nav-travSav-tab"
        data-toggle="tab"
        href="#travSav"
        role="tab"
        aria-controls="nav-trad"
        aria-selected="true"
      >
        Trav Sav - Deposited
      </a>
      <a
        className="nav-item nav-link"
        id="nav-Interest-tab"
        data-toggle="tab"
        href="#interest"
        role="tab"
        aria-controls="nav-interest"
        aria-selected="false"
      >
        Disc Interest Payment
      </a>
    </div>
  </nav>

  
  <FieldsetComponent label="">
          <b>Account Status: 
            <span
            style={accountStatusDB === "Active" &&{
               color: "Green"
            }|| {
              color: "Red"
            }}>  {accountStatusDB}</span> </b>
    </FieldsetComponent>

  <div className="tab-content" id="nav-tabContent">
    <div
      className="tab-pane fade show active"
      id="travSav"
      role="tabpanel"
      aria-labelledby="nav-travSav-tab"
    >

      {/* Fieldset 1 */}
      <FieldsetComponent label="">
        {/* row 1 */}
        <RowComponent>
          <InputField label="Customer No:" type="text" id={"txtCustomer_Close2_1_" + props.id} isDisabled={true}
            value={savingAccountDB.savingAccount?.CUSTOMER?.RefID + " - " + savingAccountDB.savingAccount?.CUSTOMER?.GB_FullName} />
          <div className="col-1"></div>
          <InputField label="Currency / Amount:" type="text" id={"txtCurrencyAmount_Close2_1_" + props.id} isDisabled={true}
            value={getNameFromId(Currency_1, savingAccountDB.detailAccount?.Currency )} />
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField label="Value Date:" type="text" id={"txtValueDate_Close2_1_"+props.id} isDisabled={true}
            value={savingAccountDB.detailAccount?.ValueDate} />
          <div className="col-1"></div>
          <InputField label="New Mat Date:" type="date" id={"dtNewMatDate_Close2_1_" + props.id}
           value={savingAccountDB.detailAccount?.MaturityDate} 
           isDisabled={disabledState}/>
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField
            label="Int Pymt Method:"
            type="text"
            id={"txtPaymentMethod_Close2_1_" + props.id}
            isDisabled={true}
            value={""}
          />
          <div className="col-1"></div>
          <InputField
            label="Interest Basis:"
            type="text"
            id={"txtInterestBasis_Close2_1_" + props.id}
            isDisabled={true}
            value={""}
          />
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField
            label="Interest Rate:"
            type="text"
            id={"txtInterestedRate_Close2_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.InterestRate}
          />
          <div className="col-1"></div>
          <InputField
            label="Total Int Amt:"
            type="text"
            id={"txtTotalIntAmt_Close2_1_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.Amount}
          />
        </RowComponent>
      </FieldsetComponent>

      <FieldsetComponent label="Eligible Interest">
        <RowComponent>
            <InputField
              label="Eligible Interest:"
              type="number-nohandle"
              id={"txtEligibleInterest_Close2_1_" + props.id}
              isDisabled={disabledState}
            />
            <div className="col-1"></div>
            <InputField
              label="Int Rate V Date:"
              type="date"
              id={"dtIntRateDate_Close2_1_" + props.id}
              isDisabled={disabledState}
            />
          </RowComponent>
      </FieldsetComponent>
      <FieldsetComponent label="Account Information">
        <RowComponent>
            <InputField
              label="Debit Account:"
              type="text"
              id={"txtDebitAccount_Close2_1_" + props.id}
              isDisabled={true}
              size={"col-6"}
              value={getNameFromCurrency(CloseSystemCurrency,savingAccountDB.detailAccount?.Currency )}
              
            />
            <div className="col-1"></div>
          
          </RowComponent>
          <RowComponent>
            <InputField
                label="Cr Acc for Principal:"
                type="text"
                id={"txtAccforPrincipal_Close2_1_" + props.id}
                isDisabled={true}
                size={"col-6"}
                value={savingAccountDB.detailAccount?.WorkingAccountRefID}
              />
              <div className="col-1"></div>
          </RowComponent>
          <RowComponent>
            <InputField
                label="Cr Acc for Interest:"
                type="text"
                id={"txtAccforInterest_Close2_1_" + props.id}
                isDisabled={true}
                size={"col-6"}
                value={savingAccountDB.detailAccount?.WorkingAccountRefID}
              />
              <div className="col-1"></div>
          </RowComponent>
      </FieldsetComponent>
    </div>
    <div
      className="tab-pane fade "
      id="interest"
      role="tabpanel"
      aria-labelledby="nav-Interest-tab"
    >
      <FieldsetComponent label="">
        <RowComponent>
          <InputField
            label="Deposit No:"
            type="text"
            id={"txtDepositNo_Close2_2_" + props.id}
            isDisabled={true}
            // value={savingAccountDB.detailAccount?.}
            value={"LD/23060/34323"}
          />
          <div className="col-1"></div>
           <InputField
              label="Currency:"
              type="text"
              id={"txtCurrency_Close2_2_" + props.id}
              isDisabled={true}
              value={getNameFromId(Currency_1,savingAccountDB.detailAccount?.Currency )} />
        </RowComponent>
        <hr />
    </FieldsetComponent>
    <FieldsetComponent >

    <RowComponent>
          <InputField
              label="Customer:"
              type="text"
              id={"txtCustomer_Close2_2_" + props.id}
              isDisabled={true}
              size={"col-6"}
              value={savingAccountDB.detailAccount?.WorkingAccountRefID} />
        </RowComponent>

        <RowComponent>
          <InputField
            label="Dr Account:"
            type="text"
            id={"txtDrAccount_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.WorkingAccountRefID}
            size={"col-6"}
          />
        </RowComponent>
        <RowComponent>
        <InputField
            label="Narrative:"
            type="text"
            id={"txtNarrative_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.NarrativeInterest}
            size={"col-6"}
          />
        </RowComponent>
        

        <hr />
    </FieldsetComponent>
       
    <FieldsetComponent>
        <RowComponent>
          <InputField
            label="Deal Rate:"
            type="number-nohandle"
            id={"txtDealRate_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Amount LCY:"
            type="text"
            id={"txtAmountLCY_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.AmountLCY}
          />
          <div className="col-1"></div>
          <InputField
            label="Amount FCY:"
            type="text"
            id={"txtAmountFCY_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.AmountFCY}
          />
        </RowComponent>

        <RowComponent>
          <InputField
            label="Currency Paid:"
            type="text"
            id={"txtCurrencyPaid_Close2_2_" + props.id}
            isDisabled={true}
            value={"VND"}
          />
          <div className="col-1"></div>
          <InputField
            label="For Teller:"
            type="text"
            id={"txtForTeller_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>

        <RowComponent>
          <InputField
            label="Credit Account:"
            type="select"
            id={"txtCreditAccount_Close2_2_" + props.id}
           list={CloseSystemCurrency.slice(5, 6)}
           isDisabled={disabledState}
          />
          <div className="col-1"></div>
          <InputField
            label="Narrative:"
            type="text"
            id={"txtNarrative2_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
        </RowComponent>
        <RowComponent>
          <InputField
            label="Waive Charge:"
            type="select"
            id={"txtWaiveCharge_Close2_2_" + props.id}
            list={TempData.BooleanData}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>
          
        </RowComponent>
        <hr />
        <RowComponent>
          <InputField
            label="New Cust Bal:"
            type="text"
            id={"txtNewCustBal_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.CustBal}
          />
          <div className="col-1"></div>
          <InputField
            label="Amt Paid:"
            type="text"
            id={"txtAmtPaid_Close2_2_" + props.id}
            isDisabled={true}
            value={savingAccountDB.detailAccount?.AmtPaid}
          />
        </RowComponent>
        <hr />
        <RowComponent>
          <InputField
            label="Print Ln No of:"
            type="number-nohandle"
            id={"txtPrintNo_Close2_2_" + props.id}
            isDisabled={disabledState}
          />
          <div className="col-1"></div>
        </RowComponent>
      </FieldsetComponent>
    </div>
  </div>
  </>
  
  )
  else
  return (
    <div>
      Account does not existed.
    </div>
  )
}



function getIdFromName(list, nameTemp) {
    const obj = list.find(obj => obj.name === nameTemp);
    return obj ? obj.id : null;
  }
  
  function getNameFromId(list, id) {
    const obj = list.find(obj => obj.id === id);
    return obj ? obj.name : null;
  }
  

   function getNameFromCurrency(list, currency) {
    const obj = list.find(obj => obj.currency === currency);
    return obj ? obj.name : null;
  }
  
  function currencyFormat(value, currency) {
    let resultValue = value ? value.toLocaleString('it-IT', { style: 'currency', currency: currency }) : "";
    console.log(resultValue.slice(0, -4))
    return resultValue.slice(0, -4)
  }
  
  
  function amountCal(origin, rate) {
    console.log(origin)
    console.log(rate)
    return origin * 0.0004316238 * rate
  }