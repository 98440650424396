
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from "../../../../apis/mainAccountApi";
import savingAccountApi from "../../../../apis/savingAccountApi";
import DataTable from '../../../../components/DataTable/DataTable';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountType from "../../../../data/AccountType";
import Category from "../../../../data/Category";
import CityList from "../../../../data/CityList";
import Currency_1 from "../../../../data/Currency_1";
import Currency_2 from "../../../../data/Currency_2";
import ProductLine from "../../../../data/ProductLine";
import TempData from "../../../../data/TempData";
import InfoIcon from "../../../../public/icon/InfoIcon";
import DeleteIcon from "../../../../public/icon/deleteIcon";
import Close_ArrearPeriodic_Modal from "./Close_ArrearPeriodic_Modal";
import Close_Discounted_Modal from "./Close_Discounted_Modal";
import Open_ArrearSA_Modal from "./Open_ArrearSA_Modal";
import Open_DiscountedSA_Modal from "./Open_DiscountedSA_Modal";
import Open_PeriodicSA_Modal from "./Open_PeriodicSA_Modal";
export default function Enquiry_SA() {
  const [activeTab, setActiveTab] = useState("active");
  const [modalDisabled, setmodalDisabled] = useState(true);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [enquiryType, setEnquiryType] = useState(0);
  const [rowState_1, setRowState_1] = useState([]);
  const handleSetRowState_1 = (row) => {
    setRowState_1(row);
  };
  const [rowState_2, setRowState_2] = useState([]);
  const handleSetRowState_2 = (row) => {
    setRowState_2(row);
  };

  const [modalInstanceID, setModalInstanceID] = useState(null);
  const handleSetModel = (item) => {
    setModalInstanceID(item);
  };
  // 5. key
  const [key, setKey] = useState(0);
  const resetComponent = () => {
    setKey(prevKey => prevKey + 1);
  };


  const [getCategory, setCategory] = useState(0);
  const Cell = ({ row }) => (
      <button className="btn btn-sm btn-primary me-2">Edit</button>

  );
 
  return (
    <div>
      {/* button action  */}
      <div
        className="row align-items-center text-center mb-3 noCopy
        "
        style={{ height: "50px", backgroundColor: "#eceff4", margin: "3px" ,
        position: 'sticky',
        top: '48.2px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'}}
      >
        {/* Button: Commit Data */}
        <div
          className="col-2 noCopy"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
          onClick={() => {}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-save-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" /></svg>
          <div style={{ display: "inline" }}> Commit Data </div>
        </div>
        {/* Button: Authorize */}
        <div
          className="col-2 "
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
          // on Click
          onClick={() => {}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-check-circle-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>
          Authorize
        </div>
        {/* Button: Search */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          onClick={ ()=> {
              let accountTypeReq = getIdFromName(accountType, document.getElementById('sltType')?.value)
              if(accountTypeReq === 1 && enquiryType === 0){
                      let params = {
                        refID: document.getElementById("txtRefID").value || null,
                        status: getIdFromName(TempData.Status, document.getElementById('sltStatus')?.value) == 0 ? null : getIdFromName(TempData.Status, document.getElementById('sltStatus')?.value),
                        category: getIdFromName(Category, document.getElementById('sltCategory')?.value) == 0 ? null : getIdFromName(Category, document.getElementById('sltCategory')?.value),
                        customerID: document.getElementById("txtCustomerID").value || null,
                        productLine:  getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value),
                        principalFrom: document.getElementById("txtPrincipalfr").value || null,
                        principalTo: document.getElementById("txtPrincipalto").value || null,
                        currency:  getIdFromName(Currency_2, document.getElementById('sltCurrency')?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltCurrency')?.value),
                        // isActive: true
                      }
                    
                      savingAccountApi.enquiryArrear(params)
                      .then(response => {
                        console.log("response")
                        console.log(response.data.data)
                        let tempRow = []
                        response.data.data.map((item, index) => {
                          let tempObject = {
                            RefID: item.SAVINGACCOUNT?.Account,
                            Status: getNameFromId(TempData.Status, item.SAVINGACCOUNT?.Status),
                            CustomerID: item.SAVINGACCOUNT?.CUSTOMER?.RefID,
                            Category: getNameFromId(Category, item.Category),
                            CCY:  getNameFromId(Currency_2, item.Currency),
                            ProductLine: getNameFromId(ProductLine, item.ProductLine),
                            Principal: currencyFormat(item.PrincipalAmount, "VND"),
                            Button: <div><button 
                                onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item.SAVINGACCOUNT?.id)
                                  setModalType("Arrear")
                                  setModalTitle("Arrear Saving Account - Information - RefID: " + item.SAVINGACCOUNT?.Account)
                                }}
                                type="button" className="btn" 
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                              <button 
                                onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item.SAVINGACCOUNT?.id)
                                  setModalType("CloseArrearPeriodic")
                                  setModalTitle("Close Arrear Saving Account - Information - RefID: " + item.SAVINGACCOUNT?.Account)
                                }}
                                type="button" className="btn" 
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<DeleteIcon />}</button>
                              </div>
                          }
                          tempRow.push(tempObject)
                        })
                        handleSetRowState_1(tempRow)
                      })
                      .catch(error => {
                        console.log(error)
                      });




              }else if(accountTypeReq === 2 && enquiryType === 0){
                    let params = {
                      refID: document.getElementById("txtRefID").value || null,
                      status: getIdFromName(TempData.Status, document.getElementById('sltStatus')?.value) == 0 ? null : getIdFromName(TempData.Status, document.getElementById('sltStatus')?.value),
                      category: getIdFromName(Category, document.getElementById('sltCategory')?.value) == 0 ? null : getIdFromName(Category, document.getElementById('sltCategory')?.value),
                      customerID: document.getElementById("txtCustomerID").value || null,
                      productLine:  getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value),
                      principalFrom: document.getElementById("txtPrincipalfr").value || null,
                      principalTo: document.getElementById("txtPrincipalto").value || null,
                      currency:  getIdFromName(Currency_2, document.getElementById('sltCurrency')?.value) == 0 ? null : getIdFromName(Currency_2, document.getElementById('sltCurrency')?.value),
                      // isActive: true
                    }
                  
                    savingAccountApi.enquiryPeriodic(params)
                    .then(response => {
                      console.log("response")
                      console.log(response.data.data)
                      let tempRow = []
                      response.data.data.map((item, index) => {
                        console.log("item")
                        console.log(item)
                        let tempObject = {
                          RefID: item.SAVINGACCOUNT?.Account,
                          Status: getNameFromId(TempData.Status, item.SAVINGACCOUNT?.Status),
                          CustomerID: item.SAVINGACCOUNT?.CUSTOMER?.RefID,
                          Category: getNameFromId(Category, item.Category),
                          CCY:  getNameFromId(Currency_2, item.Currency),
                          ProductLine: getNameFromId(ProductLine, item.ProductLine),
                          Principal: currencyFormat(item.PrincipalAmount, "VND"),
                          Button: <div> <button 
                                onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item.SAVINGACCOUNT?.id)
                                  setModalType("Periodic")
                                  setModalTitle("Periodic Saving Account - Information - RefID: " + item.SAVINGACCOUNT?.Account)
                                }}
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} 
                                type="button" className="btn" data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                            <button 
                                onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item.SAVINGACCOUNT?.id)
                                  setModalType("CloseArrearPeriodic")
                                  setModalTitle("Close Periodic Saving Account - Information - RefID: " + item.SAVINGACCOUNT?.Account)
                                }}
                                type="button" className="btn" 
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<DeleteIcon />}</button>
                          </div>
                          }
                        tempRow.push(tempObject)
                      })
                      handleSetRowState_1(tempRow)
                    })
                    .catch(error => {
                      console.log(error)
                    });




              }else if(enquiryType === 1){
                console.log("Discounted")
                let params = {
                  refID: document.getElementById("txtRefID_2").value || null,
                  status: getIdFromName(TempData.Status, document.getElementById('sltStatus_2')?.value) == 0 ? null : getIdFromName(TempData.Status, document.getElementById('sltStatus_2')?.value),
                  LDID: document.getElementById("txtLDID_2").value || null,
                  workingAccountID: document.getElementById("txtWorkingAcc_2").value || null,
                  workingAccountName: document.getElementById("txtWorkingAccName_2").value || null,
                  principalFrom: document.getElementById("txtPrincipalfr_2").value || null,
                  principalTo: document.getElementById("txtPrincipalto_2").value || null,
                  currency: getIdFromName(Currency_1, document.getElementById('sltCurrency_2')?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById('sltCurrency_2')?.value),
                }
                console.log("param")
                console.log(params)
                savingAccountApi.enquiryDiscounted(params)
                    .then(response => {
                      console.log("response")
                      console.log(response.data.data)
                      let tempRow = []
                      response.data.data.map((item, index) => {
                        let tempObject = {
                          RefID: item.SAVINGACCOUNT?.Account,
                          LDID: "LD/23060/34323",
                          Status: getNameFromId(TempData.Status, item.SAVINGACCOUNT?.Status),
                          WorkingAccount: item.WorkingAccountRefID,
                          Currency:  getNameFromId(Currency_1, item.Currency),
                          Principal: currencyFormat(item.AmountFCY ? item.AmountFCY : item.AmountLCY, "VND"),
                          Button: <div> <button 
                                onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item.SAVINGACCOUNT?.id)
                                  setModalType("Discounted")
                                  setModalTitle("Discounted Saving Account - Information - RefID: " + item.SAVINGACCOUNT?.Account)
                                
                                }}
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)"}} 
                                type="button" className="btn" data-toggle="modal" data-target="#exampleModalCenter">{<InfoIcon />}</button>
                              <button 
                                onClick={()=> {
                                  resetComponent()
                                  handleSetModel(item.SAVINGACCOUNT?.id)
                                  setModalType("CloseDiscounted")
                                  setModalTitle("Close Discounted Saving Account - Information - RefID: " + item.SAVINGACCOUNT?.Account)
                                }}
                                type="button" className="btn" 
                                style={{backgroundColor: "rgba(107, 107, 107, 0.7)", marginLeft: "5px"}} data-toggle="modal" data-target="#exampleModalCenter">{<DeleteIcon />}</button>
                            </div>
                        }
                        tempRow.push(tempObject)
                      })
                      handleSetRowState_2(tempRow)
                      
                    })

                    
                    .catch(error => {
                      console.log(error)
                    });


              }else {
                
              }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-search"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.9)", marginRight: "8px" }}><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
          Search
        </div>
        {/* Button: Print Deal Slip */}
        <div
          className="col-2 "
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-printer-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" /><path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /></svg>
          Print Deal Slip
        </div>
        {/* Button: Edit Data  */}
        <div
          className="col-2 "
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgba(107,107,107, 0.2)",height: "100%",lineHeight: "50px",}}
        >
          <svg xmlns="http://www.w3.org/2000/svg"width={16}height={16}fill="currentColor"className="bi bi-pencil-fill"viewBox="0 0 16 16"style={{ color: "rgba(107,107,107,.2)", marginRight: "8px" }}><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" /></svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item mr-3">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              id="enquiry_1Tab"
              href="#enquiry_1"
              data-toggle="pill"
              aria-controls="enquiry_1"
              role="tab"
              aria-selected="true"
              onClick={() => {
                setActiveTab("active")
                setEnquiryType(0)
              }}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Enquiry Arrear/Periodic Saving Account
            </a>
          </li>
          <li>
            <a
              className={`nav-link ${activeTab === "enquiry2" ? "active" : ""}`}
              data-toggle="pill"
              id="enquiry_2Tab"
              href="#enquiry_2"
              aria-controls="enquiry_2"
              role="tab"
              aria-selected="false"
              onClick={() => {
                setActiveTab("enquiry2")
                setEnquiryType(1)
              }}
              style={{
                backgroundColor: activeTab === "enquiry2" ? "#d71921" : "",
              }}
            >
              Enquiry Discounted Saving Account
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>
    
    <div class="tab-content" id="EnquiryContent">
      { activeTab == "active" && <div id="enquiry_1"
          className="tab-pane fade show active" role="tabpanel" aria-labelledby="enquiry_1Tab">
              {/* Fieldset 1 */}
            <FieldsetComponent label="">
              {/* row 1 */}
              <RowComponent>
                <InputField label="Type:" type="select" id="sltType" list={accountType.slice(0, 2)}
                defaultValue={"Arrear Saving Account"}/>
                <div className="col-1"></div>
                <InputField label="Status:" type="select" id="sltStatus" list={TempData.Status} />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                <InputField label="RefID:" type="text" id="txtRefID" />
                <div className="col-1"></div>
                <InputField label="Customer ID:" type="text" id="txtCustomerID"/>
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                <InputField
                  label="Category:"
                  type="select-depend"
                  id="sltCategory"
                  state={getCategory}
                  setState={setCategory}
                  list={Category.slice(3, 5)}
                />
                <div className="col-1"></div>
                <InputField
                  label="Product Line:"
                  type="select"
                  id="sltProductLine"
                  list={ProductLine.filter((instance) => {
                                    
                    let value = getIdFromName(Category, getCategory)
                    return instance.category == value
                })}
                />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                  label="Currency:"
                  type="select"
                  id="sltCurrency"
                  list={Currency_2}
                />
                <div className="col-1"></div>
              </RowComponent>
              <RowComponent>
                <InputField
                  label="Principal from:"
                  type="number-nohandle"
                  id="txtPrincipalfr"
                />
                <div className="col-1"></div>
                <InputField
                  label="Principal to:"
                  type="number-nohandle"
                  id="txtPrincipalto"
                />
              </RowComponent>
            </FieldsetComponent>

            <DataTable columnsTemp={columns_1} rowsTemp={rowState_1} id="EnquiryTable_1"/>
      </div>
    }

    { activeTab == "enquiry2" && <div id="enquiry_2"
          className="tab-pane fade show active" role="tabpanel" aria-labelledby="enquiry_2Tab">
              {/* Fieldset 1 */}
            <FieldsetComponent label="">
              {/* row 1 */}
              <RowComponent>
                <InputField label="RefID:" type="text" id="txtRefID_2" />
                <div className="col-1"></div>
                <InputField label="LD id:" type="text" id="txtLDID_2"  />
              </RowComponent>
              {/* row 1 */}
              <RowComponent>
                <InputField label="Working acc id:" type="text" id="txtWorkingAcc_2" />
                <div className="col-1"></div>
                <InputField label="Working Acc Name:" type="text" id="txtWorkingAccName_2"  />
              </RowComponent>
              {/* row 1 */}
              <RowComponent>
              <InputField
                  label="Currency:"
                  type="select"
                  id="sltCurrency_2"
                  list={Currency_2}
                />
                <div className="col-1"></div>
                <InputField label="Status:" type="select" id="sltStatus_2" list={TempData.Status} />
              </RowComponent>
              <RowComponent>
                <InputField
                  label="Principal from:"
                  type="number-nohandle"
                  id="txtPrincipalfr_2"
                />
                <div className="col-1"></div>
                <InputField
                  label="Principal to:"
                  type="number-nohandle"
                  id="txtPrincipalto_2"
                />
              </RowComponent>
            </FieldsetComponent>

            <DataTable columnsTemp={columns_2} rowsTemp={rowState_2} id="EnquiryTable_2"/>
      </div>
    }

       {/* Button trigger modal */}
  
  {/* Modal Arrear_SA Modal*/}
  <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
      <div className="modal-content"
            style={{
              overflow: 'auto',
              maxWidth: 'none',
              width: '1222px',
              // height: '1222px'
            }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">{modalTitle}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" 
            onClick={() => {
            if(!modalDisabled){
              setmodalDisabled(true)
              // setModalInstanceID(0)
            }
              
          }}>

            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body" style={{marginTop:'0', paddingTop: '0'}} key={key}>
            {modalType === "Arrear" && <Open_ArrearSA_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"update"}/>}
              {/* setInstanceState={setExistedAccountDB} instanceState={existedAccountDB}/> */}
            {modalType === "Periodic"&& <Open_PeriodicSA_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"update"}/>}
            {modalType === "Discounted"&& <Open_DiscountedSA_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"update"}/>}
            {modalType === "CloseArrearPeriodic"&& <Close_ArrearPeriodic_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"close"}/>}
            {modalType === "CloseDiscounted"&& <Close_Discounted_Modal modalInstanceID={modalInstanceID} disabledState={modalDisabled} setDisabledState={setmodalDisabled} id={"close"}/>}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
            if(!modalDisabled)
              setmodalDisabled(true)
              // setModalInstanceID(0)
            //  setExistedAccountDB(null)
          }} >Close</button>
          <button type="button" className="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
    </div>   

      
  </div>
   
    
  );
}

const accountType = [{
  "id": 1, "name": "Arrear Saving Account",
}, {"id": 2, "name": "Periodic Saving Account"},
{"id": 3, "name": "Discounted Saving Account"}]


const columns_1 = [
  {
    label: 'Ref ID',
    field: 'RefID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Status',
    field: 'Status',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Customer ID',
    field: 'CustomerID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Category',
    field: 'Category',
    sort: 'asc',
    width: 150
  },
  {
    label: 'CCY',
    field: 'CCY',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Product Line',
    field: 'ProductLine',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Principal',
    field: 'Principal',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Button',
    field: 'Button',
    sort: 'asc',
    width: 150,
    // cell: 
      
  },
]


const columns_2 = [
  {
    label: 'Ref ID',
    field: 'RefID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'LD ID',
    field: 'LDID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Status',
    field: 'Status',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Working Account',
    field: 'WorkingAccount',
    sort: 'asc',
    width: 400
  },
  {
    label: 'Currency',
    field: 'Currency',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Principal',
    field: 'Principal',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Button',
    field: 'Button',
    sort: 'asc',
    width: 150,
    // cell: 
      
  },
]
const rows =  [
  {
    name: 'Tiger Nixon',
    position: 'System Architect',
    office: 'Edinburgh',
    age: '61',
    date: '2011/04/25',
    salary: '$320'
  },
  
]


function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}

function getNameFromId(list, id) {
  const obj = list.find(obj => obj.id === id);
  return obj ? obj.name : null;
}


function currencyFormat(value, currency){
  let resultValue = value ? value.toLocaleString('it-IT', {style : 'currency', currency : currency}) : "";
  console.log(resultValue.slice(0, -4))
  return resultValue.slice(0, -4)
}