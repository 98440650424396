import axios from 'axios';

const chargeCollectionApi = {
  byCash: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/charge/create_cccash`, params,{ mode: 'no-cors' });
  },

  fromAccount: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/charge/create_ccaccount`, params, { mode: 'no-cors' });
  },

  enquiry: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/charge/enquiry`, params, { mode: 'no-cors' });
  },
};

export default chargeCollectionApi;