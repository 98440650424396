import React from 'react'
import DataTable01 from '../../../components/DataTable01/DataTable01'
import DatatablePage from '../../../components/DataTable/DataTable'

export default function Capture_SM() {
  return (
    <div>


      <DatatablePage />

      {/* <DataTable01 /> */}

    </div>
  )
}
 