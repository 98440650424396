import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './style.css';

const DataTable = ({ columnsTemp, rowsTemp }) => {
  const data = {
    columns: columnsTemp,
    rows: rowsTemp
  };

  return (
    <MDBDataTable
    
      striped
      bordered
      small
      data={data}
      scrollX
    />
  );
}


export default DataTable;