import React from 'react'
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent'
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';

export default function CollectionForCredit_CP(props) {
  return (
    <div>
      <hr></hr>
      {/* Fieldset 1 */}
      <FieldsetComponent>
        {/* row 1 */}
        <RowComponent>
          <InputField
            label="Account Type:"
            type="text"
            id={`sltAccountType_` + props.id}
            // list={AccountType}
            required={true}
          />
          <div className="col-1"></div>
          <InputField
            label="Customer Account:"
            type="text"
            // id="txtCustomerAccount"
            id={`txtCustomerAccount_` + props.id}
            required={true}
          />
        </RowComponent>
      </FieldsetComponent>
    </div>
  );
}
