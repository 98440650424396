/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
// import { Form } from 'react-bootstrap';
import dataSelect from "../../../data/select.json";
// import "react-datepicker/dist/react-datepicker.css";
import CityList from "../../../data/CityList";
import CountryList from "../../../data/CountryList";
import DocTypeList from "../../../data/DocTypeList";

import OpenIndividualCustomer_Update from "./OpenIndividualCustomer_Update"

import "./CustomerManagement.css";
import FieldsetComponent from "../../../components/FieldsetComponent/FieldsetComponent";
import RowComponent from "../../../components/RowComponent/RowComponent";
import InputField from "../../../components/InputField/InputField";
import DataTable from "../../../components/DataTable/DataTable";
import cusApi from "../../../apis/cusApi";
import Sector from "../../../data/Sector";
import SubSector from "../../../data/SubSector";
import SubIndustry from "../../../data/SubIndustry";
import Industry from "../../../data/Industry";
import AuthorizeIcon from "../../../components/ActionBar/AuthorizeIcon";
import CommitDataIcon from "../../../components/ActionBar/CommitDataIcon";
import SearchIcon from "../../../components/ActionBar/SearchIcon";
import PrintDealSlipIcon from "../../../components/ActionBar/PrintDealSlipIcon";
import EditDataIcon from "../../../components/ActionBar/EditDataIcon";
// import DataTable from "cdbreact/dist/components/DataTable";

export default function EnquiryCustomer() {

    // 4. Select State
    const [getSector, setSector] = useState(0);
    const [getIndustry, setIndustry] = useState(0);

    // 5. key
    const [key, setKey] = useState(0);
    const resetComponent = () => {
      setKey(prevKey => prevKey + 1);
    };

  const [childID, setChildID] = useState(null);
  const handleSetChildID = (item) => {
    setChildID(item);
  };

  const [activeTab, setActiveTab] = useState("active");
  const [rowState, setRowState] = useState([]);
  const handleSetRowState = (row) => {
    setRowState(row);
  };
  const Cell = ({ row }) => (
      <button className="btn btn-sm btn-primary me-2">Edit</button>

  );

  return (
    <div>

      {/* button action  */}
      <div
        className="row align-items-center text-center mb-3 noCopy"
        style={{ height: "50px", backgroundColor: "#eceff4", margin: "3px", position:"sticky", top: "48.2px", zIndex:"10", marginTop:"0", marginLeft:"0", marginRight:"0"}}
      >
        {/* Button: Commit Data */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          onClick={() => {}}
        >
          <CommitDataIcon />
          <div style={{ display: "inline" }}> Commit Data </div>
        </div>
        {/* Button: Authorize */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          // on Click
          onClick={() => {}}
        >
          <AuthorizeIcon />
          Authorize
        </div>
        {/* Button: Search */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
          onClick={ ()=> {
              let tempCustomerID;
              if (document.getElementById('sltCustomerType').value === 'P - Person') tempCustomerID = 1;
              else if (document.getElementById('sltCustomerType').value === 'C - Corporate') tempCustomerID = 2;
              else tempCustomerID = null;
              let params = {
                customerType: tempCustomerID,
                customerID: document.getElementById('txtCustomerID').value || null,
                GB_FullName: document.getElementById('txtGBFullName').value || null,
                phoneNumber: document.getElementById('txtPhone').value || null,
                docID: document.getElementById('txtDocID').value || null,
                mainSector: getIdFromName(Sector, document.getElementById('sltMainSector')?.value) == 0 ? null : getIdFromName(Sector, document.getElementById('sltMainSector')?.value),
                subSector: getIdFromName(SubSector, document.getElementById('sltSubSector')?.value) == 0 ? null : getIdFromName(SubSector, document.getElementById('sltSubSector')?.value),
                mainIndustry: getIdFromName(Industry, document.getElementById('sltMainIndustry')?.value) == 0 ? null : getIdFromName(Industry, document.getElementById('sltMainIndustry')?.value),
                subIndustry: getIdFromName(SubIndustry, document.getElementById('sltSubIndustry')?.value) == 0 ? null : getIdFromName(SubIndustry, document.getElementById('sltSubIndustry')?.value),
              }
              cusApi.enquiry(params)
              .then(response => {
                let tempRow = []
                response.data.data.map((item, index) => {
                  let tempObject = {
                    CustomerID: item.RefID,
                    CustomerType: TypeCustomer[item.CustomerType-1].name,
                    GBFullName: item.GB_FullName,
                    DocID: item.DocID,
                    Phone: item.PhoneNumber,
                    viewedit: <button 
                      onClick={()=> {
                        // clearAll()
                        resetComponent()
                        handleSetChildID(item.id)
                      }}
                    type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">O</button>
                  }
                  tempRow.push(tempObject)
                })
                handleSetRowState(tempRow)
              })
              .catch(error => {
                console.log(error)
              });
          }}
        >
          <SearchIcon />
          Search
        </div>
        {/* Button: Print Deal Slip */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
        >
          <PrintDealSlipIcon />
          Print Deal Slip
        </div>
        {/* Button: Edit Data  */}
        <div
          className="col-2 buttonAction"
          style={{borderRight: "solid 1px rgba(0,0,0,.2)",fontWeight: "",color: "rgb(107,107,107)",height: "100%",lineHeight: "50px",}}
        >
          <EditDataIcon />
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Enquiry Customer
            </a>
          </li>
        </ul>
      </div>

      <hr></hr>

      {/* Fieldset 1 */}
      <FieldsetComponent label="">
        {/* row 1 */}
        <RowComponent>
          <InputField label="Customer Type:" type="select" id="sltCustomerType" list={TypeCustomer}/>
          <div className="col-1"></div>
        </RowComponent>
        {/* row 2 */}
        <RowComponent>
          <InputField label="Customer ID:" type="text" id="txtCustomerID" />
          <div className="col-1"></div>
          <InputField label="Phone:" type="text" id="txtPhone" ph="-- Enter Phone Number --"/>
        </RowComponent>
        {/* row 3 */}
        <RowComponent>
          <InputField
            label="GB Full Name:"
            type="text"
            id="txtGBFullName"
          />
          <div className="col-1"></div>
          <InputField
            label="Doc ID:"
            type="text"
            id="txtDocID"
          />
        </RowComponent>
        {/* row 4 */}
        <RowComponent>
          <InputField
            label="Main Sector:"
            type="select-depend"
            id="sltMainSector"
            list={Sector}
            state={getSector}
            setState={setSector}
          />
          <div className="col-1"></div>
          <InputField
            label="Sub Sector:"
            type="select"
            id="sltSubSector"
            list={SubSector.filter((option) => {
              let value = getIdFromName(Sector, getSector)
              return option.sector == value
            })}
          />
        </RowComponent>
        {/* row 5 */}
        <RowComponent>
          <InputField
            label="Main Industry:"
            type="select-depend"
            id="sltMainIndustry"
            list={Industry}
            state={getIndustry}
            setState={setIndustry}
          />
          <div className="col-1"></div>
          <InputField
            label="Sub Industry:"
            type="select"
            id="sltSubIndustry"
            list={SubIndustry.filter((option) => {
              let value = getIdFromName(Industry, getIndustry)
              return option.industry == value
            })}
          />
        </RowComponent>
      </FieldsetComponent>

      <FieldsetComponent>
      <DataTable columnsTemp={columns} rowsTemp={rowState}/>
      </FieldsetComponent>


<div>
  {/* Button trigger modal */}
  
  {/* Modal */}
  <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
      <div className="modal-content"
            style={{
              overflow: 'auto',
              maxWidth: 'none',
              width: '1222px',
              // height: '1222px'
            }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">Individual Customer - Information</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body" style={{marginTop:'0', paddingTop: '0'}} key={key}>
            <OpenIndividualCustomer_Update childID={childID} />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" >Close</button>
          <button type="button" className="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>


</div>









    </div>
    
  );
}


const columns = [
  {
    label: 'Customer ID',
    field: 'CustomerID',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Customer Type',
    field: 'CustomerType',
    sort: 'asc',
    width: 270
  },
  {
    label: 'GB Full Name',
    field: 'GBFullName',
    sort: 'asc',
    width: 200
  },
  {
    label: 'Doc ID',
    field: 'DocID',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Phone',
    field: 'Phone',
    sort: 'asc',
    width: 150
  },
  {
    label: 'View/Edit',
    field: 'viewedit',
    sort: 'asc',
    width: 150,
    // cell: 
      
  },
]
const rows =  [
  {
    name: 'Tiger Nixon',
    position: 'System Architect',
    office: 'Edinburgh',
    age: '61',
    date: '2011/04/25',
    salary: '$320'
  },
  
]

const TypeCustomer = [{id:"1",name:"P - Person"},{id:"2",name:"C - Corporate"}]

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}


function getNameFromID(list, idTemp) {
  const obj = list.find(obj => obj.id === idTemp);
  return obj ? obj.name : null;
}


function clearAll() {
  document.getElementById('txtFirstName_Update').value = ""
  document.getElementById('txtLastName_Update').value = ""
  document.getElementById('txtMiddleName_Update').value = ""
  document.getElementById('txtGBShortName_Update').value = ""
  document.getElementById('txtGBFullName_Update').value = ""

}