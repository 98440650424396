import React, { useState, useEffect } from 'react'
import { Button, Modal, Overlay, Popover } from 'react-bootstrap';
import cusApi from '../../../../apis/cusApi';
import mainAccountApi from '../../../../apis/mainAccountApi';
import FieldsetComponent from '../../../../components/FieldsetComponent/FieldsetComponent';
import InputField from '../../../../components/InputField/InputField';
import RowComponent from '../../../../components/RowComponent/RowComponent';
import AccountOfficers from '../../../../data/AccountOfficer';
import Category from '../../../../data/Category';
import ChargeCode from '../../../../data/ChargeCode';
import CountryList from '../../../../data/CountryList';
import Currency_1 from '../../../../data/Currency_1';
import ProductLine from '../../../../data/ProductLine';
import RelationCode from '../../../../data/RelationCode';
export default function OpenAccount_MA() {


    // 1. State check required
    const [arrRe, setArrRe] = useState([])
    const handleSetArrRe = (item) => {setArrRe(item);};
    // 2. State show Error
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("error");
    const handleCloseErrorModal = () => setShowErrorModal(false);
    const handleShowErrorModal = () => setShowErrorModal(true);
    // 3. State show Success
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
      // 5. State show Backend Error
      const [showBEError, setShowBEError] = useState(false);
      const handleCloseBEErrorModal = () => setShowBEError(false);
      const handleShowBEErrorModal = () => setShowBEError(true);

    
    // State response
    const [refIDRes, setRefIDRes] = useState(""); 

    const [activeTab, setActiveTab] = useState("active");

      // 4. Select State
    const [getCategory, setCategory] = useState(0);
    useEffect(() => {
      clearDependentValue("sltProductLine")

    }, [getCategory]);

    // GET CUSTOMER LIST
    const [customerList, getCustomerList] = useState([])
    useEffect(() => {
      async function fetchCustomerList() {
        try {
          const response = await cusApi.getAll()
          const customerListDB = response.data.data.customer
          getCustomerList(customerListDB)
        } catch (error) {

        }
      }
  
      fetchCustomerList();
    }, []);
    let customerFormatList = [{"id": 0, "name": ""}]
    if(customerList.length != 0){
      customerList.map((value, index) => {
        let temp = {"id": value.customer?.id, "name": value.customer?.RefID + " - " + value.customer?.GB_FullName}
        customerFormatList.push(temp)
      })
    }

    
  return (
    <div>
      <div
      className="row align-items-center text-center mb-3"
      style={{
        height: "50px",
        backgroundColor: "#eceff4",
        margin: "3px",
        position: 'sticky',
        top: '48.2px',
        zIndex: '10',
        marginTop: '0',
        marginLeft: '0',
        marginRight: '0'
      }}
      >
        <div
          className="col-2 buttonAction noCopy"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgb(107,107,107)",
            height: "100%",
            lineHeight: "50px",
          }}
          onClick={ async (event)=> {
            checkRequired();
            if (arrRequired.length > 0) {
              setShowErrorModal(true);
              setArrRe(arrRequired);
            } else {
              const params = {
                customerID: getIdFromName(customerFormatList, document.getElementById('sltCustomerID')?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltCustomerID')?.value),
                category:  getIdFromName(Category, document.getElementById('sltCategory')?.value) == 0 ? null : getIdFromName(Category, document.getElementById('sltCategory')?.value),
                productLine: getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value) == 0 ? null : getIdFromName(ProductLine, document.getElementById('sltProductLine')?.value),
                currency:  getIdFromName(Currency_1, document.getElementById('sltCurrency')?.value) == 0 ? null : getIdFromName(Currency_1, document.getElementById('sltCurrency')?.value),
                accountTitle:  document.getElementById('txtAccountTitle').value || "",
                shortTitle:  document.getElementById('txtShortTitle').value || "",
                accountOfficer:  getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer')?.value) == 0 ? null : getIdFromName(AccountOfficers, document.getElementById('sltAccountOfficer')?.value),
                chargeCode:  getIdFromName(ChargeCode, document.getElementById('sltChargeCode')?.value) == 0 ? null : getIdFromName(ChargeCode, document.getElementById('sltChargeCode')?.value),
                joinHolder:  getIdFromName(customerFormatList, document.getElementById('sltJoinHolder')?.value) == 0 ? null : getIdFromName(customerFormatList, document.getElementById('sltJoinHolder')?.value),
                relationCode:getIdFromName(RelationCode, document.getElementById('sltRelationCode')?.value) == 0 ? null : getIdFromName(RelationCode, document.getElementById('sltRelationCode')?.value),
                joinNotes:  document.getElementById('txtJoinNotes').value || ""
    
            };

            mainAccountApi.openAccount(params)
              .then(response => {
                setRefIDRes(response.data.data.Account)
                setShowSuccessModal(true);
              })
              .catch(error => {
                setErrorMessage(error.response.data.message)
                console.log(errorMessage)
                // errorMessage = error.Data
                handleShowBEErrorModal();
              });
              
              // recreateInput();
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-save-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.9)",
              marginRight: "8px",
            }}
          >
            <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z" />
          </svg>
          <div
            style={{
              display: "inline",
            }}
          >
            {" "}
            Commit Data{" "}
          </div>
        </div>
        <div
          className="col-2 noCopy"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
            }}
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          Authorize
        </div>
        <div
          className="col-2 noCopy"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
            }}
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          Search
        </div>
        <div
          className="col-2 noCopy"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-printer-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
            }}
          >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg>
          Print Deal Slip
        </div>

        <div
          className="col-2 noCopy"
          style={{
            borderRight: "solid 1px rgba(0,0,0,.2)",
            fontWeight: "",
            color: "rgba(107,107,107, 0.2)",
            height: "100%",
            lineHeight: "50px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className="bi bi-pencil-fill"
            viewBox="0 0 16 16"
            style={{
              color: "rgba(107,107,107,.2)",
              marginRight: "8px",
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          Edit data
        </div>
      </div>

      <hr></hr>

      <div
        className="row noCopy"
        style={{
          margin: "20px 5px",
        }}
      >
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "active" ? "active" : ""}`}
              aria-current="page"
              href="#"
              onClick={() => setActiveTab("active")}
              style={{
                backgroundColor: activeTab === "active" ? "#d71921" : "",
              }}
            >
              Open Account
            </a>
            
          </li>
        </ul>
      </div>

      <hr></hr>

      <div>
        <nav>
          <div className="nav nav-tabs nav-fill col-6" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-customerInfo-tab"
              data-toggle="tab"
              href="#customer-info"
              role="tab"
              aria-controls="customer-info"
              aria-selected="true"
            >
              Account Info
            </a>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
            <div
                className="tab-pane fade show active"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
            >
                {/* Fieldset 1 */}
            <FieldsetComponent label="Account Information">
              {/* row 1 */}
              <RowComponent>
                  <InputField
                          label="Customer ID:"
                          type="select"
                          id="sltCustomerID"
                          list={customerFormatList}
                          required={true}
                          popover={true}
                      />
                    <div className="col-1"></div>
                    <InputField
                        label="Currency:"
                        type="select"
                        id="sltCurrency"
                        list={Currency_1}
                        required={true}
                    />
              </RowComponent>
              {/* row 2 */}
              <RowComponent>
                    <InputField
                        label="Category:"
                        type="select-depend"
                        id="sltCategory"
                        list={Category.slice(1, 3)}
                        required={true}
                        state={getCategory}
                        setState={setCategory}
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Product Line:"
                        type="select"
                        id="sltProductLine"
                        list={ProductLine.filter((instance) => {
                              
                            let value = getIdFromName(Category, getCategory)
                            return instance.category == value
                        })}
                    />
              </RowComponent>
              {/* row 3 */}
              <RowComponent>
                    <InputField
                        label="Account Title:"
                        type="text"
                        id="txtAccountTitle"
                    />
                    <div className="col-1"></div>
                    <InputField
                        label="Short Title:"
                        type="text"
                        id="txtShortTitle"
                    />
              </RowComponent>
              {/* row 4 */}
              <RowComponent>
                <InputField
                    label="Account Officer:"
                    type="select"
                    id="sltAccountOfficer"
                    list={AccountOfficers}
                    />
                    <div className="col-1"></div>
                    <InputField
                    label="Charge.Code:"
                    type="select"
                    id="sltChargeCode"
                    list={ChargeCode}
                    />
              </RowComponent>
            </FieldsetComponent>

              {/* Fieldset 2 */}
            <FieldsetComponent label="Join Holder">
                {/* row 1 */}
              <RowComponent>
                <InputField
                        label="ID Join Holder:"
                        type="select"
                        id="sltJoinHolder"
                        list={customerFormatList}
                    />
                    <div className="col-1"></div>
                    <InputField
                    label="Relation Code"
                    type="select"
                    id="sltRelationCode"
                    list={RelationCode}
                  />
              </RowComponent>
               {/* row 2 */}
               <RowComponent>
                <InputField
                        label="Join Notes:"
                        type="text"
                        id="txtJoinNotes"
                    />
                    <div className="col-1"></div>
              </RowComponent>
            </FieldsetComponent>

            </div>
        </div>
    </div>


     {/* --------------------------------------- */}
      {/* <Backend Error Model /> */}
      <div>
        <Modal show={showBEError} onHide={handleCloseBEErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Request failed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <b>{errorMessage}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBEErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* <ErrorModal /> */}
      <div>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: '#d71921', fontSize:'18px' }}>ERROR: Required fields missing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checkRequired()}
            {arrRe.map((item, index) => {
              return <>
                <div>- {item}</div></>})}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseErrorModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* --------------------------------------- */}
      {/* Success Modal  */}
      <div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: 'green', fontSize:'18px' }}>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <b>Main Account</b> opening was successful! <br />
          - Customer: <b>{document.getElementById('sltCustomerID')?.value}</b> <br />
          - RefID: <b>{refIDRes}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{ backgroundColor: 'green',}}onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
} 


//  if(document.getElementById("sltIndustry")?.value.length != 0 && document.getElementById("sltMainIndustry")?.value.length != 0 && obj2.industry != getIdFromName(Industry, document.getElementById("sltMainIndustry").value)){
//   arrRequired.push("SubIndustry is not belonged to Industry")
// }

function getIdFromName(list, nameTemp) {
  const obj = list.find(obj => obj.name === nameTemp);
  return obj ? obj.id : null;
}


function clearDependentValue(id){
  document.getElementById(id).value = null
}


let arrRequired = []
function checkRequired() {
  arrRequired = []
  let arr = [
    {type: "select",id: "sltCustomerID",name: 'Customer ID'},
    {type: "select",id: "sltCurrency",name: 'Currency'},
    {type: "select",id: "sltCategory",name: 'Category'}
  ];
  arr.map((item, index) => {
    if (document.getElementById(item.id)?.value.length == 0)
      arrRequired.push(item.name)
  })
  // check dependent value
  let categoryID_ = document.getElementById("sltProductLine")?.value
  let obj = ProductLine.find(obj => obj.name === categoryID_)
  if(document.getElementById("sltProductLine")?.value.length != 0 && document.getElementById("sltCategory")?.value.length != 0 && obj?.category != getIdFromName(Category, document.getElementById("sltCategory")?.value)){
    arrRequired.push("Product Line is not belonged to Category")
  }
  if (arrRequired.length > 0) return true; else return false;
}