import axios from 'axios';

const mainAccountApi = {
  openAccount: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/debit_account/open`, params);
  },
  getAccount: (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/debit_account/get/` + id);
  },
  blockAccount: (params, id) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/debit_account/block/` + id, params);
  },
  getBlockAccount: (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/debit_account/get_blockage/` + id);
  },
  unBlockAccount: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/debit_account/unblock/` + id, params);
  },
  closeAccount: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/debit_account/close/` + id, params);
  },
  enquiry: (params) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/account/debit_account/enquiry`, params);
  },
  validate: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/debit_account/validate/` + id, params);
  },
  update: (id, params) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/account/debit_account/update/` + id, params);
  },
  getCloseAccount:(id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/debit_account/get_closure/` + id);
  },
  export:(id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/export/main_account/` + id);
  },

  getAll:() => {
    return axios.get(`${process.env.REACT_APP_API_URL}/account/debit_account/get_all` );
  }
};

export default mainAccountApi;